const NETWORK_CLASSES = {
  Unknown: 'network-unknown',
  'In Network': 'network-in',
  OON: 'network-out',
};

const ICON_CLASSES = {
  generic: 'icon-specialist',
  consultant: 'icon-specialist',
  specialist: 'icon-specialist',
  lab_provider: 'icon-practice',
  practice: 'icon-practice',
};

const RATINGS = ['availability', 'expertise', 'personality'];

class ConsultantController {
  constructor($scope, $timeout, ENV, ConsultantNetworkService, FeaturesService, SpecialtiesService) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.ENV = ENV;
    this.ConsultantNetworkService = ConsultantNetworkService;
    this.FeaturesService = FeaturesService;
    this.SpecialtiesService = SpecialtiesService;
  }

  $onInit() {
    this.popoverIsVisible = false;
    this.hoveringConsultant = false;
    this.hoveringPopover = false;
    this.editable = true;
    this.setRatings();
    this.inactiveClass = this.contact.is_active ? '' : 'consultant-inactive';
    this.networkStatus = this.getInsuranceNetworkStatus();
    this.networkClass = NETWORK_CLASSES[this.networkStatus] || 'network-unknown';
    this.iconClass = this.contact.is_internal ? 'om-atom-jade' : (ICON_CLASSES[this.contact.kind] || 'icon-specialist');
    this.specialtyNames = this.getSpecialtyNames();
    this.contactUrl = `${this.ENV.adminApp.host}/contact/${this.contact.id}`;
    this.SpecialtiesService.index();
    this.specialtyDropdownFeatureFlagEnabled = false;
    this.FeaturesService.hasFeature('specialty_dropdown_for_consult_orders').then(
      isEnabled => {
        this.specialtyDropdownFeatureFlagEnabled = isEnabled;
        this.specialtySearchVisible = this.specialtySearchVisible();
      }
    );
  }

  getInsuranceNetworkStatus() {
    return this.ConsultantNetworkService.getInsuranceNetworkStatus(this.patientPrimaryInsurance, this.contact);
  }

  setRatings() {
    this.greatRatings = RATINGS.filter(rating => this.contact[rating] > 1);
    this.problematicRatings = RATINGS.filter(
      rating => this.contact[rating] < 0,
    );
  }

  showPopover() {
    this.hoveringConsultant = true;
    if (this.popoverIsVisible) {
      return;
    }
    this.popoverIsVisible = true;
    this.$timeout(() => {
      this.enterPopoverHandler();
      this.leavePopoverHandler();
    }, 100);
  }

  enterPopoverHandler() {
    document.getElementsByClassName('consultant-popover')[0] &&
      document
        .getElementsByClassName('consultant-popover')[0]
        .addEventListener('mouseover', () => {
          this.hoveringPopover = true;
        });
  }

  leavePopoverHandler() {
    document.getElementsByClassName('consultant-popover')[0] &&
      document
        .getElementsByClassName('consultant-popover')[0]
        .addEventListener('mouseleave', () => {
          this.$timeout(() => {
            this.hoveringPopover = false;
            if (!this.hoveringConsultant) {
              this.popoverIsVisible = false;
            }
          }, 0);
        });
  }

  hidePopover() {
    this.$timeout(() => {
      this.hoveringConsultant = false;
      if (!this.hoveringPopover) {
        this.popoverIsVisible = false;
      }
    }, 0);
  }

  getSpecialtyNames() {
    let specialties = [];
    if (this.contact && this.contact.specialties) {
      specialties = this.contact.specialties;
    }
    return specialties.map(specialty => specialty.name).join(', ');
  }

  specialtySearchVisible() {
    // canSelect is passed in from consultant-search.component.html
    return this.specialtyDropdownFeatureFlagEnabled && !this.canSelect && this.order.isConsultOrder;
  }

  changeConsultant() {
    this.$scope.$emit('omConsultant-change');
  }

  selectConsultant() {
    this.$scope.$emit('omConsultant-select', this.contact);
  }

  specialties() {
    return this.SpecialtiesService.allSpecialties;
  }

  onSpecialtySelect() {
    this.$scope.$emit('omConsultant-specialty-select', this.order);
  }
}

ConsultantController.$inject = ['$scope', '$timeout', 'ENV', 'ConsultantNetworkService', 'FeaturesService', 'SpecialtiesService'];

export const omConsultant = {
  bindings: {
    contact: '<',
    canChange: '<',
    canSelect: '<',
    order: '<',
    patientPrimaryInsurance: '<',
  },
  templateUrl: 'orders/shared/consultant.component.html',
  controller: ConsultantController,
};

class TemplateInsertionButtonController {
  constructor(focus) {
    this.focus = focus;
  }

  $onInit() {
    this.actionBarExpandedName = 'templateInsertionActionBar';
  }

  startTemplateInsertion() {
    this.actionBarCollapse.expand(this.actionBarExpandedName);
    this.focus('templateInsertion-search');
  }
}

TemplateInsertionButtonController.$inject = ['focus'];

export const omTemplateInsertionButton = {
  bindings: {
    actionBarCollapse: '<',
  },
  controller: TemplateInsertionButtonController,
  template: `
    <button class="om-button clickable om-icon icon-templates"
            ng-click="$ctrl.startTemplateInsertion()"
            om-shortcut-click="ctrl+.">
    </button>
  `,
};

export class MedicationService {
  PATIENT_MEDICATION_ENDPOINT = '/v2/admin/patient_medications/';
  MEDICATION_REGIMEN_ENDPOINT = '/v2/admin/medication_regimens/';
  PMR_ENDPOINT = '/v2/admin/patient_medication_regimens/';

  constructor(ApiService, Medication, LoggerService) {
    this.ApiService = ApiService;
    this.Medication = Medication;
    this.LoggerService = LoggerService;
  }
  update(medication) {
    const request = this.ApiService.update(
      `${this.PATIENT_MEDICATION_ENDPOINT}${medication.id}`,
      medication,
    );

    request.then(
      response => {
        medication = { ...medication, ...new this.Medication(response) };
      },
      error => this.LoggerService.log('MedicationService', error),
    );

    return request;
  }

  delete(medication) {
    medication.delete();
    return this.update(medication);
  }
  resume(medication) {
    medication.resume();
    return this.update(medication);
  }
  discontinue(medication) {
    medication.discontinue();
    return this.update(medication);
  }
  createWithAssociations(medication) {
    const request = this.ApiService.save(
      `${this.PATIENT_MEDICATION_ENDPOINT}create_with_associations`,
      medication,
    );

    request.then(
      response => {
        medication = { ...medication, ...new this.Medication(response) };
      },
      error =>
        this.LoggerService.log(
          'MedicationService.createWithAssociations',
          error,
        ),
    );

    return request;
  }
  createRegimen(regimen) {
    const request = this.ApiService.save(
      this.MEDICATION_REGIMEN_ENDPOINT,
      regimen,
    );

    request.catch(error =>
      this.LoggerService.log('MedicationService.createRegimen', error),
    );

    return request;
  }
  changeRegimen(regimen) {
    const request = this.ApiService.save(this.PMR_ENDPOINT, regimen);

    request.catch(error =>
      this.LoggerService.log('MedicationService.changeRegimen', error),
    );

    return request;
  }
  regimenHistory(medication) {
    const requestUrl = `${this.PATIENT_MEDICATION_ENDPOINT}${
      medication.id
    }/patient_medication_regimens`;

    const request = this.ApiService.get(requestUrl);

    request.catch(error =>
      this.LoggerService.log('MedicationService.regimenHistory', error),
    );
    return request;
  }
  prescriptionHistory(medicationId) {
    const requestUrl = `${
      this.PATIENT_MEDICATION_ENDPOINT
    }${medicationId}/rx_history`;
    const request = this.ApiService.get(requestUrl);

    request.catch(error =>
      this.LoggerService.log('MedicationService.prescriptionHistory', error),
    );
    return request;
  }
}

MedicationService.$inject = ['ApiService', 'Medication', 'LoggerService'];

const preferencesPath = '/v2/admin/internal_user_preferences';

export class InternalUserPreferencesService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  get() {
    return this.ApiService.get(preferencesPath);
  }
}

InternalUserPreferencesService.$inject = ['ApiService'];

class RecategorizeDocumentActionBarController {
  constructor({ search }) {
    this.index = search.indexes.filing_templates;
  }

  $onInit() {
    this.actionBarExpandedName = 'recategorizeDocumentActionBar';
  }

  recategorizeDocument() {
    const template = this.template;
    this.onRecategorize({ template });
    this.actionBarCollapse.collapse();
  }

  cancel() {
    this.template = null;
    this.actionBarCollapse.collapse();
  }
}

RecategorizeDocumentActionBarController.$inject = ['ENV'];

export const omRecategorizeDocumentActionBar = {
  bindings: {
    actionBarCollapse: '<',
    onRecategorize: '&',
    profile: '<',
  },
  controller: RecategorizeDocumentActionBarController,
  templateUrl: 'notes/recategorize-document-action-bar.component.html',
};

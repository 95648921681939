class PaginationController {
  $onInit() {
    this.numPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  previousPage() {
    this.currentPage -= 1;
    this.goToPage({ page: this.currentPage });
  }

  nextPage() {
    this.currentPage += 1;
    this.goToPage({ page: this.currentPage });
  }
}

export const omPagination = {
  bindings: {
    currentPage: '<',
    itemsPerPage: '<',
    totalItems: '<',
    goToPage: '&',
  },
  controller: PaginationController,
  template: `<div class="caption"
                  ng-if="$ctrl.numPages > 1"
                  om-layout
                  om-layout-align="space-between center"
                  om-layout-gutter>
               <span>Page {{$ctrl.currentPage}} of {{$ctrl.numPages}}</span>
               <button ng-if="$ctrl.currentPage !== 1"
                       class="om-button -flat-icon"
                       ng-click="$ctrl.previousPage()">
                 <span class="om-icon clickable icon-page-left"></span>
               </button>
               <button class="om-button -flat-icon"
                       ng-if="$ctrl.currentPage < $ctrl.numPages"
                       ng-click="$ctrl.nextPage()">
                 <span class="om-icon clickable icon-page-right"></span>
               </button>
             </div>`,
};

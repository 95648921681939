const SURVEYS_ENDPOINT = '/v2/surveys';

export class SurveyService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getAll() {
    return this.ApiService.get(SURVEYS_ENDPOINT);
  }
}

SurveyService.$inject = ['ApiService'];

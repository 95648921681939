class RichTextEditorController {
  constructor($scope, $timeout, ShortcutClick) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.ShortcutClick = ShortcutClick;
  }

  $onInit() {
    this.$scope.$on(this.focusOn, this.focus.bind(this));
    this.$scope.$on('omRichTextEditor-insertHTML', this.insertHTML.bind(this));
    this.registerShortcutClick();

    if (this.useMarkdownStyling === undefined) {
      this.useMarkdownStyling = true;
    }
  }

  focus() {
    if (this.editor) {
      this.editor.focus();
    } else {
      this.$timeout(() => this.focus());
    }
  }

  insertHTML(event, html) {
    const selection = this.editor.getSelection(true);
    const cursorPosition = selection.index;
    if (selection.length > 0) {
      this.editor.deleteText(cursorPosition, selection.length);
    }
    this.editor.setSelection(cursorPosition + 1, 0);
    this.editor.clipboard.dangerouslyPasteHTML(cursorPosition, html);
  }

  setEditor(editor) {
    this.editor = editor;
  }

  registerShortcutClick() {
    if (!this.triggerShortcutClickOn) {
      return;
    }

    if (this.editor) {
      const bindingProperties = this.ShortcutClick.parseStringToEventProperties(
        this.triggerShortcutClickOn,
      );
      bindingProperties.key = bindingProperties.which;
      delete bindingProperties.which;
      this.editor.keyboard.addBinding(
        bindingProperties,
        this.triggerShortcutClick.bind(this),
      );
    } else {
      this.$timeout(() => this.registerShortcutClick());
    }
  }

  triggerShortcutClick() {
    this.ShortcutClick.trigger(this.triggerShortcutClickOn);
  }
}
RichTextEditorController.$inject = ['$scope', '$timeout', 'ShortcutClick'];

export const omRichTextEditor = {
  bindings: {
    draftText: '=',
    focusOn: '@',
    triggerShortcutClickOn: '@',
    placeholderText: '<',
    hideLink: '<',
    useMarkdownStyling: '<',
  },
  controller: RichTextEditorController,
  templateUrl: 'shared/rich-text-editor.component.html',
};

const templatesPath = '/v2/admin/message_templates';

export class MessageTemplatesService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  get(id) {
    return this.ApiService.get(`${templatesPath}/${id}`, {
      params: { patient_id: this.$stateParams.patientId },
    });
  }
}

MessageTemplatesService.$inject = ['ApiService', '$stateParams'];

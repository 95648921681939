export const omTitle = () => ({
  restrict: 'E',
  require: '^^omCardHeader',
  transclude: true,
  replace: true,
  scope: {
    heading: '@',
  },
  template: `
    <div class="om-title" om-layout om-layout-align="space-between stretch">
      <h4 om-flex class="inline om-title-text">{{heading}}</h4>
      <div om-layout om-layout-align="start stretch" ng-transclude/>
    </div>
  `,
});

class SignByDrawerController {
  constructor(ENV) {
    this.taskAssigneesIndex = ENV.search.indexes.task_assignees;
  }

  signOnBehalfOf = () => {
    this.sign({ onBehalfOf: this.onBehalfOf }).then(() => {
      this.onClose();
      this.expandCollapse.collapse();
    });
  };
}

SignByDrawerController.$inject = ['ENV'];

export const omSignByDrawer = {
  bindings: { sign: '&', onClose: '&', expandCollapse: '<' },
  templateUrl: 'orders/shared/sign-by-drawer.component.html',
  controller: SignByDrawerController,
};

const attachmentPath = '/v2/admin/attachments';

export class AttachmentService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getFileUploadHttpConfig() {
    // undefined Content-Type and transformRequest taken from here
    // https://stackoverflow.com/a/38640878
    return {
      headers: { 'Content-Type': undefined },
      transformRequest: data => {
        const formData = new FormData();
        angular.forEach(data, (value, key) => {
          formData.append(key, value);
        });
        return formData;
      },
    };
  }

  save(params) {
    const config = this.getFileUploadHttpConfig();
    return this.ApiService.save(attachmentPath, params, config);
  }

  delete(id) {
    return this.ApiService.delete(`${attachmentPath}/${id}`);
  }
}

AttachmentService.$inject = ['ApiService'];

const esLocationPath = '/v2/admin/elasticsearch_location';

export class ElasticsearchLocationService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  get() {
    return this.ApiService.get(esLocationPath);
  }
}

ElasticsearchLocationService.$inject = ['ApiService'];

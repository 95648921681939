export const googlePlacePredictionHighlight = () => prediction => {
  let index = 0;
  let result = '';
  const highlight = (text, offset, length) =>
    `<strong>${text.slice(offset, offset + length)}</strong>`;
  prediction.matched_substrings.forEach(match => {
    if (match.offset > index) {
      result += prediction.description.slice(index, match.offset);
      index = match.offset;
    }
    result += highlight(prediction.description, match.offset, match.length);
    index += match.length;
  });
  if (index < prediction.description.length) {
    result += prediction.description.slice(index);
  }
  return result;
};

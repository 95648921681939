const REJECTION_REASONS = [
  'Facility/specialist not accepting patients',
  'Not medically necessary',
  'Other...',
  'Out of Network',
  'Patient missing information',
  'Prior steps not met',
];

class OrderActionBarController {
  constructor($q, $scope, LegacyUiHrefService) {
    this.reasons = REJECTION_REASONS;
    this.$q = $q;
    this.$scope = $scope;
    this.LegacyUiHrefService = LegacyUiHrefService;
    this.deleting = false;
    this.rejecting = false;
    this.canceling = false;
  }

  $onInit() {
    this.updateSecondaryActions();
    this.$scope.$on('update-order-action-bar-secondary-actions', () =>
      this.updateSecondaryActions(),
    );
    this.$scope.$on(
      'start-signing-order-on-behalf-of',
      this.startSigningOrderOnBehalfOf.bind(this),
    );
  }

  editOrder() {
    this.$scope.$emit('edit-order');
  }

  startSigningOrderOnBehalfOf($event, sendingOptions) {
    this.signOnBehalfOf = true;
    this.orderActionBarCollapse.expand();
    this.onBehalfOfSendingOptions = sendingOptions;
  }

  stopSigningOrderOnBehalfOf() {
    this.onBehalfOfSendingOptions = undefined;
    this.signOnBehalfOf = false;
  }

  signOrderOnBehalfOf(onBehalfOf) {
    const eventArgs = { onBehalfOfId: onBehalfOf.database_id };
    if (this.onBehalfOfSendingOptions !== undefined) {
      this.$scope.$emit(
        'send-order',
        Object.assign(eventArgs, this.onBehalfOfSendingOptions),
      );
    } else {
      this.$scope.$emit('sign-order', eventArgs);
    }
    return this.$q.resolve();
  }

  startReject() {
    this.rejecting = true;
    this.orderActionBarCollapse.expand();
  }

  cancelReject() {
    this.rejecting = false;
    this.messageTemplate = null;
    this.orderActionBarCollapse.collapse();
  }

  startCanceling() {
    this.canceling = true;
    this.orderActionBarCollapse.expand();
  }

  cancelCanceling() {
    this.canceling = false;
    this.orderActionBarCollapse.collapse();
  }

  cancelOrder() {
    this.$scope.$emit('cancel-internal-order');
  }

  cloneOrder() {
    this.$scope.$emit('clone-internal-order');
  }

  rejectOrder() {
    this.$scope.$emit('reject-order');
  }

  sendOrder() {
    this.$scope.$emit('send-order');
  }

  performProcedureNow() {
    this.$scope.$emit('send-order',
      { automateApproval: true, doNotRedirect: true, performNow: true }
    );
  }

  startDelete() {
    this.deleting = true;
    this.orderActionBarCollapse.expand();
  }

  cancelDelete() {
    this.deleting = false;
    this.orderActionBarCollapse.collapse();
  }

  deleteOrder() {
    this.$scope.$emit('delete-order');
  }

  completeOrder() {
    this.$scope.$emit('complete-order');
  }

  updateSecondaryActions() {
    this.secondaryActions = [];
    if (!this.order) {
      return;
    }
    if (this.order.status === 'signed') {
      this.secondaryActions = this.secondaryActions.concat(
        this._signedActions(),
      );
    }
    if (this.order.status === 'approved') {
      this.secondaryActions = this.secondaryActions.concat(
        this._approvedActions(),
      );
    }
    if (this.order.status === 'sent') {
      this.secondaryActions = this.secondaryActions.concat(this._sentActions());
    }
    if (this.order.status === 'unsigned') {
      this.secondaryActions = this.secondaryActions.concat(
        this._unsignedActions(),
      );
    }
    if (this.order.status !== 'unsigned') {
      this.secondaryActions = this.secondaryActions.concat(this._printAction());
    }
    if (this.order.status === 'completed') {
      this.secondaryActions = this.secondaryActions.concat(
        this._completedActions(),
      );
    }
    if (this.order.status === 'reviewed') {
      this.secondaryActions = this.secondaryActions.concat(
        this._reviewedActions(),
      );
    }
  }

  _unsignedActions() {
    return [
      {
        label: 'Delete Order',
        action: () => this.startDelete(),
      },
    ];
  }

  _sentActions() {
    const actions = [];

    // supports a cancellation flow for internal procedure orders
    if (this.order.is_internal) {
      actions.push({
        label: 'Cancel Order',
        action: () => this.startCanceling(),
      });
      actions.push({
        label: 'Clone Order',
        action: () => this.cloneOrder(),
      });
    }

    if (this.order.transmission_failed) {
      actions.push({
        label: 'Complete Order',
        action: () => this.completeOrder(),
      });
    } else {
      actions.push({
        label: 'Resend Order',
        action: () => this.sendOrder(),
      });
    }
    actions.push({
      template: `<om-redact-button-menu-item as-button="$ctrl.asButton"></om-redact-button-menu-item>`,
    });
    return actions;
  }

  _signedActions() {
    return [
      {
        label: 'Reject Order',
        action: () => this.startReject(),
      },
      {
        label: 'Edit Order',
        action: () => this.editOrder(),
      },
      {
        label: 'Delete Order',
        action: () => this.startDelete(),
      },
    ];
  }

  _approvedActions() {
    return [
      {
        label: 'Edit Order',
        action: () => this.editOrder(),
      },
      {
        label: 'Delete Order',
        action: () => this.startDelete(),
      },
    ];
  }

  _completedActions() {
    const actions = [];

    if (this.order.is_internal) {
      actions.push({
        label: 'Clone Order',
        action: () => this.cloneOrder(),
      });
    }
    return actions;
  }

  _reviewedActions() {
    const actions = [];

    if (this.order.is_internal) {
      actions.push({
        label: 'Clone Order',
        action: () => this.cloneOrder(),
      });
    }
    return actions;
  }

  _printAction() {
    const baseUrl = this.printUrl;
    const patientParam = `?patient_id=${this.patient.id}`;
    const legacyHref = `${baseUrl}${this.order.id}${patientParam}`;
    const target = '_blank';

    return [
      {
        label: 'Print',
        action: () =>
          this.LegacyUiHrefService.openLegacyHref(legacyHref, target),
      },
    ];
  }
}

OrderActionBarController.$inject = ['$q', '$scope', 'LegacyUiHrefService'];

export const omOrderActionBar = {
  bindings: {
    editable: '<',
    form: '<',
    order: '<',
    patient: '<',
    printUrl: '<',
    signable: '<',
  },
  templateUrl: 'orders/shared/order-action-bar.component.html',
  controller: OrderActionBarController,
};

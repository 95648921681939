import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

class BasicFollowUpController {
  constructor(
    $stateParams,
    FollowUpOrderService,
    ProblemService,
    FOLLOW_UP_ORDER_TYPES,
  ) {
    this.basicFollowUpForm = null;
    this.followUpOrderService = FollowUpOrderService;
    this.analyticsType = FOLLOW_UP_ORDER_TYPES.basic.analyticsType;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.problemService = ProblemService;
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }

  $onInit() {
    this.followUpComponent.registerChild(this);
    if (!this.order.id) {
      this.order = { type: this.FOLLOW_UP_ORDER_TYPES.basic.name };
      this.order.problem = {};
    }
    this.remindedOn = this.buildReminded();
    this.problemService
      .query(this.patientId, { custom: false, resolved: false })
      .then(problems => {
        this.patientProblems = problems;
        this.ready = true;
      });
  }

  _handleError({ data }) {
    this.apiError = data.message;
  }

  signFollowUpOrder() {
    const order = _.clone(this.order);
    this.apiError = null;
    order.problem_id = order.problem.id;
    delete order.problem;
    if (this.order.id) {
      const request = this.followUpOrderService.updateFollowUpOrder(
        this.patientId,
        order,
      );
      request.catch(this._handleError.bind(this));
      return request;
    }
    const request = this.followUpOrderService.createFollowUpOrder(
      this.patientId,
      order,
    );
    request.catch(this._handleError.bind(this));
    return request;
  }

  deleteFollowUpOrder() {
    return this.followUpOrderService.deleteFollowUpOrder(
      this.patientId,
      this.order,
    );
  }

  validateForm() {
    this.basicFollowUpForm.$setSubmitted();
    return this.basicFollowUpForm.$valid;
  }

  onProblemSelect() {
    this.onFormFieldChanged({ fieldName: 'Problems Field' });
  }

  onManualDateEntry(newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }

    this.remindedOn = this.buildReminded();
    this.order.due_on = newDueOnValue;
    this.onFormFieldChanged({ fieldName: 'Date Field' });
  }

  onShortcutDateEntry(quantity, unit, newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }

    this.remindedOn = this.buildReminded();
    this.order.due_on = newDueOnValue;
    this.onDatePickerSelected({ quantity, unit });
  }

  buildReminded() {
    if (!this.order.due_on) {
      return undefined;
    }
    return '1 day';
  }
}

BasicFollowUpController.$inject = [
  '$stateParams',
  'FollowUpOrderService',
  'ProblemService',
  'FOLLOW_UP_ORDER_TYPES',
];

export const omBasicFollowUp = {
  bindings: {
    order: '<',
    ready: '<',
    onFormFieldChanged: '&',
    onDatePickerSelected: '&',
  },
  require: {
    followUpComponent: '^omFollowUp',
  },
  controller: BasicFollowUpController,
  templateUrl: 'orders/follow-up/basic-follow-up.component.html',
};

class InProgressMessageHeaderController {
  constructor(ENV, focus, InternalUserPreferencesService, roleGroupSort) {
    this.focus = focus;
    this.InternalUserPreferencesService = InternalUserPreferencesService;
    this.taskAssigneesIndex = ENV.search.indexes.task_assignees;
    this.roleGroupSort = roleGroupSort;
  }

  $onInit() {
    this.fetchDefaultAssignee();
  }

  fetchDefaultAssignee() {
    return this.InternalUserPreferencesService.get().then(preferences => {
      // do not overwrite assignee if set
      if (this.inProgressMessage.reply_to) {
        this.reassigned = true;
        return;
      }
      this.setReplyTo(preferences.default_send_as_assignee);
    });
  }

  maybeUpdate() {
    // always update for posts
    // only update for comments if there is text to prevent creating a
    // comment without text
    if (
      !!this.inProgressMessage.text ||
      this.omMessaging.isPost(this.inProgressMessage)
    ) {
      this.omMessaging.update();
    }
  }

  setReplyTo(assignee) {
    this.inProgressMessage.reply_to = assignee;
    this.maybeUpdate();
  }

  setNotificationRecipient(assignee) {
    this.inProgressMessage.notification_recipient = {
      assignee_ident: assignee.identifier,
      ...assignee,
    };
  }

  startAssign() {
    this.assigned = true;
    this.focus('currentMessage-assign');
  }

  startNotify() {
    this.notified = true;
    this.focus('currentMessage-notify');
  }
}

InProgressMessageHeaderController.$inject = [
  'ENV',
  'focus',
  'InternalUserPreferencesService',
  'roleGroupSort',
];

export const omInProgressMessageHeader = {
  bindings: {
    inProgressMessage: '<',
    profile: '<',
  },
  require: {
    omMessaging: '^^omMessaging',
  },
  controller: InProgressMessageHeaderController,
  templateUrl: 'messaging/in-progress-message-header.component.html',
};

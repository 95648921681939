class TOSReminderController {
  constructor($scope, TOSReminderService) {
    this.$scope = $scope;
    this.TOSReminderService = TOSReminderService;
    this.reminderSent = false;
  }

  sendReminderEmail($event) {
    $event.preventDefault();
    this.TOSReminderService.post(this.patientId);
    this.reminderSent = true;
  }
}

TOSReminderController.$inject = ['$scope', 'TOSReminderService'];

export const omTosReminder = {
  bindings: {
    patientId: '<',
  },
  controller: TOSReminderController,
  templateUrl: 'encounter/tos-reminder.component.html',
};

import { AttachmentService } from './attachment.service';
import { isPdfDocument } from './is-pdf-document.service';
import { LabDataService } from './lab-data.service';
import { omLabResult } from './lab-result.component';
import { omLabResultHeader } from './lab-result-header.component';
import { omNote } from './note.component';
import { NoteService } from './note.service';
import { omNoteHeader } from './note-header.component';
import { NoteTemplatesService } from './note-templates.service';
import { omPrintedNote } from './printed-note.component';
import { omRecategorizeDocumentActionBar } from './recategorize-document-action-bar.component';

angular
  .module('onelifeUi.notes', ['onelifeUi.shared'])
  .component('omLabResult', omLabResult)
  .component('omLabResultHeader', omLabResultHeader)
  .component('omNote', omNote)
  .component('omNoteHeader', omNoteHeader)
  .component('omPrintedNote', omPrintedNote)
  .component('omRecategorizeDocumentActionBar', omRecategorizeDocumentActionBar)
  .factory('isPdfDocument', () => isPdfDocument)
  .factory('LabDataService', LabDataService)
  .factory('NoteService', NoteService)
  .service('AttachmentService', AttachmentService)
  .service('NoteTemplatesService', NoteTemplatesService);

class ButtonMenuItemController {
  constructor($compile, $element, $scope) {
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
  }

  $onChanges() {
    if (this.button.template) {
      const compiled = this.$compile(this.button.template)(this.$scope);
      this.$element.html(compiled);
    }
  }
}

ButtonMenuItemController.$inject = ['$compile', '$element', '$scope'];

export const omButtonMenuItem = {
  templateUrl: 'shared/button-menu-item.component.html',
  controller: ButtonMenuItemController,
  bindings: {
    asButton: '<',
    button: '<',
  },
};

export const getPatientIdFromUrl = () => {
  const fullPath = window.location.href;

  const matches = fullPath.match(
    // eslint-disable-next-line no-useless-escape
    `/(?:.+?)patients\/([0-9]+)?\/chart.*/`,
  );

  if (matches && matches.length > 0) {
    return matches[1];
  }

  return null;
};

export class PendingNewRxService {
  API_ENDPOINT = '/v2/admin/pending_new_rxes/';
  constructor(ApiService, $rootScope, LoggerService, PendingNewRx) {
    this.ApiService = ApiService;
    this.$rootScope = $rootScope;
    this.LoggerService = LoggerService;
    this.PendingNewRx = PendingNewRx;
  }
  refreshCartAndMedications() {
    this.$rootScope.$emit('patientMedicationsRefresh');
    this.$rootScope.$emit('newRxCartRefresh');
  }
  create(data) {
    const request = this.ApiService.save(`${this.API_ENDPOINT}`, data);

    request.catch(error =>
      this.LoggerService.log('PendingNewRxService: create', error),
    );

    return request;
  }
  update(pendingNewRx) {
    const request = this.ApiService.update(
      `${this.API_ENDPOINT}${pendingNewRx.id}`,
      pendingNewRx,
    );

    pendingNewRx.pendingUpdate = true;

    request.then(({ quantity, refills }) => {
      pendingNewRx.pendingUpdate = false;
      _.merge(pendingNewRx, { quantity, refills });
      return new this.PendingNewRx(pendingNewRx);
    });

    request.catch(error =>
      this.LoggerService.log('PendingNewRxService: update', error),
    );

    return request;
  }
  delete(pendingNewRx) {
    const request = this.ApiService.delete(
      `${this.API_ENDPOINT}${pendingNewRx.id}`,
    );

    pendingNewRx.pendingUpdate = true;
    request.then(() => {
      pendingNewRx.pendingUpdate = false;
      this.refreshCartAndMedications();
      return new this.PendingNewRx(pendingNewRx);
    });

    request.catch(error =>
      this.LoggerService.log('PendingNewRxService: delete', error),
    );

    return request;
  }
  markReadyToSign({ id }) {
    const request = this.ApiService.get(
      `${this.API_ENDPOINT}${id}/ready_to_sign`,
    );

    request.catch(error =>
      this.LoggerService.log('PendingNewRxService: markReadyToSign', error),
    );

    return request;
  }
  addNewMedicationToCart(patientId, regimenId, patientMedicationRegimenId) {
    const request = this.create({
      patient_id: patientId,
      medication_regimen_id: regimenId,
      patient_medication_regimen_id: patientMedicationRegimenId,
    });
    request.then(this.refreshCartAndMedications.bind(this));
    return request;
  }
}
PendingNewRxService.$inject = [
  'ApiService',
  '$rootScope',
  'LoggerService',
  'PendingNewRx',
];

export const CustomComplete = $filter => (args) => {
  const search = args.search || '';
  const limit = args.limit || 9;
  const defaults = args.defaults || {};

  const fullList = args.fullList;
  const filteredList = args.filteredList;
  const key = args.key;
  const filter = {};
  filter[key] = search;
  const tempList = $filter('limitTo')($filter('filter')(fullList, filter), limit);

  const presentInList = _.find(fullList, (item =>
    item[key].toLowerCase() === search.toLowerCase()
  ));

  if (!presentInList) {
    const customItem = _.clone(defaults);
    customItem[key] = search;
    tempList.push(customItem);
  }
  filteredList.length = 0;
  _.each(tempList, (item) => { filteredList.push(item); });
};

CustomComplete.$inject = ['$filter'];

export const omSearchFilters = () => ({
  restrict: 'E',
  require: '^^omSearch',
  replace: true,
  transclude: true,
  controller: 'omSearchFiltersCtrl',
  scope: {
    label: '@',
  },
  template: `<div class="om-search-filters" om-layout-gutter>
              <div class="om-search-filters-label">{{label}}</div>
              <ul om-layout om-layout-align="space-between" om-flex>
                <li ng-repeat="filter in filters">
                  <a class="om-search-filters-option"
                     href=""
                     ng-class="{active:filter.selected}"
                     ng-click="select(filter)"
                     analytics-on
                     analytics-event="Timeline Filter Selected"
                     analytics-filter-value="{{filter.name}}">
                      {{filter.name}}
                  </a>
                </li>
              </ul>
              <ng-transclude></ng-transclude>
            </div>`,
});

class RemainingCharacterCountController {
  remainingChars() {
    return this.text ? this.max - this.text.length : this.max;
  }
}

export const omRemainingCharacterCount = {
  transclude: true,
  template:
    '<span class="pull-right caption">{{$ctrl.remainingChars()}} characters remaining</span>' +
    '<ng-transclude ng-class="{\'ng-invalid\': $ctrl.remainingChars() === 0}"></ng-transclude>',
  bindings: {
    max: '<',
    text: '<',
  },
  controller: RemainingCharacterCountController,
};

const workspacePrefix = 'app.chart.workspace';

class TimelineListController {
  constructor($state, $stateParams, TimelineItem) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.TimelineItem = TimelineItem;
  }

  $onChanges() {
    this.timelineComponent.highlight(this.itemOpenInWorkspace());
    this._setListItems();
  }

  _setListItems() {
    this.listItems = this.results.map(result =>
      this._buildTimelineItemFromSearchResult(result),
    );
  }

  _buildTimelineItemFromSearchResult(result) {
    return new this.TimelineItem(result);
  }

  itemOpenInWorkspace() {
    const matchingResult = id =>
      this.results.find(
        result => result[id] && result[id].toString() === this.$stateParams.id,
      );

    if (this.isNoteState() || this.ispatientTimelinePostState()) {
      return matchingResult('database_id');
    } else if (this.isSummaryState()) {
      return matchingResult('summary_id');
    } else if (this.isLabOrderState()) {
      return matchingResult('lab_order_id');
    }
    return null;
  }

  isDraft = timelineObj =>
    !!(
      timelineObj.draft ||
      (timelineObj.comments &&
        timelineObj.comments.some(comment => comment.draft))
    );

  isNoteState() {
    return this.$state.includes(`${workspacePrefix}.notes`);
  }

  ispatientTimelinePostState() {
    return this.$state.includes(`${workspacePrefix}.patientTimelinePosts`);
  }

  isSummaryState() {
    return this.$state.includes(`${workspacePrefix}.summaries`);
  }

  isLabOrderState() {
    return this.$state.includes('app.chart.orders');
  }
}

TimelineListController.$inject = ['$state', '$stateParams', 'TimelineItem'];

export const omTimelineList = {
  templateUrl: 'timeline/timeline-list.component.html',
  controller: TimelineListController,
  bindings: {
    results: '<',
    isSupportingDocumentsAttachable: '<',
    supportingDocumentsAttached: '<',
  },
  require: {
    timelineComponent: '^omTimeline',
  },
};

import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

export class EncounterController {
  constructor(
    $analytics,
    $location,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $window,
    CommentService,
    PageAnalytics,
    PatientPharmacy,
    PatientService,
    PatientTimelinePostService,
    PendingNewRxService,
    Pharmacy,
    RefillRequest,
    newRxCart,
    medications,
    patient,
    patientPharmacies,
    profile,
    renewalsCount,
    uiGmapGoogleMapApi,
    TOSReminderService,
    FeaturesService,
  ) {
    this.$analytics = $analytics;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
    this.CommentService = CommentService;
    this.PageAnalytics = PageAnalytics;
    this.PatientPharmacy = PatientPharmacy;
    this.PatientService = PatientService;
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.PendingNewRxService = PendingNewRxService;
    this.Pharmacy = Pharmacy;
    this.RefillRequest = RefillRequest;
    this.newRxCart = newRxCart;
    this.medications = medications;
    this.patient = patient;
    this.patientPharmacies = patientPharmacies;
    this.profile = profile;
    this.renewalsCount = renewalsCount;
    this.uiGmapGoogleMapApi = uiGmapGoogleMapApi;
    this.TOSReminderService = TOSReminderService;
    this.FeaturesService = FeaturesService;

    this.sectionsLinkable = false;
    uiGmapGoogleMapApi.then(() => {});

    this.loadPharmacyAndCredentialsOnCart();
    this.setRootScopeListeners();
    this.trackUserAnalytics();
    this.setFeatures();
  }
  loadPharmacyAndCredentialsOnCart(cart = this.newRxCart) {
    const signedOnBehalfOfId = cart.signed_on_behalf_of_id;
    this.newRxCart.setPrescriberAndCredentials(
      signedOnBehalfOfId || this.profile.id,
    );
    this.newRxCart.setPatientPharmacy(this.patientPharmacies);
  }
  setFeatures() {
    this.showInsights = false;
    this.FeaturesService.hasFeature('chart_insights').then(featureEnabled => {
      this.showInsights = featureEnabled;
    });
  }
  setMessagingListeners() {
    this.$rootScope.$on('setCurrentMessage', (event, post) => {
      this.currentMessage = post;
    });

    this.$rootScope.$on('deleteDraftMessage', () => {
      this.$state.go('app.chart.workspace.newWorkspaceItem').then(() => {
        this.$rootScope.$emit('selectTab', 'Workspace');
        this.$rootScope.$emit('refreshTimeline');
        this.currentMessage = null;
      });
    });
  }
  setNewRxListeners() {
    this.$rootScope.$on('newRxCartRefresh', () => {
      this.newRxCart.$get().then(cart => {
        this.loadPharmacyAndCredentialsOnCart(cart);
      });
    });
  }
  setPharmacyListeners() {
    this.$rootScope.$on('pharmacySaved', () => {
      this.patientPharmacies.$query().then(() => {
        this.newRxCart.patientPharmacy = _.head(this.patientPharmacies);
      });
    });
  }
  setRenewalListeners() {
    this.$rootScope.$on(
      'updateRenewalCount',
      (event, count) => (this.renewalsCount = count),
    );
  }
  setRootScopeListeners() {
    this.setMessagingListeners();
    this.setNewRxListeners();
    this.setPharmacyListeners();
    this.setRenewalListeners();
    this.setScrollingListeners();
    this.setSummaryListeners();
    this.setTimelineListeners();
  }
  setScrollingListeners() {
    this.scrollingEnabled = true;

    this.$rootScope.$on('enableScrolling', (event, enabled) => {
      this.scrollingEnabled = enabled;
    });
  }
  clearMessage() {
    this.currentMessage = null;
  }
  linkSection(section, ...params) {
    this.$rootScope.$emit('sectionLinked', section, params);
  }
  setSummaryListeners() {
    this.$rootScope.$on('summaryClosed', () => {
      this.sectionsLinkable = false;
      this.$state.go('app.chart.workspace.newWorkspaceItem').then(() => {
        this.$rootScope.$emit('selectTab', 'Workspace');
        this.$rootScope.$emit('refreshTimeline');
        this.$rootScope.lastEditedSummary = undefined;
      });
    });

    this.$rootScope.$on('summaryLoaded', (event, signed) => {
      this.sectionsLinkable = !signed;
    });
  }
  setTimelineListeners() {
    this.$rootScope.$on('expandTimeline', (event, isExpanded) => {
      this.timelineExpanded = isExpanded;
    });
  }
  trackUserAnalytics() {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    this.$analytics.setUsername(this.profile.id);
    this.$analytics.setSuperProperties({
      patientId,
      application: 'onelife-ui',
    });
  }
}

EncounterController.$inject = [
  '$analytics',
  '$location',
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$timeout',
  '$window',
  'CommentService',
  'PageAnalytics',
  'PatientPharmacy',
  'PatientService',
  'PatientTimelinePostService',
  'PendingNewRxService',
  'Pharmacy',
  'RefillRequest',
  'newRxCart',
  'medications',
  'patient',
  'patientPharmacies',
  'profile',
  'renewalsCount',
  'uiGmapGoogleMapApi',
  'TOSReminderService',
  'FeaturesService',
];

import { getParamsFromUrl } from '../orders/upgrade-param-mapper';

class LetterController {
  constructor(
    $q,
    $rootScope,
    focus,
    toastr,
    LetterService,
    NoteService,
    TodoService,
    PatientService,
    NotificationsService,
    $analytics,
    $state,
    $stateParams,
    CommentService,
    ProfileService,
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.focus = focus;
    this.toastr = toastr;
    this.LetterService = LetterService;
    this.NoteService = NoteService;
    this.TodoService = TodoService;
    this.PatientService = PatientService;
    this.NotificationsService = NotificationsService;
    this.$analytics = $analytics;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.CommentService = CommentService;
    ProfileService.get().then(profile => {
      this.profile = profile;
    });
    this.successEvent = 'generate_letter_done';
    this.errorEvent = 'generate_letter_error';
  }

  $onInit() {
    const noteId = getParamsFromUrl('letters') || this.$stateParams.id;
    const getNote = this.NoteService.get(noteId);
    const getTodo = this.TodoService.getNoteTodo({ id: noteId });
    const getPatient = this.PatientService.get(this.$stateParams.patientId);
    const getLetterTemplates = this.LetterService.getLetterTemplates();

    return this.$q.all([getNote, getTodo, getPatient, getLetterTemplates]).then(([note, todo, patient, templates]) => {
      this.note = note;
      this.todo = todo;
      this.noteHasIncompleteTodo = this.todo.isIncomplete();
      this.patient = patient;
      this.letterTemplates = templates;
      this.setLetterBody();
      this.setDisplayAddress();
      this.setDisplayPhones();
      this.setSignature();

      this.analyticsBaseAttributes = {
        workflow: 'Charting',
        component: 'Official Letter',
        note_id: this.note.id,
      };
    });
  }

  updateSubject(subject) {
    this.note.subject = subject;
    return this.NoteService.update(this.note).then(() => {
      this.$rootScope.$emit('refreshTimeline');
    });
  }

  applyLetterTemplate(letterTemplate) {
    if (!letterTemplate) return;
    this.updateSubject(letterTemplate.name);

    this.LetterService.getLetterTemplate(letterTemplate.id).then(template => {
      this._trackTemplateSelection(template);
      this.focus('currentLetter-body');
      this.letterBody = template.body;
    });
  }

  update() {
    this.note.body = this.letterBody;
    return this.NoteService.update(this.note);
  }

  setDeleting(deleting) {
    this.deleting = deleting;
  }

  delete() {
    this._trackDeleteDraft();
    return this.NoteService.delete(this.note.id).then(() => {
      this.toastr.success('Your letter has been deleted');
      this.$rootScope.$emit('refreshTimeline');
      this.$state.go('app.chart.workspace.newWorkspaceItem');
    });
  }

  setLetterBody() {
    if (this.note.body !== '<br>') ( this.letterBody = this.note.body );
  }

  setDisplayAddress() {
    const address = this.patient.office.address;
    let street = address.street;

    if (address.address2) { street += ` · ${address.address2}` }

    this.officeAddress = `${street} · ${address.city}, ${address.state.short_name} ${address.zip_code}`;
  }

  setDisplayPhones() {
    const patientOffice = this.patient.office;

    if (patientOffice.phone) { this.phone = `ph: ${patientOffice.phone} ` };
    if (patientOffice.fax) { this.fax = `fax: ${patientOffice.fax} ` };

    if (this.phone && this.fax) {
      this.phones = `${this.phone} · ${this.fax}`;
    } else {
      this.phones = this.phone || this.fax;
    }
  }

  setSignature() {
    this.displayName = this.profile.primary.name;

    if (this.profile.roles.includes("provider")) {
      this.npi = this.profile.npi;
    } else if (this.profile.roles.includes("admin")) {
      this.displayName += ', Administrative Assistant';
    } else if (this.profile.roles.includes("phlebotomist")) {
      this.displayName += ', Phlebotomist';
    }
  }

  signLetter() {
    this._trackSignButtonEvent('Letter Signed');

    this.update()
    .then(() => this.NoteService.generateLetter(this.note.id, this.patient.id))
    .then(() => {
      this.loadingPDF = true;
      this.bindToNotifications();
    });
  }

  bindToNotifications() {
    const pusherChannel = `note-${this.$stateParams.id}`;

    this.notificationsConnection = this.NotificationsService.subscribe(pusherChannel);
    this.notificationsConnection.bind(this.successEvent, this.displayGeneratedPdf.bind(this));
    this.notificationsConnection.bind(this.errorEvent, this.handleError.bind(this));
  }

  disconnectFromNotifications() {
    if (this.notificationsConnection) {
      this.notificationsConnection.unbind(this.successEvent);
      this.notificationsConnection.unbind(this.errorEvent);
    }
  }

  displayGeneratedPdf() {
    this.disconnectFromNotifications();
    this._trackSignButtonEvent('Letter Generated');
    this.$rootScope.$emit('refreshTimeline');
    this.$state.go('app.chart.workspace.notes', {
      id: this.note.id,
    });
  }

  handleError() {
    this.disconnectFromNotifications();
    this._trackSignButtonEvent('Letter Generation Failed');
    this.loadingPDF = false;
    this.toastr.warning('Failed to generate letter PDF, please try again.');
  }

  saveComment(id, body) {
    return this.CommentService.saveNoteComment(id, { body });
  }

  getComments(id) {
    return this.CommentService.getNoteComments(id);
  }

  closeLetter() {
    this.$state.go('app.chart.workspace.newWorkspaceItem');
  }

  _trackTemplateSelection(template) {
    this.$analytics.eventTrack('Letter Template Selected', {
      ...this.analyticsBaseAttributes,
      subcomponent: 'Apply Template Field',
      letter_template_id: template.id,
    });
  }

  _trackDeleteDraft() {
    this.$analytics.eventTrack('Letter Draft Deleted', {
      ...this.analyticsBaseAttributes,
      subcomponent: 'Delete Button',
    });
  }

  _trackSignButtonEvent(event) {
    this.$analytics.eventTrack(event, {
      ...this.analyticsBaseAttributes,
      subcomponent: 'Sign Button',
    });
  }
}

LetterController.$inject = [
  '$q',
  '$rootScope',
  'focus',
  'toastr',
  'LetterService',
  'NoteService',
  'TodoService',
  'PatientService',
  'NotificationsService',
  '$analytics',
  '$state',
  '$stateParams',
  'CommentService',
  'ProfileService',
];

export const omLetter = {
  templateUrl: 'notes/letter.component.html',
  controller: LetterController
};

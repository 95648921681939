import { omDeleteMessageOption } from './delete-message-option.component';
import { omInProgressMessageHeader } from './in-progress-message-header.component';
import { InternalUserPreferencesService } from './internal-user-preferences.service';
import { omMessaging } from './messaging.component';
import { omMessagingProductSpecialistOptions } from './messaging-product-specialist-options.component';
import { omMoveMessageOption } from './move-message-option.component';
import { MessageTemplatesService } from './message-templates.service';
import { MessagingWarningsService } from './messaging-warnings.service';
import { PatientTimelineCommentService } from './patient-timeline-comment.service';
import { PatientTimelinePostService } from './patient-timeline-post.service';
import { PatientTimelineS3PointerService } from './patient-timeline-s3-pointer.service';
import { omPatientTimelineComment } from './patient-timeline-comment.component';
import { patientTimelinePostsComponent } from './patient-timeline-posts.component';
import { omS3PointerLink } from './s3-pointer-link.component';

angular
  .module('onelifeUi.messaging', ['onelifeUi.patient'])
  .component('patientTimelinePostsComponent', patientTimelinePostsComponent)
  .component('omDeleteMessageOption', omDeleteMessageOption)
  .component('omInProgressMessageHeader', omInProgressMessageHeader)
  .component('omMessaging', omMessaging)
  .component(
    'omMessagingProductSpecialistOptions',
    omMessagingProductSpecialistOptions,
  )
  .component('omMoveMessageOption', omMoveMessageOption)
  .component('omPatientTimelineComment', omPatientTimelineComment)
  .component('omS3PointerLink', omS3PointerLink)
  .factory('InternalUserPreferencesService', InternalUserPreferencesService)
  .factory('MessageTemplatesService', MessageTemplatesService)
  .factory('MessagingWarningsService', MessagingWarningsService)
  .factory('PatientTimelineCommentService', PatientTimelineCommentService)
  .factory('PatientTimelinePost', PatientTimelinePostService)
  .factory('PatientTimelineS3PointerService', PatientTimelineS3PointerService);

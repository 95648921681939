export function splitHighlight(text, query) {
  const escapedQuery = _.escapeRegExp(query).replace(/\s/g, '|');
  const re = new RegExp(`(^|\\W)(${escapedQuery})`, 'i');
  const output = [];
  const textFragments = text.split(' ');
  let replacedFragment;

  textFragments.forEach(fragment => {
    replacedFragment = fragment.replace(re, '$1<strong>$2</strong>');
    output.push(replacedFragment);
  });

  return output.join(' ');
}

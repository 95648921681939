class ProfileImageBubble {
  $onInit() {
    const COLOR_COMBINATIONS = 5;
    const DEFAULT_COLOR_COMBINATION = 1;
    this.resolvableImage = this.url;
    if (this.colorCombo) {
      this.colorCombo = (this.colorCombo % COLOR_COMBINATIONS) + 1;
    } else {
      this.colorCombo = DEFAULT_COLOR_COMBINATION;
    }
  }

  fallbackToInitials() {
    this.resolvableImage = false;
  }
}

export const omProfileImageBubble = {
  bindings: {
    initials: '<',
    url: '<',
    colorCombo: '<',
  },
  controller: ProfileImageBubble,
  template: `<span ng-if="$ctrl.resolvableImage" om-background-image="{{$ctrl.resolvableImage}}"/>
             <img ng-if="$ctrl.url" hidden ng-src="{{$ctrl.url}}"
                  on-error="$ctrl.fallbackToInitials()"/>
             <span ng-if="!$ctrl.resolvableImage" om-layout="vertical" om-layout-align="center"
                   class="combo-{{$ctrl.colorCombo}}">
               <h4>{{$ctrl.initials}}</h4>
             </span>`,
};

import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

const PATIENTS_PATH = '/v2/admin/patients';

export class HistoryVaccineService {
  constructor($stateParams, $rootScope, ApiService, HistoryVaccine) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.HistoryVaccine = HistoryVaccine;
  }

  create(vaccineOrder) {
    return this.ApiService.save(
      `${PATIENTS_PATH}/${this.patientId}/history_vaccines`,
      { vaccine_order: vaccineOrder },
    ).then(response => new this.HistoryVaccine(response));
  }

  update(historyVaccine) {
    return this.ApiService.update(
      `${PATIENTS_PATH}/${this.patientId}/history_vaccines/${
        historyVaccine.id
      }`,
      historyVaccine,
    );
  }

  complete(historyVaccine) {
    return this.ApiService.save(
      `${PATIENTS_PATH}/${this.patientId}/history_vaccines/complete`,
      historyVaccine,
    );
  }
}

HistoryVaccineService.$inject = [
  '$stateParams',
  '$rootScope',
  'ApiService',
  'HistoryVaccine',
];

export class Patient {
  constructor(attributes = {}) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  incompleteDemographics() {
    return !(this.dob && this.first_name && this.last_name && this.gender && (this.primary_address || this.addresses.length > 0));
  }

  formattedAge() {
    if (this.age >= 3) return `${this.age} yo`;
    return this.infant_age;
  }

  isTeen() {
    return this.age > 16 && this.age < 18;
  }

  isPreteen() {
    return this.age < 16;
  }

  acceptsDigitalCommunications() {
    return !!this.patient_preferences && this.patient_preferences.accepts_clinical_emails;
  }
}

const POST_ENDPOINT_PREFIX = '/v2/admin/patients';
const POST_ENDPOINT = 'patient_timeline/posts';
const COMMENT_ENDPOINT = 'comments';
export class PatientTimelineCommentService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  save(comment, postId) {
    return this.ApiService.save(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${postId}/${COMMENT_ENDPOINT}`,
      comment,
    );
  }

  update(comment, postId) {
    return this.ApiService.update(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${postId}/${COMMENT_ENDPOINT}/${comment.id}`,
      comment,
    );
  }

  delete(comment, postId) {
    return this.ApiService.delete(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${postId}/${COMMENT_ENDPOINT}/${comment.id}`,
    );
  }
}

PatientTimelineCommentService.$inject = ['ApiService', '$stateParams'];

export const omAutosaveErrorMessage = () => ({
  restrict: 'AE',
  require: '^omForm',
  replace: true,
  transclude: true,
  template: `<div class="om-autosave-message om-autosave-error-message">
               <om-response-errors response="omFormCtrl.errorResponse"
                                   error-key="{{errorKey}}"></om-response-errors>
             </div>`,
  link(scope, element, attrs) {
    scope.errorKey = attrs.omAutosaveErrorMessageKey;
  },
});

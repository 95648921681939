class ProblemHistoryController {
  constructor(ProblemService) {
    this.ProblemService = ProblemService;
  }

  $onInit() {
    return this.ProblemService.queryProblemHistories(this.problem.id).then(
      problemHistories => {
        this.history = problemHistories;
      },
    );
  }
}

ProblemHistoryController.$inject = ['ProblemService'];

export const omProblemHistory = {
  templateUrl: 'problems/problem-history.html',
  controller: ProblemHistoryController,
  bindings: {
    problem: '<',
  },
};

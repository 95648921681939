export const pharmacyAddress = () => (
  address = { display_address_street: '', city: '', state: '' },
) => {
  const displayAddress =
    address.display_address_street &&
    address.display_address_street !== 'Unknown'
      ? `${address.display_address_street} `
      : '';
  return `${displayAddress}${address.city}, ${address.state}`;
};

export class omSearchFiltersCtrl {
  constructor($scope) {
    $scope.filters = [];
    const filters = $scope.filters;

    $scope.select = filterToSelect => {
      filters.forEach(filter => {
        filter.selected = false;
      });
      filterToSelect.selected = true;
      $scope.$emit('set-current-filter', filterToSelect.name);
      return $scope.$parent.filter(filterToSelect.filter);
    };

    this.add = filter => {
      if (filters.length === 0) {
        $scope.select(filter);
      }
      filters.push(filter);
    };
  }
}

omSearchFiltersCtrl.$inject = ['$scope'];

class TimelineListItemController {
  constructor($state, $stateParams) {
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  $onChanges() {
    this.isAttachable = this.timelineListItem.isAttachable();
    this.isDraft = this.timelineListItem.isDraft();
  }

  isAttached() {
    return this.timelineListItem.isIncludedInDocumentList(
      this.supportingDocumentsAttached,
    );
  }

  resultsFilterIsActive() {
    return this.timelineComponent.currentFilter === 'Results';
  }
}

TimelineListItemController.$inject = ['$state', '$stateParams', 'TimelineItem'];

export const omTimelineListItem = {
  templateUrl: 'timeline/timeline-list-item.component.html',
  controller: TimelineListItemController,
  bindings: {
    timelineListItem: '<',
    isSupportingDocumentsAttachable: '<',
    supportingDocumentsAttached: '<',
  },
  require: {
    timelineComponent: '^omTimeline',
  },
};

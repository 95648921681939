export class LoggerService {
  constructor($log, Rollbar, $injector) {
    this.$log = $log;
    this.Rollbar = Rollbar;
    this.$injector = $injector;
  }

  error(message, data = {}, title = '') {
    this.Rollbar.error(message, data);
    this.$log.error(['Error:', message].join(' '), data, title);
  }

  info(message, data = {}, title = '') {
    this.showToast(message, title);
    this.$log.info(['Info:', message].join(' '), data, title);
  }

  success(message, data = {}, title = '') {
    this.showToast(message, title, 'success');
    this.$log.log(['Success:', message].join(' '), data, title);
  }

  warning(message, data = {}, title = '') {
    this.showToast(message, title, 'warning');
    this.$log.warn(['Warning:', message].join(' '), data, title);
  }

  log(...args) {
    this.$log.log(args);
  }

  showToast(message, title = '', logLevel = 'info') {
    this.$injector.get('toastr')[logLevel](message, title);
  }
}
LoggerService.$inject = ['$log', 'Rollbar', '$injector'];

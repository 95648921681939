class ResponseErrorsController {
  $onChanges() {
    this.updateErrors();
  }

  updateErrors() {
    this.errors = null;
    if (this.response) {
      if (this.errorKey && this.response[this.errorKey]) {
        this.errors = this.response[this.errorKey].errors;
      } else if (!this.errorKey) {
        this.errors = this.response.errors;
      }
    }
  }
}

ResponseErrorsController.$inject = [];

export const omResponseErrors = {
  templateUrl: 'shared/response-errors.component.html',
  controller: ResponseErrorsController,
  bindings: {
    response: '<',
    errorKey: '@',
  },
};

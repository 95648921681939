class OrderApprovalController {
  constructor(ENV) {
    this.ENV = ENV;
  }

  $onInit() {
    this.contactAppUrl = `${this.ENV.adminApp.host}`;
  }

  showOrderApprovalReport() {
    return (
      this.order.status === 'sent' ||
      this.order.status === 'completed' ||
      this.order.status === 'redacted' ||
      this.order.status === 'reviewed'
    );
  }
}

OrderApprovalController.$inject = ['ENV'];

export const omOrderApproval = {
  bindings: {
    order: '<',
    editable: '<'
  },
  templateUrl: 'orders/shared/order-approval.component.html',
  controller: OrderApprovalController,
};

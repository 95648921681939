// NOTE!!!!!!!!!
// Avoid usage in a ng-if, this will delete the modal's scope and cause the
// modal UI to lock up. Just use inside of ng-show.
export class omModalCtrl {
  constructor($scope, $attrs, $parse, $templateCache, $compile, $document, $animate, $transclude, $element) {
    this.$scope = $scope;
    this.$attrs = $attrs;
    this.$parse = $parse;
    this.$templateCache = $templateCache;
    this.$compile = $compile;
    this.$document = $document;
    this.$animate = $animate;
    this.$transclude = $transclude;
    this.$element = $element;

    this.$scope.$modal = {
      open: () => {
        const templateName = this.$parse(this.$attrs.template)(this.$scope);
        const closeAction = this.$attrs.onClose || '$modal.close()';
        const container = angular.element('<div class="container" tabindex="0"></div>');

        if (this.$attrs.header) {
          const header = angular.element(`<div class='header'>
                                            <h1>${$attrs.header}</h1>
                                            <button type="button" class='om-icon icon-close clickable' ng-click='${closeAction}'/></button>
                                          </div>`);
          container.append(header);
        }
        const body = angular.element('<div class="body"></div>');
        body.html(this.$templateCache.get(templateName));
        container.append(body);

        const modal = angular.element('<div class="om-modal"></div>');
        modal.append(this.$compile(container)(this.$scope));
        $animate.enter(modal, this.$document.find('body'));

        const renderedModalContainer = this.$document.find('.om-modal .container');
        renderedModalContainer[0].focus();
        renderedModalContainer.on('keydown', this.trapTabFocus.bind(this));
      },
      close: () => this.$animate.leave(this.$document.find('.om-modal')),
    };
    $transclude(this.$scope, transEl => this.$element.append(transEl));
  }
  trapTabFocus(e) {
    const container = this.$document.find('.om-modal .container');
    const TAB_KEY = 9;
    const ESC_KEY = 27;
    const focusableElementsString =
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), *[tabindex]';
    const excludeHiddenElement = element =>
      !(
        angular
        .element(element)
        .parents()
        .hasClass('ng-hide') || angular.element(element).hasClass('ng-hide')
      );
    const focusableElements = Array.from(container.querySelectorAll(focusableElementsString)).filter(excludeHiddenElement);
    const currentTabElement = this.$document[0].activeElement;
    if (focusableElements.length > 0) {
      const firstTabElement = focusableElements[0];
      const lastTabElement = focusableElements[focusableElements.length - 1];
      if (e.keyCode === TAB_KEY) {
        if (lastTabElement.isSameNode(currentTabElement)) {
          e.preventDefault();
          firstTabElement.focus();
        }
      }
    } else {
      currentTabElement.focus();
    }
    if (e.keyCode === ESC_KEY) {
      this.$scope.$modal.close();
    }
  }
}

omModalCtrl.$inject = ['$scope', '$attrs', '$parse', '$templateCache', '$compile', '$document', '$animate', '$transclude', '$element'];

export const omModal = () => ({
  restrict: 'E',
  transclude: true,
  scope: true,
  template: '<div class="om-modal-control" />',
  controller: 'omModalCtrl',
  controllerAs: 'modalCtrl',
});

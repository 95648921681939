export function TokenInterceptor(ENV, authToken) {
  return {
    request(config) {
      if (config.url.indexOf(ENV.api.baseUrl) === 0) {
        const token = authToken.get();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
  };
}

TokenInterceptor.$inject = ['ENV', 'authToken'];

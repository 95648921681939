export const omCollapseExpand = () => ({
  restrict: 'AE',
  require: '^omCollapse',
  link(scope, element, attrs, collapse) {
    element.addClass('om-collapse-expand');
    element.on('click', event => {
      collapse.expand();
      event.stopPropagation();
    });
  },
});

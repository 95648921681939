class ProcedureOrderListItemController {
  constructor($state, $analytics, ProcedureOrderService) {
    this.$state = $state;
    this.$analytics = $analytics;
    this.isProcedureOrder = true;
    this.ProcedureOrderService = ProcedureOrderService;
  }

  $onInit() {
    this.ordered_by = this.order.ordered_by;
    this.title = this.order.procedure_type_short_description;
    this.setSubtitle();
  }

  editView() {
    this.$state.go('app.chart.orders.editProcedureOrder', {
      id: this.order.id,
    });
  }

  setSubtitle() {
    if (this.order.contact && this.order.contact.name) {
      this.subtitle = this.order.contact.name;
    } else {
      this.subtitle = '';
    }
  }

  isDeleteable() {
    return this.order.status === 'unsigned' || this.order.status === 'signed';
  }

  deleteOrder() {
    this.ProcedureOrderService.delete(this.order.id);
    this.$analytics.eventTrack('Procedure Order Deleted', {
      workflow: 'Charting',
      component: 'Orders',
      orderType: 'Procedure',
      subComponent: 'Orders List',
      orderId: this.order.id,
    });
    this.ordersComponent.remove(this.order);
  }
}

ProcedureOrderListItemController.$inject = ['$state', '$analytics', 'ProcedureOrderService'];

export const omProcedureOrderListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: ProcedureOrderListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

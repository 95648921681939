export const omConfirmDrawer = {
  bindings: {
    onCancel: '&',
    onConfirm: '&',
    confirmationQuestion: '@',
    confirmButtonText: '@',
    confirmButtonDisabled: '<'
  },
  templateUrl: 'orders/shared/confirm-drawer.component.html',
};

const PRESET_LOCATION_QUERY = ' ';
const FIELDS = ['geometry'];
const RADIUS = 200000; // 200 km ~= 125 mi

class GooglePlacesController {
  constructor(
    $element,
    $timeout,
    uiGmapGoogleMapApi,
    LoggerService,
    GooglePlacesService,
  ) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.uiGmapGoogleMapApi = uiGmapGoogleMapApi;
    this.LoggerService = LoggerService;
    this.GooglePlacesService = GooglePlacesService;
    this.query = '';
  }

  $onInit() {
    if (this.locationBias) {
      this._setLocationBias();
    }
    if (this.selectedLocation && this.selectedLocation.address) {
      this.showSelectedLocation = true;
      // Hide placeholder even though nothing is in the input.
      this.query = PRESET_LOCATION_QUERY;
    }
  }

  _setLocationBias() {
    this.uiGmapGoogleMapApi.then(maps => {
      this.location = new maps.LatLng(
        this.locationBias.latitude,
        this.locationBias.longitude,
      );
    });
  }

  $postLink() {
    this.input = this.$element.find('input[uib-typeahead]');
  }

  _onResult(result) {
    this.onResult(result);
    // Manually set this, because one-way binding does not update when object identity changes.
    this.selectedLocation = result && result.location;
  }

  getPredictions(query) {
    this.predictionError = null;
    const request = {
      input: query,
    };
    if (this.location) {
      Object.assign(request, {
        location: this.location,
        radius: RADIUS,
      });
    }
    return this.GooglePlacesService.getPlacePredictions(request).then(
      predictions => {
        this.predictions = predictions;
        return predictions;
      },
      err => {
        this.LoggerService.error(err, request);
        this.predictionError = 'Could not retrieve autocomplete predictions';
        this.predictions = [];
        return this.predictions;
      },
    );
  }

  onKeyUp($event) {
    if (this.queryFocus && this.showDefaultLocations) {
      this._navigateDefaultLocations($event);
    }
  }

  _navigateDefaultLocations($event) {
    if ($event.key === 'ArrowDown') {
      $event.stopPropagation();
      this.keyActiveLocation += 1;
      if (this.keyActiveLocation >= this.defaultLocations.length) {
        this.keyActiveLocation = 0;
      }
      this.mouseActiveLocation = null;
    } else if ($event.key === 'ArrowUp') {
      $event.stopPropagation();
      this.keyActiveLocation -= 1;
      if (this.keyActiveLocation < 0) {
        this.keyActiveLocation = this.defaultLocations.length - 1;
      }
      this.mouseActiveLocation = null;
    } else if ($event.key === 'Enter') {
      $event.stopPropagation();
      this.selectLocation(this.defaultLocations[this.keyActiveLocation]);
    }
  }

  onPlaceChanged() {
    // Do not show selected preset location anymore, we've chosen a Google place
    this.hasPresetLocation = false;

    const request = { placeId: this.query.place_id, fields: FIELDS };
    return this.GooglePlacesService.getPlaceDetails(
      request,
      this._getAttributionElement(),
    ).then(place => {
      this._onResult({
        location: {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        },
      });
      this.$timeout(() => this.input.blur());
    });
  }

  _getAttributionElement() {
    return this.$element.find('.google-places-attribution')[0];
  }

  onQueryBlur() {
    if (!this.selectedLocation) {
      // Erase query if no actual result is selected.
      // This avoids looking like we are using the search query when we are not.
      this.query = '';
      this.predictions = null;
      this._onResult({ location: null });
    } else if (this.hasPresetLocation) {
      // If a preset location was selected and nothing changed, show that instead (and hide the placeholder).
      this.query = PRESET_LOCATION_QUERY;
      this.hasPresetLocation = false;
    }
    // Show selected location on top of input.
    this.showSelectedLocation = true;
    // Remove keyboard focus from default locations menu so it can be hidden if the mouse is also no longer on it.
    this.keyActiveLocation = null;
  }

  onQueryChange() {
    if (this.query.length === 0) {
      // Erase location if all text is deleted.
      this.predictions = null;
      this._onResult({ location: null });

      // Show preset options menu if no Google Places query is typed in.
      this._showDefaultLocations();
    } else {
      // Hide preset options menu if a Google Places query is typed in.
      this._hideDefaultLocations();
    }
  }

  onQueryFocus($event) {
    // Show preset address on focus if a preset option was selected.
    if (this.selectedLocation && this.selectedLocation.address) {
      this.hasPresetLocation = true;
      this.query = this.selectedLocation.address;
    }

    // Highlight existing text to allow easy changing.
    this.$timeout(() => {
      $event.target.select();
    });

    // Show preset options menu when the input is first focused.
    // This way you can choose one immediately.
    this._showDefaultLocations();
    // Hide selected location from on top of input.
    this.showSelectedLocation = false;
  }

  selectLocation(location) {
    // Hide placeholder even though nothing is in the input.
    this.query = PRESET_LOCATION_QUERY;
    this._onResult({ location });
    this.input.blur();
    this.keyActiveLocation = null;
    this.mouseActiveLocation = null;
  }

  _hideDefaultLocations() {
    this.showDefaultLocations = false;
  }

  _showDefaultLocations() {
    this.showDefaultLocations = true;
    this.keyActiveLocation = 0;
  }
}

GooglePlacesController.$inject = [
  '$element',
  '$timeout',
  'uiGmapGoogleMapApi',
  'LoggerService',
  'GooglePlacesService',
];

export const omGooglePlaces = {
  bindings: {
    defaultLocations: '<',
    locationBias: '<',
    onResult: '&',
    selectedLocation: '<',
  },
  templateUrl: 'shared/google-places.component.html',
  controller: GooglePlacesController,
};

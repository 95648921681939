class ProblemFormController {
  constructor(ENV) {
    this.problemTypesIndex = ENV.search.indexes.problem_types;
    this.problemCodesIndex = ENV.search.indexes.problem_codes;
  }

  delete() {
    const problem = this.problem;
    return this.deleteProblem({ problem });
  }

  save() {
    const problem = this.problem;
    if (hasOwnProperty.call(problem, 'a_and_p_display_text')) {
      problem.a_and_p = problem.a_and_p_display_text;
    }
    return this.saveProblem({ problem });
  }

  onProblemCodeSelect() {
    this.problem.custom_problem_type_code = this.problem.custom_problem_code_selection.code;
    this.problem.custom_problem_type_code_description = this.problem.custom_problem_code_selection.detail_description;
  }

  createStandardProblem() {
    this.problem.use_custom_problem_type = false;
    this.problem.problem_type = null;
    this.problem.custom_problem_type_description = null;
  }
}

ProblemFormController.$inject = ['ENV'];

export const omProblemForm = {
  bindings: {
    problem: '=',
    isAllowedToAutoCreateBasicFollowUpOrders: '<',
    showHistory: '<',
    saveProblem: '&',
    deleteProblem: '&',
    addProblemSessionId: '<',
  },
  controller: ProblemFormController,
  templateUrl: 'problems/problem-form.component.html',
};

export class SummaryAddendumService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  save(data) {
    return this.ApiService.save(
      `/v2/admin/summaries/${data.summary_id}/summary_addendums`,
      data,
    );
  }
}

SummaryAddendumService.$inject = ['ApiService'];

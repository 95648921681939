class MessagingController {
  constructor(
    ENV,
    focus,
    toastr,
    MessageTemplatesService,
    PatientTimelinePostService,
    PatientTimelineCommentService,
    PatientTimelineS3PointerService,
    LoggerService,
    MessagingWarningsService,
    TodoService,
    Rollbar,
    S3,
    $q,
    $rootScope,
    $state,
    $stateParams,
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.focus = focus;
    this.toastr = toastr;
    this.LoggerService = LoggerService;
    this.MessageTemplatesService = MessageTemplatesService;
    this.MessagingWarningsService = MessagingWarningsService;
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.PatientTimelineCommentService = PatientTimelineCommentService;
    this.PatientTimelineS3PointerService = PatientTimelineS3PointerService;
    this.TodoService = TodoService;
    this.Rollbar = Rollbar;
    this.S3 = S3;
    this.messageTemplatesIndex = ENV.search.indexes.message_templates;
  }

  $onInit() {
    this.MessagingWarningsService.messagingWarnings().then(errors => {
      this.errors = errors;
    });
    this.getThreadWithTodo();

    this.formSubmitError = false;
    this.errorMsg = '';

    if (this.post.content_attributes.topic) {
      this.focus('currentMessage-body');
    }

    this.titleEditingRestricted =
      !this.post.draft && !!this.post.content_attributes.topic;
  }

  addAttachment(file) {
    if (!file) {
      return this.$q.resolve();
    }

    const key = this.PatientTimelineS3PointerService.getAttachmentKey(
      file.name,
    );
    const s3Pointer = { title: file.name };
    if (this.inProgressMessage.s3_pointers === undefined) {
      this.inProgressMessage.s3_pointers = [];
    }
    this.form.uploading = true;
    this.inProgressMessage.s3_pointers.push(s3Pointer);

    return this.S3.upload(key, file)
      .then(
        data => {
          Object.assign(s3Pointer, { bucket: data.Bucket, key: data.Key });
        },
        err => {
          const message = err.message;
          this.errorMsg = `Upload failed: ${message}`;
          this.formSubmitError = true;
          this.inProgressMessage.s3_pointers = this.inProgressMessage.s3_pointers.filter(
            pointer => pointer !== s3Pointer,
          );
        },
      )
      .then(() => {
        this.form.$dirty = true;
        this.form.uploading = false;
      });
  }

  deleteAttachment(attachment) {
    attachment._destroy = true;
    this.form.$dirty = true;
    return this.S3.delete(attachment.key, attachment.bucket);
  }

  getThreadWithTodo() {
    this.setSentComments();
    this.inProgressMessage = this.setInProgressMessage();
    this.setTodo();
  }

  applyMessageTemplate(template) {
    if (!template) {
      return;
    }

    this.MessageTemplatesService.get(template.id).then(({ html }) => {
      this.focus('currentMessage-body');
      this.$rootScope.$broadcast('omRichTextEditor-insertHTML', html);
    });
  }

  setTodo() {
    this.TodoService.getPatientTimelinePostTodo(this.post).then(todo => {
      this.todo = todo;
      this.updateButton();
    });
  }

  setSentComments() {
    this.sentComments =
      this.post.comments &&
      this.post.comments.filter(comment => !comment.draft);
  }

  completeTodo() {
    this.TodoService.completeAndSignNote(this.todo).then(todo => {
      this.todo = todo;
      this.updateButton();
    });
  }

  reopenTodo() {
    this.TodoService.reopen(this.todo).then(todo => {
      this.todo = todo;
      this.toastr.success('Your task has been reopened');
      this.$rootScope.$emit('refreshTimeline');
      this.updateButton();
    });
  }

  hasCompleteTodo() {
    return !!this.todo && this.todo.isComplete();
  }

  hasIncompleteTodo() {
    return !!this.todo && this.todo.isIncomplete();
  }

  maximize() {
    this.isMinimized = false;
    this.focus('currentMessage-body');
  }

  updateSubject(subject) {
    this.post.content_attributes.topic = subject;
    this.focus('currentMessage-body');
    const toUpdate = {
      id: this.post.id,
      content_attributes: this.post.content_attributes,
    };
    return this.PatientTimelinePostService.update(toUpdate).then(() => {
      this.titleEditingRestricted =
        this.post.content_attributes.topic && !this.post.draft;
      this.$rootScope.$emit('refreshTimeline');
    });
  }

  _reloadPost() {
    this.$rootScope.$emit('refreshTimeline');
    return this.PatientTimelinePostService.get(this.post.id).then(post => {
      this.post = post;
      this.getThreadWithTodo();
    });
  }

  _mergeComment(comment) {
    // clone S3 pointer information, but create new instances attached to this new comment
    const s3Pointers = comment.s3_pointers.map(pointer => {
      delete pointer.id;
      return pointer;
    });

    Object.assign(
      this.inProgressMessage,
      {
        html: comment.html,
        reply_to: comment.reply_to,
        notification_recipient: comment.notification_recipient,
        notify: comment.notify,
        s3_pointers: s3Pointers,
      },
    );
    this.focus('currentMessage-body');
    return this.update();
  }

  _replaceComment() {
    const oldComment = this.inProgressMessage;
    return this._reloadPost().then(() => this._mergeComment(oldComment));
  }

  _replacePost() {
    const oldPost = this.inProgressMessage;
    return this.PatientTimelinePostService.clone(oldPost).then(post => {
      this.$rootScope.$emit('refreshTimeline');
      return this.$state.go('app.chart.workspace.patientTimelinePosts', { id: post.id });
    });
  }

  _replaceDeletedInProgressMessage() {
    if (this.isPost(this.inProgressMessage)) {
      this.toastr.warning('The thread has been deleted. Creating a new thread with your changes...');
      return this._replacePost();
    }

    this.toastr.warning('This reply has been deleted. A new draft has been created with your changes.');
    return this._replaceComment();
  }

  _reloadSentInProgressMessage() {
    this.toastr.warning('This reply has already been sent. Reloading the thread...');
    return this._reloadPost();
  }

  _updateFailure(errorResponse) {
    if (errorResponse.status === 404) {
      return this._replaceDeletedInProgressMessage();
    }
    if (errorResponse.status === 422 && errorResponse.data.errors && errorResponse.data.errors.draft) {
      return this._reloadSentInProgressMessage();
    }

    throw errorResponse;
  }

  _mergeResponse(response) {
    if (response.draft !== this.inProgressMessage.draft) {
      this.toastr.warning('This thread has already been sent. Reloading the thread...');
      return this._reloadPost();
    }

    return this.mergeS3Pointers(this.inProgressMessage, response);
  }

  update() {
    if (this.sendingMessage === true) {
      return Promise.resolve();
    }

    this.updateButton();
    const toSave = Object.assign({}, this.inProgressMessage);
    const mergeResponse = this._mergeResponse.bind(this);
    const updateFailure = this._updateFailure.bind(this);

    if (this.isPost(toSave)) {
      toSave.content_attributes.html = this.inProgressMessage.html;
      return this.PatientTimelinePostService.update(toSave).then(mergeResponse, updateFailure);
    } else if (toSave.id) {
      return this.PatientTimelineCommentService.update(
        toSave,
        this.post.id,
      ).then(mergeResponse, updateFailure);
    }
    return this.PatientTimelineCommentService.save(toSave, this.post.id).then(
      response => {
        this.post.comments.push(response);
        const message = this.setInProgressMessage();
        delete message.html;
        this.inProgressMessage = Object.assign(
          {},
          this.inProgressMessage,
          message,
        );
        this.$rootScope.$emit('refreshTimeline');
        mergeResponse(response);
      }, updateFailure,
    );
  }

  send(event) {
    if (this.messageBodyPresent()) {
      const onMessageSent = post => {
        this.sendingMessage = false;
        this.post = post;
        this.toastr.success('Your message was sent');
        this.$rootScope.$emit('refreshTimeline');

        if (this.docked) {
          this.onClose();
        } else {
          this.getThreadWithTodo();
        }
      };

      const toSave = Object.assign({}, this.inProgressMessage, {
        draft: false,
        event,
      });

      const sendPost = () =>
        toSave.id
          ? this.PatientTimelinePostService.update(toSave)
          : this.PatientTimelinePostService.save(toSave);
      const sendComment = () =>
        toSave.id
          ? this.PatientTimelineCommentService.update(toSave, this.post.id)
          : this.PatientTimelineCommentService.save(toSave, this.post.id);
      this.sendingMessage = true;

      if (this.isPost(toSave)) {
        toSave.content_attributes.html = this.inProgressMessage.html;
        return sendPost().then(onMessageSent);
      }

      return sendComment()
        .then(() => this.PatientTimelinePostService.get(this.post.id))
        .then(onMessageSent);
    }
    return Promise.resolve();
  }

  messageBodyPresent() {
    const msgAsHtmlDoc = new DOMParser().parseFromString(
      this.inProgressMessage.html,
      'text/html',
    );

    if (this.form.$invalid && !this.form.$error.required) {
      this.errorMsg =
        'There was an error sending your message.  Please refresh the page and try again.';
      this.formSubmitError = true;
      // remove the following line once we can see some kind of errors in rollbar
      this.Rollbar.error(
        '[messaging] Message form invalid without LoggerService',
        this.form.$error,
      );
      this.LoggerService.error(
        '[messaging] Message form invalid',
        this.form.$error,
      );
      return false;
    }

    if (!this.inProgressMessage.html || msgAsHtmlDoc.body.textContent === '') {
      this.errorMsg = 'Please enter a message to send.';
      this.formSubmitError = true;
      return false;
    }

    this.errorMsg = '';
    this.formSubmitError = false;
    return true;
  }

  isPost(obj) {
    return !!obj.content_attributes;
  }

  setInProgressMessage() {
    if (this.post.draft) {
      this.showDeleteDraft = true;
      return this._inProgressPost();
    }

    if (!this.post.patient_visible) {
      return null;
    }

    const draftComment = this.post.comments.find(comment => comment.draft);
    if (draftComment) {
      this.showDeleteDraft = true;
      return draftComment;
    }

    this.showDeleteDraft = false;
    return { draft: true, html: '' };
  }

  _inProgressPost() {
    if (!this.post.patient_visible) {
      return null;
    }

    const inProgressMessage = this.post;
    if (this.post.content_attributes) {
      inProgressMessage.html = this.post.content_attributes.html;
    }
    return inProgressMessage;
  }

  inProgressMessageIsEmpty() {
    return !this.inProgressMessage || !this.inProgressMessage.id;
  }

  updateButton() {
    const hasIncompleteTodo = this.hasIncompleteTodo();
    const inProgressMessageIsEmpty = this.inProgressMessageIsEmpty();

    this.canFinishTaskOnly = hasIncompleteTodo && inProgressMessageIsEmpty;
    this.canSendAndFinishTask = hasIncompleteTodo && !inProgressMessageIsEmpty;
    this.canSendOnly = !hasIncompleteTodo && !!this.inProgressMessage;
    this.canReopenTask = this.hasCompleteTodo();
  }

  mergeS3Pointers(message, response) {
    const newPointers = [];
    if (!message.s3_pointers) {
      message.s3_pointers = response.s3_pointers;
      return;
    }

    message.s3_pointers.forEach(attributes => {
      const pointer = response.s3_pointers.find(
        ptr => ptr.bucket === attributes.bucket && ptr.key === attributes.key,
      );
      if (pointer) {
        newPointers.push(Object.assign({}, attributes, { id: pointer.id }));
      } else if (!attributes._destroy) {
        newPointers.push(attributes);
      }
    });
    message.s3_pointers = newPointers;
  }

  deleteDraft() {
    if (this.post.draft) {
      return this.PatientTimelinePostService.delete(this.post);
    }

    if (this.inProgressMessage.id) {
      const toDelete = this.inProgressMessage;
      return this.PatientTimelineCommentService.delete(
        toDelete,
        this.post.id,
      ).then(() => {
        this.post.comments = this.post.comments.filter(
          comment => comment.id !== toDelete.id,
        );
        this.inProgressMessage = this.setInProgressMessage();
        this.updateButton();
        this.$rootScope.$emit('refreshTimeline');
      });
    }
    return Promise.resolve();
  }

  getComments(id) {
    return this.CommentService.getPostComments(id);
  }

  saveComment(id, body) {
    return this.CommentService.savePostComment(id, { body });
  }
}

MessagingController.$inject = [
  'ENV',
  'focus',
  'toastr',
  'MessageTemplatesService',
  'PatientTimelinePostService',
  'PatientTimelineCommentService',
  'PatientTimelineS3PointerService',
  'LoggerService',
  'MessagingWarningsService',
  'TodoService',
  'Rollbar',
  'S3',
  '$q',
  '$rootScope',
  '$state',
  '$stateParams',
];

export const omMessaging = {
  bindings: {
    post: '<',
    docked: '<',
    onClose: '&',
    profile: '<',
  },
  controller: MessagingController,
  templateUrl: 'messaging/messaging.component.html',
};

import { getParamsFromUrl } from '../upgrade-param-mapper';
import {
  getPatientIdFromUrl
} from '../../upgrade/upgrade-param-mapper';

class LabOrderFormController {
  constructor(
    ENV,
    $q,
    $state,
    $stateParams,
    LabOrderService,
    toastr,
    CommentService,
    LabOrderSetService,
    MeasurementOrderTypeSourceService,
    FeaturesService,
    PatientService,
    $filter,
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.LabOrderService = LabOrderService;
    this.FeaturesService = FeaturesService;
    this.toastr = toastr;
    this.CommentService = CommentService;
    this.LabOrderSetService = LabOrderSetService;
    this.MeasurementOrderTypeSourceService = MeasurementOrderTypeSourceService;
    this.labOrderTypesIndex = ENV.search.indexes.lab_order_types;
    this.labOrderSets = [];
    this.visibleLabOrderSets = [];
    this.showMoreOptions = false;
    this.submissionInProgress = false;
    this.cancelling = false;
    this.deleting = false;
    this.signingOnBehalfOf = false;
    this.ready = false;
    this.showLabelEditor = false;
    this.PatientService = PatientService;
  }

  $onInit() {
    const orderId = getParamsFromUrl('lab_orders') || this.$stateParams.id; // This is used to get sticky states working in hybrid mode
    const getLabOrder = this.LabOrderService.get(orderId);
    const getMOTS = this.MeasurementOrderTypeSourceService.query();
    const getLabOrderSets = this.LabOrderSetService.getAll();
    this.FeaturesService.hasFeature('auto_dymo_label').then(featureEnabled => {
      this.showLabelEditor = featureEnabled;
    });
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    const getPatient = this.PatientService.get(patientId);

    this.$q
      .all([getLabOrder, getMOTS, getLabOrderSets, getPatient])
      .then(([labOrder, mots, labOrderSetResult, patient]) => {
        this.labOrder = labOrder;
        this.setLabOrder(labOrder);
        this.measurementOrderTypeSources = mots;
        this.labOrderSets = labOrderSetResult;
        this.updateVisibleLabOrderSets();
        this.ready = true;
        this.patient = patient;
        this.labOrder.measurement_order_type_source_id = this.getDefaultLabSourceId();
      });
  }

  addLabOrderSet = labOrderSet => {
    labOrderSet.lab_order_types.forEach(labTest =>
      this.labOrder.addLabTest(labTest),
    );
    return this.updateLabOrder();
  };

  addLabTest = labOrderType => {
    this.labOrder.addLabTest(labOrderType);
    return this.updateLabOrder();
  };

  addCustomLabTest = labOrderType => {
    this.labOrder.addCustomLabTest(labOrderType);
    return this.updateLabOrder();
  };

  removeLabTest = labOrderType => {
    this.labOrder.removeLabTest(labOrderType);
    return this.updateLabOrder();
  };

  updateVisibleLabOrderSets = () => {
    if (!this.labOrder || !this.labOrder.lab_order_indications) {
      this.visibleLabOrderSets = [];
      return;
    }

    const indicatedProblemTypeIds = this.labOrder.lab_order_indications.map(
      indication => indication.problem_type.id,
    );

    this.visibleLabOrderSets = this.labOrderSets.filter(orderSet => {
      const orderSetProblemTypeIds = orderSet.problem_types.map(
        problemType => problemType.id,
      );
      return indicatedProblemTypeIds.some(problemTypeId =>
        orderSetProblemTypeIds.includes(problemTypeId),
      );
    });
  };

  customErrors = () => ({
    noIndications: !(
      this.labOrder.lab_order_indications &&
      this.labOrder.lab_order_indications.length > 0
    ),
    noTests: !(
      this.labOrder.lab_order_types && this.labOrder.lab_order_types.length > 0
    ),
  });

  hasIndicationsAndTests = () =>
    !(this.customErrors().noTests || this.customErrors().noIndications);

  setLabOrder = (obj = null) => {
    this.labOrder = obj;
    this.editable = this.labOrder.status === 'unsigned';
    this.declinedLabOrder = this.labOrder.status === 'declined';
    if (
      this.labOrder &&
      (this.labOrder.note_to_phleb || this.labOrder.stat || this.labOrder.cc)
    ) {
      this.showMoreOptions = true;
    } else {
      this.showMoreOptions = false;
    }
  };

  updateLabOrder = () =>
    this.LabOrderService.update(this.labOrder).then(result => {
      this.labOrder.merge(result);
    });

  cancelLabOrder = () => {
    this.labOrder.status = 'cancelled';
    return this.LabOrderService.update(this.labOrder).then(result => {
      this.labOrder = result;
      this.toggleCancelling();
    });
  };

  signingDisabled = form => form.saving || this.submissionInProgress;
  isValid = form => form.$valid && this.hasIndicationsAndTests();

  signLabOrder = (condition, signOnBehalfOf) => {
    if (!condition) {
      return Promise.reject();
    }

    if (signOnBehalfOf) {
      this.labOrder.signed_on_behalf_of_id = signOnBehalfOf.database_id;
    }

    this.submissionInProgress = true;
    this.labOrder.status = 'signed';
    return this.LabOrderService.update(this.labOrder).then(result => {
      this.setLabOrder(result);
      this.submissionInProgress = false;
      this.closeSigningOnBehalfOf();
    });
  };

  sampleCollected = () =>
    this.LabOrderService.collect(this.labOrder).then(
      result => {
        this.setLabOrder(result);
      },
      e => {
        this.errors = e.data.errors.base[0];
      },
    );

  cloneLabOrder = () =>
    this.LabOrderService.clone(this.labOrder.id).then(result => {
      const cloneLink = this.$state.href('app.chart.orders.edit', {
        id: result.id,
      });
      this.toastr.success(
        `Your <strong>lab order</strong> has been cloned.  <a href=${cloneLink}>View clone</a>`,
      );
      this.returnToOrderList();
    });

  deleteLabOrder = () =>
    this.LabOrderService.delete(this.labOrder.id).then(() => {
      this.returnToOrderList();
      this.deleting = false;
    });

  reactivate = () => {
    this.labOrder.status = 'signed';
    this.LabOrderService.update(this.labOrder).then(result => {
      this.setLabOrder(result);
    });
  };

  returnToOrderList = () => this.$state.go('app.chart.orders.list');

  editSignedOrder = () =>
    this.LabOrderService.get(this.labOrder.id).then(response => {
      this.labOrder = response;
      if (response.status === 'unsigned' || response.status === 'signed') {
        this.labOrder.status = 'unsigned';
        this.updateLabOrder();
        this.editable = true;
      } else {
        this.editable = this.labOrder.status === 'unsigned';
        this.errors =
          'This lab order has already been collected or cancelled and therefore may no longer be edited.';
      }
    });

  getDefaultLabSourceId = () => {
    const defaultSource = this.measurementOrderTypeSources.find(
      source => this.labOrder.measurement_order_type_source_id === source.id,
    );

    const firstMeasurementTypeSource = this.measurementOrderTypeSources[0];
    const labSourceId = defaultSource ? defaultSource.id : firstMeasurementTypeSource.id;

    return labSourceId;
  };

  isLabcorp = () => {
    const selectedSource = this.measurementOrderTypeSources.find(
      source => this.labOrder.measurement_order_type_source_id === source.id,
    );
    return !selectedSource || selectedSource.name === 'Labcorp';
  };

  isQuest = () => {
    const selectedSource = this.measurementOrderTypeSources.find(
      source => this.labOrder.measurement_order_type_source_id === source.id,
    );
    return !selectedSource || selectedSource.name.startsWith('Quest');
  };

  isUCSD = () => {
    const selectedSource = this.measurementOrderTypeSources.find(
      source => this.labOrder.measurement_order_type_source_id === source.id,
    );
    return !selectedSource || selectedSource.name === 'UCSD Health';
  };

  toggleCancelling = () => {
    this.cancelling = !this.cancelling;
  };

  toggleDeleting = () => {
    this.deleting = !this.deleting;
  };

  closeSigningOnBehalfOf = () => {
    this.signingOnBehalfOf = false;
  };

  openSigningOnBehalfOf(form) {
    this.signingOnBehalfOf = true;
    if (form.$valid) {
      // Prevents assignee search box indicating error when pristine
      form.$setPristine();
    } else {
      form.$setSubmitted();
    }
  }

  async copyDymo(event, includeSex = false) {
    const element = angular.element(event.target);
    let dymo = `First Name: ${this.patient.first_name}\nLast Name: ${this.patient.last_name}\nDOB: ${this.$filter('omDate4y')(this.patient.dob)}`;
    const dymoType = includeSex ? 'Long Label' : 'Short Label';
    if (includeSex) {
      dymo += `\nSex: ${this.patient.gender[0]}`;
    }
    try {
      await navigator.clipboard.writeText(dymo);
      this.toastr.success(`Copied ${dymoType} to clipboard`);
    } catch (err) {
      this.toastr.warn(`Couldn't copy ${dymoType} to clipboard`);
    } finally {
      element.blur();
    }
  }

  getComments = id => this.CommentService.getLabOrderComments(id);
  saveComment = (id, body) =>
    this.CommentService.saveLabOrderComment(id, { body });
}

LabOrderFormController.$inject = [
  'ENV',
  '$q',
  '$state',
  '$stateParams',
  'LabOrderService',
  'toastr',
  'CommentService',
  'LabOrderSetService',
  'MeasurementOrderTypeSourceService',
  'FeaturesService',
  'PatientService',
  '$filter',
];

export const omLabOrderForm = {
  templateUrl: 'orders/lab/lab-order-form.component.html',
  controller: LabOrderFormController,
  bindings: {
    patient: '<',
  },
};

const noteTypePath = '/v2/admin/note_types';

export class NoteTypeService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  query() {
    return this.ApiService.get(noteTypePath);
  }
}

NoteTypeService.$inject = ['ApiService'];

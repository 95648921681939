export function omLegacyUiHref(LegacyUiHrefService) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      return element.bind('click', event => {
        LegacyUiHrefService.openLegacyHref(attrs.omLegacyUiHref, attrs.target);
        return event.preventDefault();
      });
    },
  };
}

omLegacyUiHref.$inject = ['LegacyUiHrefService'];

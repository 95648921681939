const POST_ENDPOINT_PREFIX = '/v2/admin/patients';
const POST_ENDPOINT = 'patient_timeline/posts';
export class PatientTimelinePostService {
  constructor(ApiService, $stateParams, $rootScope) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
  }

  get(postId) {
    return this.ApiService.get(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${postId}`,
    );
  }

  save(post) {
    return this.ApiService.save(
      `${POST_ENDPOINT_PREFIX}/${this.$stateParams.patientId}/${POST_ENDPOINT}`,
      post,
    );
  }

  update(post) {
    return this.ApiService.update(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${post.id}`,
      post,
    );
  }

  create(topic, labId) {
    let post = {
      content_type: 'text',
      draft: true,
    };
    if (labId) {
      post = Object.assign(post, { lab_data_id: labId });
    }
    if (topic) {
      post = Object.assign(post, {
        content_attributes: { topic },
      });
    }
    return this.save(post);
  }

  clone(post) {
    const newPost = _.cloneDeep(post);
    delete newPost.id;
    delete newPost.content_attributes.id;
    if (newPost.s3_pointers) {
      newPost.s3_pointers.forEach(pointer => {
        delete pointer.id;
      });
    }
    return this.save(newPost);
  }

  move(post) {
    return this.ApiService.update(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${post.id}/move`,
      post,
    );
  }

  delete(post) {
    return this.ApiService.delete(
      `${POST_ENDPOINT_PREFIX}/${
        this.$stateParams.patientId
      }/${POST_ENDPOINT}/${post.id}`,
    ).then(response => {
      this.$rootScope.$emit('deleteDraftMessage');
      return response;
    });
  }
}

PatientTimelinePostService.$inject = [
  'ApiService',
  '$stateParams',
  '$rootScope',
];

import { AppointmentService } from './appointment.service';
import { CartFillDates } from './cart-fill-dates';
import { CartValidationService } from './cart-validation';
import { ChartAccessService } from './chart-access.service';
import { EncounterController } from './encounter.controller';
import { PatientTimelinePostService } from '../messaging/patient-timeline-post.service';
import { omTosReminder } from './tos-reminder.component';
import { TOSReminderService } from './tos-reminder.service';
import '../notes/notes.module';
import '../summaries/summaries.module';
import '../timeline/timeline.module';
import '../workspace/workspace.module';
import '../notes/letters.module';

const saveLastEditedSummary = ($rootScope, $state, $transitions) => {
  $transitions.onStart(
    {
      from: state =>
        state.parent && state.parent.includes['app.chart.workspace'],
    },
    () => {
      let lastEditedSummaryId;
      if ($state.is('app.chart.workspace.summaries')) {
        lastEditedSummaryId = $state.params.id;
      }
      $rootScope.lastEditedSummary = lastEditedSummaryId;
      return true;
    },
  );
};

saveLastEditedSummary.$inject = ['$rootScope', '$state', '$transitions'];

angular
  .module('onelifeUi.encounter', [
    'onelifeUi.shared',
    'onelifeUi.notes',
    'onelifeUi.summaries',
    'onelifeUi.timeline',
    'onelifeUi.workspace',
    'onelifeUi.letters',
  ])
  .run(saveLastEditedSummary)
  .component('omTosReminder', omTosReminder)
  .controller('EncounterController', EncounterController)
  .factory('AppointmentService', AppointmentService)
  .factory('PatientTimelinePostService', PatientTimelinePostService)
  .factory('TOSReminderService', TOSReminderService)
  .service('CartFillDates', CartFillDates)
  .service('CartValidation', CartValidationService)
  .service('ChartAccessService', ChartAccessService);

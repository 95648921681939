export const FOLLOW_UP_ORDER_TYPES = {
  visit: {
    name: 'VisitFollowUpOrder',
    shortName: 'visit',
    label: 'In Office Visit',
    analyticsType: 'Visit',
    path: 'visit_follow_up_orders',
  },
  basic: {
    name: 'BasicFollowUpOrder',
    shortName: 'basic',
    label: 'Specific Problem',
    analyticsType: 'Basic',
    path: 'basic_follow_up_orders',
  },
  generic: {
    name: 'GenericFollowUpOrder',
    shortName: 'generic',
    label: 'General',
    analyticsType: 'Generic',
    path: 'generic_follow_up_orders',
  },
};

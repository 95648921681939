class SummariesController {
  constructor(
    $rootScope,
    CommentService,
    SummaryService,
    TodoService,
    $stateParams,
    $q,
  ) {
    this.$rootScope = $rootScope;
    this.CommentService = CommentService;
    this.SummaryService = SummaryService;
    this.TodoService = TodoService;
    this.$stateParams = $stateParams;
    this.$q = $q;
  }

  $onInit() {
    const getSummary = this.SummaryService.get();
    const getTodo = this.TodoService.getSummaryTodo({
      id: this.$stateParams.id,
    });
    this.$q.all([getSummary, getTodo]).then(([summary, todo]) => {
      this.summary = summary;
      this.todo = todo;
      this.$rootScope.$emit('summaryLoaded', this.summary.signed);
    });

    this.$rootScope.$on('summaryAlreadySigned', (event, updatedSummary) => {
      this.summary = updatedSummary;
    });
  }

  closeSummary() {
    this.$rootScope.$emit('summaryClosed', this.summary);
  }

  getComments(id) {
    return this.CommentService.getNoteComments(id);
  }

  saveComment(id, body) {
    return this.CommentService.saveNoteComment(id, { body });
  }
}

SummariesController.$inject = [
  '$rootScope',
  'CommentService',
  'SummaryService',
  'TodoService',
  '$stateParams',
  '$q',
];

export const omSummaries = {
  bindings: {
    profile: '<', // inherited through 'app' state resolve
  },
  controller: SummariesController,
  templateUrl: 'summaries/summaries.component.html',
};

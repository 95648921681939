import { ElasticsearchLocationService } from './elasticsearch-location.service';
import { omSearchController } from './search.controller';
import { omSearch } from './search.directive';
import { omSearchBox } from './search-box.directive';
import { omSearchFilter } from './search-filter.directive';
import { omSearchFiltersCtrl } from './search-filters.controller';
import { omSearchFilters } from './search-filters.directive';
import { Search } from './search.service';

angular
  .module('onelifeUi.search', ['elasticsearch'])
  .service('ElasticsearchLocationService', ElasticsearchLocationService)
  .controller('omSearchCtrl', omSearchController)
  .controller('omSearchFiltersCtrl', omSearchFiltersCtrl)
  .directive('omSearch', omSearch)
  .directive('omSearchBox', omSearchBox)
  .directive('omSearchFilter', omSearchFilter)
  .directive('omSearchFilters', omSearchFilters)
  .service('$search', Search);

export const omFormSpinnerIcon = () => ({
  require: '^omForm',
  template:
    '<i class="fa fa-lg fa-spinner fa-pulse" ng-show="omFormCtrl.spinnerVisible"></i>',
  link(scope, element, attrs, omFormCtrl) {
    element.addClass('om-form-spinner-icon');
    angular.extend(omFormCtrl.form, {
      showSpinner() {
        omFormCtrl.spinnerVisible = true;
      },
      hideSpinner() {
        omFormCtrl.spinnerVisible = false;
      },
    });
  },
});

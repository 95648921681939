export class CommentService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getRenewalComments({ id: commentable_id, class_name: commentable_type }) {
    return this.ApiService.get('/v2/admin/comments', {
      params: { commentable_id, commentable_type },
    });
  }

  saveRenewalComment({
    id: commentable_id,
    class_name: commentable_type,
    newComment,
  }) {
    const { body } = newComment;
    return this.ApiService.save('/v2/admin/comments', {
      commentable_id,
      commentable_type,
      body,
    });
  }

  getNoteComments({ id }) {
    return this.ApiService.get(`/v2/admin/notes/${id}/comments`);
  }

  saveNoteComment({ id, newComment: comment }) {
    return this.ApiService.save(`/v2/admin/notes/${id}/comments`, comment);
  }

  getLabOrderComments({ id }) {
    return this.ApiService.get(`/v2/admin/lab_orders/${id}/comments`);
  }

  saveLabOrderComment({ id, newComment: comment }) {
    return this.ApiService.save(`/v2/admin/lab_orders/${id}/comments`, comment);
  }

  getConsultOrderComments({ id }) {
    return this.ApiService.get(`/v2/admin/consult_orders/${id}/comments`);
  }

  saveConsultOrderComment({ id, newComment: comment }) {
    return this.ApiService.save(
      `/v2/admin/consult_orders/${id}/comments`,
      comment,
    );
  }

  getProcedureOrderComments({ id }) {
    return this.ApiService.get(`/v2/admin/procedure_orders/${id}/comments`);
  }

  saveProcedureOrderComment({ id, newComment: comment }) {
    return this.ApiService.save(
      `/v2/admin/procedure_orders/${id}/comments`,
      comment,
    );
  }

  getPostComments({ id }) {
    return this.ApiService.get(`/v2/admin/posts/${id}/comments`);
  }

  savePostComment({ id, newComment: comment }) {
    return this.ApiService.save(`/v2/admin/posts/${id}/comments`, comment);
  }

  delete(id) {
    return this.ApiService.delete(`/v2/admin/comments/${id}`);
  }
}

CommentService.$inject = ['ApiService'];

class FaxButtonMenuItemController {
  constructor(toastr, NoteService) {
    this.toastr = toastr;
    this.NoteService = NoteService;
    this.messages = {
      faxQueued: 'Your document has been added to the outbound fax queue.',
    };
  }

  openModal(modal) {
    this.modal = modal;
    modal.open();
  }

  fax() {
    const data = {
      faxNumber: this.faxNumber,
      coverPageText: this.coverPageText,
    };

    return this.NoteService.fax(this.noteId, data).then(note => {
      this.modal.close();
      this.toastr.success(this.messages.faxQueued);
      return note;
    });
  }
}

FaxButtonMenuItemController.$inject = ['toastr', 'NoteService'];

export const omFaxButtonMenuItem = {
  bindings: {
    noteId: '<',
    asButton: '<',
  },
  controller: FaxButtonMenuItemController,
  templateUrl: 'shared/fax-button-menu-item.component.html',
};

export class TOSReminderService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  post(patientId) {
    return this.ApiService.save(
      `/v2/admin/patients/${patientId}/send_reminder_email`,
    ).then(response => response.data);
  }
}

TOSReminderService.$inject = ['ApiService'];

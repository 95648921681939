export class Renewal {
  constructor(attributes = {}) {
    attributes.comments = [];
    attributes.earliest_fill_date = attributes.earliest_fill_date
      ? new Date(attributes.earliest_fill_date)
      : null;

    attributes.valid_prescribers = attributes.valid_prescribers || [];

    attributes.requestedByPatient =
      attributes.class_name === 'PatientRxRequest';

    attributes = this._setPrescriber(attributes);

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }
  _setPrescriber(attributes) {
    const onlyOneValidPrescriberExists =
      attributes.valid_prescribers &&
      attributes.valid_prescribers.length === 1 &&
      (attributes.prescriber === undefined ||
        attributes.prescriber.id !== attributes.valid_prescribers[0].id);

    const noValidPrescriberSelected =
      !attributes.prescriber && attributes.valid_prescribers;
    if (onlyOneValidPrescriberExists || noValidPrescriberSelected) {
      attributes.prescriber = attributes.valid_prescribers[0];
    }

    if (attributes.prescriber) {
      if (!attributes.prescriber_id)
        attributes.prescriber_id = attributes.prescriber.id;
      if (attributes.prescribing_credential)
        attributes.prescribing_credential_id =
          attributes.prescribing_credential.id;
    }
    return attributes;
  }
  updateAssignee(assignee) {
    this.task = Object.assign({}, this.task, {
      assignee: { id: assignee.id, name: this.formatAssignee(assignee.name) },
    });
  }
  setCredentials(
    creds = {
      prescribing_credentials: [],
    },
  ) {
    this.prescribingCredentials = creds.prescribing_credentials;
    this.prescribing_credential = creds;
  }
  formatAssignee(fullFirstLastName) {
    const names = fullFirstLastName.split(' ');
    return [names[0][0], names[1]].join('. ');
  }
  isPending() {
    return this.cart_state === 'new';
  }
  isDenied() {
    return this.cart_state === 'do_not_prescribe';
  }
  isApproved() {
    return this.cart_state === 'prescribe';
  }
  updateFillDates(minDate, forceOverwrite = false) {
    this.min_earliest_fill_date = minDate;
    if (
      forceOverwrite ||
      !this.earliest_fill_date ||
      (!!this.earliest_fill_date &&
        this.min_earliest_fill_date.getTime() >
          this.earliest_fill_date.getTime())
    ) {
      this.earliest_fill_date = new Date(this.min_earliest_fill_date.getTime());
    }
  }
}

class SupportingDocumentsController {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
    this.pageLimitMap = {
      Fax: 30,
      'Direct Message': 100,
    };
  }

  $onInit() {
    this.setPageCount();
    this.setTransmissionMethod();
    this.setTransmissionMethodPageLimit();
  }

  $onChanges(changes) {
    if (changes) {
      if (changes.documents) {
        this.setPageCount();
      }
      if (changes.contact) {
        this.setTransmissionMethod();
        this.setTransmissionMethodPageLimit();
      }
    }
  }

  setPageCount() {
    this.pageCount = this.documents.reduce(
      (total, doc) => total + doc.approximate_pdf_pages,
      0,
    );
  }

  setTransmissionMethod() {
    if (this.contact && this.contact.direct_messaging_enabled) {
      this.transmissionMethod = 'Direct Message';
    } else {
      this.transmissionMethod = 'Fax';
    }
  }

  setTransmissionMethodPageLimit() {
    this.transmissionMethodPageLimit = this.transmissionMethod
      ? this.pageLimitMap[this.transmissionMethod]
      : this.pageLimitMap.Fax;
  }

  setTimelineNote(id) {
    this.$rootScope.$emit('set-timeline-note', id);
  }

  supportURL() {
    return 'https://sites.google.com/a/onemedical.com/one-medical-wiki/the-1life-web-application/fixing-broken-documents';
  }
}

SupportingDocumentsController.$inject = ['$rootScope'];

export const omSupportingDocuments = {
  bindings: {
    documents: '<',
    unsuccessfulDocuments: '<',
    editable: '<',
    contact: '<',
  },
  controller: SupportingDocumentsController,
  templateUrl: 'orders/shared/supporting-documents.component.html',
};

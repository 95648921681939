class EditInPlaceController {
  constructor(focus) {
    this.focus = focus;
  }

  $onInit() {
    if (this.required === undefined) this.required = true;
    if (this.name === undefined) this.name = 'inPlaceTitle';
    if (this.autocomplete === undefined) this.autocomplete = 'off';

    this.editingSubject = !this.textToEdit;
    this.focusOnInput();
  }

  toggleEditingSubject() {
    this.editingSubject = !this.textToEdit || !this.editingSubject;
  }

  focusOnInput() {
    this.focus('editTitleInPlace-input');
  }

  update() {
    this.toggleEditingSubject();
    return this.updateText({ subject: this.textToEdit || '' });
  }
}

EditInPlaceController.$inject = ['focus'];

export const omEditTitleInPlace = {
  bindings: {
    required: '<',
    textToEdit: '<',
    updateText: '&',
    placeholder: '@',
    name: '@',
    autocomplete: '@',
  },
  controller: EditInPlaceController,
  template: `<span om-layout om-layout-align="start center" om-layout-gutter
                  ng-if="!$ctrl.editingSubject"
                  ng-click="$ctrl.toggleEditingSubject(); $ctrl.focusOnInput();">
               <span>{{$ctrl.textToEdit | cut:false:90}}</span>
               <span om-flex="5" class="om-icon icon-edit"></span>
             </span>
             <input class="om-input -edit-in-place"
                    name="{{$ctrl.name}}"
                    placeholder="{{$ctrl.placeholder}}"
                    autocomplete="{{$ctrl.autocomplete}}"
                    type="text"
                    ng-blur="$ctrl.update()"
                    ng-enter="$ctrl.update()"
                    ng-if="$ctrl.editingSubject"
                    ng-required="{{$ctrl.required}}"
                    ng-model="$ctrl.textToEdit"
                    om-focus-on="editTitleInPlace-input"
                    om-ignore-dirty
                    om-layout-fill>`,
};

export class SurveyOrderService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  create(patientId, order) {
    return this.ApiService.save(
      `/v2/admin/patients/${patientId}/survey_orders`,
      order,
    );
  }

  delete(patientId, order) {
    return this.ApiService.delete(
      `/v2/admin/patients/${patientId}/survey_orders/${order.id}`,
    );
  }

  get(patientId, orderId) {
    return this.ApiService.get(
      `/v2/admin/patients/${patientId}/survey_orders/${orderId}`,
    ).then(response => {
      response.due_on = new Date(response.due_on);
      return response;
    });
  }
}

SurveyOrderService.$inject = ['ApiService'];

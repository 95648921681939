class SignedVaccineOrderController {
  constructor() {
    this.showEditableDueDate = false;
  }
}

export const omSignedVaccineOrder = {
  controller: SignedVaccineOrderController,
  templateUrl: 'orders/vaccine/signed-vaccine-order.component.html',
  bindings: {
    vaccineOrder: '<',
    updateVaccineOrder: '<',
    allowEditableDueDate: '<',
  },
};

import { LabOrder } from './lab-order';
import { LabOrderService } from './lab-order.service';
import { LabOrderSetService } from './lab-order-set.service';
import { omLabOrderForm } from './lab-order-form.component';
import { omLabOrderListItem } from './lab-order-list-item.component';

angular
  .module('onelifeUi.orders.lab', [])
  .component('omLabOrderForm', omLabOrderForm)
  .component('omLabOrderListItem', omLabOrderListItem)
  .factory('LabOrder', () => LabOrder)
  .factory('LabOrderService', LabOrderService)
  .factory('LabOrderSetService', LabOrderSetService);

export const timeFrame = () => (_.memoize((collection) => {
  const dayInMilliseconds = 1000 * 60 * 60 * 24;
  const weekThreshold = dayInMilliseconds * 7;
  const monthThreshold = dayInMilliseconds * 30;
  const yearThreshold = dayInMilliseconds * 365;

  return collection.reduce((groups, result) => {
    const now = (new Date()).getTime();
    const timeDifference = (now - Date.parse(result.timeline_date));
    let _timeFrame;

    if ((weekThreshold > timeDifference)) {
      _timeFrame = 'Last 7 Days';
    }
    if (((weekThreshold) < timeDifference) && (timeDifference < monthThreshold)) {
      _timeFrame = 'A Week Ago';
    } else if ((monthThreshold < timeDifference) && (timeDifference < yearThreshold)) {
      _timeFrame = 'A Month Ago';
    } else if (((yearThreshold) < timeDifference)) {
      _timeFrame = 'A Year Ago';
    }
    groups[_timeFrame] = groups[_timeFrame] || [];
    groups[_timeFrame].push(result);
    return groups;
  }, {});
}, collection => (JSON.stringify(collection))));

const RESULTS_TOPIC = 'Your Test Results';

class NoteController {
  constructor(
    ENV,
    $q,
    $rootScope,
    focus,
    isMiscTask,
    isPdfDocument,
    S3,
    toastr,
    $analytics,
    $state,
    $stateParams,
    AttachmentService,
    CommentService,
    LabDataService,
    NoteService,
    NoteTemplatesService,
    ProfileService,
    PatientTimelinePostService,
    PatientTimelineS3PointerService,
    TodoService,
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.focus = focus;
    this.isMiscTask = isMiscTask;
    this.finishTaskWithoutSigning = false;
    this.isPdfDocument = isPdfDocument;
    this.AttachmentService = AttachmentService;
    this.CommentService = CommentService;
    this.LabDataService = LabDataService;
    this.NoteService = NoteService;
    this.NoteTemplatesService = NoteTemplatesService;
    this.TodoService = TodoService;
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.PatientTimelineS3PointerService = PatientTimelineS3PointerService;
    this.S3 = S3;
    this.toastr = toastr;
    this.$analytics = $analytics;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.labReviewMessagingEnabled = false;
    this.canComposeMessage = false;
    this.canRecategorizeDocument = false;
    this.editable = false;
    this.noteTemplatesIndex = ENV.search.indexes.note_templates;
    ProfileService.get().then(profile => {
      this.profile = profile;
    });
  }

  $onInit() {
    const getNote = this.NoteService.get(this.$stateParams.id);
    const getTodo = this.TodoService.getNoteTodo({ id: this.$stateParams.id });

    this.attachError = null;
    this.editingSubject = false;

    this.deleteActionBarExpandName = 'deleteActionBar';
    return this.$q
      .all([getNote, getTodo])
      .then(([note, todo]) => {
        this.note = note;
        this.todo = todo;
        this.LabDataService.getFor(this.note);
        this.$rootScope.$emit('summaryLoaded', true);
        this.noteHasIncompleteTodo = this.todo.isIncomplete();
        this.canComposeMessage = this.hasAttachedDocuments();
        this.canRecategorizeDocument =
          this.note.note_type &&
          this.note.note_type.for_documents;
        this.combinedHistory = this.combineTransmissionHistory();
        this._setNoteTypeIsMiscTask();
        this.updateEditable();
        this.updateSecondaryActions();
        this.isLetter =
          this.note.note_type && this.note.note_type.name === 'Official Letter';
        this.finishTaskWithoutSigning = !!this.todo && this.todo.is_manual;

        if (this.note.subject) {
          this.focus('currentNote-body');
        }
      });
  }

  updateEditable() {
    if (this.noteHasIncompleteTodo && this.noteTypeIsMiscTask) {
      this.editable = true;
    } else {
      this.editable = false;
    }
  }

  updateSecondaryActions() {
    this.secondaryActions = [];
    if (this.canComposeMessage) {
      this.secondaryActions.push({
        label: 'Compose Message',
        action: () => {
          this.newMessageFromAttachments(this.note.documents);
        },
      });
    }
    if (this.noteHasIncompleteTodo) {
      this.secondaryActions.push({
        label: 'Delete',
        action: () => {
          this.toggleDeleting();
        },
      });
    }
    if (this.canRecategorizeDocument) {
      this.secondaryActions.push({
        label: 'Recategorize',
        action: () => {
          this.noteActionBarCollapse.expand('recategorizeDocumentActionBar');
          this.focus('recategorizeDocument-search');
        },
      });
    }
    this.secondaryActions.push({
      template: `
        <om-fax-button-menu-item note-id="${this.note.id}"
                                 as-button="$ctrl.asButton"></om-fax-button-menu-item>
      `,
    });
    this.secondaryActions.push({
      template: `
        <om-print-button-menu-item note-id="${this.note.id}"
                                   as-button="$ctrl.asButton"></om-print-button-menu-item>
      `,
    });
  }

  hasAttachedDocuments() {
    return !!this.note.documents && this.note.documents.length > 0;
  }

  closeNote() {
    this.$rootScope.$emit('summaryClosed', this.note);
  }

  newMessageFromAttachments(docs) {
    const labResultsDoc = docs.find(doc => doc.lab_data);
    const existingPostId =
      labResultsDoc &&
      labResultsDoc.lab_data.patient_timeline_post &&
      labResultsDoc.lab_data.patient_timeline_post.id;
    if (existingPostId) {
      return this.newCommentOnPost(existingPostId);
    }
    return this.newPostFromAttachments(docs);
  }

  newCommentOnPost(postId) {
    return this.PatientTimelinePostService.get(postId).then(post => {
      this.$rootScope.$emit('setCurrentMessage', post);
      return post;
    });
  }

  isResults() {
    return this.note.note_type && this.note.note_type.tags.includes('results');
  }

  getAttachmentTitle(doc) {
    let ext = doc.ext;
    if (this.hasPdf(doc)) ext = 'pdf';
    if (this.isResults()) {
      return `Results ${this.note.id}.${ext}`;
    }
    return `Document ${this.note.id}.${ext}`;
  }

  getPostTitle() {
    if (this.isResults()) {
      return RESULTS_TOPIC;
    }
    return null;
  }

  hasPdf(doc) {
    return doc.pdf_key && doc.pdf_bucket;
  }

  getAwsKey(doc) {
    if (this.hasPdf(doc)) {
      return doc.pdf_key;
    }
    return doc.aws_key;
  }

  getAwsBucket(doc) {
    if (this.hasPdf(doc)) {
      return doc.pdf_bucket;
    }
    return doc.aws_bucket;
  }

  newPostFromAttachments(docs) {
    const copiedDocs = docs.map(doc => {
      const awsKey = this.getAwsKey(doc);
      const awsBucket = this.getAwsBucket(doc);
      const attachmentTitle = this.getAttachmentTitle(doc);
      const attachmentKey = this.PatientTimelineS3PointerService.getAttachmentKey(
        attachmentTitle,
      );
      if (!this.hasPdf(doc) && doc.ext === 'tiff') {
        this.$analytics.eventTrack('Tiff attached to message', doc);
      }
      return this.S3.copy(awsKey, awsBucket, attachmentKey).then(s3Object =>
        Object.assign({}, s3Object, { title: attachmentTitle }),
      );
    });

    const associatedLabId = docs
      .filter(doc => doc.for_lab === true)
      .map(doc => doc.parent_id)[0];
    const postTitle = this.getPostTitle();
    const createPost = this.PatientTimelinePostService.create(
      postTitle,
      associatedLabId,
    ).then(post => {
      this.$rootScope.$emit('setCurrentMessage', post);
      return post;
    });

    return this.$q
      .all([createPost, ...copiedDocs])
      .then(([post, ...s3Objects]) => {
        post.s3_pointers = s3Objects.map(s3Object => ({
          bucket: s3Object.Bucket,
          key: s3Object.Key,
          title: s3Object.title,
        }));
        return post;
      });
  }

  noteIsNotBlank() {
    return !!this.note.body && this.note.body !== '<br>';
  }

  update() {
    return this.NoteService.update(this.note);
  }

  updateSubject(subject) {
    this.note.subject = subject;
    this.focus('currentNote-body');
    return this.NoteService.update(this.note).then(() => {
      this.$rootScope.$emit('refreshTimeline');
    });
  }

  completeTask() {
    if (this.finishTaskWithoutSigning) {
      return this.TodoService.completeWithoutSigningNote(this.todo).then(() => {
        this.noteHasIncompleteTodo = false;
        this.updateEditable();
        this.updateSecondaryActions();
      });
    }
    return this.TodoService.completeAndSignNote(this.todo).then(() => {
      this.noteHasIncompleteTodo = false;
      this.updateEditable();
      this.updateSecondaryActions();
    });
  }

  finishNote() {
    this._trackFinishNote();
    if (this.editable) {
      // Do not allow further edits and ignore any pending autosaves
      this.editable = false;
      this.form.$dirty = false;
      return this.update().then(() => this.completeTask());
    }
    return this.completeTask();
  }

  getFinishLabel() {
    return (
      this.todo.finish_label ||
      (this.finishTaskWithoutSigning ? 'Finish Task' : 'Complete Task')
    );
  }

  toggleDeleting() {
    this.noteActionBarCollapse.toggle(this.deleteActionBarExpandName);
  }

  delete() {
    return this.NoteService.delete(this.note.id).then(() => {
      this.toastr.success('Your note has been deleted');
      this.$rootScope.$emit('refreshTimeline');
      this.$state.go('app.chart.workspace.newWorkspaceItem');
    });
  }

  saveComment(id, body) {
    return this.CommentService.saveNoteComment(id, { body });
  }

  getComments(id) {
    return this.CommentService.getNoteComments(id);
  }

  combineTransmissionHistory() {
    const combinedHistory = [];
    if (this.note.faxes && this.note.faxes.length > 0) {
      this.note.faxes.forEach(fax => {
        const item = fax;
        item.transmission_type = 'fax';
        item.transmission_type_display = 'Fax';
        combinedHistory.push(item);
      });
    }
    return combinedHistory.sort((a, b) =>
      a.created_at > b.created_at ? 1 : -1,
    );
  }

  applyNoteTemplate(template) {
    if (!template) {
      return;
    }

    this._trackTemplateInsertion(template.id);

    this.NoteTemplatesService.get(template.id).then(({ body }) => {
      this.focus('currentNote-body');
      this.$rootScope.$broadcast('omRichTextEditor-insertHTML', body);
    });
  }

  addAttachment(file) {
    if (!file) {
      return this.$q.resolve();
    }

    const attachment = { name: file.name };
    this.note.attachments.push(attachment);
    const params = {
      attachable_type: 'Note',
      attachable_id: this.note.id,
      file,
    };

    this.attachError = null;
    this.form.uploading = true;
    return this.AttachmentService.save(params)
      .then(
        newAttachment => {
          Object.assign(attachment, newAttachment);
        },
        error => {
          this.attachError = `File could not be attached, ${
            error.data['attachable_file.file']
            }`;
          this.note.attachments = _.reject(this.note.attachments, attachment);
        },
      )
      .finally(() => {
        this.form.uploading = false;
      });
  }

  deleteAttachment(attachmentToDelete) {
    return this.AttachmentService.delete(attachmentToDelete.id).then(() => {
      this.note.attachments = this.note.attachments.filter(
        attachment => attachment.id !== attachmentToDelete.id,
      );
    });
  }

  recategorizeDocument(template) {
    if (template) {
      return this.NoteService.recategorize(this.note.id, template).then((note) => {
        this.toastr.success(`Document recategorized to ${note.note_type.name}`);
        this.$state.go('app.chart.workspace.newWorkspaceItem');
        this.$rootScope.$emit('unset-timeline-note');
        this.$rootScope.$emit('refreshTimeline');
      });
    }
    return this.$q.resolve();
  }

  _setNoteTypeIsMiscTask() {
    const note = this.note;
    this.noteTypeIsMiscTask =
      note && note.note_type && this.isMiscTask(note.note_type.name);
  }

  _trackTemplateInsertion(templateId) {
    if (this.note && this.note.note_type) {
      if (this.noteTypeIsMiscTask) {
        this.$analytics.eventTrack('Miscellaneous Note Template Inserted', {
          workflow: 'Charting',
          component: this.note.note_type.name,
          subcomponent: `Insert Template Button`,
          note_id: this.note.id,
          template_id: templateId
        });
      }
    }
  }

  _trackFinishNote() {
    if (this.note && this.note.note_type) {
      if (this.noteTypeIsMiscTask) {
        this.$analytics.eventTrack('Miscellaneous Note Finished', {
          workflow: 'Charting',
          component: this.note.note_type.name,
          subcomponent: `${this.getFinishLabel()} Button`,
          note_id: this.note.id,
        });
      }
    }
  }
}

NoteController.$inject = [
  'ENV',
  '$q',
  '$rootScope',
  'focus',
  'isMiscTask',
  'isPdfDocument',
  'S3',
  'toastr',
  '$analytics',
  '$state',
  '$stateParams',
  'AttachmentService',
  'CommentService',
  'LabDataService',
  'NoteService',
  'NoteTemplatesService',
  'ProfileService',
  'PatientTimelinePostService',
  'PatientTimelineS3PointerService',
  'TodoService',
];

export const omNote = {
  bindings: {
    currentMessage: '<',
  },
  templateUrl: 'notes/note.component.html',
  controller: NoteController,
};

class FileUploadButtonController {
  constructor($element) {
    this.$element = $element;
  }

  $onInit() {
    this.$element.find('input').on('change', this.fileChosen.bind(this));
  }

  fileChosen(event) {
    const file = event.target.files[0];
    this.onFileChosen({ file });
  }
}

FileUploadButtonController.$inject = ['$element'];

export const omFileUploadButton = {
  controller: FileUploadButtonController,
  template: `<button class="om-button clickable om-icon icon-attach">
               <input class="clickable"
                      type="file">
             </button>`,
  bindings: {
    onFileChosen: '&',
  },
};

export function omClick($parse) {
  return {
    restrict: 'A',
    compile($element, attrs) {
      const action = $parse(attrs.omClick);
      const condition = attrs.omClickIf ? $parse(attrs.omClickIf) : () => true;
      const confirmation = attrs.omClickConfirm ? () => confirm(attrs.omClickConfirm) : () => true; // eslint-disable-line no-alert

      return (scope, element) => {
        element.on('click', event =>
          scope.$apply(() => {
            if (condition(scope) && confirmation()) {
              return action(scope, { $event: event });
            }
            return null;
          })
        );
      };
    },
  };
}

omClick.$inject = ['$parse'];

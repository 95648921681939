import { omBmiMeasurementsForm } from './bmi-measurements-form.component';
import { omInfantGrowthMeasurementsForm } from './infant-growth-measurements-form.component';
import { omSignedSummaryForm } from './signed-summary-form.component';
import { omSummaries } from './summaries.component';
import { Summary } from './summary';
import { SummaryService } from './summary.service';
import { omSummaryAddenda } from './summary-addenda.component';
import { SummaryAddendumService } from './summary-addendum.service';
import { omSummaryHeader } from './summary-header.component';
import { omSummaryMeasurementsForm } from './summary-measurements-form.component';
import { omSummaryMeasurementsTable } from './summary-measurements-table.component';
import { omUnsignedSummaryForm } from './unsigned-summary-form.component';
import { omVitalsForm } from './vitals-form.component';

angular
  .module('onelifeUi.summaries', [])
  .component('omBmiMeasurementsForm', omBmiMeasurementsForm)
  .component('omInfantGrowthMeasurementsForm', omInfantGrowthMeasurementsForm)
  .component('omSignedSummaryForm', omSignedSummaryForm)
  .component('omSummaries', omSummaries)
  .component('omSummaryAddenda', omSummaryAddenda)
  .component('omSummaryHeader', omSummaryHeader)
  .component('omSummaryMeasurementsForm', omSummaryMeasurementsForm)
  .component('omSummaryMeasurementsTable', omSummaryMeasurementsTable)
  .component('omUnsignedSummaryForm', omUnsignedSummaryForm)
  .component('omVitalsForm', omVitalsForm)
  .factory('Summary', () => Summary)
  .factory('SummaryAddendumService', SummaryAddendumService)
  .service('SummaryService', SummaryService);

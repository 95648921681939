export function configureApi({ api: { baseUrl } }, $provide) {
  $provide.decorator('$resource', [
    '$delegate',
    $delegate => (...args) => {
      args[0] = `${baseUrl}${args[0]}`;
      args[2] = _.mapValues(args[2], action => {
        if (action.url) {
          action.url = `${baseUrl}${action.url}`;
        }
        return action;
      });

      return $delegate(...args);
    },
  ]);
}

configureApi.$inject = ['ENV', '$provide'];

class RenewalCheckoutCtrl {
  constructor(
    $rootScope,
    RefillRequest,
    Renewal,
    RenewalRequestService,
    CartValidation,
    NotificationsService,
    focus,
  ) {
    this.$rootScope = $rootScope;
    this.RefillRequest = RefillRequest;
    this.Renewal = Renewal;
    this.RenewalRequestService = RenewalRequestService;
    this.CartValidation = CartValidation;
    this.NotificationsService = NotificationsService;
    this.twoFactorPollingEvent = '2fa_push_polling_done';
    this.focus = focus;
    this.$modal = {};
    this.messages = {};
    this.submissionInProgress = false;
    this.secondFactorToken = '';
    this.passwordVerified = false;
    this.password = '';
    this.messages = {
      mustHandwrite:
        "Currently, this prescription can't be sent electronically. Please handwrite the prescription now and confirm prior to checkout.",
      mustConfirmHandwritten:
        'All handwritten prescriptions must be confirmed prior to checkout.',
      mustConfirmReadyToSign:
        'All controlled substance prescriptions must be marked ready to sign prior to checkout.',
      handwrittenConfirmation:
        "I've written this prescription and it will be noted in the Rx history.",
      twoFactorAgreement: `By completing the two-factor authentication protocol at this time, you are legally signing the prescription(s)
    and authorizing the transmission of the above information to the pharmacy for dispensing. The two-factor authentication protocol may
    only be completed by the practitioner whose name and DEA registration number appear above.`,
      approvePush: 'Please confirm your signature on your mobile device.',
      successfulPush: 'Signature confirmed, transmitting renewals...',
      errorPush: 'Sorry, there was an error with the signature confirmation.',
      unknownError: 'Sorry, an unknown error occurred',
    };
    this.messages.pushStatus = this.messages.approvePush;
    this.allReadyToSignItemsConfirmed = true;
    this.allHandwrittenItemsConfirmed = true;
    this.date = Date.now();
    this.invalidPassword = false;
    this.invalidSecondFactor = false;
    this.saving = false;
  }
  $onInit() {
    this.notifyPatient = this.renewalList.patient.patient_preferences.accepts_clinical_emails;
  }

  setRenewalsPrescribedBySelf(modal) {
    this.renewalsPrescribedBySelf = {};
    if (this.renewalList.cart.renewalsReadyForCheckout) {
      this.renewalList.cart.renewalsReadyForCheckout.forEach(renewal => {
        this.renewalsPrescribedBySelf[renewal.id] =
          renewal.prescriber_id === this.profile.id;
      });
    }

    modal.open();
  }

  modalFormSubmitButtonText() {
    if (this.submissionInProgress) {
      return 'Sending...';
    }
    return 'Send';
  }
  clearAuthFields() {
    this.submissionInProgress = false;
    if (!this.passwordVerified) {
      this.password = '';
    }
    this.secondFactorToken = '';
  }

  resetAuthFlags() {
    this.invalidPassword = false;
    this.invalidSecondFactor = false;
    this.unknownError = false;
    this.validationError = false;
  }

  setFormSubmissionInProgress() {
    this.submissionInProgress = true;
    this.resetAuthFlags();
  }
  disconnectFromNotificationsChannel() {
    if (this.notificationsConnection) {
      this.isPollingForCartToken = false;
      this.notificationsConnection.unbind(this.twoFactorPollingEvent);
    }
  }
  twoFactorPollingComplete({
    success,
    error,
    cart_push_token: pushVerificationToken,
  }) {
    this.submissionInProgress = false;

    if (success) {
      const params = {
        password: this.password,
        cart_push_token: pushVerificationToken,
        refill_requests: this.prepareApprovedRenewalsForCheckout(),
      };
      this.messages.pushStatus = this.messages.successfulPush;
      this.transmitRequests(params);
    } else if (error) {
      this.messages.pushStatus = this.messages.errorPush;
    }
  }

  bindToNotificationsChannel(channel) {
    this.isPollingForCartToken = true;
    this.messages.pushStatus = this.messages.approvePush;
    this.notificationsConnection = this.NotificationsService.subscribe(channel);
    this.notificationsConnection.bind(
      this.twoFactorPollingEvent,
      this.twoFactorPollingComplete.bind(this),
    );
  }

  fallbackToManualTwoFactor() {
    this.disconnectFromNotificationsChannel();
    this.manualTwoFactorRequired = true;
  }

  verifyPasswordBeforeCheckout() {
    const params = {
      password: this.password,
      rx_cart_id: this.renewalList.cart.id,
    };

    const verify = this.RefillRequest.verifyPassword(params).$promise;
    verify.then(
      ({ password_verified: passwordVerified, pusher_channel: channel }) => {
        this.passwordVerified = passwordVerified;
        if (channel) {
          this.bindToNotificationsChannel(channel);
        } else {
          this.manualTwoFactorRequired = true;
          this.submissionInProgress = false;
        }
      },
    );
    verify.catch(this.handleError.bind(this));
  }

  handleError({ data: errorResponse, status }) {
    const {
      errors: fieldErrors,
      password_verified: passwordVerified,
    } = errorResponse;
    this.clearAuthFields();
    this.disconnectFromNotificationsChannel();

    const fieldErrorExceptions =
      fieldErrors && fieldErrors.exceptions ? fieldErrors.exceptions : [];
    const fieldErrorExceptionsExist = fieldErrorExceptions.length > 0;

    if (!passwordVerified && fieldErrors) {
      this.focus('renewalCheckoutFocus-password');
      if (fieldErrorExceptionsExist)
        this.invalidPassword = fieldErrorExceptions[0];
      else
        this.invalidPassword = fieldErrors.password
          ? fieldErrors.password.join(' ')
          : 'Password invalid';
    } else if (passwordVerified && fieldErrors) {
      this.focus('renewalCheckoutFocus-token');
      if (fieldErrorExceptionsExist)
        this.invalidSecondFactor = fieldErrorExceptions[0];
      else {
        this.invalidSecondFactor = fieldErrors.secondFactorToken
          ? fieldErrors.secondFactorToken.join(' ')
          : 'Second factor token invalid';
      }
    }

    if (status === 500) {
      this.unknownError = this.messages.unknownError;
    }

    if (status === 422) {
      this.validationError = fieldErrorExceptionsExist
        ? fieldErrorExceptions.join(' ')
        : errorResponse.message;
    }
  }

  readyToSign(ready, renewalRequest) {
    if (ready) {
      this.RenewalRequestService.markReadyToSign(renewalRequest);
    }
  }

  $onDestroy() {
    this.passwordVerified = false;
    this.resetAuthFlags();
    this.clearAuthFields();
    this.allReadyToSignItemsConfirmed = true;
    this.allHandwrittenItemsConfirmed = true;
    this.manualTwoFactorRequired = false;
    this.disconnectFromNotificationsChannel();
    this.messages.pushStatus = this.messages.approvePush;
    this.notifyPatient = this.renewalList.patient.patient_preferences.accepts_clinical_emails;
  }
  resetAndClose() {
    this.$onDestroy();
    this.$modal.close();
  }

  prepareApprovedRenewalsForCheckout() {
    const renewalsToCheckOut = this.renewalList.cart.renewalsReadyForCheckout;
    renewalsToCheckOut.forEach(
      renewal => (renewal.notify_patient = this.notifyPatient),
    );
    return renewalsToCheckOut;
  }

  handleCheckoutResponse({ cart_items: cartItems }) {
    cartItems = cartItems ? cartItems.map(item => new this.Renewal(item)) : [];

    const cartItemsByPharmacy = _.groupBy(cartItems, 'pharmacy.id');
    const renameCartStateKey = (items, state) =>
      state === 'prescribe' ? 'approved' : 'denied';
    const groupedByCartState = items => _.groupBy(items, 'cart_state');
    const sumOfApprovedAndDenied = groups => {
      const { approved = [], denied = [] } = groups;
      const { length: approvedTotal } = approved;
      const { length: deniedTotal } = denied;
      return approvedTotal + deniedTotal;
    };

    this.renewalList.completedRefillRequests = _.mapValues(
      cartItemsByPharmacy,
      items => _.mapKeys(groupedByCartState(items), renameCartStateKey),
    );

    _.mapValues(this.renewalList.completedRefillRequests, details => {
      const firstGroupOfRenewalsByCartState = _.find(
        details,
        key => key.length > 0,
      )[0];
      details.pharmacy = firstGroupOfRenewalsByCartState.pharmacy;
      details.count = sumOfApprovedAndDenied(details);
    });

    this.renewalList.handwrittenRefillRequests = cartItems.filter(
      item => item.must_handwrite && item.isApproved(),
    );
    this.RefillRequest.latestCart().then(response => {
      this.renewalList.cart = response;
      this.$rootScope.$emit(
        'updateRenewalCount',
        this.renewalList.cart.cartItems.length,
      );
      this.renewalList.prepareRenewalCart();
      this.submissionInProgress = false;
      this.disconnectFromNotificationsChannel();
      this.resetAndClose();
    });
  }
  notifyRootScope() {
    this.$rootScope.$broadcast('$$rebind::refillsPendingApprovalCount');
    this.$rootScope.$emit('patientMedicationsRefresh');
  }
  transmitRequests(params) {
    return this.RefillRequest.rx_checkout(params)
      .$promise.then(
        this.handleCheckoutResponse.bind(this),
        this.handleError.bind(this),
      )
      .then(this.notifyRootScope.bind(this));
  }
  completeCheckout($event) {
    const approvedRequests = this.renewalList.cart.renewalsReadyForCheckout.filter(
      request => request.isApproved(),
    );

    const requiresTwoFactorCheckout =
      this.CartValidation.requireTwoFactorCheckout(approvedRequests) &&
      !this.secondFactorToken;

    this.allHandwrittenItemsConfirmed = this.CartValidation.allHandwrittenRequestsConfirmed(
      approvedRequests,
    );
    this.allReadyToSignItemsConfirmed = this.CartValidation.allSignedRequestsConfirmed(
      approvedRequests,
    );
    const allControlledSubstancesConfirmed =
      this.allHandwrittenItemsConfirmed && this.allReadyToSignItemsConfirmed;

    const params = {
      password: this.password,
      rx_cart_id: this.renewalList.cart.id,
    };

    if (allControlledSubstancesConfirmed) {
      if (requiresTwoFactorCheckout && !this.passwordVerified) {
        this.setFormSubmissionInProgress();
        this.verifyPasswordBeforeCheckout();
      } else {
        params.refill_requests = this.prepareApprovedRenewalsForCheckout();
        if (this.secondFactorToken)
          params.secondFactorToken = this.secondFactorToken;
        this.setFormSubmissionInProgress();
        this.transmitRequests(params);
      }
    }

    $event.preventDefault();
  }
}
RenewalCheckoutCtrl.$inject = [
  '$rootScope',
  'RefillRequest',
  'Renewal',
  'RenewalRequestService',
  'CartValidation',
  'NotificationsService',
  'focus',
];
export const omRenewalCheckout = {
  require: {
    renewalList: '^^omRenewalList',
  },
  bindings: {
    patient: '<',
    saving: '<',
    profile: '<',
  },
  templateUrl: 'renewals/renewal-checkout.component.html',
  controller: RenewalCheckoutCtrl,
};

class TabsController {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
    this.panes = [];
  }
  $onInit() {
    this.$rootScope.$on('selectTab', (event, paneName) => this.selectByName(paneName));
  }
  select(paneToSelect) {
    this.panes.forEach((pane) => {
      pane.selected = false;
    });

    paneToSelect.selected = true;
  }
  selectByName(paneName) {
    const paneExists = this.panes.filter(pane => pane.heading === paneName).length > 0;

    if (paneExists) {
      this.panes.forEach(pane => (pane.selected = pane.heading === paneName));
    }
  }

  addPane(pane) {
    if (this.panes.length === 0 || pane.selectIf) {
      this.select(pane);
    }
    this.panes.push(pane);
  }
}

TabsController.$inject = ['$rootScope'];

export const omTabs = {
  controller: TabsController,
  transclude: true,
  template: `<div class="om-tabs" om-layout-fill>
               <ul class="om-tabs-nav">
                 <li ng-repeat="pane in $ctrl.panes" ng-class="{ active:pane.selected }"
                     ng-click="$ctrl.select(pane)" ui-state="pane.sref">
                     {{pane.heading}}<span ng-if="pane.badge && pane.badge != 0" class="om-badge -warning">{{pane.badge}}</span>
                 </li>
               </ul>
               <div class="om-tab-content" ng-transclude></div>
             </div>`,
};

export class CartFillDates {
  calculateNewRx = (cartItem, cartItems) => {
    // created_at is used as the canonical order of the new rxes in the cart.
    // It gets recalculated every times a med earliest date changes.
    const siblings = this._getSiblingsOfMatchingRoute(cartItem, cartItems).sort(
      (a, b) => a.created_at > b.created_at,
    );
    const itemIndex = siblings.indexOf(cartItem);
    const firstSibling = siblings[0];

    siblings.forEach((item, index) => {
      // We only need to recalculate younger sibling meds, ie, ones that are created later and further down in the order.
      if (index <= itemIndex) {
        return;
      }
      const firstMinDate = this._thirtyDaysOut(
        firstSibling.earliest_fill_date,
        index,
      );
      const prevMed = siblings[index - 1];
      const prevMinDate = this._thirtyDaysOut(prevMed.earliest_fill_date, 1);

      // We wanna make sure the minDate is far enough away from the first med in the list, and from it's next oldest sibling.
      const minDate =
        prevMinDate.getTime() > firstMinDate.getTime()
          ? prevMinDate
          : firstMinDate;
      this._setMin(item, minDate);
    });
  };

  calculateRenewals = (cartItem, cartItems) => {
    let minDate;
    const siblings = this._getSiblingsOfMatchingRoute(cartItem, cartItems);
    let approvedSiblings = siblings.filter(
      item => item.isApproved() && !!item.earliest_fill_date,
    );
    approvedSiblings = approvedSiblings.sort(
      (a, b) => a.earliest_fill_date.getTime() > b.earliest_fill_date.getTime(),
    );
    const forceEarliestFillDateUpdate = approvedSiblings.length === 0;

    // We use the list of already Approved renewals to determine when the next fill date is.
    // This gets calculated every time you "Approve" or "Undo" a pending renewal.
    if (forceEarliestFillDateUpdate) {
      minDate = new Date();
    } else {
      const lastSibling = approvedSiblings[approvedSiblings.length - 1];
      minDate = this._thirtyDaysOut(lastSibling.earliest_fill_date, 1);
    }

    // Once we've determined our minDate, we only want to update the "new" renewals, ie, not Approved or Denied.
    siblings
      .filter(sibling => sibling.isPending())
      .map(item => {
        item.updateFillDates(minDate, forceEarliestFillDateUpdate);
        return item;
      });
  };

  _thirtyDaysOut = (time, scalar) => {
    let newDate;
    if (time) {
      newDate = new Date(time.getTime());
    } else {
      newDate = new Date();
    }
    const extensionPeriod = scalar * 30;
    newDate.setDate(newDate.getDate() + extensionPeriod);
    return newDate;
  };

  _setMin = (item, time) => {
    item.min_earliest_fill_date = time;
    if (
      !item.earliest_fill_date ||
      (!!item.earliest_fill_date &&
        item.min_earliest_fill_date.getTime() >
          item.earliest_fill_date.getTime())
    ) {
      item.earliest_fill_date = new Date(item.min_earliest_fill_date.getTime());
    }
    return item;
  };

  _getSiblingsOfMatchingRoute = (cartItem, cartItems) =>
    cartItems.filter(
      item => item.medication_route.id === cartItem.medication_route.id,
    );
}

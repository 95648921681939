import keyCode from 'keycode';

const ESCAPE_CODE = keyCode('esc');
const MODIFIERS = {
  ctrlKey: /ctrl\+/i,
  altKey: /alt\+/i,
  metaKey: /meta\+/i,
  shiftKey: /shift\+/i,
};

// Binds a global keybinding to a specific element's click handler.
export class ShortcutClick {
  constructor($document, $window) {
    this.$document = $document;
    this.$window = $window;
    this.keys = {};
    this.body = $document[0].body;
    this.ctrlIsMeta = /Mac/i.test($window.navigator.platform);

    this.bindHandler();
  }

  register(pattern, element) {
    const eventProperties = this.parseStringToEventProperties(pattern);
    this.keys[this.eventPropertiesToString(eventProperties)] = angular.element(
      element,
    );
  }

  deregister(pattern) {
    const eventProperties = this.parseStringToEventProperties(pattern);
    delete this.keys[this.eventPropertiesToString(eventProperties)];
  }

  trigger(pattern) {
    const eventProperties = this.parseStringToEventProperties(pattern);
    const registeredElement = this.keys[
      this.eventPropertiesToString(eventProperties)
    ];
    if (registeredElement) {
      registeredElement.focus();
      registeredElement.triggerHandler('click');
    }
  }

  handler(event) {
    const targetedElement = angular.element(event.target);
    if (event.which === ESCAPE_CODE) {
      // blur fields when you hit the escape key
      targetedElement.blur();
    }

    // No field is focused on, and no text is highlighted by the user (if someone's trying to copy with / Ctrl+C
    if (
      this.$document[0].activeElement === this.body &&
      this.$window.getSelection().toString() === ''
    ) {
      this.trigger(this.eventPropertiesToString(event));
    }
  }

  bindHandler() {
    angular.element(this.body).on('keydown', this.handler.bind(this));
  }

  parseStringToEventProperties(pattern = '') {
    const eventProperties = {};

    Object.entries(MODIFIERS).forEach(([modifier, modifierPattern]) => {
      const newPattern = pattern.replace(modifierPattern, '');
      if (newPattern !== pattern) {
        eventProperties[modifier] = true;
      }
      pattern = newPattern;
    });

    if (
      this.ctrlIsMeta &&
      eventProperties.ctrlKey &&
      !eventProperties.metaKey
    ) {
      eventProperties.metaKey = true;
      delete eventProperties.ctrlKey;
    }

    if (pattern !== '') eventProperties.which = keyCode(pattern);
    return eventProperties;
  }

  eventPropertiesToString({ which, ctrlKey, altKey, metaKey, shiftKey }) {
    let string = keyCode(which);
    if (shiftKey) {
      string = `shift+${string}`;
    }
    if (metaKey) {
      string = `meta+${string}`;
    }
    if (altKey) {
      string = `alt+${string}`;
    }
    if (ctrlKey) {
      string = `ctrl+${string}`;
    }
    return string;
  }
}

ShortcutClick.$inject = ['$document', '$window'];

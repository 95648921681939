class VaccineOrderListItemController {
  constructor($state) {
    this.$state = $state;
    this.isVaccineOrder = true;
  }

  $onInit() {
    this.title = this.order.name;
    this.ordered_by = this.order.internal_user.name;
    this.due_on = this.order.date_due;
    this.status = this.order.status;
    this.subtitle = this.order.step_in_series_phrase;
  }

  editView() {
    this.$state.go('app.chart.orders.editVaccineOrder', {
      orderId: this.order.id,
    });
  }

  isDueDateVisible() {
    return !!this.due_on && this.order.status === 'signed';
  }

  highlightDueDate() {
    return !!this.due_on && moment(this.due_on) < moment().endOf('day');
  }
}

VaccineOrderListItemController.$inject = ['$state'];

export const omVaccineOrderListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: VaccineOrderListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

export class PageAnalytics {
  constructor($analytics, $window, $document) {
    this.$analytics = $analytics;
    this.$window = $window;
    this.$document = $document;
    this.trackedEvents = {
      tabStart: 'Tab Context Started',
      tabEnd: 'Tab Context Ended',
    };

    this.sessionProperties = {
      sessionFingerprint: +new Date(),
    };
    this.visibilityChange = '';
    this.addEventListeners();
  }
  addEventListeners() {
    if (this.isHidden()) {
      this.$document[0].addEventListener(
        this.visibilityChange,
        this.handleVisibilityChange.bind(this),
        false,
      );
    }

    this.$analytics.eventTrack(
      this.trackedEvents.tabStart,
      this.sessionProperties,
    );
  }
  isHidden() {
    const { msHidden, webkitHidden } = this.$document[0];
    let hiddenKey = 'hidden';
    this.visibilityChange = 'visibilitychange';

    if (msHidden) {
      hiddenKey = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (webkitHidden) {
      hiddenKey = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }

    return this.$document[0][hiddenKey];
  }

  handleVisibilityChange() {
    let eventMessage = this.trackedEvents.tabStart;
    if (this.isHidden()) eventMessage = this.trackedEvents.tabEnd;
    this.$analytics.eventTrack(eventMessage, this.sessionProperties);
  }
}

PageAnalytics.$inject = ['$analytics', '$window', '$document'];

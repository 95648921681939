class PatientTimelinePostsController {
  constructor(
    PatientTimelinePostService,
    $state,
    $stateParams,
    ProfileService,
  ) {
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.$state = $state;
    this.$stateParams = $stateParams;

    ProfileService.get().then(profile => {
      this.profile = profile;
    });
  }

  $onInit() {
    this.PatientTimelinePostService.get(this.$stateParams.id).then(post => {
      this.post = post;
    });
  }

  closePost() {
    this.$state.go('app.chart.workspace.newWorkspaceItem');
  }
}

PatientTimelinePostsController.$inject = [
  'PatientTimelinePostService',
  '$state',
  '$stateParams',
  'ProfileService',
];

export const patientTimelinePostsComponent = {
  templateUrl: 'messaging/patient-timeline-post.html',
  controller: PatientTimelinePostsController,
};

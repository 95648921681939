export function configureGoogleMaps(
  uiGmapGoogleMapApiProvider,
  { googleMaps },
) {
  uiGmapGoogleMapApiProvider.configure({
    key: googleMaps,
    v: '3.26',
    libraries: 'weather,geometry,visualization,places',
  });
}

configureGoogleMaps.$inject = ['uiGmapGoogleMapApiProvider', 'ENV'];

class PatientMedication {
  constructor($stateParams, MedicationService, PendingNewRxService) {
    this.MedicationService = MedicationService;
    this.PendingNewRxService = PendingNewRxService;
    this.$stateParams = $stateParams;
  }
  addToCart() {
    const {
      route,
      latest_regimen: latestRegimen,
      latest_patient_medication_regimen: latestPatientRegimen,
    } = this.medication;
    this.patientMedicationsList.disableByRoute(route.id);
    this.PendingNewRxService.addNewMedicationToCart(
      this.$stateParams.patientId,
      latestRegimen.id,
      latestPatientRegimen.id,
    );
  }
  delete() {
    return this.MedicationService.delete(this.medication).then(() => {
      this.patientMedicationsList.remove(this.medication);
    });
  }
  discontinue() {
    return this.MedicationService.discontinue(this.medication).then(response =>
      this.patientMedicationsList.updateMedication(response),
    );
  }
  resume() {
    return this.MedicationService.resume(this.medication).then(response =>
      this.patientMedicationsList.updateMedication(response),
    );
  }
  update() {
    return this.MedicationService.update(this.medication).then(response => {
      this.patientMedicationsList.updateMedication(response);
    });
  }
}

PatientMedication.$inject = [
  '$stateParams',
  'MedicationService',
  'PendingNewRxService',
];

export const omPatientMedication = {
  controller: PatientMedication,
  bindings: {
    medication: '<',
    inCart: '<',
    patientName: '<',
  },
  require: {
    patientMedicationsList: '^^omPatientMedicationsList',
  },
  templateUrl: 'medications/patient-medication.component.html',
};

import { AwsSession } from './aws-session.service';
import { omBackgroundImage } from './background-image.directive';
import { basicHighlight } from './basic-highlight.filter';
import { splitHighlight } from './split-highlight.filter';
import { omBlockCharacters } from './block-characters.directive';
import { omButtonMenu } from './button-menu.component';
import { omButtonMenuItem } from './button-menu-item.component';
import { omCheckLabel } from './check-label.component';
import { className } from './class-name.filter';
import { omClick } from './click.directive';
import { CommentService } from './comment.service';
import { omComments } from './comments.component';
import { CustomComplete } from './custom-complete.service';
import { cut } from './cut.filter';
import { emphasize } from './emphasize.filter';
import { omDateStringValidator } from './date-string-validator.directive';
import { omEditTitleInPlace } from './edit-title-in-place.component';
import { ngEnter } from './enter.directive';
import { omFaxButtonMenuItem } from './fax-button-menu-item.component';
import { FeaturesService } from './features.service';
import { omFileLink } from './file-link.component';
import { omFillAfterDate } from './fill-after-date.component';
import { omFileUploadButton } from './file-upload-button.component';
import { omFocus } from './focus.directive';
import { omFocusOn } from './focus-on.directive';
import { focus } from './focus.service';
import { FuzzyDate } from './fuzzy-date.service';
import { omFuzzyDateValidator } from './fuzzy-date-validator.directive';
import { googlePlacePredictionHighlight } from './google-place-prediction-highlight.filter';
import { omGooglePlaces } from './google-places.component';
import { GooglePlacesService } from './google-places.service';
import { groupBy } from './group-by.filter';
import { omIgnoreDirty } from './ignore-dirty.directive';
import { isMiscTask } from './is-misc-task.service';
import { omLegacyUiHref } from './legacy-ui-href.directive';
import { LegacyUiHrefService } from './legacy-ui-href.service';
import { makePossessive } from './make-possessive.filter';
import { omModal, omModalCtrl } from './modal.directive';
import { omMultiSelect } from './multi-select.component';
import { omNoteHistory } from './note-history.component';
import { omNoteIcon } from './note-icon.component';
import { onError } from './on-error.directive';
import { PageAnalytics } from './page-analytics.service';
import { omPagination } from './pagination.component';
import { omPhoneNumberValidator } from './phone-number-validator.directive';
import { omPdfViewer } from './pdf-viewer.component';
import { phoneNumber } from './phone-number.filter';
import { omPrintButtonMenuItem } from './print-button-menu-item.component';
import { Profile } from './profile';
import { ProfileService } from './profile.service';
import { omProfileImageBubble } from './profile-image-bubble.component';
import { omRemainingCharacterCount } from './remaining-character-count.component';
import { omResponseErrors } from './response-errors.component';
import { omRichTextEditor } from './rich-text-editor.component';
import { roleGroupSort } from './role-group-sort.service';
import { S3 } from './s3.service';
import { safeHtml } from './safe-html.filter';
import { omScroll } from './scroll.directive';
import { omScrollTo } from './scroll-to.directive';
import { omShortcutClick } from './shortcut-click.directive';
import { ShortcutClick } from './shortcut-click.service';
import { omStopEvent } from './stop-event.directive';
import { omSwapIcons } from './swap-icons.directive';
import { omTabs } from './tabs.component';
import { omTabPane } from './tab-pane.component';
import { omTaskAssignment } from './task-assignment.component';
import { omTemplateInsertionActionBar } from './template-insertion-action-bar.component';
import { omTemplateInsertionButton } from './template-insertion-button.component';
import { omTextAreaWithInfo } from './text-area-with-info.component';
import { omTrackableAutocompleteInput } from './trackable-autocomplete-input.directive'
import { timeFrame } from './timeframe.filter';
import { typeahead } from './typeahead.directive';
import { USStateCodes } from './us-state-codes.service';
import { PendingRequests } from './pending-requests.service';
import { omCartCheckoutButton } from './cart-checkout-button.component';
import { omPatientIncompleteDemographics } from './patient-incomplete-demographics.component';
import {
  omDate2y,
  omDate4y,
  omDateWithMonth4y,
  omDateWithTime2y,
  omDateWithTime4y,
  om12Time,
  om24Time,
} from './om-date-formats.filter';
import './card/card.module';
import './collapse/collapse.module';
import './detail-list/detail-list.module';
import './drawer/drawer.module';
import './form/form.module';

angular
  .module('onelifeUi.shared', [
    'onelifeUi.shared.card',
    'onelifeUi.shared.collapse',
    'onelifeUi.shared.detailList',
    'onelifeUi.shared.drawer',
    'onelifeUi.shared.form',
  ])
  .component('omButtonMenu', omButtonMenu)
  .component('omButtonMenuItem', omButtonMenuItem)
  .component('omCheckLabel', omCheckLabel)
  .component('omComments', omComments)
  .component('omEditTitleInPlace', omEditTitleInPlace)
  .component('omFaxButtonMenuItem', omFaxButtonMenuItem)
  .component('omFileLink', omFileLink)
  .component('omFillAfterDate', omFillAfterDate)
  .component('omFileUploadButton', omFileUploadButton)
  .component('omGooglePlaces', omGooglePlaces)
  .component('omMultiSelect', omMultiSelect)
  .component('omNoteHistory', omNoteHistory)
  .component('omNoteIcon', omNoteIcon)
  .component('omPagination', omPagination)
  .component('omPdfViewer', omPdfViewer)
  .component('omProfileImageBubble', omProfileImageBubble)
  .component('omRemainingCharacterCount', omRemainingCharacterCount)
  .component('omResponseErrors', omResponseErrors)
  .component('omRichTextEditor', omRichTextEditor)
  .component('omPrintButtonMenuItem', omPrintButtonMenuItem)
  .component('omTabs', omTabs)
  .component('omTabPane', omTabPane)
  .component('omTaskAssignment', omTaskAssignment)
  .component('omTemplateInsertionActionBar', omTemplateInsertionActionBar)
  .component('omTemplateInsertionButton', omTemplateInsertionButton)
  .component('omTextAreaWithInfo', omTextAreaWithInfo)
  .component('omCartCheckoutButton', omCartCheckoutButton)
  .component('omPatientIncompleteDemographics', omPatientIncompleteDemographics)
  .controller('omModalCtrl', omModalCtrl)
  .directive('omBackgroundImage', omBackgroundImage)
  .directive('omBlockCharacters', omBlockCharacters)
  .directive('omClick', omClick)
  .directive('omDateStringValidator', omDateStringValidator)
  .directive('ngEnter', ngEnter)
  .directive('omFocus', omFocus)
  .directive('omFocusOn', omFocusOn)
  .directive('omFuzzyDateValidator', omFuzzyDateValidator)
  .directive('omIgnoreDirty', omIgnoreDirty)
  .directive('omLegacyUiHref', omLegacyUiHref)
  .directive('omModal', omModal)
  .directive('onError', onError)
  .directive('omPhoneNumberValidator', omPhoneNumberValidator)
  .directive('omScroll', omScroll)
  .directive('omScrollTo', omScrollTo)
  .directive('omShortcutClick', omShortcutClick)
  .directive('omStopEvent', omStopEvent)
  .directive('omSwapIcons', omSwapIcons)
  .directive('typeahead', typeahead)
  .directive('omTrackableAutocompleteInput', omTrackableAutocompleteInput)
  .factory('AwsSession', AwsSession)
  .factory('CommentService', CommentService)
  .factory('CustomComplete', CustomComplete)
  .factory('FeaturesService', FeaturesService)
  .factory('focus', focus)
  .factory('GooglePlacesService', GooglePlacesService)
  .factory('isMiscTask', () => isMiscTask)
  .factory('PageAnalytics', PageAnalytics)
  .factory('Profile', () => Profile)
  .factory('ProfileService', ProfileService)
  .factory('roleGroupSort', roleGroupSort)
  .factory('S3', S3)
  .factory('ShortcutClick', ShortcutClick)
  .factory('PendingRequests', () => PendingRequests)
  .factory('USStateCodes', USStateCodes)
  .filter('basicHighlight', () => basicHighlight)
  .filter('splitHighlight', () => splitHighlight)
  .filter('className', className)
  .filter('cut', cut)
  .filter('emphasize', emphasize)
  .filter('googlePlacePredictionHighlight', googlePlacePredictionHighlight)
  .filter('groupBy', groupBy)
  .filter('makePossessive', makePossessive)
  .filter('phoneNumber', phoneNumber)
  .filter('safeHtml', safeHtml)
  .filter('timeFrame', timeFrame)
  .filter('omDate2y', omDate2y)
  .filter('omDate4y', omDate4y)
  .filter('omDateWithMonth4y', omDateWithMonth4y)
  .filter('omDateWithTime2y', omDateWithTime2y)
  .filter('omDateWithTime4y', omDateWithTime4y)
  .filter('om12Time', om12Time)
  .filter('om24Time', om24Time)
  .service('FuzzyDate', FuzzyDate)
  .service('LegacyUiHrefService', LegacyUiHrefService);

class DueDateController {
  constructor() {
    this.tomorrow = moment()
      .add(1, 'days')
      .format('YYYY-MM-DD');
  }

  setDueDate(event, quantity, unit) {
    event.target.blur();
    const dueOn = moment().add(quantity, unit);
    this.dueOn = dueOn.toDate();
    this.onShortcutDateEntry({ quantity, unit, newDueOnValue: this.dueOn });
  }
}

export const omDueDate = {
  bindings: {
    dueOn: '<',
    onManualDateEntry: '&',
    onShortcutDateEntry: '&',
  },
  controller: DueDateController,
  template: `
  <div om-layout-gutter>
    <div class="button-group" om-layout-fill>
      <button type="button" class="om-button -secondary" ng-click="$ctrl.setDueDate($event, 2, 'weeks')">2 Weeks</button>
      <button type="button" class="om-button -secondary" ng-click="$ctrl.setDueDate($event, 1, 'month')">1 Month</button>
      <button type="button" class="om-button -secondary" ng-click="$ctrl.setDueDate($event, 3, 'months')">3 Months</button>
      <button type="button" class="om-button -secondary" ng-click="$ctrl.setDueDate($event, 6, 'months')">6 Months</button>
      <button type="button" class="om-button -secondary" ng-click="$ctrl.setDueDate($event, 1, 'year')">1 Year</button>
    </div>
    <div>
      <label om-layout="vertical">Or set a specific date
        <input type="date"
               required
               name="due_on"
               min="{{$ctrl.tomorrow}}"
               ng-model="$ctrl.dueOn"
               ng-change="$ctrl.onManualDateEntry({newDueOnValue: $ctrl.dueOn})"
               class="om-input -flush-bottom">
      </label>
    </div>
  </div>
  `,
};

class RenewalList {
  constructor(
    $rootScope,
    CartFillDates,
    ENV,
    RefillRequest,
    Renewal,
    RenewalRequestService,
    PendingRequests,
    Rollbar,
  ) {
    this.$rootScope = $rootScope;
    this.CartFillDates = CartFillDates;
    this.ENV = ENV;
    this.RefillRequest = RefillRequest;
    this.Renewal = Renewal;
    this.RenewalRequestService = RenewalRequestService;
    this.PendingRequests = PendingRequests;
    this.Rollbar = Rollbar;
    this.pendingRequests = new PendingRequests();
    this.cart = {};
    this.allRenewalsReadyForCheckoutDenied = false;
    this.scrollingEnabled = true;
    this.renewalForm = {};
  }

  $onInit() {
    this.isProvider = this.profile.hasRole('provider');
    this.prepareRenewalCart();
  }
  _calculateSiblingMinFillDates(renewal) {
    if (renewal.dispensable_restricted_controlled_substance) {
      this.CartFillDates.calculateRenewals(renewal, this.cart.cartItems);
    }
  }

  _checkForAllDenials() {
    this.allRenewalsReadyForCheckoutDenied = this.cart.renewalsReadyForCheckout.every(
      renewal => renewal.isDenied(),
    );
    if (this.allRenewalsReadyForCheckoutDenied) this.cart.require_2fa = false;
  }

  _currentUserCanCheckout() {
    const found = this.cart.renewalsReadyForCheckout.find(
      renewal => renewal.canCheckout === false,
    );
    return found === undefined;
  }
  prepareRenewalCart() {
    this.cart.cartItems.forEach(renewal => {
      this._calculateSiblingMinFillDates(renewal);
    });
    this._checkForAllDenials();
  }

  checkSiblings(renewal) {
    this.$rootScope.$broadcast('$$rebind::refillsPendingApprovalCount');
    this._checkForAllDenials();
    this._calculateSiblingMinFillDates(renewal);
  }

  pullFromCheckout(renewal) {
    _.remove(
      this.cart.renewalsReadyForCheckout,
      item => item.id === renewal.id,
    );
  }

  addToCheckout(renewal) {
    this.cart.renewalsReadyForCheckout.push(renewal);
  }

  pullFromPending(renewal) {
    _.remove(this.cart.pendingRenewals, item => item.id === renewal.id);
  }
  addToPending(renewal) {
    this.cart.pendingRenewals.push(renewal);
  }

  disabledCheckout() {
    let isDisabled = false;

    if (!this.isProvider || this.patient.incompleteDemographics()) {
      isDisabled = true;
    }

    if (!this._currentUserCanCheckout()) isDisabled = true;

    if (this.cart.renewalsReadyForCheckout.length === 0) isDisabled = true;

    if (this.pendingRequests.inProgress) {
      isDisabled = true;
    }

    return isDisabled;
  }
}
RenewalList.$inject = [
  '$rootScope',
  'CartFillDates',
  'ENV',
  'RefillRequest',
  'Renewal',
  'RenewalRequestService',
  'PendingRequests',
  'Rollbar',
];

export const omRenewalList = {
  controller: RenewalList,
  bindings: {
    cart: '<',
    profile: '<',
    patient: '<',
  },
  templateUrl: 'renewals/renewal-list.component.html',
};

// This directive can be used to set or remove an attribute when the focus
// is placed upon a given input field.
export const omSetAttributeOnFocus = () => ({
  link(scope, element, attrs) {
    element.on('focus', () => {
      _.set(scope, attrs.omSetAttributeOnFocus, true);
    });

    element.on('blur', () => {
      _.set(scope, attrs.omSetAttributeOnFocus, false);
    });
  },
});

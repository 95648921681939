class ProcedureOrderGuidance {
  constructor(marked) {
    this.marked = marked;
  }

  showClinicalGuidance() {
    return !!(this.order.is_internal &&
      this.order.procedure_type &&
      this.order.procedure_type.clinical_guidance);
  }

  showAdministrationGuidance() {
    return !!(this.order.is_internal &&
      this.order.procedure_type &&
      this.order.procedure_type.administration_guidance &&
      !this.editable);
  };

  showNotesToLab() {
    return !!((this.order.notes_to_lab && !this.editable) &&
      this.order.is_internal);
  };

  handleUpdateNotesToLab() {
    this.handleTrackLabNotesEvent();
  };

  parsedClinicalGuidance() {
    let parsedMarkdown = '';

    if (
      this.order &&
      this.order.procedure_type &&
      this.order.procedure_type.clinical_guidance
    ) {
      parsedMarkdown = this.marked(this.order.procedure_type.clinical_guidance);
    }

    return parsedMarkdown;
  };

  parsedAdministrationGuidance() {
    let parsedMarkdown = '';

    if (
      this.order &&
      this.order.procedure_type &&
      this.order.procedure_type.administration_guidance
    ) {
      parsedMarkdown = this.marked(this.order.procedure_type.administration_guidance);
    }

    return parsedMarkdown;
  };
};

ProcedureOrderGuidance.$inject = ['marked'];

export const omProcedureOrderGuidance = {
  bindings: {
    order: '<',
    editable: '<',
    handleTrackLabNotesEvent: '&',
  },
  templateUrl: 'orders/shared/procedure-order-guidance.component.html',
  controller: ProcedureOrderGuidance,
};

export function configureAnalytics($analyticsProvider, { mixpanelToken }) {
  if (
    typeof mixpanel !== 'undefined' &&
    mixpanel !== null &&
    mixpanelToken != null
  ) {
    mixpanel.init(mixpanelToken, { track_pageview: false });
  }
  $analyticsProvider.virtualPageviews(false);
}

configureAnalytics.$inject = ['$analyticsProvider', 'ENV'];

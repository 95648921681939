export class MedicationRouteService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  _baseApiPath() {
    return `/v2/admin/patients/${this.$stateParams.patientId}/medication_routes/`;
  }

  get(routeId) {
    return this.ApiService.get(`${this._baseApiPath()}${routeId}`);
  }
}

MedicationRouteService.$inject = ['ApiService', '$stateParams'];

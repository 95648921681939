export const getParamsFromUrl = component => {
  const fullPath = window.location.href;

  switch (component) {
    case 'follow_up_orders': {
      const matches = fullPath.match(
        // eslint-disable-next-line no-useless-escape
        `/(?:.+?)patients\/[0-9]+?\/chart\/orders\/${component}\/([A-Za-z]+)\/([0-9]+)/`,
      );
      if (matches && matches.length > 0) {
        return [matches[1], matches[2]];
      }
      break;
    }

    case 'letters': {
      const matches = fullPath.match(
        // eslint-disable-next-line no-useless-escape
        `/(?:.+?)patients\/[0-9]+?\/chart\/letters\/([0-9]+)/`,
      );
      if (matches && matches.length > 0) {
        return matches[1];
      }
      break;
    }

    case 'lab_orders':
    case 'consult_orders': {
      const matches = fullPath.match(
        // eslint-disable-next-line no-useless-escape
        `/(?:.+?)patients\/[0-9]+?\/chart\/orders\/([0-9]+)/`,
      );
      if (matches && matches.length > 0) {
        return matches[1];
      }
      break;
    }

    default: {
      const matches = fullPath.match(
        // eslint-disable-next-line no-useless-escape
        `/(?:.+?)patients\/[0-9]+?\/chart\/orders\/${component}\/([0-9]+)/`,
      );
      if (matches && matches.length > 0) {
        return matches[1];
      }
      break;
    }
  }

  return null;
};

const highlight = (text, pattern) =>
  text ? text.replace(pattern, match => `<strong>${match}</strong>`) : '';

const displayWith = (procedure, extra, pattern) =>
  `${procedure.clinical_description} (${highlight(extra, pattern)})`;

export const procedureHighlight = () => (procedure, query) => {
  const escapedQuery = _.escapeRegExp(query);
  const keywords = escapedQuery.split(/\s/);
  const re = new RegExp(`\\b(${escapedQuery})`, 'i');
  const pattern = new RegExp(`(${keywords.join('|')})`, 'ig');

  const exactTag = procedure.tags.find(tag => re.test(tag))

  if (exactTag) {
    return displayWith(procedure, exactTag, re)
  }

  if (pattern.test(procedure.clinical_description)) {
    return highlight(procedure.clinical_description, pattern);
  } else if (pattern.test(procedure.clinical_abbreviation)) {
    return displayWith(procedure, procedure.clinical_abbreviation, pattern);
  } else if (pattern.test(procedure.lay_description)) {
    return displayWith(procedure, procedure.lay_description, pattern);
  } else if (pattern.test(procedure.abbreviation)) {
    return displayWith(procedure, procedure.abbreviation);
  }
  return displayWith(procedure, procedure.tags.find(tag => pattern.test(tag)));
};

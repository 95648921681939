import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

const labOrdersPath = '/v2/admin/lab_orders';
const patientsPath = '/v2/admin/patients';

export class LabOrderService {
  constructor($stateParams, $rootScope, ApiService, LabOrder) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.LabOrder = LabOrder;
  }

  get(labOrderId) {
    return this.ApiService.get(`${labOrdersPath}/${labOrderId}`).then(
      response => new this.LabOrder(response),
    );
  }

  create() {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    return this.ApiService.save(
      `${patientsPath}/${patientId}/lab_orders`,
      new this.LabOrder(),
    ).then(response => {
      const createdOrder = new this.LabOrder(response);
      this.$rootScope.$emit('labOrderCreated', createdOrder);
      return createdOrder;
    });
  }

  update(labOrder) {
    return this.ApiService.update(
      `${labOrdersPath}/${labOrder.id}`,
      labOrder,
    ).then(response => new this.LabOrder(response));
  }

  collect(labOrder) {
    return this.ApiService.update(
      `${labOrdersPath}/${labOrder.id}/collect`,
      labOrder,
    ).then(response => new this.LabOrder(response));
  }

  clone(labOrderId) {
    return this.ApiService.save(
      `${labOrdersPath}/${labOrderId}/clone`,
      {},
    ).then(response => new this.LabOrder(response));
  }

  delete(labOrderId) {
    return this.ApiService.delete(`${labOrdersPath}/${labOrderId}`).then(
      response => new this.LabOrder(response),
    );
  }

}

LabOrderService.$inject = [
  '$stateParams',
  '$rootScope',
  'ApiService',
  'LabOrder',
];

const highlight = (text, pattern) =>
  text ? text.replace(pattern, match => `<strong>${match}</strong>`) : '';

const displayWith = (problemType, extra, pattern) =>
  `${problemType.clinical_description} (${highlight(extra, pattern)})`;

export const problemTypeHighlight = () => (problemType, query) => {
  const escapedQuery = _.escapeRegExp(query);
  const re = new RegExp(`\\b(${escapedQuery})`, 'i');

  const keywords = escapedQuery.split(/\s/);
  const pattern = new RegExp(`(${keywords.join('|')})`, 'ig');

  const exactTagMatch = problemType.tags.find(tag => re.test(tag))

  let returnString = problemType.clinical_description;

  if (exactTagMatch) {
    returnString = displayWith(problemType, exactTagMatch, re)
  }

  if (pattern.test(problemType.clinical_description)) {
    returnString = highlight(problemType.clinical_description, pattern);
  } else if (pattern.test(problemType.clinical_abbreviation)) {
    returnString = displayWith(problemType, problemType.clinical_abbreviation, pattern);
  } else if (pattern.test(problemType.lay_description)) {
    returnString = displayWith(problemType, problemType.lay_description, pattern);
  } else if (pattern.test(problemType.abbreviation)) {
    returnString = displayWith(problemType, problemType.abbreviation, pattern);
  }

  return returnString;
};

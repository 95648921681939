class TaskAssignmentController {
  constructor(ENV, TodoService, roleGroupSort) {
    this.taskAssigneesIndex = ENV.search.indexes.task_assignees;
    this.TodoService = TodoService;
    this.roleGroupSort = roleGroupSort;
  }

  updateTodo() {
    return this.TodoService.update(this.todo);
  }
}

TaskAssignmentController.$inject = ['ENV', 'TodoService', 'roleGroupSort'];

export const omTaskAssignment = {
  bindings: {
    todo: '<',
  },
  controller: TaskAssignmentController,
  templateUrl: 'shared/task-assignment.component.html',
};

const summaryPathPrefix = '/v2/admin/patients';
const summaryPath = '/v2/admin/summaries';
const summaryPathSuffix = 'summaries';

export class SummaryService {
  constructor(ApiService, $stateParams, Summary) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
    this.Summary = Summary;
  }

  _measurementsToArray(measurements) {
    const asArray = [];
    Object.values(measurements).forEach(measurement =>
      asArray.push(measurement),
    );
    return asArray;
  }

  save(params) {
    return this.ApiService.save(
      `${summaryPathPrefix}/${
        this.$stateParams.patientId
      }/${summaryPathSuffix}`,
      { summary: params },
    );
  }

  get() {
    return this.ApiService.get(`${summaryPath}/${this.$stateParams.id}`, {
      patientId: this.$stateParams.patientId,
    }).then(summaryResponse => {
      const measurements = summaryResponse.measurements.reduce(
        (byKey, measurement) => {
          byKey[measurement.abbreviation] = measurement;
          return byKey;
        },
        {},
      );
      summaryResponse.measurements = measurements;
      return new this.Summary(summaryResponse);
    });
  }

  update(summary) {
    if (summary.measurements) {
      summary.measurements = this._measurementsToArray(summary.measurements);
    }
    return this.ApiService.update(
      `${summaryPath}/${this.$stateParams.id}`,
      summary,
      { patientId: this.$stateParams.patientId },
    );
  }

  sign(summary) {
    return this.ApiService.update(
      `${summaryPath}/${this.$stateParams.id}/sign`,
      summary,
      { patientId: this.$stateParams.patientId },
    );
  }

  delete() {
    return this.ApiService.delete(`${summaryPath}/${this.$stateParams.id}`, {
      patientId: this.$stateParams.patientId,
    });
  }
}

SummaryService.$inject = ['ApiService', '$stateParams', 'Summary'];

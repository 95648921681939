const emailRecipientPattern = /"?(.*?)"? <.*>$/;

class TransmissionDetailsController {
  emailRecipient(email) {
    return email && email.to ? emailRecipientPattern.exec(email.to)[1] : '';
  }
}

TransmissionDetailsController.$inject = [];

export const omTransmissionDetails = {
  bindings: {
    history: '<',
  },
  controller: TransmissionDetailsController,
  templateUrl: 'orders/shared/transmission-details.component.html',
};

const patientsPath = '/v2/admin/patients';

export class PatientService {
  constructor(ApiService, $stateParams, Patient) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
    this.Patient = Patient;
  }

  get(patientId) {
    return this.ApiService.get(`${patientsPath}/${patientId}`).then(response => this.Patient(response));
  }

  update(attributes) {
    return this.ApiService.update(`${patientsPath}/${this.$stateParams.patientId}`, attributes);
  }
}

PatientService.$inject = ['ApiService', '$stateParams', 'Patient'];

export class FollowUpOrderService {
  constructor(ApiService, FOLLOW_UP_ORDER_TYPES) {
    this.ApiService = ApiService;
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }

  createFollowUpOrder(patientId, order) {
    return this.ApiService.save(
      `/v2/admin/patients/${patientId}/${this.getPathFromOrder(order)}`,
      order,
    );
  }

  updateFollowUpOrder(patientId, order) {
    return this.ApiService.update(
      `/v2/admin/patients/${patientId}/${this.getPathFromOrder(order)}/${
        order.id
      }`,
      order,
    );
  }

  deleteFollowUpOrder(patientId, order) {
    return this.ApiService.delete(
      `/v2/admin/patients/${patientId}/${this.getPathFromOrder(order)}/${
        order.id
      }`,
    );
  }

  getFollowUpOrder(patientId, order) {
    return this.ApiService.get(
      `/v2/admin/patients/${patientId}/${this.getPathFromOrder(order)}/${
        order.id
      }`,
    ).then(response => {
      response.due_on = new Date(response.due_on);
      return response;
    });
  }

  getPathFromOrder(order) {
    if (order.type === this.FOLLOW_UP_ORDER_TYPES.visit.name) {
      return this.FOLLOW_UP_ORDER_TYPES.visit.path;
    } else if (order.type === this.FOLLOW_UP_ORDER_TYPES.basic.name) {
      return this.FOLLOW_UP_ORDER_TYPES.basic.path;
    }
    return this.FOLLOW_UP_ORDER_TYPES.generic.path;
  }
}

FollowUpOrderService.$inject = ['ApiService', 'FOLLOW_UP_ORDER_TYPES'];

class PrintButtonMenuItemController {
  constructor($document, $timeout) {
    this.$document = $document;
    this.$timeout = $timeout;
  }

  print() {
    this._writeHtmlToIframe();
    this._openPrintDialog();
  }

  _writeHtmlToIframe() {
    const printDocument = this._getIFrameWindow().document.open();

    printDocument.write(this._printedNoteHtmlContent());
    printDocument.close();
  }

  _printedNoteHtmlContent() {
    let stylingTags = '';

    this.$document.find('link[rel], style').each((_, element) => {
      stylingTags += element.outerHTML;
    });

    const bodyContents = this.$document
      .find('.printed-document-contents')
      .html();

    return `
      <html>
      <head>
        ${stylingTags}
      </head>
      <body>
        ${bodyContents}
      </body>
      </html>
    `;
  }

  _getIFrameWindow() {
    if (this.iframeWindow) {
      return this.iframeWindow;
    }

    const iframe = this.$document.find('.printed-document-iframe').get(0);
    if (iframe) {
      this.iframeWindow = iframe.contentWindow;
    }
    return this.iframeWindow;
  }

  _openPrintDialog() {
    this.$timeout(() => {
      this._getIFrameWindow().print();
    }, 100);
  }
}

PrintButtonMenuItemController.$inject = ['$document', '$timeout'];

export const omPrintButtonMenuItem = {
  bindings: {
    noteId: '<',
    asButton: '<',
  },
  controller: PrintButtonMenuItemController,
  templateUrl: 'shared/print-button-menu-item.component.html',
};

import { patchErrorHandler } from './window-onerror-workaround';

export const configureRollbar = (ENV, RollbarProvider) => {
  const rollbarConfig = ENV.rollbar
    ? Object.assign({}, ENV.rollbar)
    : { enabled: false };

  if (
    rollbarConfig.payload &&
    rollbarConfig.payload.client &&
    rollbarConfig.payload.client.javascript
  ) {
    // this constant is set to the git HEAD sha by webpack
    rollbarConfig.payload.client.javascript.code_version = "332f2c1593125a51dfc77e9de933fd6e9ed251f9";
  }

  rollbarConfig.scrubTelemetryInputs = true;
  rollbarConfig.hostBlackList = ['api.mixpanel.com'];

  RollbarProvider.init(rollbarConfig);

  // Wait until Rollbar has finished loading before patching window.onerror
  const patchIntervalId = setInterval(() => {
    if (window._rollbarInitialized) {
      clearInterval(patchIntervalId);
      patchErrorHandler();
    }
  }, 100);
};

configureRollbar.$inject = ['ENV', 'RollbarProvider'];

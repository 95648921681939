import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

export class PatientPharmacyService {
  constructor(ApiService, $stateParams, PatientPharmacy) {
    this.$stateParams = $stateParams;
    this.PatientPharmacy = PatientPharmacy;
    this.ApiService = ApiService;
  }

  getApiEndPoint(patientId) {
    if (!patientId) {
      patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    }
    return `/v2/admin/patients/${patientId}/patient_pharmacies`;
  }

  query(patientId) {
    return this.ApiService.get(this.getApiEndPoint(patientId)).then(
      response => {
        const items = response.map(item => new this.PatientPharmacy(item));
        items.push(
          new this.PatientPharmacy({
            pharmacy: {
              name: 'print',
              display_name: 'Send to Printer',
              isNotValidPharmacy: true,
            },
          }),
        );
        items.push(
          new this.PatientPharmacy({
            pharmacy: {
              name: 'find',
              display_name: 'Find a Pharmacy',
              isNotValidPharmacy: true,
            },
          }),
        );
        return items;
      },
    );
  }

  save(pharmacy) {
    return this.ApiService.save(this.getApiEndPoint(), pharmacy).then(
      data => new this.PatientPharmacy(data),
    );
  }

  delete(patientPharmacyId) {
    return this.ApiService.delete(
      `/v2/admin/patient_pharmacies/${patientPharmacyId}`,
    );
  }
}

PatientPharmacyService.$inject = [
  'ApiService',
  '$stateParams',
  'PatientPharmacy',
];

class OrderActionBarPrimaryButtonController {
  constructor($scope) {
    this.$scope = $scope;
  }

  autosaveInProgress() {
    return this.form.saving;
  }

  // Sign

  signOrder() {
    this.$scope.$emit('sign-order');
  }

  displaySign() {
    return this.editable && !this.order.can_be_auto_approved;
  }

  disableSign() {
    return this.autosaveInProgress() || this.form.$invalid || !this.signable;
  }

  // Sign and Send

  signAndSendOrder() {
    this.$scope.$emit('send-order', { automateApproval: true });
  }

  signAndSendOrderManually() {
    this.$scope.$emit('send-order', {
      automateApproval: true,
      sendManually: true,
    });
  }

  signOrderOnBehalfOf() {
    this.$scope.$emit('start-signing-order-on-behalf-of');
  }

  signOnBehalfOfAndSendOrder() {
    this.$scope.$emit('start-signing-order-on-behalf-of', {
      automateApproval: true,
    });
  }

  signOnBehalfOfAndSendOrderManually() {
    this.$scope.$emit('start-signing-order-on-behalf-of', {
      automateApproval: true,
      sendManually: true,
    });
  }

  displaySignAndSend() {
    return this.editable && this.order.can_be_auto_approved;
  }

  disableSignAndSend() {
    return this.autosaveInProgress() || this.form.$invalid || !this.signable;
  }

  // Approve and Send

  approveAndSendOrder() {
    this.$scope.$emit('send-order');
  }

  approveAndSendOrderManually() {
    this.$scope.$emit('send-order', { sendManually: true });
  }

  displayApproveAndSend() {
    return this.order.status === 'signed';
  }

  // Resend / Complete

  displaySend() {
    return this.order.status === 'approved';
  }

  displayResend() {
    return this.order.status === 'sent' && this.order.transmission_failed;
  }

  displayComplete() {
    return this.order.status === 'sent' && !this.order.transmission_failed && !this.order.canRequestReviewOnCompletion();
  }

  displayCompleteAndRequestReview() {
    return this.order.status === 'sent' && !this.order.transmission_failed && this.order.canRequestReviewOnCompletion();
  }

  disableComplete() {
    return this.autosaveInProgress() || this.form.$invalid;
  }

  completeOrderAndRequestReview() {
    this.$scope.$emit('complete-order', { requestReviewOnCompletion: true });
  }

  reviewOrder() {
    this.$scope.$emit('review-internal-order');
  }
}

OrderActionBarPrimaryButtonController.$inject = ['$scope'];

export const omOrderActionBarPrimaryButton = {
  bindings: {
    order: '<',
    editable: '<',
    signable: '<',
    form: '<',
    sendOrder: '&',
    completeOrder: '&',
  },
  templateUrl: 'orders/shared/order-action-bar-primary-button.component.html',
  controller: OrderActionBarPrimaryButtonController,
};

class TemplateInsertionActionBarController {
  $onInit() {
    this.actionBarExpandedName = 'templateInsertionActionBar';
  }

  applyTemplate() {
    const template = this.template;
    this.onApply({ template });
    this.actionBarCollapse.collapse();
  }

  cancel() {
    this.template = null;
    this.actionBarCollapse.collapse();
  }
}

TemplateInsertionActionBarController.$inject = [];

export const omTemplateInsertionActionBar = {
  bindings: {
    actionBarCollapse: '<',
    index: '<',
    onApply: '&',
    profile: '<',
  },
  controller: TemplateInsertionActionBarController,
  templateUrl: 'shared/template-insertion-action-bar.component.html',
};

export function ngEnter() {
  return {
    restrict: 'A',
    link($scope, element, attrs) {
      element.on('keydown', (event) => {
        const enterKeyCode = 13;
        const code = event.keyCode || event.which;
        if (code === enterKeyCode) {
          if (event.altKey) {
            element.val(element.val().concat('\n'));
          } else {
            event.preventDefault();
            $scope.$apply(attrs.ngEnter);
          }
        }
      });
    },
  };
}

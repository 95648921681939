class BlockCharacters {
  constructor($scope, modelController) {
    this.$scope = $scope;
    this.modelController = modelController;
    modelController.$parsers.push(this.handleChange.bind(this));
  }

  handleChange(value) {
    if (value == null) return undefined;

    const pattern = /[\n\r<>!?[\]}{=)(*&^%$#~@]/g;
    const scrubbedValue = value.replace(pattern, '');
    this.modelController.$setViewValue(scrubbedValue);
    this.modelController.$render();
    return scrubbedValue;
  }
}

export function omBlockCharacters() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, controllers) => new BlockCharacters(scope, controllers),
  };
}

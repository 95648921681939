export class GooglePlacesService {
  constructor($q, uiGmapGoogleMapApi) {
    this.$q = $q;
    this.uiGmapGoogleMapApi = uiGmapGoogleMapApi;
  }

  getPlaceDetails(request, attributionElement) {
    return this._getPlacesClient(attributionElement).then(placesClient =>
      this._getPlaceDetails(placesClient, request),
    );
  }

  _getPlaceDetails(placesClient, request) {
    const deferred = this.$q.defer();
    request.sessionToken = this.sessionToken;
    placesClient.getDetails(request, (place, status) => {
      if (status === this.placesStatus.OK) {
        deferred.resolve(place);
      } else {
        deferred.reject(
          `[getPlaceDetails] Got places status='${status}' for request=${JSON.stringify(
            request,
          )}`,
        );
      }
    });
    return deferred.promise;
  }

  getPlacePredictions(request) {
    return this._getAutocompleteClient().then(autocompleteClient =>
      this._getPlacePredictions(autocompleteClient, request),
    );
  }

  _getPlacePredictions(autocompleteClient, request) {
    const deferred = this.$q.defer();
    request.sessionToken = this.sessionToken;
    autocompleteClient.getPlacePredictions(request, (predictions, status) => {
      if (status === this.placesStatus.OK) {
        deferred.resolve(predictions);
      } else if (status === this.placesStatus.ZERO_RESULTS) {
        deferred.resolve([]);
      } else {
        deferred.reject(
          `[getPlacePredictions] Got places status='${status}' for request=${JSON.stringify(
            request,
          )}`,
        );
      }
    });
    return deferred.promise;
  }

  _getAutocompleteClient() {
    if (this.autocompleteClient) {
      return this.$q.resolve(this.autocompleteClient);
    }

    return this.uiGmapGoogleMapApi.then(maps => {
      this.autocompleteClient = new maps.places.AutocompleteService();
      this.sessionToken =
        this.sessionToken || new maps.places.AutocompleteSessionToken();
      this.placesStatus = maps.places.PlacesServiceStatus;
      return this.autocompleteClient;
    });
  }

  _getPlacesClient(attributionElement) {
    if (this.placesClient && this.attributionElement === attributionElement) {
      return this.$q.resolve(this.placesClient);
    }

    return this.uiGmapGoogleMapApi.then(maps => {
      this.attributionElement = attributionElement;
      this.placesClient = new maps.places.PlacesService(attributionElement);
      this.placesStatus = maps.places.PlacesServiceStatus;
      return this.placesClient;
    });
  }
}

GooglePlacesService.$inject = ['$q', 'uiGmapGoogleMapApi'];

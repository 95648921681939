class RenewalItem {
  constructor(Renewal, RenewalRequestService) {
    this.Renewal = Renewal;
    this.RenewalRequestService = RenewalRequestService;
    this.renewal = {};
    this.$index = 0;
    this.totalCount = 0;
    this.isLast = false;
    this.profile = {};
  }
  $onInit() {
    this.renewal = new this.Renewal(this.renewal);
    this.isDeniedRenewal = this.renewal.isDenied();
    this.isPendingRenewal = this.renewal.isPending();
    this.canSignOnBehalfOf =
      this.renewal.valid_prescribers.length > 0 &&
      this.profile &&
      this.profile.has_signature_image &&
      this.renewalList.isProvider;

    this._mustSelfCheckout();
    this._setCanCheckout();
  }
  _setCanCheckout() {
    const renewalPrescriberId = this.renewal.prescriber
      ? this.renewal.prescriber.id
      : this.renewal.prescriber_id;

    const renewalProcessedBySomeoneElse =
      renewalPrescriberId &&
      renewalPrescriberId !== this.profile.id &&
      this.renewal.cart_state !== 'new';

    if (
      renewalProcessedBySomeoneElse &&
      (this.mustSelfCheckout || !this.canSignOnBehalfOf)
    ) {
      this.renewal.canCheckout = false;
    } else {
      this.renewal.canCheckout = true;
    }
  }
  _mustSelfCheckout() {
    const selfCheckoutState = ['WA', 'NY'];
    this.mustSelfCheckout =
      this.renewal.dispensable_restricted_controlled_substance ||
      selfCheckoutState.indexOf(this.renewal.pharmacy.address.state) > -1;
  }
  undo() {
    const pendingCartState = 'new';
    const emptyDenialReason = {
      code: null,
      description: null,
    };
    _.merge(this.renewal, {
      cart_state: pendingCartState,
      denial_reason: emptyDenialReason,
      class_name: this.renewal.class_name,
      id: this.renewal.id,
    });

    const request = this.RenewalRequestService.updateRenewal(this.renewal);

    this.renewalList.pullFromCheckout(this.renewal);
    request.then(response => {
      this.renewal.earliest_fill_date = null;
      _.merge(this.renewal, response);
      this.renewalList.addToPending(this.renewal);
      this.renewalList.checkSiblings(this.renewal);
    });
    this.renewalList.pendingRequests.add(request);
  }
}

RenewalItem.$inject = ['Renewal', 'RenewalRequestService'];

export const omRenewalItem = {
  bindings: {
    $index: '<',
    isLast: '<',
    officeState: '<',
    renewal: '<',
    totalCount: '<',
    profile: '<',
    formIsSaving: '<',
  },
  require: {
    renewalList: '^^omRenewalList',
    detailList: '?^^omDetailList',
  },
  templateUrl: 'renewals/renewal-item.component.html',
  controller: RenewalItem,
};

export class PatientPharmacy {
  constructor(attributes = {}) {
    if (attributes.pharmacy && !attributes.pharmacy.isNotValidPharmacy) {
      // We've got 2 dummy PatientPharmacies for "find" and "print" options which shouldn't have labels.
      attributes = PatientPharmacy.addServiceLabels(attributes);
    }
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  isNonEpcsPharmacy() {
    const isEpcsPharmacy =
      this.pharmacy.is_surescripts &&
      this.services &&
      this.services.controlledSubstance;
    return !this.pharmacy.isNotValidPharmacy && !isEpcsPharmacy;
  }

  static addServiceLabels = attributes => {
    const { pharmacy: pharmacyDetails } = attributes;
    const { labels } = pharmacyDetails;

    const hasLabel = (labelsArg = [], text) =>
      !!labelsArg.find(label => label.text === text);

    attributes.faxOnly = hasLabel(labels, 'Fax Only');
    attributes.is24h = hasLabel(labels, '24h');

    attributes.services =
      pharmacyDetails.ss_service_levels &&
      pharmacyDetails.ss_service_levels.reduce(
        (service, { keyword, description }) => ({
          ...service,
          [_.camelCase(keyword)]: description,
        }),
        {},
      );

    return attributes;
  };
}

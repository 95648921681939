class ProblemsController {
  constructor(
    $filter,
    $q,
    $rootScope,
    $state,
    $stateParams,
    toastr,
    FeaturesService,
    ProblemService,
    Problem,
    $timeout,
  ) {
    this.$orderBy = $filter('orderBy');
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.toastr = toastr;
    this.ProblemService = ProblemService;
    this.Problem = Problem;
    this.openProblems = {};
    this.FeaturesService = FeaturesService;
    this.notificationText = null;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.ProblemService.query(this.$stateParams.patientId).then(problems => {
      this.problems = this.sortProblems(problems);
    });
    this.hasPermissionToAutoCreateBasicFollowUpOrders();
  }

  newProblem() {
    return new this.Problem();
  }

  resetAddingProblemSessionId() {
    this.addProblemSessionId = +new Date();
  }

  linkSection() {
    this.$rootScope.$emit('sectionLinked', 'Problems', [this.problems]);
  }

  activeProblems() {
    return this.problems && this.problems.filter(problem => problem.active);
  }

  resolvedProblems() {
    return this.problems && this.problems.filter(problem => !problem.active);
  }

  saveProblem(problem) {
    if (problem.id) {
      this.ensureSummaryState(
        'Your problem A&P edit has been added to this note.',
      );
      return this.ProblemService.update(
        this.$stateParams.patientId,
        problem,
      ).then(savedProblem => {
        Object.assign(
          this.problems.find(p => p.id === savedProblem.id),
          savedProblem,
        );
        this.problems = this.sortProblems(this.problems);
        this.$rootScope.$broadcast('$$rebind::problemUpdated');
        if (!savedProblem.is_minor_change) {
          this.$rootScope.$emit('problemUpdated', savedProblem);
        }
        this.close(problem);
      });
    }

    this.ensureSummaryState(
      'Your problem A&P edit has been added to this note.',
    );
    return this.ProblemService.save(this.$stateParams.patientId, problem).then(
      savedProblem => {
        savedProblem.setCustomProblemCode();
        this.problems = this.sortProblems(this.problems.concat(savedProblem));
        this.$rootScope.$emit('problemSaved', savedProblem);
        this.$rootScope.$broadcast('$$rebind::problemUpdated');
        this.close(problem);
      },
    );
  }

  deleteProblem(problem) {
    this.ensureSummaryState(
      'Your problem A&P has been removed from this note.',
    );
    return this.ProblemService.delete(
      this.$stateParams.patientId,
      problem.id,
    ).then(deletedProblem => {
      const index = this.problems.findIndex(p => p.id === deletedProblem.id);
      if (index > -1) {
        this.problems.splice(index, 1);
        this.$rootScope.$emit('problemDeleted', deletedProblem);
        this.close(problem);
      }
    });
  }

  resolveProblem(problem) {
    return this.ProblemService.resolve(
      this.$stateParams.patientId,
      problem,
    ).then(savedProblem => {
      Object.assign(
        this.problems.find(p => p.id === savedProblem.id),
        savedProblem,
      );
      this.problems = this.sortProblems(this.problems);
      this.$rootScope.$emit('problemResolved', savedProblem);
      this.$rootScope.$broadcast('$$rebind::problemUpdated');
      this.close(problem);
    });
  }

  reactivateProblem(problem) {
    return this.ProblemService.reactivate(
      this.$stateParams.patientId,
      problem,
    ).then(savedProblem => {
      Object.assign(
        this.problems.find(p => p.id === savedProblem.id),
        savedProblem,
      );
      this.problems = this.sortProblems(this.problems);
      this.$rootScope.$emit('problemReactivated', savedProblem);
      this.$rootScope.$broadcast('$$rebind::problemUpdated');
    });
  }

  open(problem) {
    this.openProblems[problem.id] = true;
    // this is a workaround for angular-elastic not properly handling height adjustments
    // on elements initially hidden on page load
    this.$timeout(() => {
      this.$rootScope.$broadcast('elastic:adjust');
    }, 200);
  }

  close(problem) {
    delete this.openProblems[problem.id];
  }

  // private

  ensureSummaryState(message) {
    const summaryState = 'app.chart.workspace.summaries';
    if (!this.$state.is(summaryState) && this.$rootScope.lastEditedSummary) {
      return this.$state
        .go(summaryState, { id: this.$rootScope.lastEditedSummary })
        .then(() => {
          this.$rootScope.$emit('selectTab', 'Workspace');
          this.toastr.success(message);
        });
    }
    return this.$q.resolve();
  }

  hasPermissionToAutoCreateBasicFollowUpOrders() {
    this.FeaturesService.hasFeature('auto_check_in_creation').then(allowed => {
      this.isAllowedToAutoCreateBasicFollowUpOrders = allowed;
    });
  }

  sortProblems(problems) {
    return this.$orderBy(problems, [
      '-included_in_medical_history',
      'problem_type.clinical_description',
    ]);
  }
}

ProblemsController.$inject = [
  '$filter',
  '$q',
  '$rootScope',
  '$state',
  '$stateParams',
  'toastr',
  'FeaturesService',
  'ProblemService',
  'Problem',
  '$timeout',
];

export const omProblems = {
  templateUrl: 'problems/_problems.html',
  controller: ProblemsController,
  bindings: {
    sectionsLinkable: '<',
  },
};

export const omCollapseCollapse = () => ({
  restrict: 'AE',
  require: '^omCollapse',
  link(scope, element, attrs, collapse) {
    element.addClass('om-collapse-collapse');
    element.on('click', event => {
      collapse.collapse();
      event.stopPropagation();
    });
  },
});

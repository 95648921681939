class SupportingDocumentsAlertController {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
  }

  orderHasFailedDocuments() {
    return this.unsuccessfulDocuments.length > 0;
  }
}

SupportingDocumentsAlertController.$inject = ['$rootScope'];

export const omSupportingDocumentsAlert = {
  bindings: {
    unsuccessfulDocuments: '<',
  },
  controller: SupportingDocumentsAlertController,
  templateUrl: 'orders/shared/supporting-documents-alert.component.html',
};

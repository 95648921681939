export function typeahead() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attr, ngModel) {
      let preservedValue = ngModel.$viewValue;

      element.on('click', () => {
        preservedValue = ngModel.$viewValue;
        ngModel.$setViewValue('');
        ngModel.$render();
      });

      element.on('blur', () => {
        if (!ngModel.$viewValue || ngModel.$viewValue.length === 0) {
          ngModel.$setViewValue(preservedValue);
          ngModel.$render();
        }
      });
    },
  };
}

import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';
import { getParamsFromUrl } from '../upgrade-param-mapper';

class FollowUpController {
  constructor(
    $analytics,
    $state,
    $stateParams,
    FollowUpOrderService,
    FeaturesService,
    FOLLOW_UP_ORDER_TYPES,
  ) {
    this.FOLLOW_UP_TYPES = {
      visit_follow_up_orders: {
        id: FOLLOW_UP_ORDER_TYPES.visit.name,
        label: FOLLOW_UP_ORDER_TYPES.visit.label,
      },
      basic_follow_up_orders: {
        id: FOLLOW_UP_ORDER_TYPES.basic.name,
        label: FOLLOW_UP_ORDER_TYPES.basic.label,
      },
      generic_follow_up_orders: {
        id: FOLLOW_UP_ORDER_TYPES.generic.name,
        label: FOLLOW_UP_ORDER_TYPES.generic.label,
      },
    };

    this.analyticsBaseAttributes = {
      workflow: 'Charting',
      component: 'Orders',
      order_type: 'Follow Up',
    };

    this.$analytics = $analytics;
    this.order = {};
    this.saving = false;
    this.deleting = false;
    this.confirmingDelete = false;
    this.childReady = false;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();

    /* Used to get sticky states working in hybrid mode */
    const paramMatches = getParamsFromUrl('follow_up_orders');
    this.orderId =
      paramMatches && paramMatches.length >= 1
        ? paramMatches[1]
        : $stateParams.id;
    this.orderType =
      paramMatches && paramMatches.length >= 1
        ? paramMatches[0]
        : $stateParams.orderType;
    /* End of sticky states fix */

    this.orderType = this.orderType
      ? FOLLOW_UP_ORDER_TYPES[this.orderType].name
      : FOLLOW_UP_ORDER_TYPES.visit.name;
    this.FollowUpOrderService = FollowUpOrderService;
    this.FeaturesService = FeaturesService;
    this.$state = $state;
    this.followUpTypes = [];
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }
  $onInit() {
    this.setEnabledFollowUpTypes();
    if (this.followUpTypes.length > 0)
      this.selectedFollowUpType = this.followUpTypes[0];
    if (this.orderId) {
      this.getFollowUpOrder();
    } else {
      this.childReady = true;
    }
  }
  setEnabledFollowUpTypes() {
    Object.keys(this.FOLLOW_UP_TYPES).map(key =>
      this.FeaturesService.hasFeature(key).then(enabled => {
        if (enabled) this.followUpTypes.push(this.FOLLOW_UP_TYPES[key]);
        this.setSelectedFollowUpType();
      }),
    );
  }
  getFollowUpOrder() {
    this.FollowUpOrderService.getFollowUpOrder(this.patientId, {
      id: this.orderId,
      type: this.orderType,
    }).then(response => {
      this.order = response;
      this.childReady = true;
    });
  }
  setSelectedFollowUpType() {
    this.selectedFollowUpType = this.followUpTypes.find(
      type => type.id === this.orderType,
    );
  }
  cancel() {
    this.$state.go('app.chart.orders.list');
  }

  signOrder() {
    const valid = this.childComponent.validateForm();
    if (valid) {
      this.saving = true;

      if (this.childComponent.order && this.childComponent.order.id) {
        this.$analytics.eventTrack('Order Edited', {
          ...this.analyticsBaseAttributes,
          order_subtype: this.childComponent.analyticsType,
          subcomponent: 'Sign Button',
          order_id: this.childComponent.order.id,
        });
      } else {
        this.$analytics.eventTrack('Order Signed', {
          ...this.analyticsBaseAttributes,
          order_subtype: this.childComponent.analyticsType,
          subcomponent: 'Sign Button',
        });
      }

      this.childComponent
        .signFollowUpOrder()
        .then(() => {
          this.saving = false;
          this.$state.go('app.chart.orders.list');
        })
        .catch(() => {
          this.saving = false;
        });
    }
  }

  toggleDeleteConfirmation() {
    this.confirmingDelete = !this.confirmingDelete;
  }

  deleteOrder() {
    this.deleting = true;

    this.$analytics.eventTrack('Order Deleted', {
      ...this.analyticsBaseAttributes,
      order_subtype: this.childComponent.analyticsType,
      subcomponent: 'Sign Button',
      order_id: this.childComponent.order.id,
    });

    this.childComponent.deleteFollowUpOrder().then(() => {
      this.deleting = false;
      this.$state.go('app.chart.orders.list');
    });
  }

  registerChild(childComponent) {
    this.childComponent = childComponent;
  }

  selectFollowUpType() {
    this.fieldChanged('Follow Up Type Field');
  }

  fieldChanged(fieldName) {
    this.$analytics.eventTrack('Order Field Changed', {
      ...this.analyticsBaseAttributes,
      subcomponent: fieldName,
      order_subtype: this.childComponent.analyticsType,
    });
  }

  datePickerSelected(quantity, unit) {
    const buttonName = `${quantity} ${unit.charAt(0).toUpperCase() +
      unit.slice(1)} Button`;
    this.$analytics.eventTrack('Order Date Picker Selected', {
      ...this.analyticsBaseAttributes,
      subcomponent: buttonName,
      order_subtype: this.childComponent.analyticsType,
    });
  }
}

FollowUpController.$inject = [
  '$analytics',
  '$state',
  '$stateParams',
  'FollowUpOrderService',
  'FeaturesService',
  'FOLLOW_UP_ORDER_TYPES',
];

export const omFollowUp = {
  controller: FollowUpController,
  templateUrl: 'orders/follow-up/follow-up.component.html',
};

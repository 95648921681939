export class ConsultOrder {
  constructor(attributes = { status: 'unsigned', supporting_documents: [] }) {
    if (attributes.created_at)
      attributes.created_at = moment(attributes.created_at);

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
    this.isConsultOrder = true;
  }

  addIndication(indication) {
    this.indication = indication;
  }

  removeIndication(indication) {
    if (this.indication === indication) {
      delete this.indication;
    }
  }

  canRequestReviewOnCompletion() {
    return false;
  }
}

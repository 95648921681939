// please just use for focusing on fields.  
// This directive provides a focusOn method on the element which can be used
// to change the focus on click or other actions
export function omFocus($timeout, $rootScope) {
  return (scope) => {
    scope.focusOn = evName => $timeout(() => $rootScope.$broadcast(evName));
  };
}

omFocus.$inject = ['$timeout', '$rootScope'];

const RELATIVE_PROVIDERS_ENDPOINT = '/v2/admin/orders/relative_providers';

export class FollowUpProvidersService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getAll(patientId) {
    return this.ApiService.get(`${RELATIVE_PROVIDERS_ENDPOINT}`, {
      params: { patient_id: patientId },
    });
  }
}

FollowUpProvidersService.$inject = ['ApiService'];

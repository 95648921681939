const problemCodePathPrefix = '/v2/admin/problem_types';
const problemCodePathSuffix = 'problem_codes';

export class ProblemCodeService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  findByProblemType(problemType, problemCodeLocation) {
    const params = {};
    if (problemCodeLocation) {
      params.problem_code_location_id = problemCodeLocation.id;
    }
    return this.ApiService.get(
      `${problemCodePathPrefix}/${problemType.id}/${problemCodePathSuffix}`,
      { params },
    );
  }
}

ProblemCodeService.$inject = ['ApiService'];

export const omFocusOn = () => ({
  restrict: 'A',
  link($scope, element, attrs) {
    $scope.$on(attrs.omFocusOn, () => {
      let input;
      if (element[0].classList.contains('ui-select-container')) {
        input = element.find('input');
      } else {
        input = element;
      }
      if (attrs.omFocusHighlight !== undefined) {
        input.select();
      }
      input.focus();
    });
  },
});

export const groupBy = () => _.memoize(((collection, field) => {
// we wanna be able to group by something like pharmacy.id etc
  const deepFields = field.split('.');
  return _.groupBy(collection, (item) => {
    let finalVal = item;
    _.each(deepFields, (deepField) => {
      finalVal = finalVal[deepField];
    });
    return finalVal; // this is the final thing we wanna actually group by
  });
}), (collection, field) =>
  // to tell objects apart we're making a uniquie key based on the
  // objects in the array and the fields
  JSON.stringify(collection) + field
);

export function omBackgroundImage() {
  return {
    link(scope, element, attrs) {
      const url = attrs.omBackgroundImage;
      element.css({
        'background-image': `url(${url})`,
        'background-size': 'cover',
      });
    },
  };
}

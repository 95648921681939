import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

class OrdersListController {
  constructor(
    $analytics,
    $rootScope,
    $state,
    $stateParams,
    ConsultOrderService,
    FeaturesService,
    LabOrderService,
    OrdersService,
    ProcedureOrderService,
    VaccineOrderService,
  ) {
    this.$analytics = $analytics;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.ConsultOrderService = ConsultOrderService;
    this.FeaturesService = FeaturesService;
    this.LabOrderService = LabOrderService;
    this.OrdersService = OrdersService;
    this.ProcedureOrderService = ProcedureOrderService;
    this.VaccineOrderService = VaccineOrderService;
    this.activeOrders = [];
    this.inactiveOrders = [];
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.isFollowUpEnabled = false;
    this.isSurveyOrderEnabled = false;
    this.setRootScopeListeners();
  }
  $onInit() {
    this.setFeatureFlags();
    this.OrdersService.getAll(this.patientId).then(response => {
      this.parseOrders(response);
    });
  }

  setRootScopeListeners() {
    this.setConsultOrderListener();
    this.setLabOrderListener();
    this.setVaccineOrderListener();
  }

  setConsultOrderListener() {
    this.$rootScope.$on('consultOrderCreated', (event, { id }) => {
      this.$state.go('app.chart.orders.editConsultOrder', { id });
    });
  }

  setLabOrderListener() {
    this.$rootScope.$on('labOrderCreated', (event, { id }) => {
      this.$state.go('app.chart.orders.edit', { id });
    });
  }

  setVaccineOrderListener() {
    this.$rootScope.$on('vaccineOrderCreated', (event, { id: orderId }) => {
      this.$state.go('app.chart.orders.editVaccineOrder', { orderId });
    });
  }

  setFeatureFlags() {
    this.FeaturesService.hasFeature('visit_follow_up_orders').then(
      isEnabled => (this.isFollowUpEnabled = isEnabled),
    );
    this.FeaturesService.hasFeature('survey_follow_up_orders').then(
      isEnabled => (this.isSurveyOrderEnabled = isEnabled),
    );
  }

  parseOrders(orders) {
    orders.forEach(order => {
      if (this.isInactiveOrder(order)) {
        this.inactiveOrders.push(order);
      } else {
        this.activeOrders.push(order);
      }
    });
  }

  isInactiveOrder(order) {
    return (
      this.isInactiveConsultOrder(order) ||
      this.isInactiveVaccineOrder(order) ||
      this.isInactiveProcedureOrder(order)
    );
  }

  isInactiveConsultOrder(order) {
    return (
      !!order.isConsultOrder &&
      (order.order.status === 'completed' || order.order.status === 'redacted' || order.order.status === 'declined' || order.order.status === 'patient_completed')
    );
  }

  isInactiveProcedureOrder(order) {
    return (
      !!order.isProcedureOrder &&
      !order.order.reviewable &&
      (order.order.status === 'completed' ||
        order.order.status === 'redacted' ||
        order.order.status === 'reviewed')
    );
  }

  isInactiveVaccineOrder(order) {
    return !!order.isVaccineOrder && !order.order.is_active;
  }

  allOrdersAreEmpty() {
    return this.inactiveOrdersAreEmpty() && this.activeOrdersAreEmpty();
  }

  inactiveOrdersAreEmpty() {
    return this.inactiveOrders.length === 0;
  }

  activeOrdersAreEmpty() {
    return this.activeOrders.length === 0;
  }

  newLabOrder() {
    this.trackNewOrderSelectedEvent('Lab');
    return this.LabOrderService.create();
  }

  newProcedureOrder() {
    this.trackNewOrderSelectedEvent('Procedure');
    this.ProcedureOrderService.create().then(response => {
      this.$state.go('app.chart.orders.editProcedureOrder', {
        id: response.id,
      });
    });
  }

  newConsultOrder() {
    this.trackNewOrderSelectedEvent('Consult');
    return this.ConsultOrderService.create();
  }

  newFollowUpOrder() {
    this.trackNewOrderSelectedEvent('Follow Up');
    this.$state.go('app.chart.orders.newFollowUpOrder');
  }

  newVaccineOrder() {
    this.trackNewOrderSelectedEvent('Vaccine');
    return this.VaccineOrderService.create();
  }

  newSurveyOrder() {
    this.trackNewOrderSelectedEvent('Survey');
    this.$state.go('app.chart.orders.newSurveyOrder');
  }

  remove(order) {
    _.remove(
      this.activeOrders,
      resource =>
        order.id === resource.order.id && resource.order.type === order.type,
    );
  }

  trackNewOrderSelectedEvent(orderType) {
    this.$analytics.eventTrack('Create Order Selected', {
      workflow: 'Charting',
      component: 'Orders',
      subcomponent: 'Create Order Menu',
      order_type: orderType,
    });
  }
}

OrdersListController.$inject = [
  '$analytics',
  '$rootScope',
  '$state',
  '$stateParams',
  'ConsultOrderService',
  'FeaturesService',
  'LabOrderService',
  'OrdersService',
  'ProcedureOrderService',
  'VaccineOrderService',
];

export const omOrdersList = {
  controller: OrdersListController,
  templateUrl: 'orders/orders-list.component.html',
};

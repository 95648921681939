const planTypesUsingNetworks = ['EPO', 'HMO', 'POS'];
const filteredNetworkNames = ['Other', 'None'];
const oneMile = '1.6km';
const fifteenMiles = '24km';

class ConsultantSearchController {
  constructor(
    ENV,
    $analytics,
    $search,
    $stateParams,
    ProfileService,
  ) {
    this.$analytics = $analytics;
    this.$search = $search;
    this.$stateParams = $stateParams;
    this.ProfileService = ProfileService;
    this.contactsIndex = ENV.search.indexes.contacts;
    this.ready = false;
    this.profileOffice = null;
  }

  $onInit() {
    this._savePatientAddresses(this.patient);
    this._setDefaultLocation();

    this.ProfileService.get().then(profile => {
      this._setProviderLocation(profile.office);
    });

    this.filterSettings = {
      filterInNetworkOnly: false,
    };

    this.hasSearched = false;
    this.results = [];
    this.queryString = '';
  }

  $onChanges() {
    if (this.filterSettings && this.diagnosticGroupId) {
      this.search();
    }
  }

  _savePatientAddresses(patient) {
    this.defaultLocations = [];
    if (!patient) {
      return;
    }

    const locationsByKind = _.groupBy(patient.addresses, 'kind');
    _.forEach(locationsByKind, (addresses, kind) => {
      let label = `${patient.preferred_name}'s ${kind}`;
      if (kind === 'other') {
        label = `${patient.preferred_name}'s address`;
      }

      const sortedAddresses = _.sortBy(addresses, 'address');
      const labeledAddresses = sortedAddresses.map(address =>
        Object.assign({}, address, { label }),
      );
      this.defaultLocations = this.defaultLocations.concat(labeledAddresses);
    });

    if (patient.office) {
      this.defaultLocations.push(
        Object.assign({}, patient.office.address, {
          label: `${patient.preferred_name}'s preferred office`,
          address: this._addressDisplay(patient.office.address),
        }),
      );
    }
  }

  _setDefaultLocation() {
    this.defaultLocation = null;
    if (this.patient && this.patient.primary_address) {
      this.defaultLocation = this.defaultLocations.find(
        address => address.id === this.patient.primary_address.id,
      );
    }
  }

  _setProviderLocation(profileOffice) {
    if (profileOffice) {
      this.defaultLocations.push(
        Object.assign({}, profileOffice, {
          hasBorder: true,
          label: `One Medical (${profileOffice.name})`,
          address: this._addressDisplay(profileOffice),
        }),
      );
    }
    if (!this.defaultLocation) {
      this.defaultLocation = profileOffice;
    }
  }

  _addressDisplay(address) {
    if (!address) {
      return '';
    }

    let display = address.street || address.address1;
    const zip = address.zip_code || address.zip;
    if (address.address2) {
      display += `, ${address.address2}`;
    }
    display += `, ${address.city}, ${address.state.short_name} ${zip}`;
    return display;
  }

  search({ trackEvent } = { trackEvent: true }) {
    if (!this.diagnosticGroupId && !this.queryString) {
      this.results = [];
      this.hasSearched = false;
      return Promise.resolve();
    }

    const query = this._searchQuery();

    return this.$search.search(query).then(results => {
      this.results = results.hits.hits.map(result =>
        Object.assign({}, result._source, {
          distance: this._getDistance(result),
        }),
      );
      this.hasSearched = true;

      if (trackEvent) {
        this._trackSearchExecution();
      }
    });
  }

  setLocation(location) {
    this.location = location;
    this.search();
  }

  _searchQuery() {
    let query = this._getBaseQuery();
    query = this._applyLocationSettings(query);
    query = this._applyFilterSettings(query);

    return query;
  }

  _getDistance(result) {
    return result.fields.distance[0];
  }

  _applyLocationSettings(query) {
    const location = this.location || this.defaultLocation;

    if (location) {
      const boostByDistance = {
        gauss: {
          location: {
            origin: {
              lat: location.latitude,
              lon: location.longitude,
            },
            offset: oneMile,
            scale: fifteenMiles,
          },
        },
        weight: 10,
      };
      // Add a computed distance field for display
      query.body._source = ['*'];
      query.body.script_fields = this._distanceScriptField(location);
      // Use distance and ratings to compute a new _score
      query.body.query.function_score.functions.push(boostByDistance);
      // Filter by distance to ensure nothing outside of range
      query.body.query.function_score.query.bool.filter.bool.filter = this._distanceFilter(
        location,
      );
    }

    return query;
  }

  _applyFilterSettings(query) {
    const primaryInsurance = this.patient.primary_insurance || {};

    const shoulds = [];
    const musts = [];

    const insuranceFilters =
      this.filterSettings.filterInNetworkOnly &&
      primaryInsurance.name !== 'Self Pay';

    if (insuranceFilters) {
      const carrierMatchHash = this._buildCarrierMatchHash(primaryInsurance);
      const filterSettings = {
        // We consider results that have no contact_insurances AND no insurance_networks
        // as "Unknown"--even if they're marked as is_complete: true.
        //
        // This part of the query prevents us from filtering out this case when the
        // "Exclude OON" box is checked.
        bool: {
          must_not: [
            { exists: { field: 'contact_insurances' } },
            { exists: { field: 'insurance_networks' } },
          ],
        },
      };

      shoulds.push({ term: { is_complete: false } });
      shoulds.push(filterSettings);
      shoulds.push(carrierMatchHash);
    }

    if (this.diagnosticGroupId) {
      musts.push({
        term: {
          'diagnostic_groups.id': this.diagnosticGroupId || 0,
        },
      });
    }

    if (insuranceFilters || this.diagnosticGroupId) {
      query.body.query.function_score.query.bool.filter.bool.should = shoulds;
      query.body.query.function_score.query.bool.filter.bool.must = musts;
    }

    return query;
  }

  _buildCarrierMatchHash(primaryInsurance) {
    const patientInsuranceType = primaryInsurance.type;
    const carrierId = primaryInsurance.carrier_id || 0;
    const carrierIdTermHash = {
      term: {
        'contact_insurances.insurance_carrier.id': carrierId,
      },
    };

    // If patient's primary insurance includes a network ID, restrict to contacts with a
    // carrier match AND a network ID match; otherwise, just check for the carrier match.
    if (
      planTypesUsingNetworks.includes(patientInsuranceType) &&
      primaryInsurance.network &&
      !filteredNetworkNames.includes(primaryInsurance.network)
    ) {
      return {
        bool: {
          must: [
            carrierIdTermHash,
            {
              term: { 'insurance_networks.id': primaryInsurance.network_id },
            },
          ],
        },
      };
    }

    return carrierIdTermHash;
  }

  _sortOptions() {
    if (this.externalOnly === undefined || this.externalOnly) {
      return [{ is_active: { order: 'desc' } }, { _score: { order: 'asc' } }]
    }
    return [{ is_internal: { order: 'desc' } }, { is_active: { order: 'desc' } }, { _score: { order: 'asc' } }]
  }

  _getBaseQuery() {
    const emptyFilters = {
      bool: {
        should: [],
        must: [],
        minimum_should_match: '0<1', // 0 if there are 0 'should' conditions; otherwise 1
        filter: {},
      },
    };
    const ratingsBoost = {
      field_value_factor: {
        field: 'ratings_modifier',
        missing: 0,
        factor: 0.5,
      },
    };
    const healthSystemPartnershipBoost = {
      filter: {
        match: { is_partner: true },
      },
      weight: 1
    };
    const healthSystemPartnershipPracticeBoost = {
      filter: {
        bool: {
          must: [{ term: { is_partner: true } }, { term: { kind: 'practice' } },]
        },
      },
      weight: 1
    };
    const isQualityBoost = {
      filter: {
        match: { is_quality: true },
      },
      weight: 1
    };
    const isElectronicallyIntegratedBoost = {
      filter: {
        match: { is_electronically_integrated: true },
      },
      weight: 1
    };
    const hasPreferredAccessBoost = {
      filter: {
        match: { has_preferred_access: true },
      },
      weight: 1
    };

    if (!this.queryString && this.diagnosticGroupId) {
      return {
        index: this.contactsIndex,
        size: 50,
        body: {
          sort: [
            { is_active: { order: 'desc' } },
            { _score: { order: 'desc' } },
          ],
          query: {
            function_score: {
              query: {
                bool: {
                  must_not: {
                    term: {
                      kind: 'group',
                    },
                  },
                  filter: emptyFilters,
                },
              },
              functions: [ratingsBoost, healthSystemPartnershipBoost, isQualityBoost, isElectronicallyIntegratedBoost,
                hasPreferredAccessBoost, healthSystemPartnershipPracticeBoost],
              boost_mode: 'replace',
              score_mode: 'sum',
            },
          },
        },
      };
    }

    const searchQuery = this.queryString;
    return {
      index: this.contactsIndex,
      size: 50,
      body: {
        sort: [{ is_active: { order: 'desc' } }, { _score: { order: 'desc' } }],
        query: {
          function_score: {
            query: {
              bool: {
                must: [
                  {
                    multi_match: {
                      query: searchQuery,
                      fields: [
                        'specialties.name',
                        'display_name',
                        'company_name',
                      ],
                      type: 'cross_fields',
                      operator: 'and',
                    },
                  },
                ],
                must_not: [
                  {
                    term: {
                      kind: 'group',
                    },
                  },
                ],
                filter: emptyFilters,
              },
            },
            functions: [ratingsBoost, healthSystemPartnershipBoost, isQualityBoost, isElectronicallyIntegratedBoost,
              hasPreferredAccessBoost, healthSystemPartnershipPracticeBoost],
            boost_mode: 'replace',
            score_mode: 'sum',
          },
        },
      },
    };
  }

  _distanceFilter(location) {
    return {
      geo_distance: {
        distance: '125mi',
        distance_type: 'arc',
        location: {
          lat: location.latitude,
          lon: location.longitude,
        },
      },
    };
  }

  // Calculate distance for display (arcDistance is the same algorithm as arc)
  _distanceScriptField(location) {
    return {
      distance: {
        script: {
          lang: 'painless',
          source: `
def metersToMiles = 0.000621371;
return doc['location'].arcDistance(params.lat, params.lon) * metersToMiles;
         `,
          params: {
            lat: location.latitude,
            lon: location.longitude,
          },
        },
      },
    };
  }

  _trackSearchExecution() {
    this.$analytics.eventTrack('Order Consultant Search Executed', {
      workflow: 'Charting',
      component: 'Orders',
      subcomponent: 'Search Button',
      order_id: this.order.id,
      order_type: this.order.type,
      form_type: 'Sign Form',
      input_string: this.queryString,
    });
  }
}

ConsultantSearchController.$inject = [
  'ENV',
  '$analytics',
  '$search',
  '$stateParams',
  'ProfileService',
];

export const omConsultantSearch = {
  bindings: { patient: '<', order: '<', diagnosticGroupId: '<', externalOnly: '<' },
  templateUrl: 'orders/shared/consultant-search.component.html',
  controller: ConsultantSearchController,
};

class TextAreaWithInfo {
  constructor($element) {
    this.$element = $element;
  }

  $onChanges(changes) {
    if (changes && changes.ngParentModel) {
      this.text = changes.ngParentModel.currentValue || '';
    }
  }

  updateModel() {
    this.ngModel.$setViewValue(this.text);
  }

  updateModelWithPlaceholder() {
    if (this.usePlaceholderIfEmpty !== true) { return; }
    if (this.text.length === 0) {
      this.text = this.placeholder;
      this.updateModel();
      this.onPlaceholderEdit();
    }
  }
}

TextAreaWithInfo.$inject = ['$element'];

export const omTextAreaWithInfo = {
  bindings: {
    placeholder: '<',
    usePlaceholderIfEmpty: '<',
    description: '@',
    characterWarning: '<',
    ngParentModel: '<ngModel',
    onPlaceholderEdit: '&',
  },
  require: {
    ngModel: 'ngModel',
  },
  controller: TextAreaWithInfo,
  templateUrl: 'shared/text-area-with-info.component.html',
};

export const cut = () => (value, wordwise, max, tail) => {
  if (!value) {
    return '';
  }
  max = parseInt(max, 10);
  if (!max || value.length <= max) {
    return value;
  }
  value = value.substr(0, max);
  if (wordwise) {
    const lastspace = value.lastIndexOf(' ');
    if (lastspace !== -1) {
      value = value.substr(0, lastspace);
    }
  }
  return value + (tail || ' …');
};

class ConsultantAutocompleteController {
  constructor(ENV, $analytics, $search, $timeout) {
    this.consultantSearchAutocompleteIndex =
      ENV.search.indexes.consultant_autocomplete;
    this.$analytics = $analytics;
    this.$search = $search;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.setPlaceholderText();
  }

  setPlaceholderText() {
    let placeholderText;
    if (this.order.type === 'ProcedureOrder') {
      placeholderText = 'Search by name';
    } else {
      placeholderText = 'Search by specialty or name';
    }
    this.placeholderText = placeholderText;
  }

  typeaheadSelect(event) {
    // Inclusion of $timeout allows queryString to be set correctly (via ng-model)
    // before performConsultantSearch() executes
    this.$timeout(() => {
      // Skip tracking in consultant-search module; track as autocomplete selection instead
      this.performConsultantSearch({ trackEvent: false });
      this._trackTypeaheadSelect();
    });

    event.stopPropagation();
  }

  handleKeydown(event) {
    if (event.keyCode === 13) {
      this.performConsultantSearch();
      event.preventDefault();
    }
  }

  search() {
    // Inclusion of $timeout allows queryString to update correctly
    // before query is passed to $search
    return this.$timeout(() => {
      const searchTerms = [];
      const query = this._buildAutocompleteSearchQuery();

      return this.$search.search(query).then(results => {
        const hits = results.hits.hits;
        let currentTerm;
        let x;

        for (x = 0; x < hits.length; x += 1) {
          currentTerm = hits[x]._source.term;

          if (!searchTerms.includes(currentTerm)) {
            searchTerms.push(currentTerm);
          }

          if (searchTerms.length >= 8) {
            break;
          }
        }

        return searchTerms;
      });
    });
  }

  _buildAutocompleteSearchQuery() {
    return {
      index: this.consultantSearchAutocompleteIndex,
      size: 100, // Grab more results than needed to eliminate duplicates
      body: {
        query: {
          bool: {
            must: [
              {
                match: {
                  term: {
                    query: this.queryString,
                    operator: 'and',
                  },
                },
              },
            ],

            should: [
              {
                constant_score: {
                  filter: {
                    term: {
                      doctype: {
                        value: 'specialty',
                      },
                    },
                  },
                  boost: 1000,
                },
              },
              {
                constant_score: {
                  filter: {
                    term: {
                      doctype: {
                        value: 'diagnostic_group',
                      },
                    },
                  },
                  boost: 100,
                },
              },
            ],

            must_not: [
              {
                term: {
                  kind: 'group',
                },
              },
            ],

          },
        },
      },
    };
  }

  _trackTypeaheadSelect() {
    this.$analytics.eventTrack('Order Consultant Typeahead Selected', {
      workflow: 'Charting',
      component: 'Orders',
      subcomponent: 'Consultant Field',
      order_id: this.order.id,
      order_type: this.order.type,
      form_type: 'Sign Form',
      typeahead_option_selected: this.queryString,
      input_string: this.userInputString, // set by omTrackableAutocompleteInput
    });
  }
}

ConsultantAutocompleteController.$inject = [
  'ENV',
  '$analytics',
  '$search',
  '$timeout',
];

export const omConsultantAutocomplete = {
  bindings: {
    queryString: '=',
    performConsultantSearch: '&',
    order: '<',
  },
  templateUrl: 'orders/shared/consultant-autocomplete.component.html',
  controller: ConsultantAutocompleteController,
};

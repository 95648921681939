export const omFormCancel = $parse => ({
  require: 'omForm',
  link(scope, element, attrs, omFormCtrl) {
    const modelName = attrs.omFormCancel;
    const getter = $parse(modelName);
    scope.master = angular.copy(getter(scope));

    angular.extend(omFormCtrl.form, {
      cancel() {
        angular.copy(scope.master, getter(scope));
        omFormCtrl.form.$setPristine();
      },
      reset() {
        scope.master = angular.copy(getter(scope));
        omFormCtrl.form.$setPristine();
      },
    });
  },
});

omFormCancel.$inject = ['$parse'];

export const omAutosave = $timeout => ({
  restrict: 'AE',
  require: '^omForm',
  link(scope, element, attrs, omFormCtrl) {
    omFormCtrl.form.saving = false;
    omFormCtrl.errorResponse = null;

    // messageDelay makes sure a "saving message" will show up even if the saving is very fast.
    // It also prevents the "saving..." button-message and pill from blinking in and out too fast.
    const defaultDelay = 1500;
    const parsedDelay = parseInt(attrs.omAutosaveMessageDelay, 0);
    const messageDelay = parsedDelay >= 0 ? parsedDelay : defaultDelay;

    // autosaveDelay is how often we check the form to see if it needs saving.
    const autosaveDelay = parseInt(attrs.omAutosaveDelay, 0) || 300;

    const doSave = () => {
      if (!omFormCtrl.form || omFormCtrl.form.saving) return;
      omFormCtrl.form.saving = true;
      omFormCtrl.errorResponse = null;
      omFormCtrl.form.$setPristine();
      scope.$eval(attrs.omAutosave).then(
        () => {
          $timeout(() => {
            if (omFormCtrl.form) {
              omFormCtrl.form.saving = false;
            }
          }, messageDelay);
        },
        errorResponse => {
          omFormCtrl.form.saving = false;
          omFormCtrl.errorResponse = errorResponse.data;
        },
      );

      if (omFormCtrl.form.reset) omFormCtrl.form.reset();
    };
    const autosave = () =>
      $timeout(() => {
        if (omFormCtrl.form && omFormCtrl.form.$dirty) {
          doSave();
        }
        autosave();
      }, autosaveDelay);
    autosave();
  },
});

omAutosave.$inject = ['$timeout'];

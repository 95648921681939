import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

class VisitFollowUpController {
  constructor(
    $q,
    $stateParams,
    AppointmentTypeService,
    FollowUpOrderService,
    FollowUpProvidersService,
    ProblemService,
    ReasonDetailService,
    FOLLOW_UP_ORDER_TYPES,
  ) {
    this.visitFollowUpForm = null;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.followUpOrderService = FollowUpOrderService;
    this.ReasonDetailService = ReasonDetailService;
    this.analyticsType = FOLLOW_UP_ORDER_TYPES.visit.analyticsType;
    this.reasonDetailPlaceholder = '';
    this.problemService = ProblemService;
    this.followUpProvidersService = FollowUpProvidersService;
    this.appointmentTypeService = AppointmentTypeService;
    this.$q = $q;
    this.formReady = false;
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }
  _handleError({ data }) {
    this.apiError = data.message;
  }
  $onInit() {
    this.followUpComponent.registerChild(this);
    if (!this.order.id) {
      this.order = { type: this.FOLLOW_UP_ORDER_TYPES.visit.name };
      this.order.problems = [];
    } else {
      this.order.problems.map(problem => {
        problem.multiSelectName = problem.clinical_description;
        return problem;
      });
    }
    this.remindedOn = this.buildReminded();
    this.reasonDetailPlaceholder = this.ReasonDetailService.buildDetails(
      this.order.problems,
    );
    this.getFormOptionData();
  }

  getFormOptionData() {
    this.$q
      .all([
        this.problemService.query(this.patientId, {
          custom: false,
          resolved: false,
        }),
        this.followUpProvidersService.getAll(this.patientId),
        this.appointmentTypeService.getAll(this.patientId),
      ])
      .then(([problems, providers, appointments]) => {
        this.patientProblems = problems.map(problem => {
          problem.multiSelectName = problem.problem_type.clinical_description;
          return problem;
        });

        this.providers = providers;
        if (this.order && this.order.visit_with_provider_id == null) {
          this.order.visit_with_provider_id = this.providers[0];
        }

        this.appointmentTypes = appointments;

        this.formReady = true;
      });
  }

  signFollowUpOrder() {
    const order = _.clone(this.order);
    this.apiError = null;
    order.problem_ids = this.order.problems.map(problem => problem.id);
    delete order.problems;
    if (order.reason_details == null || order.reason_details.length === 0) {
      order.reason_details = this.reasonDetailPlaceholder;
    }
    if (this.order.id) {
      const request = this.followUpOrderService.updateFollowUpOrder(
        this.patientId,
        order,
      );
      request.catch(this._handleError.bind(this));
      return request;
    }
    const request = this.followUpOrderService.createFollowUpOrder(
      this.patientId,
      order,
    );
    request.catch(this._handleError.bind(this));
    return request;
  }

  deleteFollowUpOrder() {
    return this.followUpOrderService.deleteFollowUpOrder(
      this.patientId,
      this.order,
    );
  }

  validateForm() {
    this.visitFollowUpForm.$setSubmitted();
    return this.visitFollowUpForm.$valid;
  }

  updateReasonDetails() {
    const oldValue = this.reasonDetailPlaceholder;
    this.reasonDetailPlaceholder = this.ReasonDetailService.buildDetails(
      this.order.problems,
    );
    if (oldValue === this.order.reason_details) {
      this.order.reason_details = this.reasonDetailPlaceholder;
    }
  }

  onReasonAdd() {
    this.onFormFieldChanged({ fieldName: 'Problems Field' });
  }

  onReasonRemove() {
    this.onFormFieldChanged({ fieldName: 'Problems Field' });
  }

  onPlaceholderEdit() {
    this.onFormFieldChanged({ fieldName: 'Note Field' });
  }

  onProviderSelect() {
    this.onFormFieldChanged({ fieldName: 'Provider Picker Field' });
  }

  onAppointmentTypeSelect() {
    this.onFormFieldChanged({ fieldName: 'Appointment Type Field' });
  }

  onManualDateEntry(newDueOnValue) {
    this.remindedOn = this.buildReminded(newDueOnValue);
    this.order.due_on = newDueOnValue;
    this.onFormFieldChanged({ fieldName: 'Date Field' });
  }

  onShortcutDateEntry(quantity, unit, newDueOnValue) {
    this.remindedOn = this.buildReminded(newDueOnValue);
    this.order.due_on = newDueOnValue;
    this.onDatePickerSelected({ quantity, unit });
  }

  buildReminded(newDueOnValue) {
    if (!this.order.due_on && newDueOnValue === undefined) {
      return undefined;
    }

    const dueOn = newDueOnValue || this.order.due_on;
    const remindIn =
      moment(dueOn).diff(moment().endOf('week'), 'weeks') > 9
        ? '1 month'
        : '1 week';
    return remindIn;
  }
}

VisitFollowUpController.$inject = [
  '$q',
  '$stateParams',
  'AppointmentTypeService',
  'FollowUpOrderService',
  'FollowUpProvidersService',
  'ProblemService',
  'ReasonDetailService',
  'FOLLOW_UP_ORDER_TYPES',
];

export const omVisitFollowUp = {
  bindings: {
    order: '<',
    onFormFieldChanged: '&',
    onDatePickerSelected: '&',
  },
  require: {
    followUpComponent: '^omFollowUp',
  },
  controller: VisitFollowUpController,
  templateUrl: 'orders/follow-up/visit-follow-up.component.html',
};

import { VaccineOrder } from './vaccine-order';
import { getParamsFromUrl } from '../upgrade-param-mapper';

const DEFAULT_ERROR = 'Vaccine Order could not be updated';

class VaccineOrderController {
  constructor(VaccineOrderService, $stateParams, $state) {
    this.VaccineOrderService = VaccineOrderService;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.saving = false;
    this.vaccineOrder = new VaccineOrder();
    this.dateToday = moment().format('YYYY-MM-DD');
    this.orderId =
      getParamsFromUrl('vaccine_orders') || this.$stateParams.orderId; // This is used to get sticky states working in hybrid mode
  }

  $onInit() {
    this.setVaccineOrder();
  }

  setVaccineOrder() {
    this.editable = true;
    if (this.orderId) {
      this.VaccineOrderService.get(this.orderId).then(order => {
        this.vaccineOrder = order;
      });
    }
  }

  signVaccineOrder(goToOrders = true) {
    this.VaccineOrderService.sign(this.vaccineOrder)
      .then(order => {
        this.vaccineOrder = order;
        if (goToOrders) this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  updateVaccineOrder(vaccineOrderParams = null) {
    vaccineOrderParams = vaccineOrderParams || this.vaccineOrder;
    return this.VaccineOrderService.update(vaccineOrderParams);
  }

  setVaccine(vaccine) {
    this.vaccineOrder.vaccine = vaccine;
    this.vaccineOrder.step = null;
  }

  cancel() {
    this.$state.go('app.chart.orders.list');
  }

  delete() {
    this.VaccineOrderService.delete(this.orderId)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  setDeleting(deleting) {
    this.deleting = deleting;
  }

  setVaccineOrderStep(step) {
    this.vaccineOrder.step = step.toString();
  }

  editVaccineOrder = () => {
    this.VaccineOrderService.edit(this.vaccineOrder.id)
      .then(order => {
        this.vaccineOrder = order;
      })
      .catch(error => this.setError(error));
  };

  setError(error) {
    if (error && error.data && error.data[0]) {
      this.orderError = error.data[0];
    } else {
      this.orderError = DEFAULT_ERROR;
    }
  }
}

VaccineOrderController.$inject = [
  'VaccineOrderService',
  '$stateParams',
  '$state',
];

export const omVaccineOrder = {
  controller: VaccineOrderController,
  templateUrl: 'orders/vaccine/vaccine-order.component.html',
};

class ConsultOrderListItemController {
  constructor($state, $analytics, ConsultOrderService) {
    this.$state = $state;
    this.$analytics = $analytics;
    this.ConsultOrderService = ConsultOrderService;
  }

  $onInit() {
    this.isConsultOrder = true;
    this.title = this.order.contact.name;
    this.subtitle = this.order.indication;
    this.ordered_by = this.order.ordered_by;
    this.updatingOrder = false;
  }

  // Used by shared orders list template
  isDueDateVisible() {
    return false;
  }

  // stub - to be implemented in a later story
  isCancellable() {
    return false;
  }

  isCompletable() {
    return this.order.status === 'sent' && !this.updatingOrder;
  }

  isDeleteable() {
    return this.order.status === 'unsigned' || this.order.status === 'signed';
  }

  completeOrder() {
    const listItemController = this;
    const resetUpdatingOrder = () => {
      this.updatingOrder = false;
    };

    const completeConsultOrder = consultOrder => {
      consultOrder.status = 'completed';
      const processUpdatedOrder = ({ status }) => {
        listItemController.order.status = status;
      };

      return this.ConsultOrderService.update(consultOrder).then(
        processUpdatedOrder,
      );
    };

    this.updatingOrder = true;

    return this.ConsultOrderService.get(this.order.id)
      .then(completeConsultOrder)
      .finally(resetUpdatingOrder);
  }

  deleteOrder() {
    this.ConsultOrderService.delete(this.order.id);
    this.ordersComponent.remove(this.order);
    this.$analytics.eventTrack('Consult Order Deleted', {
      workflow: 'Charting',
      component: 'Orders',
      orderType: 'Consult',
      subComponent: 'Orders List',
      orderId: this.order.id,
    });
  }

  editView() {
    this.$state.go('app.chart.orders.editConsultOrder', { id: this.order.id });
  }
}

ConsultOrderListItemController.$inject = [
  '$state',
  '$analytics',
  'ConsultOrderService',
];

export const omConsultOrderListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: ConsultOrderListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

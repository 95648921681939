const MAX_TEXT_LENGTH = 300;
class PatientTimelineCommentController {
  constructor(marked) {
    this.marked = marked;
  }

  $onInit() {
    this.textIsCollapsed =
      !this.alwaysExpand && this.text && this.text.length > MAX_TEXT_LENGTH;
    this.parsedText = this.getParsedMarkdown();
  }

  getParsedMarkdown() {
    if (this.text) {
      // this is to fix an issue with unicode parsing of emails in angle brackets (#159667767)
      const unbracketed = this.text
        .replace(/\\</g, '&lt;')
        .replace(/\\>/g, '&gt;');
      return this.marked(unbracketed);
    }
    return null;
  }
}

PatientTimelineCommentController.$inject = ['marked'];

export const omPatientTimelineComment = {
  bindings: {
    postedBy: '<',
    text: '<',
    updatedAt: '<',
    alwaysExpand: '<',
    s3Pointers: '<',
  },
  controller: PatientTimelineCommentController,
  templateUrl: 'messaging/patient-timeline-comment.component.html',
};

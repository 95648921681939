export const omExpanded = () => ({
  restrict: 'AE',
  require: '^^omCollapse',
  scope: {
    expandedName: '=?',
  },
  replace: true,
  transclude: true,
  // Need the ng-hide to prevent animation on initial render
  template:
    '<div class="om-expanded ng-hide om-animate" ng-show="collapse.isExpanded(expandedName)" ng-transclude />',
  link(scope, element, attrs, collapse) {
    scope.collapse = collapse;
  },
});

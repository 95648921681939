export class PharmacyService {
  ENDPOINT = '/v2/admin/pharmacies';
  constructor(ApiService, Pharmacy) {
    this.ApiService = ApiService;
    this.Pharmacy = Pharmacy;
  }
  search(params) {
    return this.ApiService.get(this.ENDPOINT, { params }).then(results => {
      results.pharmacies = results.pharmacies.map(
        result => new this.Pharmacy(result),
      );
      return results;
    });
  }
}
PharmacyService.$inject = ['ApiService', 'Pharmacy'];

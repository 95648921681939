import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

class FollowUpListItemController {
  MAX_PROBLEMS_LENGTH = 29;

  constructor(
    FollowUpOrderService,
    ProblemsFormatterService,
    $state,
    $stateParams,
    $analytics,
    FOLLOW_UP_ORDER_TYPES,
  ) {
    this.FollowUpOrderService = FollowUpOrderService;
    this.ProblemsFormatterService = ProblemsFormatterService;
    this.$state = $state;
    this.$analytics = $analytics;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.isFollowUpOrder = true;
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }

  $onInit() {
    if (this.order.type === this.FOLLOW_UP_ORDER_TYPES.visit.name) {
      this.type = this.FOLLOW_UP_ORDER_TYPES.visit.shortName;
      this.analyticsType = this.FOLLOW_UP_ORDER_TYPES.visit.analyticsType;
      this.title = `Follow Up - ${this.FOLLOW_UP_ORDER_TYPES.visit.label}`;
    } else if (this.order.type === this.FOLLOW_UP_ORDER_TYPES.basic.name) {
      this.type = this.FOLLOW_UP_ORDER_TYPES.basic.shortName;
      this.analyticsType = this.FOLLOW_UP_ORDER_TYPES.basic.analyticsType;
      this.title = `Follow Up - ${this.FOLLOW_UP_ORDER_TYPES.basic.label}`;
    } else if (this.order.type === this.FOLLOW_UP_ORDER_TYPES.generic.name) {
      this.type = this.FOLLOW_UP_ORDER_TYPES.generic.shortName;
      this.analyticsType = this.FOLLOW_UP_ORDER_TYPES.generic.analyticsType;
      this.title = `Follow Up - ${this.FOLLOW_UP_ORDER_TYPES.generic.label}`;
    }

    this.analyticsBaseAttributes = {
      workflow: 'Charting',
      component: 'Orders',
      order_type: 'Follow Up',
      subcomponent: 'Orders List',
      order_subtype: this.analyticsType,
      order_id: this.order.id,
    };

    this.analyticsType = `${this.type}_follow_up_order`;
    this.subtitle = this.ProblemsFormatterService.shortenDescriptions({
      listOfValues: this.listOfDescriptions(),
      numOfChars: this.MAX_PROBLEMS_LENGTH,
    });
    this.ordered_by = this.order.ordered_by;
    this.due_on = this.order.due_on;
  }

  listOfDescriptions() {
    if (this.order.problems) {
      return this.order.problems.map(problem => problem.clinical_description);
    } else if (this.order.problem) {
      return [this.order.problem.clinical_description];
    }
    return [''];
  }

  isDeleteable() {
    return this.order.status === 'signed';
  }

  isDueDateVisible() {
    return this.isDeleteable();
  }

  editView() {
    this.$analytics.eventTrack('Order Viewed', this.analyticsBaseAttributes);
    this.$state.go('app.chart.orders.editFollowUpOrder', {
      id: this.order.id,
      orderType: this.type,
    });
  }

  deleteOrder() {
    this.$analytics.eventTrack('Order Deleted', this.analyticsBaseAttributes);
    this.FollowUpOrderService.deleteFollowUpOrder(this.patientId, this.order);
    this.ordersComponent.remove(this.order);
  }
}

FollowUpListItemController.$inject = [
  'FollowUpOrderService',
  'ProblemsFormatterService',
  '$state',
  '$stateParams',
  '$analytics',
  'FOLLOW_UP_ORDER_TYPES',
];

export const omFollowUpListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: FollowUpListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

import { VaccineOrder } from './vaccine-order';
import { HistoryVaccine } from './history-vaccine';
import { VaccineOrderService } from './vaccine-order.service';
import { HistoryVaccineService } from './history-vaccine.service';
import { omVaccineOrder } from './vaccine-order.component';
import { omVaccineOrderListItem } from './vaccine-order-list-item.component';
import { omVaccineAdminister } from './vaccine-administer.component';
import { omSignedVaccineOrder } from './signed-vaccine-order.component';
import { omAdministeredHistoryVaccine } from './administered-history-vaccine.component';
import { omVaccineNameInput } from './vaccine-name-input.component';
import { vaccineHighlight } from './vaccine-highlight.filter';

angular
  .module('onelifeUi.orders.vaccine', [])
  .component('omVaccineOrder', omVaccineOrder)
  .component('omVaccineAdminister', omVaccineAdminister)
  .component('omVaccineOrderListItem', omVaccineOrderListItem)
  .component('omSignedVaccineOrder', omSignedVaccineOrder)
  .component('omAdministeredHistoryVaccine', omAdministeredHistoryVaccine)
  .component('omVaccineNameInput', omVaccineNameInput)
  .filter('vaccineHighlight', vaccineHighlight)
  .factory('VaccineOrder', () => VaccineOrder)
  .factory('HistoryVaccine', () => HistoryVaccine)
  .service('VaccineOrderService', VaccineOrderService)
  .service('HistoryVaccineService', HistoryVaccineService);

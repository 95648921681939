class InactiveOrdersListController {}

InactiveOrdersListController.$inject = [];

export const omInactiveOrdersList = {
  bindings: {
    activeOrdersAreEmpty: '<',
    inactiveOrders: '<',
  },
  controller: InactiveOrdersListController,
  templateUrl: 'orders/inactive-orders-list.component.html',
};

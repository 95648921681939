import { NewRxCart } from './rx-cart';
import { PatientPharmacy } from './patient-pharmacy';
import { PatientPharmacyService } from './patient-pharmacy.service';
import { PendingNewRx } from './pending-new-rx';
import { PendingNewRxService } from './pending-new-rx.service';
import { PharmacyService } from './pharmacy.service';
import { Pharmacy } from './pharmacy';
import { pharmacyAddress } from './pharmacy-address.filter';
import { PharmacyChoice } from './pharmacy-choice.component';
import {
  omPendingNewRxCheckout,
  omPendingNewRxCheckoutCtrl,
} from './pending-new-rx-checkout.controller';
import { omPharmacyPicker, PharmacyPicker } from './pharmacy-picker.component';
import { omPharmacySelect, PharmacySelect } from './pharmacy-select.component';

angular
  .module('onelifeUi.newRx', [])
  .component('omPharmacyChoice', PharmacyChoice)
  .component('omPharmacyPicker', omPharmacyPicker)
  .component('omPharmacySelect', omPharmacySelect)
  .controller('PharmacyPicker', PharmacyPicker)
  .controller('PharmacySelect', PharmacySelect)
  .controller('omPendingNewRxCheckoutCtrl', omPendingNewRxCheckoutCtrl)
  .directive('omPendingNewRxCheckout', omPendingNewRxCheckout)
  .factory('NewRxCart', NewRxCart)
  .factory('PendingNewRx', () => PendingNewRx)
  .factory('PatientPharmacy', () => PatientPharmacy)
  .factory('Pharmacy', () => Pharmacy)
  .filter('pharmacyAddress', pharmacyAddress)
  .service('PharmacyService', PharmacyService)
  .service('PendingNewRxService', PendingNewRxService)
  .service('PatientPharmacyService', PatientPharmacyService);

class PatientMedicationsList {
  constructor(
    $rootScope,
    $stateParams,
    Medication,
    MedicationService,
    PatientMedicationsService,
  ) {
    this.Medication = Medication;
    this.MedicationService = MedicationService;
    this.PatientMedicationsService = PatientMedicationsService;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;

    this.rootScopeListeners = [];
  }
  $onInit() {
    this.sortAndPartitionMedications();
    this.addDetachableListener(
      this.$rootScope.$on(
        'patientMedicationsRefresh',
        this.fetchMedications.bind(this),
      ),
    );
  }
  $onDestroy() {
    this.rootScopeListeners.forEach(listener => listener());
  }
  addDetachableListener(listener) {
    this.rootScopeListeners.push(listener);
  }
  fetchMedications() {
    this.PatientMedicationsService.getAllForPatient(
      this.$stateParams.patientId,
    ).then(response => {
      this.medications = response;
      this.sortAndPartitionMedications();
    });
  }
  linkSection() {
    this.$rootScope.$emit('sectionLinked', 'Medications', [
      this.medications.active,
    ]);
  }
  sortAndPartitionMedications() {
    const sorted = _.sortBy(this.medications, medication => {
      let duration = 100;
      let isPrn = 0;

      if (medication.latest_patient_medication_regimen) {
        const latestRegimen = medication.latest_patient_medication_regimen;
        if (latestRegimen.discontinues_at) {
          duration = 0;
        }
        if (latestRegimen.is_prn) {
          isPrn = 1000;
        }
      }

      if (duration && isPrn) {
        duration = 0;
      }

      const firstCharacterCode = medication.route.name
        .toLowerCase()
        .charCodeAt();
      const lowerCaseA = 97;
      const alphabeticalDelta = firstCharacterCode - lowerCaseA;

      return duration + isPrn + alphabeticalDelta;
    });

    const [active, inactive] = _.partition(sorted, 'active');

    this.medications.active = [];
    active.forEach(activeMed => this.medications.active.push(activeMed));

    this.medications.inactive = [];
    inactive.forEach(inactiveMed =>
      this.medications.inactive.push(inactiveMed),
    );
  }
  activateAndUpdateMedicationRegimen(
    existingMedication,
    { latest_regimen: regimen, active },
  ) {
    existingMedication.active = active;
    existingMedication.latest_regimen = regimen;
    return existingMedication;
  }
  findDuplicateRoutes(route) {
    return this.medications.active.filter(
      ({ route: medicationRoute }) => medicationRoute.id === route.id,
    );
  }
  medicationExistsInHistory(newMedication) {
    return this.medications.find(medication => {
      const medicationMatched = medication.id === newMedication.id;
      if (medicationMatched) {
        medication = this.activateAndUpdateMedicationRegimen(
          medication,
          newMedication,
        );
      }
      return medicationMatched;
    });
  }
  disableByRoute(routeId) {
    this.medications
      .filter(medication => medication.medication_route_id === routeId)
      .forEach(match => {
        if (match.is_prescribable) {
          match.is_prescribable.allowed = false;
        } else {
          match.is_prescribable = { allowed: false };
        }
      });
    return this.medications;
  }
  saveAndAddToHistory(data) {
    return this.MedicationService.createWithAssociations(data).then(
      response => {
        const savedMedication = new this.Medication(response);
        if (!this.medicationExistsInHistory(savedMedication)) {
          this.medications.push(savedMedication);
        }

        return this.sortAndPartitionMedications();
      },
    );
  }
  remove(medication) {
    _.remove(this.medications, { id: medication.id });
    this.sortAndPartitionMedications();
  }
  updateMedication(updates) {
    const medicationIndex = this.medications.findIndex(
      item => item.id === updates.id,
    );

    if (medicationIndex !== -1) {
      this.medications[medicationIndex] = new this.Medication({
        ...this.medications[medicationIndex],
        ...updates,
      });
      this.sortAndPartitionMedications();
    }
  }
}

PatientMedicationsList.$inject = [
  '$rootScope',
  '$stateParams',
  'Medication',
  'MedicationService',
  'PatientMedicationsService',
];

export const omPatientMedicationsList = {
  controller: PatientMedicationsList,
  bindings: {
    patient: '<',
    newRxCart: '<',
    medications: '<',
    sectionsLinkable: '<',
  },
  templateUrl: 'medications/patient-medications-list.component.html',
};

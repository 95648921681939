class Order {
  constructor(order) {
    this.order = order;
    this.isVaccineOrder = this.order.type === 'VaccineOrder';
    this.isLabOrder = this.order.type === 'LabOrder';
    this.isFollowUpOrder =
      this.order.type === 'VisitFollowUpOrder' ||
      this.order.type === 'BasicFollowUpOrder' ||
      this.order.type === 'GenericFollowUpOrder';
    this.isConsultOrder = this.order.type === 'ConsultOrder';
    this.isSurveyOrder = this.order.type === 'SurveyOrder';
    this.isProcedureOrder = this.order.type === 'ProcedureOrder';
  }
}

export class OrdersService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getAll(patientId) {
    return this.ApiService.get(
      `/v2/admin/patients/${patientId}/patient_orders`,
    ).then(orders => orders.map(order => new Order(order)));
  }
}

OrdersService.$inject = ['ApiService'];

class OrderStatusLabelController {
  constructor() {
    // the values in this object are the modifier CSS class for om-label
    this.orderStatusClassMapping = {
      approved: '',
      cancelled: '-inactive',
      collected: '-success',
      completed: '-success',
      declined: '-inactive',
      reviewable: '',
      redacted: '-inactive',
      reviewed: '-success',
      sent: '-success',
      ready: '',
      signed: '',
      unsigned: '',
    };
  }

  $onInit() {
    this.useDisplayStatus();
    this.statusClass = this.getStatusClass();
  }

  $onChanges() {
    this.useDisplayStatus();
    this.statusClass = this.getStatusClass();
  }

  useDisplayStatus() {
    this.status = this.displayStatus ? this.displayStatus : this.status;
  }

  getStatusClass() {
    if (this.status in this.orderStatusClassMapping) {
      return this.orderStatusClassMapping[this.status];
    }
    return '-inactive';
  }
}

export const omOrderStatusLabel = {
  bindings: {
    status: '<',
    displayStatus: '<',
  },
  controller: OrderStatusLabelController,
  templateUrl: 'orders/shared/order-status-label.component.html',
};

import { Client } from 'elasticsearch-browser';

export class Search {
  constructor(ElasticsearchLocationService, authToken) {
    this.locationPromise = ElasticsearchLocationService.get().then(
      ({ url }) => {
        if (!url) {
          throw new Error('Elasticsearch location is undefined');
        }
        const esUrl = new URL(url);
        const basicCredentialsSupplied =
          esUrl.username.length !== 0 && esUrl.password.length !== 0;

        if (basicCredentialsSupplied) {
          this.client = new Client({ host: esUrl.href });
        } else {
          const token = authToken.get();
          const authHeaders = { Authorization: `Bearer ${token}` };
          this.client = new Client({
            host: {
              protocol: esUrl.protocol,
              host: esUrl.hostname,
              port: esUrl.port,
              path: esUrl.pathname,
              headers: authHeaders,
            },
          });
        }
      },
    );
  }

  scroll(params) {
    return this.locationPromise.then(() => this.client.scroll(params));
  }

  search(query) {
    return this.locationPromise.then(() => this.client.search(query));
  }
}

Search.$inject = ['ElasticsearchLocationService', 'authToken'];

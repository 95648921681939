const APPOINTMENT_TYPES_ENDPOINT = '/v2/admin/orders/appointment_types';

export class AppointmentTypeService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getAll(patientId) {
    return this.ApiService.get(`${APPOINTMENT_TYPES_ENDPOINT}`, {
      params: { patient_id: patientId },
    });
  }
}

AppointmentTypeService.$inject = ['ApiService'];

export const omAutosaveSavingMessage = () => ({
  restrict: 'AE',
  require: '^omForm',
  replace: true,
  transclude: true,
  link(scope, element, attrs, omFormCtrl, $transclude) {
    // re-assigning omFormCtrl prevents some scope pollution when there are multiple forms on the same page.
    scope.omFormCtrl = omFormCtrl;
    $transclude(clone => {
      if (!clone.length) {
        scope.defaultMessage = true;
      }
    });
  },
  template: `<div class="om-autosave-message om-autosave-saving-message">
                <div class="om-messages -progress" ng-if="omFormCtrl.form.saving">Saving . . .</div>
                <span ng-if="!omFormCtrl.form.saving" ng-transclude/>
            </div>`,
});

class SignedSummaryFormController {
  constructor($rootScope, focus, SummaryAddendumService, Todo, toastr) {
    this.$rootScope = $rootScope;
    this.focus = focus;
    this.SummaryAddendumService = SummaryAddendumService;
    this.Todo = Todo;
    this.toastr = toastr;
    this.messages = {
      savingFailed:
        'Note addendum could not be saved. Please email helpdesk@onemedical.com if this issue persists',
      cosignTaskCompleted: 'Co-sign task completed',
    };
  }

  $onInit() {
    this.showAddendumForm = false;
    this.submitInProgress = false;
    this.savingError = false;
    this.hasIncompleteCosignTodo = this.hasIncompleteCosignTask();
    this.setAddendumText();
    this.updateSecondaryActions();
  }

  hasIncompleteCosignTask() {
    return this.summary.signed && this.todo.isIncompleteCosign();
  }

  toggleAddendumForm() {
    this.showAddendumForm = !this.showAddendumForm;
  }

  showCompletedCosign(finishedCosignTodos) {
    this.toastr.success(this.messages.cosignTaskCompleted);
    this.hasIncompleteCosignTodo = false;
    this.setAddendumText();
    this.$rootScope.$emit('refreshTimeline');
    this.todo = new this.Todo(finishedCosignTodos[0]);
  }

  addAddendumToSummary(newAddendum) {
    const finishedCosignTodos = newAddendum.summary.todos
      .map(({ todo }) => todo)
      .filter(
        todo =>
          todo.name === 'Co-Sign' &&
          todo.state === 'finished' &&
          todo.id === this.todo.id,
      );

    if (this.hasIncompleteCosignTodo && finishedCosignTodos.length) {
      this.showCompletedCosign(finishedCosignTodos);
    }

    delete newAddendum.summary;
    this.summary.addAddendum(newAddendum);
    this.closeAddendumForm();
  }

  createAddendum() {
    this.submitInProgress = true;
    this.savingError = false;
    const addendum = {
      summary_id: this.summary.id,
      content: this.addendumContent,
    };
    const createPromise = this.SummaryAddendumService.save(addendum).then(
      newAddendum => {
        this.addAddendumToSummary(newAddendum);
      },
      () => {
        this.savingError = true;
      },
    );

    createPromise.finally(() => {
      this.submitInProgress = false;
    });
    return createPromise;
  }

  closeAddendumForm() {
    this.addendumContent = '';
    this.toggleAddendumForm();
  }

  setAddendumText() {
    if (this.hasIncompleteCosignTodo) {
      this.addendumText = 'Co-Signature';
      this.addendumSignButtonText = 'Co-Sign Note';
    } else {
      this.addendumText = 'Addendum';
      this.addendumSignButtonText = 'Sign Addendum';
    }
  }

  updateSecondaryActions() {
    this.secondaryActions = [
      {
        label: `Add ${this.addendumText}`,
        action: () => {
          this.toggleAddendumForm();
          this.focus('addendumFocus-add');
        },
      },
      {
        template: `<om-fax-button-menu-item note-id="${
          this.summary.note.id
        }"></om-fax-button-menu-item>`,
      },
      {
        template: `<om-print-button-menu-item note-id="${
          this.summary.note.id
        }"></om-print-button-menu-item>`,
      },
    ];
  }
}

SignedSummaryFormController.$inject = [
  '$rootScope',
  'focus',
  'SummaryAddendumService',
  'Todo',
  'toastr',
];

export const omSignedSummaryForm = {
  bindings: {
    summary: '<',
    profile: '<',
    todo: '<',
    getComments: '<',
    saveComment: '<',
  },
  controller: SignedSummaryFormController,
  templateUrl: 'summaries/signed-summary-form.component.html',
};

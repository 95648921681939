class NoteHistoryController {
  $onInit() {
    if (this.note && !this.todo && this.note.todo) {
      this.todo = this.note.todo;
    }
  }

  signedBy() {
    if (this.note && this.note.signed_by && this.note.signed_by.name) {
      return this.note.signed_by.name;
    }

    return this.note.signed_by;
  }

  createdBy() {
    if (this.note.created_by) {
      if (this.note.created_by.name) {
        return this.note.created_by.name;
      }
      return this.note.created_by;
    }

    return this.note.author;
  }

  finishedAt() {
    if (this.todo && this.todo.finished_at) {
      return this.todo.finished_at;
    }
    return null;
  }

  finishedBy() {
    if (this.todo && this.todo.finished_by && this.todo.finished_by.name) {
      return this.todo.finished_by.name;
    }
    return null;
  }
}

export const omNoteHistory = {
  bindings: {
    note: '<',
    todo: '<',
  },
  controller: NoteHistoryController,
  templateUrl: 'shared/note-history.component.html',
};

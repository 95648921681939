const LETTER_TEMPLATES_PATH = '/v2/admin/letter_templates';

export class LetterService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  getLetterTemplates() {
    return this.ApiService.get(LETTER_TEMPLATES_PATH);
  }

  getLetterTemplate(id) {
    return this.ApiService.get(`${LETTER_TEMPLATES_PATH}/${id}`, {
      params: { patient_id: this.$stateParams.patientId },
    });
  }
}

LetterService.$inject = [
  'ApiService', '$stateParams',
];

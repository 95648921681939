import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';
import { getParamsFromUrl } from '../upgrade-param-mapper';
import { ProcedureOrder } from './procedure-order';

class ProcedureOrderController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $analytics,
    $q,
    ENV,
    toastr,
    FeaturesService,
    ProcedureOrderService,
    PatientService,
    Todo,
    TodoService,
    CommentService,
    marked,
  ) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$analytics = $analytics;
    this.$q = $q;
    this.toastr = toastr;
    this.FeaturesService = FeaturesService;
    this.ProcedureOrderService = ProcedureOrderService;
    this.PatientService = PatientService;
    this.saving = false;
    this.procedureOrderSets = [];
    this.visibleProcedureOrderSets = [];
    this.showConsultantSelection = false;
    this.editable = false;
    this.signable = false;
    this.ready = false;
    this.defaultDueDate = moment()
      .startOf('day')
      .toDate();
    this.canEditDueDate = false;
    this.procedureOrder = new ProcedureOrder();
    this.procedureTypesIndex = ENV.search.indexes.procedure_types;
    this.procedureLateralities = ['Left', 'Right', 'Bilateral'];
    this.procedurePatientPregnancies = ['pregnant', 'not pregnant'];
    this.Todo = Todo;
    this.TodoService = TodoService;
    this.CommentService = CommentService;
    this.marked = marked;
  }

  $onInit() {
    this.setFeatureFlags();
    this._externalEventRegistration();
    this._onProcedureOrderIdChange();
  }

  _onProcedureOrderIdChange() {
    this.$scope.$watchCollection(
      () => this.$stateParams.id,
      () => {
        this.orderId =
          getParamsFromUrl('procedure_orders') || this.$stateParams.id; // This is used to get sticky states working in hybrid mode
        const getTodo = this.TodoService.getProcedureOrderTodo(this.orderId);
        const getProcedureOrder = this.ProcedureOrderService.get(this.orderId);
        const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
        const getPatient = this.PatientService.get(patientId);

        this.$q
          .all([getProcedureOrder, getPatient, getTodo])
          .then(([procedureOrder, patient, todo]) => {
            this.procedureOrder = procedureOrder;
            this.patient = patient;
            this.todo = todo;
            this.ready = true;
            this.formatDueDate(procedureOrder);
            this.setShowTransmissionDetails();
            this.updateVisibleProcedureOrderSets();
            this.setShowRejectionReason();
            this.emitSupportingDocuments();
            this.emitSupportingDocumentsAttachable();
            this.updateFormState();
            this.$analytics.eventTrack(
              'Order Viewed',
              this.getAnalyticsProperties('Procedure Order Form'),
            );
          });
      },
    );
  }

  formatDueDate(order) {
    // Correctly Formats the due_date for consumption by the Date Picker
    this.procedureOrder.due_date = moment(order.due_date)
      .startOf('day')
      .toDate();
  }

  displayDueDateWarning() {
    return this.procedureOrder.due_date < this.defaultDueDate;
  }

  setFeatureFlags() {
    this.FeaturesService.hasFeature('new_1life_internal_procedure_orders').then(
      isEnabled => (this.isInternalProcedureOrderEnabled = isEnabled),
    );
  }

  getAnalyticsProperties(subComponent) {
    let formType = '';

    if (this.procedureOrder.status === 'unsigned') {
      formType = 'Sign Form';
    } else if (this.procedureOrder.status === 'signed') {
      formType = 'Approve Form';
    } else if (this.procedureOrder.status === 'sent') {
      formType = 'Complete Form';
    } else if (this.procedureOrder.status === 'completed') {
      formType = 'Completed Form';
    }

    return {
      workflow: 'Charting',
      component: 'Orders',
      order_type: 'Procedure Order',
      order_subtype: this.procedureOrder.is_internal
        ? 'Internal Procedure'
        : 'External Procedure',
      form_type: formType,
      is_high_priority: this.todo.high_priority,
      is_urgent: this.procedureOrder.urgency ? 'urgent' : 'routine',
      subComponent,
      order_id: this.procedureOrder.id,
    };
  }

  $onDestroy() {
    this.addSupportingDocumentEvent();
    this.removeSupportingDocumentEvent();
    this.emitSupportingDocumentsAttachable(false);
  }

  signProcedureOrder($event, { onBehalfOfId = null } = {}) {
    const updatedParams = {
      ...this.procedureOrder,
      status: 'signed',
      on_behalf_of_id: onBehalfOfId,
    };

    return this.updateProcedureOrder(updatedParams).then(
      () => {
        this._trackSignProcedureOrder(onBehalfOfId);
        this.toastr.success('Your order has been signed');
        return this.returnToOrderList();
      },
      errorResponse => {
        this.errorResponse = errorResponse.data;
        this.procedureOrder.status = 'unsigned';
        this.updateFormState();
        throw errorResponse;
      },
    );
  }

  getComments(id) {
    return this.CommentService.getProcedureOrderComments(id);
  }

  saveComment(id, body) {
    return this.CommentService.saveProcedureOrderComment(id, {
      body,
    });
  }

  completeProcedureOrder($event, {
    onBehalfOfId = null,
    requestReviewOnCompletion = false,
  } = {}) {
    this.procedureOrder.status = 'completed';
    this.procedureOrder.on_behalf_of_id = onBehalfOfId;
    this.procedureOrder.request_review_on_completion = requestReviewOnCompletion;

    return this.updateProcedureOrder().then(
      () => {
        this.$analytics.eventTrack(
          'Order Completed',
          this.getAnalyticsProperties('Complete Button'),
        );
        this.toastr.success('Your order has been completed');
        return this.returnToOrderList();
      },
      errorResponse => {
        this.errorResponse = errorResponse.data;
        this.procedureOrder.status = 'sent';
        this.updateFormState();
        throw errorResponse;
      },
    );
  }

  reviewProcedureOrder() {
    this.procedureOrder.status = 'reviewed';

    return this.updateProcedureOrder().then(
      () => {
        this.$analytics.eventTrack(
          'Procedure Order Reviewed',
          this.getAnalyticsProperties('Review Button'),
        );
        this.toastr.success(
          `You have successfully reviewed the procedure order`,
        );
        return this.returnToOrderList();
      },
      errorResponse => {
        this.errorResponse = errorResponse.data;
        this.procedureOrder.status = 'completed';
        this.updateFormState();
        throw errorResponse;
      },
    );
  }

  sendProcedureOrder(
    $event,
    {
      automateApproval = false,
      sendManually = false,
      onBehalfOfId = null,
      doNotRedirect = false,
      performNow = false,
    } = {},
  ) {
    const previousStatus = this.procedureOrder.status;
    const insAuthRequired =
      !automateApproval && !!this.procedureOrder.ins_auth_code;

    const updatedParams = {
      ...this.procedureOrder,
      status: 'sent',
      is_ins_auth_required: insAuthRequired,
      approval_was_automated: automateApproval,
      perform_now: performNow,
      send_manually: sendManually,
      on_behalf_of_id: onBehalfOfId,
    };

    this.form.saving = true; // TODO: refactor this as a fast follow
    return this.updateProcedureOrder(updatedParams).then(
      () => {
        this._trackSendProcedureOrder(updatedParams.approval_was_automated);
        this.form.saving = false; // TODO: refactor this as a fast follow
        this.toastr.success('Your order has been sent');
        return doNotRedirect ? null : this.returnToOrderList();
      },
      this._onUpdateFailure({
        status: previousStatus,
      }),
    );
  }

  updateVisibleProcedureOrderSets = () => {
    if (
      !this.procedureOrder ||
      !this.procedureOrder.procedure_order_indications
    ) {
      this.visibleProcedureOrderSets = [];
      return;
    }

    const indicatedProblemTypeIds = this.procedureOrder.procedure_order_indications.map(
      indication => indication.problem_type.id,
    );

    this.visibleProcedureOrderSets = this.procedureOrderSets.filter(
      orderSet => {
        const orderSetProblemTypeIds = orderSet.problem_types.map(
          problemType => problemType.id,
        );
        return indicatedProblemTypeIds.some(problemTypeId =>
          orderSetProblemTypeIds.includes(problemTypeId),
        );
      },
    );
  };

  updateProcedureOrder(updatedParams = {}) {
    this.errorResponse = null;
    this.saving = true;
    this.updateVisibleProcedureOrderSets();
    this.updateFormState();

    this.emitSupportingDocumentsAttachable();

    if (this.procedureOrder.procedure_type) {
      this.procedureOrder.procedure_type_id = this.procedureOrder.procedure_type.id;
      this.clearUnrequiredConditionalFields(this.procedureOrder.procedure_type);
    }

    // TODO: refactor the rest of the component state changes to
    // wait for the API response before setting component state.
    // Currently this is a fix for the form advancing after being
    // signed, and allowing users to erroneously complete it.
    const jsonPayload = {
      ...this.procedureOrder,
      ...updatedParams,
    };

    return this.ProcedureOrderService.update(jsonPayload).then(response => {
      this._mergeProcedureOrder(this.procedureOrder, response);
      this.saving = false;
      this.updateFormState();
      return this.procedureOrder;
    });
  }

  updateFormState() {
    this.toggleEditable();
    this.toggleSignable();
    this.$rootScope.$broadcast('update-order-action-bar-secondary-actions');
    this.toggleShowConsultantSelection();
  }

  clearUnrequiredConditionalFields(procedureOrderType) {
    // The procedureOrderType here is the type of the procedure being requested.
    // If the new type does not require the field, we clear it as to not not save
    // extraneous information.

    if (!procedureOrderType.is_pregnancy_doc_required) {
      this.procedureOrder.patient_pregnancy = null;
    }

    if (!procedureOrderType.is_laterality_required) {
      this.procedureOrder.laterality = null;
    }

    if (!procedureOrderType.is_kidney_function_doc_required) {
      this.procedureOrder.creatinine_measured_at = null;
      this.procedureOrder.latest_creatinine = null;
    }
  }

  toggleEditableDueDate() {
    this.canEditDueDate = !this.canEditDueDate;
  }

  toggleEditable() {
    this.editable = this.procedureOrder.status === 'unsigned';
  }

  toggleSignable() {
    this.signable =
      !!this.procedureOrder.contact &&
      !!this.procedureOrder.indication &&
      !this.saving;
  }

  isInternalSent() {
    return (
      this.procedureOrder.is_internal && this.procedureOrder.status === 'sent'
    );
  }

  toggleShowConsultantSelection() {
    this.showConsultantSelection =
      this.procedureOrder.procedure_type instanceof Object &&
      !this.isInternalSent();
  }

  _mergeProcedureOrder(procedureOrder, response) {
    procedureOrder.procedure_order_events = response.procedure_order_events;
    procedureOrder.laterality = response.laterality;
    procedureOrder.transmissions = response.transmissions;
    procedureOrder.can_be_auto_approved = response.can_be_auto_approved;
    procedureOrder.was_auto_approved = response.was_auto_approved;
    procedureOrder.status = response.status;
    procedureOrder.is_internal = response.is_internal;
    procedureOrder.is_ins_auth_required = response.is_ins_auth_required;
    procedureOrder.supporting_documents = response.supporting_documents;
    procedureOrder.contact = response.contact;
    procedureOrder.reviewable = response.reviewable;
    this._mergeResults(response);
    this.formatDueDate(response);
    this._mergeProcedureOrderIndication(procedureOrder, response);
    this._mergeProcedureType(procedureOrder, response);
  }

  _mergeProcedureOrderIndication(procedureOrder, response) {
    if (!response.indication) {
      return;
    }

    procedureOrder.indication.id = response.indication.id;

    if (response.indication.problem_code) {
      procedureOrder.indication.code = response.indication.problem_code.code;
    } else {
      delete procedureOrder.indication.code;
    }

    if (response.indication.clinical_description) {
      procedureOrder.indication.clinical_description =
        response.indication.clinical_description;
      procedureOrder.indication.code = response.indication.code;
    }
  }

  _mergeProcedureType(procedureOrder, response) {
    if (!response.procedure_type) {
      return;
    }
    procedureOrder.procedure_type.clinical_guidance =
      response.procedure_type.clinical_guidance;
    procedureOrder.procedure_type.administration_guidance =
      response.procedure_type.administration_guidance;
  }

  _mergeResults(response) {
    if (response.results_template_changed) {
      this.procedureOrder.results = response.results;
    }
  }

  selectConsultant(event, contact) {
    this._trackConsultantSelection(contact);
    this.procedureOrder.contact = contact;
    this.procedureOrder.contact_id = contact.id;
    this.updateProcedureOrder();
  }

  changeConsultant() {
    this.procedureOrder.contact = null;
    this.procedureOrder.contact_id = null;
  }

  returnToOrderList() {
    this.$state.go('app.chart.orders.list');
  }

  rejectProcedureOrder() {
    if (!this.procedureOrder.denial_comments) {
      this.errorResponse = {
        errors: {
          denial_comments: ['Rejection reason must be set'],
        },
      };
      return;
    }

    this.procedureOrder.status = 'unsigned';
    const toastrText =
      'This order has been rejected and reassigned to the last signer.';
    this.updateProcedureOrder().then(() => {
      this._trackRejectProcedureOrder();
      this.toastr.success(toastrText);
      this.returnToOrderList();
    });
  }

  redactProcedureOrder() {
    const previousStatus = this.procedureOrder.status;
    this.procedureOrder.status = 'redacted';

    return this.updateProcedureOrder().then(() => {
      this.$analytics.eventTrack(
        'Order Redacted',
        this.getAnalyticsProperties('Redact Button'),
      );
      this.toastr.success('Your order has been redacted');
      return this.returnToOrderList();
    }, this._onUpdateFailure({ status: previousStatus }));
  }

  cancelInternalOrder() {
    const previousStatus = this.procedureOrder.status;
    this.procedureOrder.status = 'unsigned';

    return this.updateProcedureOrder().then(() => {
      this.$analytics.eventTrack(
        'Order Cancelled',
        this.getAnalyticsProperties('Cancel Button'),
      );
    }, this._onUpdateFailure({ status: previousStatus }));
  }

  cloneProcedureOrder() {
    this.ProcedureOrderService.clone(this.procedureOrder.id).then(result => {
      const cloneLink = this.$state.href(
        'app.chart.orders.editProcedureOrder',
        {
          id: result.id,
        },
      );
      this.toastr.success(
        `Your <strong>procedure order</strong> has been cloned.  <a href=${cloneLink}>View clone</a>`,
      );
      this.returnToOrderList();
    });
  }

  addSupportingDocument(event, document) {
    if (
      !this.procedureOrder.supporting_documents.find(
        doc => doc.id === document.id,
      )
    ) {
      const previousSupportingDocuments = this.procedureOrder.supporting_documents.slice();
      this.procedureOrder.supporting_documents.push({
        id: document.id,
        author: document.author,
        created_at: document.created_at,
        subject: document.subject,
      });
      this.updateProcedureOrder().then(
        this.emitSupportingDocuments.bind(this),
        this._onUpdateFailure({
          supporting_documents: previousSupportingDocuments,
        }),
      );
    }
  }

  removeSupportingDocument(event, id) {
    this.procedureOrder.supporting_documents.splice(
      this.procedureOrder.supporting_documents.findIndex(doc => doc.id === id),
      1,
    );
    this.updateProcedureOrder().then(this.emitSupportingDocuments.bind(this));
  }

  emitSupportingDocuments() {
    this.$rootScope.$emit(
      'list-procedure-order-supporting-documents',
      this.procedureOrder.supporting_documents,
    );
  }

  emitSupportingDocumentsAttachable(override) {
    let isSupportingDocumentsAttachable;
    if (override !== undefined) {
      isSupportingDocumentsAttachable = override;
    } else {
      isSupportingDocumentsAttachable = this.editable;
    }
    this.$rootScope.$broadcast(
      'set-supporting-documents-attachable',
      isSupportingDocumentsAttachable,
    );
  }

  setShowRejectionReason() {
    this.showRejectionReason =
      !!this.todo &&
      !!this.todo.comments &&
      this.procedureOrder.status === 'unsigned';
  }

  showResults() {
    return (
      this.procedureOrder.status === 'sent' || this.isCompletedOrReviewed()
    );
  }

  isCompletedOrReviewed() {
    return (
      this.procedureOrder.status === 'completed' ||
      this.procedureOrder.status === 'reviewed'
    );
  }

  delete() {
    this.ProcedureOrderService.delete(this.orderId).then(() => {
      this.$analytics.eventTrack(
        'Order Deleted',
        this.getAnalyticsProperties('Delete Button'),
      );
      this.returnToOrderList();
    });
  }

  editProcedureOrder() {
    this.$analytics.eventTrack(
      'Order Edited',
      this.getAnalyticsProperties('Edit Button'),
    );
    this.procedureOrder.status = 'unsigned';
    this.updateProcedureOrder().then(() => {
      this.updateFormState();
    });
  }

  setDeleting(deleting) {
    this.deleting = deleting;
  }

  setShowTransmissionDetails() {
    this.showTransmissionDetails =
      !this.procedureOrder.is_internal &&
      (this.procedureOrder.status === 'sent' || this.isCompletedOrReviewed());
  }

  cancel() {
    this.returnToOrderList();
  }

  _onUpdateFailure(rollbackAttributes) {
    return errorResponse => {
      this.errorResponse = errorResponse.data;
      Object.assign(this.procedureOrder, rollbackAttributes);
      this.saving = false;
      this.form.saving = false; // TODO: refactor this as a fast follow
      this.updateFormState();
      throw errorResponse;
    };
  }

  _trackFieldChanged(field) {
    this.$analytics.eventTrack(
      'Order Field Changed',
      this.getAnalyticsProperties(field),
    );
  }

  _trackBoxChecked(field) {
    const properties = this.getAnalyticsProperties(field);
    if (field === 'Urgent Box') {
      properties.check_status = this.procedureOrder.urgency;
    } else {
      properties.check_status = this.procedureOrder.todo.high_priority;
    }
    this.$analytics.eventTrack('Order Box Checked', properties);
  }

  _trackConsultantSelection(contact) {
    this.$analytics.eventTrack('Order Consultant Selected', {
      workflow: 'Charting',
      component: 'Orders',
      subcomponent: 'Select Button',
      order_id: this.procedureOrder.id,
      order_type: this.procedureOrder.type,
      form_type: 'Sign Form',
      contact_id: contact.id,
      contact_display_name: contact.display_name,
      contact_company_name: contact.company_name,
    });
  }

  _trackSignProcedureOrder(onBehalfOfId) {
    const properties = this.getAnalyticsProperties('SignButton');
    if (onBehalfOfId) {
      properties.submission_type = 'Sign On Behalf of and Send';
    } else {
      properties.submission_type = 'Sign and Send';
    }
    properties.form_type = 'Sign Form';
    this.$analytics.eventTrack('Order Signed', properties);
  }

  _trackRejectProcedureOrder() {
    const properties = this.getAnalyticsProperties('Reject Button');
    properties.form_type = 'Approve Form';
    this.$analytics.eventTrack('Order Rejected', properties);
  }

  _trackSendProcedureOrder(approvalWasAutomated) {
    if (approvalWasAutomated) {
      this.$analytics.eventTrack(
        'Order Signed & Sent',
        this.getAnalyticsProperties('Sign & Send Button'),
      );
    } else {
      this.$analytics.eventTrack(
        'Order Approved & Sent',
        this.getAnalyticsProperties('Approve & Send Button'),
      );
    }
  }

  _externalEventRegistration() {
    this.$scope.$on('omConsultant-change', () => this.changeConsultant());
    this.$scope.$on('omConsultant-select', this.selectConsultant.bind(this));
    this.$scope.$on('delete-order', this.delete.bind(this));
    this.$scope.$on('redact-order', this.redactProcedureOrder.bind(this));
    this.$scope.$on('edit-order', this.editProcedureOrder.bind(this));
    this.$scope.$on('reject-order', this.rejectProcedureOrder.bind(this));
    this.$scope.$on('sign-order', this.signProcedureOrder.bind(this));
    this.$scope.$on('send-order', this.sendProcedureOrder.bind(this));
    this.$scope.$on('complete-order', this.completeProcedureOrder.bind(this));
    this.$scope.$on(
      'cancel-internal-order',
      this.cancelInternalOrder.bind(this),
    );
    this.$scope.$on(
      'clone-internal-order',
      this.cloneProcedureOrder.bind(this),
    );
    this.$scope.$on(
      'review-internal-order',
      this.reviewProcedureOrder.bind(this),
    );
    this.addSupportingDocumentEvent = this.$rootScope.$on(
      'add-supporting-document',
      this.addSupportingDocument.bind(this),
    );
    this.removeSupportingDocumentEvent = this.$rootScope.$on(
      'remove-supporting-document',
      this.removeSupportingDocument.bind(this),
    );
  }
}

ProcedureOrderController.$inject = [
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$analytics',
  '$q',
  'ENV',
  'toastr',
  'FeaturesService',
  'ProcedureOrderService',
  'PatientService',
  'Todo',
  'TodoService',
  'CommentService',
  'marked',
];

export const omProcedureOrder = {
  controller: ProcedureOrderController,
  templateUrl: 'orders/procedure/procedure-order.component.html',
};

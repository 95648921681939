export class MedicationFrequencyIntervalService {
  ENDPOINT = '/v2/admin/medication_frequency_intervals.json';
  constructor(ApiService, MedicationFrequencyInterval, LoggerService) {
    this.ApiService = ApiService;
    this.MedicationFrequencyInterval = MedicationFrequencyInterval;
    this.LoggerService = LoggerService;
  }
  query() {
    const request = this.ApiService.get(this.ENDPOINT);
    request.then(
      response => {
        response
          .map(item => new this.MedicationFrequencyInterval(item))
          .sort(this.sortByWordLengthAndAlphabetically);
      },
      error => {
        this.LoggerService.log('MedicationFrequencyIntervalService', error);
      },
    );

    return request;
  }
  sortByWordLengthAndAlphabetically(
    { text_short: textA },
    { text_short: textB },
  ) {
    const { length: wordLengthA } = textA;
    const { length: wordLengthB } = textB;

    if (wordLengthA < wordLengthB) {
      return -1;
    }
    if (wordLengthA > wordLengthB) {
      return 1;
    }

    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  }
}
MedicationFrequencyIntervalService.$inject = [
  'ApiService',
  'MedicationFrequencyInterval',
  'LoggerService',
];

export const omNoteHeader = {
  bindings: {
    editable: '<',
    note: '<',
    todo: '<',
    onClose: '&',
    updateSubject: '&',
  },
  templateUrl: 'notes/note-header.component.html',
};

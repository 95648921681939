export const omAutosaveCompleteButton = () => ({
  restrict: 'A',
  require: '^omForm',
  template: `<span ng-if="!omFormCtrl.form.saving && !omFormCtrl.form.uploading">{{buttonText}}</span>
             <span ng-if="omFormCtrl.form.saving">Saving...</span>
             <span ng-if="omFormCtrl.form.uploading">Uploading...</span>`,
  link: (scope, element, attrs) => {
    scope.buttonText = attrs.omAutosaveCompleteButton;
  },
});

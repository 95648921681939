class FileLinkController {
  constructor($attrs, $window, S3, Rollbar) {
    this.$window = $window;
    this.S3 = S3;
    this.Rollbar = Rollbar;
    this.deletable = false;
    if ($attrs.onDelete) {
      this.deletable = true;
    }
  }

  openNewWindow(url) {
    this.$window.open(url, '_blank');
  }

  deleteFile($event) {
    $event.stopPropagation();
    this.onDelete();
  }

  directToFile($event) {
    $event.preventDefault();
    if (this.url && !this.key && !this.bucket) {
      this.openNewWindow(this.url);
    } else if (this.key && this.bucket) {
      this.S3.getURL(this.key, this.bucket)
        .then(resolved => {
          const resolvedUrl = this.encode
            ? encodeURIComponent(resolved)
            : resolved;
          this.openNewWindow(resolvedUrl);
        })
        .catch(error =>
          this.Rollbar.error(
            `Error opening file from S3 at bucket=${this.bucket} key=${
              this.key
            }`,
            error,
          ),
        );
    }
  }
}

FileLinkController.$inject = ['$attrs', '$window', 'S3', 'Rollbar'];

export const omFileLink = {
  controller: FileLinkController,
  templateUrl: 'shared/file-link.component.html',
  bindings: {
    key: '<',
    bucket: '<',
    encode: '<',
    url: '<',
    text: '<',
    onDelete: '&',
  },
};

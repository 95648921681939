class IndicationsController {
  constructor(ENV) {
    this.problemTypesIndex = ENV.search.indexes.problem_types;
    this.problemCodesIndex = ENV.search.indexes.problem_codes;
    this.current_indication = {};
  }

  inputVisible() {
    return this.acceptsMultipleIndications() || this.noIndicationsSelected();
  }

  customErrors() {
    return {
      noIndications: this.noIndicationsSelected(),
    };
  }

  prepAndUpdateOrder(indication) {
    if (indication.problem_type)
      indication.problem_type_id = indication.problem_type.id;
    if (indication.problem_code)
      indication.problem_code_id = indication.problem_code.id;
    if (indication.problem_code_location)
      indication.problem_code_location_id = indication.problem_code_location.id;
    if (indication.problem_code_extension)
      indication.problem_code_extension_id = indication.problem_code_extension.id;
    this.order.addIndication(indication);
    this.updateOrder();
  }

  currentExtensions() {
    let extensions;
    if (
      this.current_indication.problem_code_location &&
      this.current_indication.problem_code_location.extensions &&
      this.current_indication.problem_code_location.extensions.length
    ) {
      extensions = this.current_indication.problem_code_location.extensions;
    } else if (
      this.current_indication.problem_type &&
      this.current_indication.problem_type.extensions &&
      this.current_indication.problem_type.extensions.length
    ) {
      extensions = this.current_indication.problem_type.extensions;
    }
    return extensions;
  }

  onProblemCodeSelect() {
    this.custom_indication.problem_code_extension = null
  }

  currentLocations() {
    let locations;
    if (
      this.current_indication.problem_type &&
      this.current_indication.problem_type.locations &&
      this.current_indication.problem_type.locations.length
    ) {
      locations = this.current_indication.problem_type.locations;
    }
    return locations;
  }

  onIndicationSelect() {
    const locationValid = !this.currentLocations() ||
      !!this.current_indication.problem_code_location;
    const extensionValid = !this.currentExtensions() ||
      !!this.current_indication.problem_code_extension;
    if (locationValid && extensionValid) {
      const indication = this.current_indication;
      this.current_indication = null;

      this.prepAndUpdateOrder(indication);
      this.updateVisibleOrderSets();
    }
  }

  addCustomIndication(indication) {
    this.prepAndUpdateOrder(indication);
    this.custom_indication = null;
  }

  acceptsMultipleIndications() {
    return Array.isArray(this.orderIndications);
  }

  noIndicationsSelected() {
    let hasIndications;

    if (this.acceptsMultipleIndications()) {
      hasIndications = this.orderIndications.length < 1;
    } else {
      hasIndications = !this.order.indication;
    }

    return hasIndications;
  }
}

IndicationsController.$inject = ['ENV'];

export const omIndications = {
  bindings: {
    order: '<',
    orderIndications: '<',
    form: '<',
    editable: '<',
    updateOrder: '&',
    updateVisibleOrderSets: '&',
  },
  templateUrl: 'orders/shared/indications.component.html',
  controller: IndicationsController,
};

export class PendingRequests {
  constructor() {
    this.count = 0;
    this.inProgress = false;
  }

  _handleResponse() {
    this.count -= 1;
    if (this.count === 0) {
      this.inProgress = false;
    }
  }

  add(promise) {
    this.count += 1;
    this.inProgress = true;
    promise.then(this._handleResponse.bind(this), this._handleResponse.bind(this));
  }
}
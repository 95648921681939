import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

const PATIENTS_PATH = '/v2/admin/patients';

export class VaccineOrderService {
  constructor($stateParams, $rootScope, ApiService, VaccineOrder) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.VaccineOrder = VaccineOrder;
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    this.vaccineOrderPath = `${PATIENTS_PATH}/${patientId}/vaccine_orders`;
  }

  get(orderId) {
    return this.ApiService.get(`${this.vaccineOrderPath}/${orderId}`).then(
      response => new this.VaccineOrder(response),
    );
  }

  create() {
    return this.ApiService.save(
      this.vaccineOrderPath,
      new this.VaccineOrder(),
    ).then(response => {
      const createdOrder = new this.VaccineOrder(response);
      this.$rootScope.$emit('vaccineOrderCreated', createdOrder);
      return createdOrder;
    });
  }

  update(vaccineOrder) {
    return this.ApiService.update(
      `${this.vaccineOrderPath}/${vaccineOrder.id}`,
      vaccineOrder,
    );
  }

  sign(vaccineOrder) {
    return this.ApiService.save(
      `${this.vaccineOrderPath}/${vaccineOrder.id}/sign`,
      vaccineOrder,
    ).then(response => new this.VaccineOrder(response));
  }

  delete(orderId) {
    return this.ApiService.delete(`${this.vaccineOrderPath}/${orderId}`);
  }

  softDelete(orderId) {
    return this.ApiService.save(`${this.vaccineOrderPath}/${orderId}/delete`);
  }

  cancel(orderId) {
    return this.ApiService.save(`${this.vaccineOrderPath}/${orderId}/cancel`);
  }

  reactivate(vaccineOrder) {
    return this.ApiService.save(
      `${this.vaccineOrderPath}/${vaccineOrder.id}/sign`,
    ).then(response => new this.VaccineOrder(response));
  }

  decline(orderId) {
    return this.ApiService.save(`${this.vaccineOrderPath}/${orderId}/decline`);
  }

  edit(orderId) {
    return this.ApiService.save(
      `${this.vaccineOrderPath}/${orderId}/edit`,
    ).then(response => new this.VaccineOrder(response));
  }
}

VaccineOrderService.$inject = [
  '$stateParams',
  '$rootScope',
  'ApiService',
  'VaccineOrder',
];

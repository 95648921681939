export class PendingNewRx {
  constructor(attributes = {}) {
    if (attributes.earliest_fill_date) {
      attributes.earliest_fill_date = new Date(attributes.earliest_fill_date);
    }
    if (attributes.min_earliest_fill_date) {
      attributes.min_earliest_fill_date = new Date(
        attributes.min_earliest_fill_date,
      );
    }
    if (attributes.max_earliest_fill_date) {
      attributes.max_earliest_fill_date = new Date(
        attributes.max_earliest_fill_date,
      );
    }

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }
  showMoreOptions() {
    return !!(
      this.notes_to_pharmacist ||
      this.dispense_as_written ||
      this.earliest_fill_date ||
      this.dispensable_restricted_controlled_substance ||
      this.requires_note_of_medical_need
    );
  }
}

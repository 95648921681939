class Authenticator {
  constructor(
    { oauth2 },
    $transitions,
    $window,
    authToken,
    $state,
    $http,
    $location,
  ) {
    this.$transitions = $transitions;
    this.$window = $window;
    this.providerUrl = oauth2.providerUrl;
    this.clientId = oauth2.clientId;
    this.authToken = authToken;
    this.$state = $state;
    this.$http = $http;
    this.$location = $location;
  }

  initialize() {
    const transitionCriteria = {
      to: state => !state.dontStorePath || (state.data || {}).authenticate,
      from: state => !state.dontStorePath,
    };
    this.$transitions.onStart(transitionCriteria, () => {
      let continueTransition = true;
      this.$window.sessionStorage.setItem('path', this.$location.path());
      if (!this.isAuthenticated()) {
        this.login();
        continueTransition = false;
      }
      return continueTransition;
    });
  }

  isAuthenticated() {
    return this.authToken.isPresent();
  }

  login() {
    this.$window.location.href = `${
      this.providerUrl
    }/oauth/authorize?client_id=${this.clientId}&redirect_uri=${
      this.$window.location.origin
    }&response_type=code&code_challenge=codechallenge&code_challenge_method=plain`;
  }

  logout() {
    const token = this.authToken.get();
    this.$http
      .post(
        `${this.providerUrl}/oauth/revoke`,
        {
          token,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        this.authToken.delete();
        this.$state.go('landing');
      });
  }
}

export class AuthProvider {
  constructor() {
    this.$get = [
      'ENV',
      '$transitions',
      '$window',
      'authToken',
      '$state',
      '$http',
      '$location',
      Authenticator,
    ];
  }
}

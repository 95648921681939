export class ChartAccessService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  create(patientId) {
    return this.ApiService.save(
      `/v2/admin/patients/${patientId}/chart_accesses`,
    );
  }
}

ChartAccessService.$inject = ['ApiService'];

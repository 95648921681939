class AttachTimelineItemController {
  constructor($rootScope, $state) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.isAttached = false;
    this.showAttachButton = false;
  }

  $onChanges() {
    this.setShowAttachButton();
    this.setHoverText();
  }

  $onInit() {
    this.date = this.timelineItem.timeline_date;
    this.author = this.timelineItem.timelineListItemAuthor;
  }

  setShowAttachButton() {
    this.showAttachButton = this.isSupportingDocumentsAttachable && this.isAttachable && !this.isAttached;
  }

  setHoverText() {
    if (this.showAttachButton) this.hoverText = 'Attach to Order';
    else if (this.isAttachable && this.isAttached) this.hoverText = 'Attached';
    else if (this.timelineItem.hasCorruptDocuments()) this.hoverText = 'Cannot Attach (Corrupt)';
    else if (this.timelineItem.is_protected === true) this.hoverText = 'Cannot Attach (Protected)';
    else this.hoverText = 'Cannot Attach';
  }

  onClick($event) {
    this.$rootScope.$emit('add-supporting-document', this.timelineItem);
    $event.stopPropagation();
  }
}

AttachTimelineItemController.$inject = ['$rootScope', '$state'];

export const omAttachTimelineItem = {
  templateUrl: 'timeline/attach-timeline-item.component.html',
  controller: AttachTimelineItemController,
  bindings: {
    isAttachable: '<',
    isAttached: '<',
    isHovering: '<',
    isSupportingDocumentsAttachable: '<',
    timelineItem: '<',
    timelineListView: '<',
  },
  require: {
    timelineComponent: '^omTimeline',
  },
};

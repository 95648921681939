import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

export const NewRxCart = (
  $resource,
  PendingNewRx,
  PendingNewRxService,
  $stateParams,
  $q,
  CartFillDates,
  CartValidation,
) => {
  const _NewRxCart = $resource(
    '/v2/admin/patients/:patientId/rx_carts/current_new',
    {
      patientId() {
        return $stateParams.patientId || getPatientIdFromUrl() || '@patient_id';
      },
      id: '@id',
    },
    {
      get: {
        method: 'GET',
        cache: false,
        isArray: false,
        transformResponse: [
          data => {
            const cart = angular.fromJson(data);
            cart.cartItems = cart.cart_items.map(
              prescription => new PendingNewRx(prescription),
            );
            return cart;
          },
        ],
      },
      update: {
        method: 'PUT',
        url: '/v2/admin/patients/:patientId/rx_carts/:id/update_new',
        transformRequest(data) {
          if (data.measurements) {
            const measurementsAsArray = [];
            _.each(data.measurements, measurement =>
              measurementsAsArray.push(measurement),
            );
            data.measurements = measurementsAsArray;
          }
          data.cart_items = data.cartItems;
          return angular.toJson(data);
        },
      },
      prescribers: {
        url: '/v2/admin/patients/:patientId/rx_carts/:id/prescribers',
        method: 'GET',
        isArray: true,
      },
      prescriberCredentials: {
        url:
          '/v2/admin/patients/:patientId/rx_carts/:id/prescribers/:prescriber_id/prescribing_credentials',
        method: 'GET',
      },
      erx: {
        ignore401: true,
        method: 'POST',
        url: '/v2/admin/patients/:patientId/rx_checkout/pending_new_rxes',
      },
      verifyPassword: {
        ignore401: true,
        url:
          '/v2/admin/patients/:patientId/rx_checkout/pending_new_rxes/verify_password',
        method: 'POST',
        transformRequest: data => {
          data.rx_cart_id = data.cart.id;
          return angular.toJson(data);
        },
      },
    },
  );

  _NewRxCart.new = attributes => new _NewRxCart(attributes);

  angular.extend(_NewRxCart.prototype, {
    cartSize() {
      if (this.isComplete() || !this.cartItems) {
        return 0;
      }
      return this.cartItems.length;
    },

    calculateMinDates(item) {
      CartFillDates.calculateNewRx(item, this.cartItems);
    },

    update() {
      if (this.isComplete() || this.cartItems === undefined) {
        const cartDeferred = $q.defer();
        cartDeferred.reject({ data: { 1: ['Cart cannot be updated.'] } });
        return cartDeferred.promise;
      }

      const currentCart = this;
      const errors = {};
      const cartDeferred = $q.defer();
      const errorHandler = (errorResponse, prescription) => {
        if (prescription) {
          errors[`PendingNewRx${prescription.id}`] = errorResponse.data;
        } else {
          this.cartValidationErrorMessages = errorResponse.data;
        }
      };

      const promises = currentCart.cartItems.map(prescription =>
        PendingNewRxService.update(prescription).catch(errorResponse =>
          errorHandler(errorResponse, prescription),
        ),
      );

      promises.push(
        _NewRxCart
          .update(currentCart)
          .$promise.then(({ valid_prescribers: validPrescribers }) => {
            this.valid_prescribers = validPrescribers;
          }, errorHandler),
      );

      return $q.all(promises).then(() => {
        if (_.isEmpty(errors)) {
          cartDeferred.resolve();
        } else {
          cartDeferred.reject({ data: errors });
        }
        return cartDeferred.promise;
      });
    },
    hasControlledSubstance() {
      return CartValidation.containsControlledSubstances(this.cartItems);
    },
    isEmpty() {
      return this.cartSize() === 0;
    },
    isComplete() {
      return !!this.cart_complete_at;
    },
    isPrint() {
      const selectedPharmacy = this.patientPharmacy;
      return (
        !this.pharmacy_id &&
        selectedPharmacy &&
        selectedPharmacy.pharmacy.name === 'print'
      );
    },
    transmittedPrescriptions() {
      return this.cartItems.filter(
        ({ must_handwrite: handwritten }) =>
          !handwritten || handwritten === false,
      );
    },
    handwrittenPrescriptions() {
      return this.cartItems.filter(
        ({ must_handwrite: handwritten }) =>
          handwritten && handwritten === true,
      );
    },
    inCart({ latest_regimen: latestRegimen }) {
      if (this.isComplete() || !latestRegimen) {
        return false;
      }
      const regimenFoundInCart = this.cartItems.find(
        item => latestRegimen.id === item.medication_regimen_id,
      );

      return !!regimenFoundInCart;
    },
    setPatientPharmacy(pharmacies) {
      if (this.pharmacy_id) {
        this.patientPharmacy = pharmacies.filter(
          item => item.pharmacy && item.pharmacy.id === this.pharmacy_id,
        )[0];
      }

      if (!this.patientPharmacy) {
        this.patientPharmacy = pharmacies[0];
        this.pharmacy_id =
          this.patientPharmacy.pharmacy && this.patientPharmacy.pharmacy.id;
      }

      this.showNonEpcsPharmacyError =
        this.patientPharmacy &&
        this.patientPharmacy.isNonEpcsPharmacy() &&
        this.hasControlledSubstance();
    },
    setPrescriberAndCredentials(signedOnBehalfOfId) {
      let validPrescriberMatch = this.valid_prescribers.filter(
        prescriber => prescriber.id === signedOnBehalfOfId,
      )[0];

      if (this.valid_prescribers.length === 1) {
        validPrescriberMatch = this.valid_prescribers[0];
      }

      if (validPrescriberMatch) {
        this.signed_on_behalf_of = validPrescriberMatch;
        this.signed_on_behalf_of_id = validPrescriberMatch.id;
        this.signed_on_behalf_of_name = validPrescriberMatch.name;
      } else {
        this.signed_on_behalf_of = undefined;
        this.signed_on_behalf_of_id = undefined;
        this.signed_on_behalf_of_name = undefined;
        this.selected_prescribing_credential_id = undefined;
      }

      return (
        this.signed_on_behalf_of_id &&
        this.getCredentialsForPrescriber(this.signed_on_behalf_of_id)
      );
    },
    getCredentialsForPrescriber(prescriberId) {
      return _NewRxCart
        .prescriberCredentials({
          id: this.id,
          prescriber_id: prescriberId,
        })
        .$promise.then(
          ({ id: credentialsId, prescribing_credentials: credentials }) => {
            this.signed_on_behalf_of.prescribing_credentials = credentials;
            if (credentials && credentials.length > 0) {
              this.setPrescriberCredentials(credentialsId);
            }
          },
        );
    },
    setPrescriberCredentials(credentialId) {
      const selectedCredentials = this.signed_on_behalf_of.prescribing_credentials.filter(
        creds => creds.id === credentialId,
      )[0];
      this.selected_prescribing_credential_id = credentialId;
      this.signed_on_behalf_of.dea_license_number =
        selectedCredentials.dea_license_number;
      this.signed_on_behalf_of.service_area = selectedCredentials.service_area;
      this.signed_on_behalf_of.state_of_issue =
        selectedCredentials.state_of_issue;
      this.signed_on_behalf_of.supervising_physician =
        selectedCredentials.supervising_physician;
    },
  });
  return _NewRxCart;
};

NewRxCart.$inject = [
  '$resource',
  'PendingNewRx',
  'PendingNewRxService',
  '$stateParams',
  '$q',
  'CartFillDates',
  'CartValidation',
];

export class Medication {
  RX_STATUS = {
    sending: 'Queued',
    failed: 'Failed',
    sent: 'Sent',
  };
  RX_SOURCE = {
    print: 'Printed:',
    fax: 'Fax',
    surescripts: 'eRx',
    handwritten: 'Handwritten:',
  };

  constructor(attributes = {}) {
    if (!attributes.prescription_history) attributes.prescription_history = {};

    attributes.display_date = attributes.sent_at || attributes.cart_complete_at;
    if (!attributes.med_for_display) attributes.med_for_display = {};

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
    if (this.sent_as) this.source = this.sent_as;

    this.med_for_display.status_indicator =
      this.status && this.source ? this.setRxStatusAttributes() : '';
    this.setAvailabilityAttributes();
    this.setLegacyAttributes();
    this.hasDenialReason = this.isDenied();
  }
  setAvailabilityAttributes() {
    this.isNotAvailable =
      this.route && !this.route.is_active && !this.route.legacy;
    if (this.isNotAvailable)
      this.isNotAvailableTemplate = `This medication regimen is no longer available.<br /><br /> Please discontinue
            <strong>${this.route.name}</strong>
            and prescribe an alternative.`;
  }
  setLegacyAttributes() {
    this.isLegacy = this.route && this.route.legacy;
    if (this.isLegacy)
      this.isLegacyTemplate = `Oops! This medication is out-of-date.
          <br /> <br /> To ensure compatibility with our current prescribing system, please update
          <strong>${this.route.name}: ${this.latest_regimen.text_desc}</strong>
          by adding it again and re-entering the regimen.`;
  }
  statusForApprovedRx(source, status) {
    if (this.sent_as !== 'print' && this.sent_as !== 'handwritten') {
      return `${source} ${status}:`;
    }
    return source;
  }

  statusForDeniedRx(source, status) {
    return `Denial ${status} (${source}):`;
  }
  setRxStatusAttributes() {
    const source = this.RX_SOURCE[this.source];
    const status = this.RX_STATUS[this.status];

    if (this.isDenied()) {
      return this.statusForDeniedRx(source, status);
    }
    return this.statusForApprovedRx(source, status);
  }
  discontinue() {
    this.is_active = false;
    this.is_deleted = false;
  }
  resume() {
    this.is_active = true;
    this.is_deleted = false;
  }
  delete() {
    this.is_deleted = true;
  }
  isDenied() {
    return !!this.med_for_display.denial_reason;
  }
  isEditable() {
    return this.is_active && this.route.is_active && !this.route.legacy;
  }
}

export function configureMoment() {
  moment.updateLocale('en', {
    relativeTime: {
      past: '%s ago',
      s: '%ds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'over a year',
      yy: number => `over ${number} years`,
    },
  });
}

const LAB_DATA_ENDPOINT = '/v2/admin/lab_data';

export class LabDataService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getFor(resource) {
    if (!resource.documents) {
      return;
    }

    resource.documents.forEach(doc => {
      if (doc.for_lab) {
        this.get(doc.parent_id).then(response => {
          doc.lab_data = response;
        });
      }
    });
  }

  get(id) {
    return this.ApiService.get(`${LAB_DATA_ENDPOINT}/${id}`);
  }
}

LabDataService.$inject = ['ApiService'];

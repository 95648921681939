import { omCard } from './card.directive';
import { omCardContent } from './card-content.directive';
import { omCardTitle } from './card-title.directive';
import { omTitle } from './title.directive';

angular
  .module('onelifeUi.shared.card', [])
  .directive('omCard', omCard)
  .directive('omCardTitle', omCardTitle)
  .directive('omTitle', omTitle)
  .directive('omCardContent', omCardContent);

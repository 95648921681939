const TODAY = moment().toDate();

export class HistoryVaccine {
  constructor(attributes = {}) {
    if (!attributes.was_administered) attributes.given_on = TODAY;
    if (attributes.expires_on)
      attributes.expires_on = moment(attributes.expires_on).toDate();
    if (attributes.side) this.setSide(attributes.side);

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  setSide(side) {
    if (side === 'L') this.selectedSide = 'Left';
    if (side === 'R') this.selectedSide = 'Right';
  }

  getHistoryVaccineParams() {
    const params = {
      ...this,
      vaccine_brand_id: this.vaccine_brand.id,
      clinical_route_id: this.vaccine_route.id,
    };

    if (this.vaccine_location)
      params.vaccine_location_id = this.vaccine_location.id;

    return params;
  }
}

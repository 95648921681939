export const omScroll = () => ({
  restrict: 'A',
  link(scope, element, attrs) {
    let lastScrollTop = 0;
    const raw = element[0];
    const handler = _.throttle(() => scope.$apply(attrs.omScroll), 100, { trailing: false });
    element.on('scroll', () => {
      const st = element.scrollTop();
      if ((st > lastScrollTop) && ((raw.scrollTop + raw.offsetHeight) >= (raw.scrollHeight - 10))) { handler(); }
      lastScrollTop = st;
    });
  },
});

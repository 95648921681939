export class LegacyUiHrefService {
  constructor(
    $window,
    {
      legacyUi: { host },
    },
  ) {
    this.$window = $window;
    this.host = host;
  }

  openLegacyHref(legacyUiHref, target) {
    this.$window.open(`${this.host}${legacyUiHref}`, target || '_self');
  }
}

LegacyUiHrefService.$inject = ['$window', 'ENV'];

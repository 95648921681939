export function onError() {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      element.on('error', () => {
        scope.$apply(attrs.onError);
      });
    },
  };
}

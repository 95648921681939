export const omCollapsed = () => ({
  restrict: 'AE',
  require: '^^omCollapse',
  scope: {},
  replace: true,
  transclude: true,
  template:
    '<div class="om-collapsed om-animate" ng-show="collapse.isCollapsed()" ng-transclude />',
  link(scope, element, attrs, collapse) {
    scope.collapse = collapse;
  },
});

export const omSearchFilter = () => ({
  restrict: 'E',
  require: '^omSearchFilters',
  scope: {
    filter: '@',
    name: '@',
  },
  link: {
    pre(scope, element, attrs, searchFilters) {
      searchFilters.add(scope);
    },
  },
});

export class MessagingWarningsService {
  constructor(PatientService, $q, $stateParams) {
    this.PatientService = PatientService;
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.errorMessages = {
      noDigitalCommunications: 'Patient opted out of digital communications.',
      teenMinor:
        'Patient is a teenage minor. Legally protected info might be seen by a guardian.',
      preTeenMinor:
        'Patient is a pre-teen minor. Messages might be seen by a guardian.',
      limitedAccessMember:
        'Patient has limited access membership and cannot reply.',
      expiredMember: 'Patient cannot reply until their membership is renewed.',
    };
  }

  getPatient() {
    return this.PatientService.get(this.$stateParams.patientId);
  }

  messagingWarnings() {
    const errors = [];

    return this.getPatient().then(patient => {
      if (patient.isPreteen()) {
        errors.push(this.errorMessages.preTeenMinor);
      } else if (patient.isTeen()) {
        errors.push(this.errorMessages.teenMinor);
      }

      if (patient.membership && patient.membership.limited_access) {
        errors.push(this.errorMessages.limitedAccessMember);
      }
      if (patient.membership && !patient.membership.active) {
        errors.push(this.errorMessages.expiredMember);
      }
      if (!patient.acceptsDigitalCommunications()) {
        return this.errorMessages.noDigitalCommunications;
      }

      return errors.join(' ');
    });
  }
}

MessagingWarningsService.$inject = ['PatientService', '$q', '$stateParams'];

import 'ng-rollbar';
import './api/api.module';
import './auth/auth.module';
import './encounter/encounter.module';
import './env/env.module';
import './exception/exception.module';
import './medications/medications.module';
import './messaging/messaging.module';
import './new-rx/new-rx.module';
import './notifications/notifications.module';
import './orders/orders.module';
import './patient/patient.module';
import './problems/problems.module';
import './renewals/renewals.module';
import './schedule/schedule.module';
import './search/search.module';
import './shared/shared.module';
import './todo/todo.module';
import { appInitializer } from './config/app-initializer';
import { configureAnalytics } from './config/analytics';
import { configureAngularElastic } from './config/angular_elastic';
import { configureAnimate } from './config/animate';
import { configureApi } from './config/api';
import { configureDebugInfo } from './config/debug_info';
import { configureGoogleMaps } from './config/google-map';
import { configureMoment } from './config/moment';
import { configureRollbar } from './config/rollbar';
import { configureStates } from './config/states';
import { configureToastr } from './config/toastr';
import { configureTooltips } from './config/tooltips';
import { configureUiBootstrap } from './config/ui_bootstrap';
import { configureUiRouter } from './config/ui_router';
import { hashPrefixConfig } from './config/hash-prefix';
import { promiseRejectionHandler } from './config/promise-rejection-handler';
import interceptors from './config/interceptors';
import './scss/app.scss';

angular
  .module('onelifeUi', [
    'ngQuill',
    'ngResource',
    'ngMessages',
    'ngAnimate',
    'ngSanitize',
    'templates',
    'ui.router',
    'ui.router.state.events',
    'ui.select',
    'angularMoment',
    'hc.marked',
    'monospaced.elastic',
    'angular.bind.notifier',
    'tandibar/ng-rollbar',
    'toastr',
    'om.env',
    'om.auth',
    'om.notifications',
    'onelifeUi.api',
    'onelifeUi.exception',
    'onelifeUi.encounter',
    'onelifeUi.medications',
    'onelifeUi.messaging',
    'onelifeUi.patient',
    'onelifeUi.problems',
    'onelifeUi.newRx',
    'onelifeUi.renewals',
    'onelifeUi.schedule',
    'onelifeUi.renewals',
    'onelifeUi.search',
    'onelifeUi.shared',
    'onelifeUi.todo',
    'onelifeUi.orders',
    'uiGmapgoogle-maps',
    '720kb.tooltips',
    'ui.bootstrap.dropdown',
    'ui.bootstrap.multiMap',
    'ui.bootstrap.popover',
    'ui.bootstrap.stackedMap',
    'ui.bootstrap.tooltip',
    'ui.bootstrap.typeahead',
    'uib/template/popover/popover-template.html',
    'uib/template/popover/popover-html.html',
    'angulartics',
    'angulartics.mixpanel',
  ])
  .run(appInitializer)
  .config(hashPrefixConfig)
  .config(configureAnalytics)
  .config(configureAngularElastic)
  .config(configureAnimate)
  .config(configureApi)
  .config(interceptors)
  .config(configureDebugInfo)
  .config(configureGoogleMaps)
  .config(configureMoment)
  .config(configureRollbar)
  .config(configureToastr)
  .config(configureTooltips)
  .config(configureUiBootstrap)
  .config(configureUiRouter)
  .config(promiseRejectionHandler);

/* eslint-disable no-undef */
if (!USE_UPGRADED_CHART) {
  angular.module('onelifeUi').config(configureStates);
}

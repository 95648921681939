export function omPendingNewRxCheckoutCtrl(
  $scope,
  NewRxCart,
  PendingNewRxService,
  $rootScope,
  $window,
  $q,
  focus,
  CartValidation,
  NotificationsService,
) {
  const messages = {
    defaultValidationError:
      'Sorry, an unknown validation error occured. Please try again.',
    unknownError: 'Sorry, an unknown error occurred.',
    mustHandwrite:
      "Currently, this prescription can't be sent electronically. Please handwrite the prescription now and confirm prior to checkout.",
    mustConfirmHandwritten:
      'All handwritten prescriptions must be confirmed prior to checkout.',
    mustConfirmReadyToSign:
      'All controlled substance prescriptions must be marked ready to sign prior to checkout.',
    handwrittenConfirmation:
      "I've written this prescription and it will be noted in the Rx history.",
    twoFactorAgreement: `By completing the two-factor authentication protocol at this time,
                         you are legally signing the prescription(s) and authorizing the transmission of the above
                         information to the pharmacy for dispensing. The two-factor authentication protocol
                         may only be completed by the practitioner whose name and DEA registration number appear above.`,
    pushStatus: '',
    approvePush: 'Please confirm your signature on your mobile device.',
    pushFailed: 'Sorry, there was an error with the signature confirmation.',
    pushSucceeded: 'Signature confirmed, transmitting prescriptions...',
  };

  const { id: currentProvider } = $scope.encounter.profile;

  const ctrl = {
    allHandwrittenItemsConfirmed: true,
    allReadyToSignItemsConfirmed: true,
    cart: $scope.encounter.newRxCart,
    date: Date.now(),
    invalidPassword: false,
    invalidSecondFactor: false,
    messages,
    password: '',
    passwordVerified: false,
    secondFactorToken: '',
    submissionInProgress: false,
  };

  const clearAuthFields = () => {
    if (!ctrl.passwordVerified) {
      ctrl.password = '';
    }
    ctrl.secondFactorToken = '';
  };

  const resetAuthFlags = () => {
    ctrl.invalidPassword = false;
    ctrl.invalidSecondFactor = false;
    ctrl.unknownError = false;
    ctrl.validationError = false;
  };

  const resetFields = () => {
    clearAuthFields();
    ctrl.submissionInProgress = false;
    ctrl.allHandwrittenItemsConfirmed = true;
    ctrl.allReadyToSignItemsConfirmed = true;

    resetAuthFlags();
  };

  const sendToPrinter = params => {
    params.print_rx = 'print';

    return NewRxCart.erx(params).$promise.then(({ url }) => {
      ctrl.cart.printUrl = url;
      ctrl.openPrint();
      ctrl.completeCart();
      resetFields();
    }, ctrl.handleError);
  };

  const sendERx = params =>
    NewRxCart.erx(params).$promise.then(() => {
      ctrl.completeCart();
      ctrl.resetAndClose();
    }, ctrl.handleError);

  const twoFactorPollingEvent = '2fa_push_polling_done';

  const disconnectFromNotificationsChannel = () => {
    ctrl.isPollingForCartToken = false;
    ctrl.notificationsConnection.unbind(twoFactorPollingEvent);
  };

  const twoFactorPollingComplete = ({
    success,
    error,
    cart_push_token: cartPushToken,
  }) => {
    ctrl.submissionInProgress = false;
    if (success) {
      ctrl.cartPushToken = cartPushToken;
      ctrl.messages.pushStatus = ctrl.messages.pushSucceeded;
      const erxParams = {
        password: ctrl.password,
        cart_push_token: cartPushToken,
        cart: ctrl.cart,
      };
      sendERx(erxParams);
    } else if (error) {
      ctrl.messages.pushStatus = ctrl.messages.pushFailed;
    }
  };

  const bindToNotificationsChannel = channel => {
    ctrl.isPollingForCartToken = true;
    ctrl.notificationsConnection = NotificationsService.subscribe(channel);
    ctrl.messages.pushStatus = ctrl.messages.approvePush;
    ctrl.notificationsConnection.bind(
      twoFactorPollingEvent,
      twoFactorPollingComplete,
    );
  };

  ctrl.fallbackToManualTwoFactor = () => {
    disconnectFromNotificationsChannel();
    ctrl.manualTwoFactorRequired = true;
  };

  ctrl.verifyPasswordBeforeCheckout = () => {
    const params = {
      password: ctrl.password,
      cart: ctrl.cart,
    };

    return NewRxCart.verifyPassword(params).$promise.then(
      ({
        password_verified: passwordVerified,
        pusher_channel: channelName,
      }) => {
        ctrl.passwordVerified = passwordVerified;
        ctrl.submissionInProgress = false;
        if (channelName) {
          bindToNotificationsChannel(channelName);
        } else {
          ctrl.manualTwoFactorRequired = true;
        }
      },
      ctrl.handleError,
    );
  };

  ctrl.modalFormSubmitButtonText = () => {
    if (ctrl.cart.isPrint()) {
      return 'Print';
    }
    if (ctrl.submissionInProgress) {
      return 'Sending...';
    }
    return 'Send';
  };

  ctrl.cart.notify_patient =
    $scope.encounter.patient.patient_preferences.accepts_clinical_emails;

  ctrl.prescribedBySelf = () => {
    const isSignedOnBehalfOfCurrentID =
      !!ctrl.cart.signed_on_behalf_of_id &&
      ctrl.cart.signed_on_behalf_of_id === currentProvider;
    return isSignedOnBehalfOfCurrentID;
  };

  ctrl.checkoutDisabled = () => {
    const newRxCart = $scope.encounter.newRxCart;
    const pharmacy = newRxCart.patientPharmacy;
    let isDisabled =
      $scope.pendingNewRxCheckoutForm.saving || ctrl.submissionInProgress;

    if (
      !$scope.encounter.profile.hasRole('provider') ||
      $scope.encounter.patient.incompleteDemographics() ||
      $scope.pendingNewRxCheckoutForm.$invalid
    ) {
      isDisabled = true;
    }

    if (pharmacy && pharmacy.pharmacy.name === 'find' && !newRxCart.isEmpty()) {
      isDisabled = true;
    }

    if (newRxCart.isEmpty()) {
      isDisabled = true;
    }

    if (newRxCart.isPrint() && newRxCart.hasControlledSubstance()) {
      isDisabled = true;
    }

    if (newRxCart.cartValidationErrorMessages) {
      isDisabled = true;
    }

    return isDisabled;
  };

  ctrl.openPrint = () => {
    const printWindow = $window.open(ctrl.cart.printUrl);
    if (
      !printWindow ||
      printWindow.closed ||
      typeof printWindow.closed === 'undefined'
    ) {
      $window.alert(
        'Please unblock popups for this page and click "Print Again".',
      );
    }
  };

  ctrl.openModal = () => {
    NewRxCart.get({ id: ctrl.cart.id }).$promise.then(response => {
      ctrl.cart.cartItems = response.cartItems;
      ctrl.submissionInProgress = false;
      ctrl.requireSecondFactor = response.require_2fa;
      $scope.$modal.open();
    });
  };

  ctrl.completeCart = () => {
    ctrl.cart.cart_complete_at = new Date();
    $rootScope.$emit('patientMedicationsRefresh');
  };

  ctrl.resetAndClose = () => {
    ctrl.manualTwoFactorRequired = false;
    ctrl.passwordVerified = false;
    resetFields();
    $scope.$modal.close();
    if (ctrl.notificationsConnection) disconnectFromNotificationsChannel();
  };

  ctrl.handleError = ({ data: errorResponse, status }) => {
    const { password_verified: passwordValid, errors } = errorResponse;
    const exceptions = errors && errors.exceptions ? errors.exceptions : [];
    const exceptionsExist = exceptions.length > 0;

    ctrl.submissionInProgress = false;
    clearAuthFields();
    if (ctrl.notificationsConnection) disconnectFromNotificationsChannel();

    if (!passwordValid && status === 401) {
      focus('medCheckoutFocus-password');
      if (exceptionsExist) ctrl.invalidPassword = exceptions.join(' ');
      else ctrl.invalidPassword = errors.password.join(' ');
    } else if (errors && errors.secondFactorToken) {
      focus('medCheckoutFocus-token');
      if (exceptionsExist) ctrl.invalidSecondFactor = exceptions.join(' ');
      else ctrl.invalidSecondFactor = errors.secondFactorToken.join(' ');
    }

    if (status === 500) {
      ctrl.unknownError = ctrl.messages.unknownError;
    }

    if (status === 422) {
      if (exceptionsExist) ctrl.validationError = exceptions.join(' ');
      else
        ctrl.validationError = errorResponse.message
          ? errorResponse.message
          : ctrl.messages.defaultValidationError;
    }
  };

  ctrl.setFormSubmissionInProgress = () => {
    ctrl.submissionInProgress = true;
    resetAuthFlags();
  };

  ctrl.readyToSign = (ready, pendingNewRx) => {
    if (ready) {
      PendingNewRxService.markReadyToSign(pendingNewRx);
    }
    pendingNewRx.readyToSign = ready;
  };

  ctrl.setHandwrittenConfirmed = () => {
    ctrl.cart.cart_items.forEach(item => {
      item.handwrittenConfirmed = ctrl.cart.cartItems.find(
        cartItem => cartItem.id === item.id,
      ).handwrittenConfirmed;
    });
    ctrl.cart.update();
  };

  ctrl.completeCheckout = $event => {
    if (ctrl.cart.cartItems.filter(item => item.must_handwrite).length > 0)
      ctrl.setHandwrittenConfirmed();

    ctrl.allHandwrittenItemsConfirmed = CartValidation.allHandwrittenRequestsConfirmed(
      ctrl.cart.cartItems,
    );
    ctrl.allReadyToSignItemsConfirmed = CartValidation.allSignedRequestsConfirmed(
      ctrl.cart.cartItems,
    );
    const allControlledSubstancesConfirmed =
      ctrl.allHandwrittenItemsConfirmed && ctrl.allReadyToSignItemsConfirmed;

    if (allControlledSubstancesConfirmed) {
      ctrl.setFormSubmissionInProgress();

      const erxParams = {
        password: ctrl.password,
        cart: ctrl.cart,
        rx_cart_id: ctrl.cart.id,
      };

      const requiresTwoFactorCheckout = CartValidation.requireTwoFactorCheckout(
        ctrl.cart.cartItems,
      );

      if (requiresTwoFactorCheckout) {
        if (!ctrl.passwordVerified) return ctrl.verifyPasswordBeforeCheckout();
        ctrl.submissionInProgress = false;
        erxParams.secondFactorToken = ctrl.secondFactorToken;
      }

      if (ctrl.cart.isPrint()) return sendToPrinter(erxParams);
      return sendERx(erxParams);
    }

    return $event.preventDefault();
  };

  return ctrl;
}

omPendingNewRxCheckoutCtrl.$inject = [
  '$scope',
  'NewRxCart',
  'PendingNewRxService',
  '$rootScope',
  '$window',
  '$q',
  'focus',
  'CartValidation',
  'NotificationsService',
];

export const omPendingNewRxCheckout = () => ({
  restrict: 'E',
  require: '^omModal',
  controller: 'omPendingNewRxCheckoutCtrl as checkoutCtrl',
});

const S3_POINTER_PATH_SEGMENT = 'v2/admin/patient_timeline/s3_pointers';
const DOWNLOAD_PATH_SEGMENT = 'download';

class S3PointerLinkController {
  constructor({ api: { baseUrl } }) {
    this.baseUrl = baseUrl;
  }

  $onInit() {
    this.downloadURL = `${this.baseUrl}/${S3_POINTER_PATH_SEGMENT}/${
      this.pointer.id
    }/${DOWNLOAD_PATH_SEGMENT}`;
    this.text = this.pointer.title;
  }
}

S3PointerLinkController.$inject = ['ENV'];

export const omS3PointerLink = {
  controller: S3PointerLinkController,
  templateUrl: 'messaging/s3-pointer-link.component.html',
  bindings: {
    pointer: '<',
  },
};

const CUSTOM_LAB_ORDER_TYPE_CATEGORY_ID = 9;

export class LabOrder {
  constructor(
    attributes = {
      scheduled_date: new Date(),
      status: 'unsigned',
    },
  ) {
    if (attributes.created_at)
      attributes.created_at = moment(attributes.created_at);
    if (attributes.status === 'signed') attributes.collection_date = new Date();
    if (attributes.scheduled_date)
      attributes.scheduled_date = new Date(attributes.scheduled_date);
    if (attributes.lab_order_events) {
      attributes.lab_order_events = attributes.lab_order_events.map(event => {
        event.created_at = moment(event.created_at);
        return event;
      });
    }

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  addIndication(indication) {
    if (this.lab_order_indications) {
      if (
        !_.find(this.lab_order_indications, {
          problem_type_id: indication.problem_type_id,
        }) && !_.find(this.lab_order_indications, {
          problem_code_id: indication.problem_code_id,
        })
      ) {
        this.lab_order_indications.push(indication);
      }
    } else {
      this.lab_order_indications = [indication];
    }
  }

  removeIndication(indication) {
    _.remove(this.lab_order_indications, ind =>
      this.equalIndications(indication, ind),
    );
  }

  addLabTest(test) {
    if (this.lab_order_types) {
      if (!_.find(this.lab_order_types, {
          id: test.id
        })) {
        this.lab_order_types.push(test);
      }
    } else {
      this.lab_order_types = [test];
    }
  }

  addCustomLabTest(test) {
    test.custom = true;
    test.lay_description = test.clinical_description;
    test.short_name = test.clinical_description;
    test.lab_order_type_category_id = CUSTOM_LAB_ORDER_TYPE_CATEGORY_ID;

    if (this.lab_order_types) {
      this.lab_order_types.push(test);
    } else {
      this.lab_order_types = [test];
    }
  }

  removeLabTest(test) {
    _.remove(this.lab_order_types, {
      id: test.id
    });
  }

  merge(labOrder) {
    this.mergeLabOrderIndications(labOrder.lab_order_indications);
    this.mergeLabOrderTypes(labOrder.lab_order_types);
  }

  _indicationMatchCheck(indicationLocal, indicationAPIResponse, prop) {
    // The API will response with any id if defined.
    // If an id is undefined in the API response then it can't be used in the equivalence check
    // Otherwise, compare the API value with the local value
    return !indicationAPIResponse[prop] || indicationAPIResponse[prop] === indicationLocal[prop]
  }

  // indicationB is the user defined indication.  indicationA is the indication coming from the server.
  equalIndications(indicationA, indicationB) {
    if (indicationA.id && indicationB.id && indicationA.id === indicationB.id) return true;

    return (
      this._indicationMatchCheck(indicationA, indicationB, 'problem_code_id') &&
      this._indicationMatchCheck(indicationA, indicationB, 'problem_code_location_id') &&
      this._indicationMatchCheck(indicationA, indicationB, 'problem_type_id') &&
      this._indicationMatchCheck(indicationA, indicationB, 'problem_code_extension_id')
    );
  }

  mergeLabOrderIndications(savedLabOrderIndications) {
    if (savedLabOrderIndications) {
      if (!this.lab_order_indications) {
        return;
      }

      savedLabOrderIndications.forEach(savedLabOrderIndication => {
        const labOrderIndication = this.lab_order_indications.find(indication =>
          this.equalIndications(savedLabOrderIndication, indication),
        );
        if (labOrderIndication) {
          angular.merge(labOrderIndication, savedLabOrderIndication);
        }
      });
    }
  }

  mergeLabOrderTypes(savedLabOrderTypes) {
    if (savedLabOrderTypes) {
      if (!this.lab_order_types) {
        return;
      }

      savedLabOrderTypes.forEach(savedLabOrderType => {
        const labOrderType = this.lab_order_types.find(
          type =>
          (savedLabOrderType.id && type.id === savedLabOrderType.id) ||
          (savedLabOrderType.custom &&
            type.custom_code === savedLabOrderType.custom_code),
        );
        if (labOrderType) {
          angular.merge(labOrderType, savedLabOrderType);
        }
      });
    }
  }
}

export const problemCodeHighlight = () => (problemCode, query) => {
  const escapedQuery = _.escapeRegExp(query);
  const keywords = escapedQuery.split(/\s/);
  const pattern = new RegExp(`(${keywords.join('|')})`, 'ig');
  const highlight = (text = '') =>
    text ? text.replace(pattern, match => `<strong>${match}</strong>`) : '';
  const formatProblemCode = (description, code) =>
    `${description} <em>${code}</em>`;

  if (pattern.test(problemCode.code)) {
    return formatProblemCode(
      problemCode.detail_description,
      highlight(problemCode.code),
    );
  }
  return formatProblemCode(
    highlight(problemCode.detail_description),
    problemCode.code,
  );
};

export function configureTooltips(tooltipsConfProvider) {
  tooltipsConfProvider.configure({
    smart: false,
    side: 'left',
    speed: 'medium',
    tooltipTemplateUrlCache: true,
  });
}

configureTooltips.$inject = ['tooltipsConfProvider'];

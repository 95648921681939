const endpoint = '/v2/admin/measurement_order_type_sources';
export class MeasurementOrderTypeSourceService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  query() {
    return this.ApiService.get(`${endpoint}`, { params: { limit_by_current_user: true } });
  }
}

MeasurementOrderTypeSourceService.$inject = ['ApiService'];

class LabResultController {
  $onInit() {
    this.setAutoSendFlags();
  }

  $onChanges() {
    this.setAutoSendFlags();
  }

  setAutoSendFlags() {
    if (this.labData) {
      this.failedAutoSend =
        !!this.labData.conversation_id && !this.labData.patient_timeline_post;
      this.successfulAutoSend = !!this.labData.patient_timeline_post;
    } else {
      this.failedAutoSend = false;
      this.successfulAutoSend = false;
    }
  }
}

export const omLabResult = {
  bindings: {
    awsKey: '<',
    awsBucket: '<',
    labData: '<',
  },
  templateUrl: 'notes/lab-result.html',
  controller: LabResultController,
};

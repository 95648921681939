import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

class GenericFollowUpController {
  constructor(
    $stateParams,
    PatientService,
    FollowUpOrderService,
    FOLLOW_UP_ORDER_TYPES,
  ) {
    this.genericFollowUpForm = null;
    this.analyticsType = FOLLOW_UP_ORDER_TYPES.generic.analyticsType;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.patientService = PatientService;
    this.formReady = false;
    this.followUpOrderService = FollowUpOrderService;
    this.FOLLOW_UP_ORDER_TYPES = FOLLOW_UP_ORDER_TYPES;
  }

  $onInit() {
    this.followUpComponent.registerChild(this);
    if (!this.orderExists()) {
      this.order = { type: this.FOLLOW_UP_ORDER_TYPES.generic.name };
      this.patientService.get(this.patientId).then(patient => {
        this.order.question = `Hi ${patient.nickname ||
          patient.first_name}, how are you doing since we last spoke?`;
        this.remindedOn = this.buildReminded();
        this.formReady = true;
      });
    } else {
      this.remindedOn = this.buildReminded();
      this.formReady = true;
    }
  }

  _handleError({ data }) {
    this.apiError = data.message;
  }

  orderExists() {
    return this.order && !!this.order.id;
  }

  signFollowUpOrder() {
    const order = _.clone(this.order);
    this.apiError = null;
    let request = null;

    if (this.orderExists()) {
      request = this.followUpOrderService.updateFollowUpOrder(
        this.patientId,
        order,
      );
    } else {
      request = this.followUpOrderService.createFollowUpOrder(
        this.patientId,
        order,
      );
    }
    request.catch(this._handleError.bind(this));
    return request;
  }

  deleteFollowUpOrder() {
    const request = this.followUpOrderService.deleteFollowUpOrder(
      this.patientId,
      this.order,
    );
    request.catch(this._handleError.bind(this));
    return request;
  }

  validateForm() {
    this.genericFollowUpForm.$setSubmitted();
    return this.genericFollowUpForm.$valid;
  }

  onManualDateEntry(newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }

    this.remindedOn = this.buildReminded();
    this.order.due_on = newDueOnValue;
    this.onFormFieldChanged({ fieldName: 'Date Field' });
  }

  onShortcutDateEntry(quantity, unit, newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }

    this.remindedOn = this.buildReminded();
    this.order.due_on = newDueOnValue;
    this.onDatePickerSelected({ quantity, unit });
  }

  buildReminded() {
    if (!this.order.due_on) {
      return undefined;
    }
    return '1 week';
  }
}

GenericFollowUpController.$inject = [
  '$stateParams',
  'PatientService',
  'FollowUpOrderService',
  'FOLLOW_UP_ORDER_TYPES',
];

export const omGenericFollowUp = {
  bindings: {
    order: '<',
    ready: '<',
    onFormFieldChanged: '&',
    onDatePickerSelected: '&',
  },
  require: {
    followUpComponent: '^omFollowUp',
  },
  controller: GenericFollowUpController,
  templateUrl: 'orders/follow-up/generic-follow-up.component.html',
};

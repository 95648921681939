class DeleteMessageOptionController {
  constructor(PatientTimelinePostService) {
    this.PatientTimelinePostService = PatientTimelinePostService;
  }

  deletePost() {
    return this.PatientTimelinePostService.delete(this.post).then(post => {
      this.modal.close();
      return post;
    });
  }

  openModal(modal) {
    this.modal = modal;
    modal.open();
  }
}

DeleteMessageOptionController.$inject = ['PatientTimelinePostService'];

export const omDeleteMessageOption = {
  bindings: {
    post: '<',
  },
  controller: DeleteMessageOptionController,
  templateUrl: 'messaging/delete-message-option.component.html',
};

function omFormatDate(date, formatString) {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.format(formatString);
  }
  return date;
}

// Formats for Date only
export const omDate2y = () => date => omFormatDate(date, 'MM/DD/YY');
export const omDate4y = () => date => omFormatDate(date, 'MM/DD/YYYY');

// Formats for Date with Month
export const omDateWithMonth4y = () => date => omFormatDate(date, 'MMM YYYY');

// Formats for Date with Time
export const omDateWithTime2y = () => date =>
  omFormatDate(date, 'h:mm A MM/DD/YY');
export const omDateWithTime4y = () => date =>
  omFormatDate(date, 'h:mm A MM/DD/YYYY');

// Formats for Time only
export const om12Time = () => date => omFormatDate(date, 'h:mm A');
export const om24Time = () => date => omFormatDate(date, 'HH:mm');

const API_PATH = '/v2/admin/medication_prns.json';
export class MedicationPrnService {
  constructor(ApiService, MedicationPrn) {
    this.ApiService = ApiService;
    this.MedicationPrn = MedicationPrn;
  }

  query() {
    return this.ApiService.get(API_PATH).then((response) => {
      response.map((item) => {
        item = new this.MedicationPrn(item);
        return item;
      });

      return response;
    });
  }
}
MedicationPrnService.$inject = ['ApiService', 'MedicationPrn'];

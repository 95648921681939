export class PharmacyPicker {
  constructor(
    PatientPharmacyService,
    PatientPharmacy,
    PharmacyService,
    focus,
    USStateCodes,
  ) {
    this.PatientPharmacyService = PatientPharmacyService;
    this.PatientPharmacy = PatientPharmacy;
    this.PharmacyService = PharmacyService;
    this.focus = focus;
    this.stateCodes = USStateCodes;
    this.$modal = {};
    this.searchPharmForm = {};
    this.customPharmForm = {};
    this.initializeQuery();
  }

  initializeQuery() {
    this.searchResults = { pharmacies: [] };
    this.pharmacyTypes = [
      {
        flag: 0,
        name: 'Retail Pharmacy',
      },
      {
        flag: 1,
        name: 'Mail Order Pharmacy',
      },
    ];
    this.query = {
      is_mail_order_eq: '',
      name: '',
      page: 1,
      type: '',
    };
    this.defaultQuery = {
      is_mail_order_eq: this.pharmacyTypes[0].flag,
      name: '',
      page: 1,
      type: this.pharmacyTypes[0],
    };
    this.resultsMessage = '';
  }

  $onInit() {
    this.hasError = false;
    this.showSearchControls = true;
    this.showOtherAddressField = false;
    this.addingCustom = false;
    this.searchStarted = false;
    this.searchInProgress = false;
    this.defaultQuery.location = this.defaultAddress();
    this.query = Object.assign({}, this.defaultQuery);
    if (!this.patientPharmacy) this.setPharmacy(this.patientPharmacies[0]);
  }

  defaultAddress() {
    const { addresses: patientAddresses, office: homeOffice } = this.patient;
    if (patientAddresses && patientAddresses.length > 0) {
      return patientAddresses[0].address;
    } else if (homeOffice) {
      return homeOffice.address;
    }
    return '';
  }

  resetQuery() {
    this.onPharmacyTypeChange();
    this.query = Object.assign({}, this.defaultQuery);
    this.showSearchControls = true;
    this.showOtherAddressField = false;
    this.cart.showNonEpcsPharmacyError = false;
    if (this.addingCustom) this.toggleCustom();
  }

  onPharmacyTypeChange() {
    const { page, location, name } = this.defaultQuery;
    const { flag } = this.query.type;
    this.query = Object.assign({}, this.query, {
      page,
      location,
      name,
      is_mail_order_eq: flag,
    });
    this.searchStarted = false;
    this.searchInProgress = false;
    this.searchResults = { pharmacies: [] };
    this.resultsMessage = '';
  }

  setPharmacy(patientPharmacy) {
    this.cart.pharmacy_id =
      patientPharmacy.pharmacy_id ||
      (patientPharmacy.pharmacy ? patientPharmacy.pharmacy.id : null);
    this.cart.patientPharmacy = patientPharmacy;
    this.cart.update().then(() => {
      this.cart.showNonEpcsPharmacyError =
        this.cart.patientPharmacy.isNonEpcsPharmacy() &&
        this.cart.hasControlledSubstance();
      return this.cart.setPrescriberAndCredentials(
        this.cart.signed_on_behalf_of_id || this.currentProviderId,
      );
    });
  }

  goToPage(page) {
    this.query.page = page;
    this.search({
      $dirty: false,
    });
  }

  toggleCustom() {
    this.addingCustom = !this.addingCustom;
    if (this.addingCustom) {
      this.customPatientPharmacy = new this.PatientPharmacy({
        pharmacy: { phone_numbers_attributes: [{ kind: 'fax' }] },
      });
    }
  }

  toggleSearchControls() {
    this.showSearchControls = !this.showSearchControls;
    this.searchStarted = false;
    this.searchResults = { pharmacies: [] };
    this.resultsMessage = '';
    this.resetQuery();
  }

  resetQueryAndClose() {
    this.resetQuery();
    this.$modal.close();
  }

  addPharmacy(pharmacy) {
    return this.PatientPharmacyService.save(pharmacy).then(response => {
      this.handleSelect(response);
      this.patientPharmacies.forEach(item => {
        item.is_preferred = false;
      });
      if (!this._addedPharmacyIsDuplicate(response))
        this.patientPharmacies.unshift(response);
      this.resetQueryAndClose();
    });
  }

  _addedPharmacyIsDuplicate(addedPharmacy) {
    const duplicatePharmacy = _.find(
      this.patientPharmacies,
      patientPharmacy =>
        patientPharmacy.pharmacy.id === addedPharmacy.pharmacy.id,
    );
    if (duplicatePharmacy) {
      duplicatePharmacy.is_preferred = true;
      return true;
    }
    return false;
  }

  handleSelect(selected) {
    if (selected.pharmacy.name === 'find' && this.$modal) {
      this.resetQuery();
      this.$modal.open();
    } else {
      this.setPharmacy(selected);
    }
  }

  deleteAndSetNextPharmacy($event, patientPharmacy) {
    $event.stopPropagation();
    return this.PatientPharmacyService.delete(patientPharmacy.id).then(() => {
      this.patientPharmacies = this.patientPharmacies.filter(
        item => item.id !== patientPharmacy.id,
      );
      this.setPharmacy(this.patientPharmacies[0]);
    });
  }

  generateResultsMessage(resultsCount) {
    let message = 'No matching pharmacies found.';
    if (resultsCount === 0) return message;

    if (resultsCount === 1) {
      message = `${resultsCount} ${this.query.type.name}`;
    } else if (resultsCount > 1) {
      message = `${resultsCount} ${this.query.type.name.replace(
        /Pharmacy$/,
        'Pharmacies',
      )}`;
    }

    if (this.query.name) {
      message += ` named ${this.query.name}`;
    }

    message += ' found';

    if (this.query.location) {
      message += ` near ${this.query.location}`;
    }

    return `${message}.`;
  }

  search() {
    this.searchInProgress = true;
    this.searchStarted = true;

    this.PharmacyService.search(this.query).then(response => {
      this.totalPharmacies = response.total;
      this.searchResults.pharmacies = response.pharmacies;
      this.showSearchControls = false;
      this.searchInProgress = false;
      this.resultsMessage = this.generateResultsMessage(response.total);
    });
  }
}

PharmacyPicker.$inject = [
  'PatientPharmacyService',
  'PatientPharmacy',
  'PharmacyService',
  'focus',
  'USStateCodes',
];

export const omPharmacyPicker = {
  bindings: {
    cart: '<',
    currentProviderId: '<',
    patient: '<',
    patientPharmacies: '<',
    patientPharmacy: '<',
  },
  templateUrl: 'new-rx/pharmacy-picker.html',
  controller: PharmacyPicker,
};

class RegimenHistoryController {
  constructor(MedicationService, Medication) {
    this.MedicationService = MedicationService;
    this.Medication = Medication;
  }
  $onInit() {
    this.loadHistory();
  }
  loadHistory() {
    this.MedicationService.regimenHistory(this.medication).then(
      response => (this.history = response.map(rx => new this.Medication(rx))),
    );
  }
}

RegimenHistoryController.$inject = ['MedicationService', 'Medication'];

export const omRegimenHistory = {
  controller: RegimenHistoryController,
  bindings: {
    medication: '<',
  },
  require: {
    medicationsList: '^^omPatientMedicationsList',
  },
  templateUrl: 'medications/regimen-history.html',
};

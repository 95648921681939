export const omConsultantSection = {
  bindings: {
    order: '<',
    patient: '<',
    contact: '<',
    editable: '<',
    diagnosticGroupId: '<',
    externalOnly: '<',
  },
  templateUrl: 'orders/shared/consultant-section.component.html',
};

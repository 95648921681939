export function omFuzzyDateValidator(FuzzyDate) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
      // Matches date format of MM/DD/YYYY, MM/YYYY or YYYY
      ctrl.$validators.fuzzyDateValidator = (modelValue, viewValue) => {
        ctrl.$setValidity('fuzzyDateMonthValidator', true);
        if (!FuzzyDate.isValidDate(viewValue)) {
          return false;
        }

        // If entered, check month validity
        const dateArr = viewValue.split('/');

        if (dateArr.length > 1) {
          const month = parseInt(dateArr[0], 10);
          ctrl.$setValidity('fuzzyDateMonthValidator', !!month && month > 0 && month < 13);
        }

        return true;
      };
    },
  };
}

omFuzzyDateValidator.$inject = ['FuzzyDate'];

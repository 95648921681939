class PaneController {
  setHeading(heading) {
    this.heading = heading;
  }
  $onInit() {
    this.tabsCtrl.addPane(this);
  }
}

export const omTabPane = {
  controller: PaneController,
  transclude: true,
  require: {
    tabsCtrl: '^omTabs',
  },
  bindings: {
    heading: '@',
    badge: '<',
    sref: '@',
    selectIf: '<',
  },
  template: '<div class="om-tab" ng-show="$ctrl.selected" ng-transclude></div>',
};

import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

const consultOrdersPath = '/v2/admin/consult_orders';
const patientsPath = '/v2/admin/patients';

export class ConsultOrderService {
  constructor($stateParams, $rootScope, ApiService, ConsultOrder) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.ConsultOrder = ConsultOrder;
  }

  get(consultOrderId) {
    return this.ApiService.get(`${consultOrdersPath}/${consultOrderId}`).then(
      response => new this.ConsultOrder(response),
    );
  }

  create() {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    return this.ApiService.save(
      `${patientsPath}/${patientId}/consult_orders`,
      new this.ConsultOrder(),
    ).then(response => {
      const createdOrder = new this.ConsultOrder(response);
      this.$rootScope.$emit('consultOrderCreated', createdOrder);
      return createdOrder;
    });
  }

  update(consultOrder) {
    return this.ApiService.update(
      `${consultOrdersPath}/${consultOrder.id}`,
      consultOrder,
    ).then(response => new this.ConsultOrder(response));
  }

  delete(consultOrderId) {
    return this.ApiService.delete(`${consultOrdersPath}/${consultOrderId}`);
  }
}

ConsultOrderService.$inject = [
  '$stateParams',
  '$rootScope',
  'ApiService',
  'ConsultOrder',
];

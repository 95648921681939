class MessagingProductSpecialistOptionsController {
  constructor(FeaturesService, $rootScope) {
    this.FeaturesService = FeaturesService;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.canDeletePost = false;
    this.FeaturesService.hasFeature('new_1life_messaging_deletion').then(
      canDeletePost => {
        this.canDeletePost = canDeletePost;
      },
    );
  }

  isVisible() {
    // NOTE: If it ever becomes necessary for this function to return true for draft posts,
    //       the uib-dropdown element exhibits weird behavior for posts containing drafts.
    //       Specifically, there is a conflict with the uib-dropdown element for applying
    //       message templates.
    return !this.messageContainsDraft();
  }

  messageContainsDraft() {
    return (
      this.post.draft || !!this.post.comments.find(comment => comment.draft)
    );
  }
}

MessagingProductSpecialistOptionsController.$inject = [
  'FeaturesService',
  '$rootScope',
];

export const omMessagingProductSpecialistOptions = {
  bindings: {
    post: '<',
  },
  controller: MessagingProductSpecialistOptionsController,
  templateUrl: 'messaging/messaging-product-specialist-options.component.html',
};

import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

export const RefillRequest = (
  $resource,
  $q,
  $stateParams,
  TodoService,
  Renewal,
) => {
  let cartItems;

  function sortByPharmacyIdAndRoute(cart) {
    return _.sortBy(cart, renewal => [
      renewal.pharmacy.id,
      renewal.med_for_display.name,
    ]);
  }
  const _RefillRequest = $resource(
    '/v2/admin/patients/:patientId/refill_requests/:id',
    {
      patientId() {
        return $stateParams.patientId || getPatientIdFromUrl() || '@patient_id';
      },
      id: '@id',
    },
    {
      query: {
        url: '/v2/admin/patients/:patientId/rx_carts/current_renewal',
        method: 'GET',
        transformResponse: response => {
          const data = angular.fromJson(response);
          cartItems = data.cart_items.map(item => new Renewal(item));
          data.cart_items = sortByPharmacyIdAndRoute(cartItems);
          return data;
        },
        isArray: false,
      },
      count: {
        url: '/v2/admin/patients/:patientId/rx_carts/current_renewal_count',
        method: 'GET',
        interceptor: {
          response(response) {
            return response.data.count;
          },
        },
      },
      rx_checkout: {
        ignore401: true,
        url: '/v2/admin/patients/:patientId/rx_checkout/refill',
        method: 'POST',
      },
      verifyPassword: {
        ignore401: true,
        url: '/v2/admin/patients/:patientId/rx_checkout/refill/verify_password',
        method: 'POST',
      },
    },
  );

  _RefillRequest.latestCart = params =>
    _RefillRequest.query(params).$promise.then(currentRenewals => {
      currentRenewals.pendingRenewals = currentRenewals.cart_items.filter(
        renewal => renewal.isPending(),
      );
      currentRenewals.renewalsReadyForCheckout = currentRenewals.cart_items.filter(
        renewal => renewal.isApproved() || renewal.isDenied(),
      );
      currentRenewals.cartItems = currentRenewals.cart_items;
      return currentRenewals;
    });

  _RefillRequest.getTodo = renewal =>
    TodoService.getRefillRequestTodo({
      associated_with_id: renewal.id,
      associated_with_type: renewal.class_name,
    }).then(todo => {
      renewal.todo = todo;
      renewal.todo.isLoaded = true;
      return renewal;
    });

  _RefillRequest.updateTodo = renewal =>
    TodoService.update(renewal.todo).then(todo => {
      renewal.todo = todo;
      if (renewal.todo.assignee.id !== renewal.task.assignee.id) {
        renewal.updateAssignee(todo.assignee);
      }
      return renewal;
    });
  return _RefillRequest;
};

RefillRequest.$inject = [
  '$resource',
  '$q',
  '$stateParams',
  'TodoService',
  'Renewal',
];

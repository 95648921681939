const sessionPath = '/v2/aws_session';

export class AwsSession {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  get() {
    return this.ApiService.save(sessionPath);
  }
}

AwsSession.$inject = ['ApiService'];

class IndicationsListController {
  removeIndication(indication) {
    this.order.removeIndication(indication);
    this.updateOrder();
    this.updateVisibleOrderSets();
  }
}

export const omIndicationsList = {
  bindings: {
    orderIndications: '<',
    order: '<',
    editable: '<',
    updateOrder: '&',
    updateVisibleOrderSets: '&',
  },
  templateUrl: 'orders/shared/indications-list.component.html',
  controller: IndicationsListController,
};

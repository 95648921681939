class VaccineNameInputController {
  constructor(ENV, focus) {
    this.vaccinesIndex = ENV.search.indexes.vaccines;
    this.filter = null;
    this.focus = focus;
  }

  $onInit() {
    if (this.showOnlyOrderableVaccines)
      this.filter = '{bool: { should: [ {term: { is_orderable: true } }] } }';
    this.focus('vaccinationFocus-create');
  }
}

VaccineNameInputController.$inject = ['ENV', 'focus'];

export const omVaccineNameInput = {
  bindings: {
    vaccine: '<',
    onSelect: '&',
    form: '<',
    showOnlyOrderableVaccines: '<',
  },
  templateUrl: 'orders/vaccine/vaccine-name-input.html',
  controller: VaccineNameInputController,
};

class PrintedNoteController {
  constructor(NoteService, PatientService, $q, $stateParams) {
    this.NoteService = NoteService;
    this.PatientService = PatientService;
    this.$q = $q;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    const getNote = this.NoteService.get(this.noteId);
    const getPatient = this.PatientService.get(this.$stateParams.patientId);

    this.$q.all([getNote, getPatient]).then(([note, patient]) => {
      this.note = note;
      this.patient = patient;
    });
  }
}

PrintedNoteController.$inject = [
  'NoteService',
  'PatientService',
  '$q',
  '$stateParams',
];

export const omPrintedNote = {
  bindings: { noteId: '<' },
  templateUrl: 'notes/printed-note.component.html',
  controller: PrintedNoteController,
};

const OPTIONS = {
  routine: "Routine",
  urgent: "Urgent",
  stat: "STAT",
};

class OrderInstructionsController {

  getUrgencyOptions() {
    return Object.values(OPTIONS);
  }

  getUrgency() {
    return OPTIONS[this.order.urgency]
  }

}

OrderInstructionsController.$inject = [];

export const omOrderInstructions = {
  bindings: {
    order: '<',
    editable: '<'
  },
  templateUrl: 'orders/shared/order-instructions.component.html',
  controller: OrderInstructionsController,
};

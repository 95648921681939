export class Todo {
  constructor(attributes = {}) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  isComplete() {
    return !!this.state && this.state === 'finished';
  }

  isIncomplete() {
    return !!this.state && this.state !== 'finished';
  }

  isIncompleteCosign() {
    return this.isIncomplete() && this.name === 'Co-Sign';
  }

  static existsAndIncomplete(todo) {
    return !!todo && todo.isIncomplete();
  }
}

export const omSearchBox = () => ({
  restrict: 'AE',
  require: '^^omSearch',
  replace: true,
  scope: {
    placeholder: '@',
  },
  template: `<div class="om-search-box">
               <span class="fa fa-search"></span>
               <input om-layout-fill class="om-input -flush-bottom" placeholder="{{placeholder || '&#xF002'}}" ng-model="query">
               <i ng-show="query" class="fa fa-times-circle"></i>
             </div>`,
  link(scope, element, attrs, $ctrl) {
    element.find('i').bind('click', () => {
      scope.query = '';
      return $ctrl.query(scope.query);
    });
    return element.bind('keydown', event => {
      if (event.keyCode === 13) {
        $ctrl.query(scope.query);
        event.preventDefault();
      }
    });
  },
});

class MedicationFormController {
  constructor(
    ENV,
    $q,
    MedicationRouteService,
    MedicationService,
    Medication,
    focus,
    MedicationFrequencyIntervalService,
    $stateParams,
    MedicationPrnService,
    CustomComplete,
    RenewalRequestService,
    PendingNewRxService,
    FeaturesService,
    PendingRequests,
  ) {
    this.$q = $q;
    this.MedicationRouteService = MedicationRouteService;
    this.MedicationService = MedicationService;
    this.Medication = Medication;
    this.focus = focus;
    this.MedicationFrequencyIntervalService = MedicationFrequencyIntervalService;
    this.$stateParams = $stateParams;
    this.MedicationPrnService = MedicationPrnService;
    this.CustomComplete = CustomComplete;
    this.RenewalRequestService = RenewalRequestService;
    this.PendingNewRxService = PendingNewRxService;
    this.FeaturesService = FeaturesService;
    this.medicationForm = null;
    this.validDosagePattern = /(^[1-9]+[0-9]*(-[0-9]+)?)$|(^[0-9]*((\.[0-9]+)$)|([1-9][0-9]*\/[1-9][0-9]*))/;
    this.dosageDelimiter = {
      dash: '-',
      fractional: '/',
    };
    this.medicationRoutesIndex = ENV.search.indexes.medication_routes;
    this.prnOptions = [];
    this.medicationFrequencyIntervals = [];
    this.pendingRequests = new PendingRequests();
    this.regimen = {};
  }

  $onInit() {
    this.medChoices = [];
    this.writeRxDisabled = this.prescriptionsDisabled();
    this.medicationPrnDescPrefix = ' as needed for ';
    this.medication =
      this.medication || this.renewal || new this.Medication({});
    this.MedicationFrequencyIntervalService.query().then(
      response => (this.medicationFrequencyIntervals = response),
    );
    this.loadNewRoute();
  }

  resetUsageInstructions() {
    const freeInstructionTextFieldValid =
      this.medicationForm &&
      !!this.medicationForm.freeTextInstructions &&
      !this.medicationForm.freeTextInstructions.$invalid;
    const isStructuredData =
      this.dose &&
      this.isValidDose() &&
      this.frequency_interval &&
      !this.editingFreeTextRegimen;

    if (freeInstructionTextFieldValid && this.editingFreeTextRegimen) {
      this.editingFreeTextRegimen = true;
      this.dose = undefined;
      this.frequency_interval = undefined;
    } else if (isStructuredData) {
      this.free_text = '';
    }
  }

  editingRenewal({ class_name: renewalType = '' }) {
    return (
      renewalType === 'RefillRequest' || renewalType === 'PatientRxRequest'
    );
  }

  customComplete() {
    const isStructuredData =
      this.dose && this.isValidDose() && this.frequency_interval;
    const isFreeText = this.free_text !== undefined && this.free_text !== '';
    if (this.editingFreeTextRegimen) {
      return isFreeText;
    } else if (isStructuredData) {
      return true;
    }
    return false;
  }
  medicationFormDisabled() {
    if (this.medicationForm.$invalid) {
      if (this.medicationForm.$pristine) {
        this.notPrescribableMessage = 'Please select a valid medication.';
      } else {
        this.notPrescribableMessage = 'Please complete a valid regimen.';
      }
      return true;
    }
    return false;
  }

  prescriptionsDisabled() {
    if (this.medicationForm) {
      return this.medicationFormDisabled();
    }
    if (this.showDuplicateRouteMessage) {
      this.notPrescribableMessage =
        'Please add as a concurrent medication before prescribing.';
      return true;
    }
    if (
      this.route &&
      this.route.is_prescribable &&
      !this.route.is_prescribable.allowed
    ) {
      this.notPrescribableMessage = this.route.is_prescribable.reason;
      return true;
    }
    return false;
  }

  syncModel(collection, model) {
    return _.find(collection, item => item.id === model.id);
  }

  cancelCustom() {
    this.use_custom = false;
    this.editingFreeTextRegimen = false;
    this.resetUsageInstructions();
    this.setNewRegimen();
  }

  customize() {
    this.setCustomDefaults();
    this.use_custom = true;
    focus('dispersalFocus');
  }

  setCustomDefaults() {
    this.setDispensable();
    this.setFrequencyInterval();
    this.setDuration();
    this.setDosingUnitOfMeasure();
    this.setTextDescription();

    const instructionTextExists =
      !!this.regimen.medication_prescription_template
        .medication_prescription_item_template.instructions_text ||
      !!this.medication.free_text;

    if (instructionTextExists) {
      this.editingFreeTextRegimen = true;
      this.setFreeText();
    }
  }

  setDispensable() {
    const dispId = this.regimen.medication_prescription_template
      .medication_prescription_item_template.medication_dispensable_id;
    this.dispensable =
      this.syncModel(this.route.medication_dispensables, { id: dispId }) ||
      this.route.medication_dispensables[0];
  }

  setFrequencyInterval() {
    if (this.regimen.medication_prescription_template) {
      const itemTemplate = this.regimen.medication_prescription_template
        .medication_prescription_item_template;
      this.frequency_interval = itemTemplate.medication_frequency_interval;

      if (itemTemplate.dose_high) {
        this.dose = `${parseFloat(itemTemplate.dose_low)}-${parseFloat(
          itemTemplate.dose_high,
        )}`;
      } else if (itemTemplate.dose_low) {
        this.dose = parseFloat(itemTemplate.dose_low);
      }
    }
  }

  setDuration() {
    const item = this.regimen.medication_prescription_template
      .medication_prescription_item_template;
    this.use_duration = false;
    this.duration = undefined;
    if (item.duration_days) {
      this.use_duration = true;
      this.duration = item.duration_days;
    }
  }
  setFreeText() {
    this.free_text = this.regimen.medication_prescription_template.medication_prescription_item_template.instructions_text;
  }

  setUpConcurrentEditing(route) {
    this.duplicateRoutes = this.medicationsList.findDuplicateRoutes(route);
    this.showDuplicateRouteMessage = this.duplicateRoutes.length > 0;
  }

  setRoute(id) {
    if (id) {
      this.route = this.MedicationRouteService.get(id);
      this.loadingRegimen = true;
      this.route.then(route => {
        this.route = route;
        this.setRegimen();
        this.loadingRegimen = false;

        if (this.medicationsList) this.setUpConcurrentEditing(this.route);
        this.writeRxDisabled = this.prescriptionsDisabled();
      });
      this.use_custom = false;
    }
  }

  setRegimen() {
    this.getDefaultRegimens();
    if (this.renewal) {
      this.regimen = this.medication.medication_regimen;
    } else if (this.medication.latest_regimen) {
      this.regimen = this.medication.latest_regimen;
    } else {
      this.regimen = this.setNewRegimen();
    }

    this.use_custom = this.regimen.is_custom;

    this.setCustomDefaults();
  }

  getDefaultRegimens() {
    this.regs = _.filter(
      this.route.medication_regimens,
      reg => reg.is_custom === false,
    );
    if (this.regs.length === 0) {
      this.only_custom_regimen = true;
    }
  }

  setNewRegimen() {
    if (this.regs.length > 0) {
      return _.cloneDeep(this.regs[0]);
    }
    this.only_custom_regimen = true;
    return {
      medication_prescription_template: {
        medication_prescription_item_template: {},
      },
      is_custom: true,
    };
  }

  doseIsRange() {
    return `${this.dose}`.includes(this.dosageDelimiter.dash);
  }

  doseIsFractional() {
    return `${this.dose}`.includes(this.dosageDelimiter.fractional);
  }

  isValidDose() {
    const valid = this.validDosagePattern.test(`${this.dose}`);

    return valid;
  }

  setDosingUnitOfMeasure() {
    const { dosing_unit_of_measure: unitOfMeasureDescriptions = {} } =
      this.dispensable || {};

    this.dosingUnitOfMeasure = '';
    if (
      this.doseIsRange() ||
      (parseFloat(this.doseDisplay()) > 1 &&
        unitOfMeasureDescriptions.desc_plural)
    ) {
      this.dosingUnitOfMeasure = unitOfMeasureDescriptions.desc_plural;
    } else if (unitOfMeasureDescriptions.desc) {
      this.dosingUnitOfMeasure = unitOfMeasureDescriptions.desc;
    }
  }

  doseDisplay() {
    let dosageString = '';
    if (this.isValidDose()) {
      dosageString = `${this.dose}`;
      if (this.doseIsRange()) {
        dosageString = dosageString.replace(
          this.dosageDelimiter.dash,
          _.pad(this.dosageDelimiter.dash, 3, ' '),
        );
      } else if (this.doseIsFractional()) {
        const nums = dosageString.split(this.dosageDelimiter.fractional);
        dosageString = (parseInt(nums[0], 0) / parseInt(nums[1], 0)).toFixed(2);
      }
    }
    return dosageString;
  }

  onRegimenUpdate() {
    this.setTextDescription();
    this.prescriptionsDisabled();
    this.setDosingUnitOfMeasure();
  }

  structuredDataDescription() {
    const durationExists = this.use_duration && this.duration;
    const durationDaysDescription = ` for ${this.duration} days`;

    this.setDosingUnitOfMeasure();

    const { text_desc: dispensableTextDescription = '' } =
      this.dispensable || {};
    const { medication_clinical_route: clinicalRoute = {} } =
      this.dispensable || {};
    const { clinical_description: clinicalDescription = '' } = clinicalRoute;

    const fullDispensableDescription = this.dispensable
      ? `${
          dispensableTextDescription ? `${dispensableTextDescription}, ` : ''
        }${this.doseDisplay()} ${
          this.dosingUnitOfMeasure
        } ${clinicalDescription} `
      : `${this.doseDisplay()} `;

    const durationDescription = `${
      durationExists ? durationDaysDescription : ''
    }`;

    const {
      text_short: frequencyIntervalDescription,
    } = this.frequency_interval;

    return `${fullDispensableDescription}${frequencyIntervalDescription}${durationDescription}`;
  }

  freeTextRegimenDescription() {
    return `${this.dispensable.text_desc}${
      this.free_text ? `, ${this.free_text}` : ''
    }`;
  }

  setTextDescription() {
    if (!this.customComplete()) {
      this.textDescription = `${this.dosageDelimiter.dash}${
        this.dosageDelimiter.dash
      }`;
      return this.textDescription;
    }
    if (this.editingFreeTextRegimen) {
      this.textDescription = this.freeTextRegimenDescription();
    } else {
      this.textDescription = this.structuredDataDescription();
    }

    if (this.use_as_needed && this.medication_prn) {
      this.textDescription = `${this.textDescription}${
        this.medicationPrnDescPrefix
      }${this.medication_prn.desc}`;
    }

    return this.textDescription;
  }

  remainingChars() {
    let requiredLimit = 140;
    if (this.free_text) {
      requiredLimit = 140 - this.free_text.length;
    }
    if (this.use_as_needed && this.medication_prn) {
      return (
        requiredLimit -
        this.medicationPrnDescPrefix.length -
        this.medication_prn.desc.length
      );
    }
    return requiredLimit;
  }

  cleanUpCustom() {
    this.medication_data.medication_regimen.is_custom = true;
    this.medication_data.medication_regimen.text_desc = this.setTextDescription();

    const temp = this.medication_data.medication_regimen
      .medication_prescription_template;
    const tempItem = temp.medication_prescription_item_template;
    // you have to remove id's from attribute hashes so rails will create new ones
    delete temp.id;
    delete tempItem.id;

    // only need ids in this part of the create call
    tempItem.medication_dispensable_id = this.dispensable.id;

    if (this.editingFreeTextRegimen) {
      // if the user has toggled free text, remove extra stuff
      tempItem.instructions_text = this.free_text;
      tempItem.medication_frequency_interval_id = null;
      tempItem.medication_frequency_interval = null;
      tempItem.dose_high = null;
      tempItem.dose_low = null;
      tempItem.duration_days = null;
    } else {
      tempItem.instructions_text = null;
      tempItem.medication_frequency_interval_id = this.frequency_interval.id;
      tempItem.medication_frequency_interval = this.frequency_interval;
      this.dose = `${this.dose}`; // not using free text, so we want to set everything correctly
      if (this.doseIsRange()) {
        const nums = this.dose.split(this.dosageDelimiter.dash);
        tempItem.dose_low = nums[0];
        tempItem.dose_high = nums[1];
      } else {
        tempItem.dose_low = this.doseDisplay();
        tempItem.dose_high = null;
      }

      if (this.use_duration) {
        tempItem.duration_days = this.duration;
      }
    }
  }

  transformRequestPayload() {
    this.regimen = {
      ...this.regimen,
      ...{ medication_route_id: this.route_result.id },
    };
    this.resetUsageInstructions();
    this.regimen.is_custom = false;
    this.medication_data = {};

    if (this.use_as_needed && this.medication_prn) {
      this.medication_data.medication_prn = this.medication_prn;
    }

    this.medication_data.medication_regimen = _.cloneDeep(this.regimen);
    if (this.use_custom) this.cleanUpCustom();

    // rails wants nested attributes to end with *_attributes
    this.medication_data.medication_regimen.medication_prescription_template_attributes = this.medication_data.medication_regimen.medication_prescription_template;

    this.medication_data.medication_regimen.medication_prescription_template_attributes.medication_prescription_item_template_attributes = this.medication_data.medication_regimen.medication_prescription_template_attributes.medication_prescription_item_template;

    delete this.medication_data.medication_regimen
      .medication_prescription_template;
    delete this.medication_data.medication_regimen
      .medication_prescription_template_attributes
      .medication_prescription_item_template;

    if (this.use_custom) {
      delete this.medication_data.medication_regimen.id;
    }
  }

  handleError(errorResp) {
    this.errors = errorResp.data.message;
  }

  clearErrors() {
    this.medicationForm.$setPristine();
    delete this.errors;
  }

  save() {
    this.transformRequestPayload();
    const saveAndAddMedicationPromise = this.medicationsList.saveAndAddToHistory(
      {
        patient_id: this.$stateParams.patientId,
        cart_complete: true,
        medication_data: this.medication_data,
        match_existing_patient_medication: !this.showDuplicateRouteMessage,
      },
    );

    saveAndAddMedicationPromise.then(
      this.reset.bind(this),
      this.handleError.bind(this),
    );
    this.pendingRequests.add(saveAndAddMedicationPromise);
    return saveAndAddMedicationPromise;
  }

  createNewRegimenAndAddToCart(medicationPrn, medicationRegimen) {
    return this.MedicationService.createRegimen({
      medication_prn: medicationPrn,
      medication_regimen: medicationRegimen,
      new_rx: true,
    }).then(response => {
      this.medication_data.medication_regimen = response;
      return this.PendingNewRxService.addNewMedicationToCart(
        this.$stateParams.patientId,
        this.medication_data.medication_regimen.id,
      );
    });
  }

  addToPendingNewRxCart() {
    let createPendingRxPromise;
    this.transformRequestPayload();

    if (this.use_custom) {
      createPendingRxPromise = this.createNewRegimenAndAddToCart(
        this.medication_data.medication_prn,
        this.medication_data.medication_regimen,
      );
    } else {
      createPendingRxPromise = this.PendingNewRxService.addNewMedicationToCart(
        this.$stateParams.patientId,
        this.medication_data.medication_regimen.id,
      );
    }
    createPendingRxPromise.then(
      this.reset.bind(this),
      this.handleError.bind(this),
    );
    this.pendingRequests.add(createPendingRxPromise);
    return createPendingRxPromise;
  }

  update() {
    let regPromise;
    this.transformRequestPayload();

    if (this.use_custom) {
      regPromise = this.MedicationService.createRegimen({
        medication_prn: this.medication_data.medication_prn,
        medication_regimen: this.medication_data.medication_regimen,
        renewal: this.editingRenewal(this.medication),
      });
    } else {
      // if not creating a new custom regimen, create an empty promise that gets resolved right away.
      regPromise = this.$q.when({
        id: this.medication_data.medication_regimen.id,
      });
    }

    regPromise.then(null, this.handleError.bind(this));

    const deferred = this.$q.defer();
    regPromise.then(data => {
      // when the form is a part of a refill update the regimen on the refill otherwise create a new patient medication regimen
      let patientRegPromise;
      if (this.renewal) {
        patientRegPromise = this.updateRenewalRegimen(data);
      } else {
        patientRegPromise = this.createNewPatientRegimen(data);
      }

      // only resolve the "def" promise after the 2nd request has completed
      patientRegPromise.then(resp => {
        this.clearErrors();
        this.collapseParentEditors();
        return deferred.resolve(resp);
      });

      patientRegPromise.then(null, error => {
        this.handleError(error);
        return deferred.reject(error);
      });
    }, this.handleError);

    this.pendingRequests.add(deferred.promise);

    return deferred.promise;
  }

  updateRenewalRegimen(data) {
    return this.RenewalRequestService.updateRenewal({
      id: this.medication.id,
      class_name: this.medication.class_name,
      medication_regimen_id: data.id,
    }).then(response => {
      this.renewal.package_options = _.merge(
        {},
        this.renewal.package_options,
        response.package_options,
      );
      this.renewal.med_for_display = _.merge(
        {},
        this.renewal.med_for_display,
        response.med_for_display,
      );
      this.renewal.approved_quantity = response.approved_quantity;
      this.renewal.approved_medication_package_size_id =
        response.approved_medication_package_size_id;
      this.renewal.approved_fills = response.approved_fills;
      this.medication = this.renewal;
    }, this.handleError);
  }

  createNewPatientRegimen(data) {
    const formData = this;

    return this.MedicationService.changeRegimen({
      medication_prn: formData.medication_data.medication_prn,
      medication_regimen: formData.medication_data.medication_regimen,
      patient_medication_regimen: {
        cart_complete: true,
        medication_regimen_id: data.id,
        patient_medication_id: this.medication.id,
      },
    }).then(response => {
      this.medication.latest_regimen = response.medication_regimen;
      this.medication.latest_patient_medication_regimen = {
        created_by: response.created_by,
        id: response.id,
        is_prn: response.is_prn,
        started_at: response.started_at,
      };
    }, this.handleError);
  }

  reset() {
    delete this.regimen;
    delete this.route;
    delete this.dose;
    delete this.dispensable;
    delete this.use_custom;
    delete this.medication_prn;
    delete this.route_result;
    delete this.free_text;
    this.clearErrors();
    this.collapseParentEditors();
    this.editingFreeTextRegimen = false;
    this.medChoices = [];
    this.writeRxDisabled = this.prescriptionsDisabled();
    this.showDuplicateRouteMessage = false;
  }

  cancelChange() {
    this.reset();
    this.loadNewRoute();
  }

  loadNewRoute() {
    if (this.medication.id) {
      let { route } = this.medication;
      if (this.renewal) {
        route = this.medication.medication_route;
      }
      this.route_result = { id: route.id };
      this.setRoute(route.id);
    }
  }

  collapseParentEditors() {
    this.editors.map(editor => editor.collapse());
  }
}

MedicationFormController.$inject = [
  'ENV',
  '$q',
  'MedicationRouteService',
  'MedicationService',
  'Medication',
  'focus',
  'MedicationFrequencyIntervalService',
  '$stateParams',
  'MedicationPrnService',
  'CustomComplete',
  'RenewalRequestService',
  'PendingNewRxService',
  'FeaturesService',
  'PendingRequests',
];

export const omMedicationForm = {
  controller: MedicationFormController,
  templateUrl: 'medications/medication-form.html',
  transclude: false,
  require: {
    medicationsList: '?^^omPatientMedicationsList',
  },
  bindings: {
    renewal: '<',
    medication: '<',
    editors: '<',
  },
};

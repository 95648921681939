const featuresPath = '/v2/features';
export class FeaturesService {
  constructor(ApiService, $q) {
    this.ApiService = ApiService;
    this.$q = $q;
  }

  hasFeature(feature) {
    return this.get().then(features => features.includes(feature));
  }

  get() {
    // re-use the same request for concurrent clients instead of making more requests for the same data
    if (this.inProgress) {
      return this.inProgress;
    }

    // return cached data instead of refreshing feature flags
    if (this.features) {
      return this.$q.resolve(this.features);
    }

    this.inProgress = this.ApiService.get(featuresPath).then((features) => {
      this.features = features;
      this.inProgress = undefined;
      return features;
    });
    return this.inProgress;
  }
}

FeaturesService.$inject = ['ApiService', '$q'];

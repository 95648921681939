const notePath = '/v2/admin/notes';
const patientPath = '/v2/admin/patients';

export class NoteService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  save(params) {
    return this.ApiService.save(
      `${patientPath}/${this.$stateParams.patientId}/notes`,
      params,
    );
  }

  get(id) {
    return this.ApiService.get(`${notePath}/${id}`);
  }

  update(data) {
    return this.ApiService.update(`${notePath}/${data.id}`, data);
  }

  delete(id) {
    return this.ApiService.delete(`${notePath}/${id}`);
  }

  fax(id, data) {
    return this.ApiService.update(`${notePath}/${id}/fax`, data);
  }

  generateLetter(id) {
    return this.ApiService.update(`${notePath}/${id}/generate_letter`,
      { patient_id: this.$stateParams.patientId },
    );
  }

  recategorize(id, template) {
    return this.ApiService.update(`${notePath}/${id}/recategorize`, { filing_template: template });
  }
}

NoteService.$inject = ['ApiService', '$stateParams'];

class LabOrderListItemController {
  constructor($state, LabOrderService) {
    this.$state = $state;
    this.LabOrderService = LabOrderService;
    this.isLabOrder = true;
  }

  $onInit() {
    this.title = this.order.title;
    this.subtitle = this.order.subtitle;
    this.ordered_by = this.order.internal_user.name;
    this.due_on = this.order.scheduled_date;
  }

  isDeleteable() {
    return this.order.status === 'unsigned';
  }

  isCancellable() {
    return this.order.status === 'signed';
  }

  isDueDateVisible() {
    return this.isDeleteable() || this.isCancellable();
  }

  cancelOrder() {
    this.order.status = 'cancelled';
    this.LabOrderService.update(this.order);
    this.ordersComponent.remove(this.order);
  }

  deleteOrder() {
    this.LabOrderService.delete(this.order.id);
    this.ordersComponent.remove(this.order);
  }

  editView() {
    this.$state.go('app.chart.orders.edit', { id: this.order.id });
  }
}

LabOrderListItemController.$inject = ['$state', 'LabOrderService'];

export const omLabOrderListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: LabOrderListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

const problemsPatientPathPrefix = '/v2/admin/patients';
const problemsSubpath = 'problems';

const problemsPath = '/v2/admin/problems';
const problemHistoriesSubpath = 'problem_histories';

export class ProblemService {
  constructor(ApiService, Problem, $stateParams) {
    this.ApiService = ApiService;
    this.Problem = Problem;
    this.$stateParams = $stateParams;
  }

  query(patientId, params) {
    return this.ApiService.get(
      `${problemsPatientPathPrefix}/${patientId}/${problemsSubpath}`,
      { params },
    ).then(response => response.map(problem => new this.Problem(problem)));
  }

  save(patientId, problem) {
    return this.ApiService.save(
      `${problemsPatientPathPrefix}/${patientId}/${problemsSubpath}`,
      problem,
    ).then(response => new this.Problem(response));
  }

  update(patientId, problem) {
    return this.ApiService.update(`${problemsPath}/${problem.id}`, problem, {
      params: { patientId },
    }).then(response => new this.Problem(response));
  }

  resolve(patientId, problem) {
    return this.ApiService.update(
      `${problemsPath}/${problem.id}/resolve`,
      problem,
      { params: { patientId } },
    ).then(response => new this.Problem(response));
  }

  reactivate(patientId, problem) {
    return this.ApiService.update(
      `${problemsPath}/${problem.id}/reactivate`,
      problem,
      { params: { patientId } },
    ).then(response => new this.Problem(response));
  }

  delete(patientId, problemId) {
    return this.ApiService.delete(`${problemsPath}/${problemId}`, {
      params: { patientId },
    });
  }

  queryProblemHistories(problemId) {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    return this.ApiService.get(
      `${problemsPath}/${problemId}/${problemHistoriesSubpath}`,
      { params: { patientId } },
    );
  }
}

ProblemService.$inject = ['ApiService', 'Problem', '$stateParams'];

class ButtonMenuController {
  $onChanges() {
    this.updateButtons();
  }

  updateButtons() {
    this.mainButton = this.buttons[0];
    if (this.buttons.length > 1) {
      this.additionalButtons = this.buttons.slice(1);
    } else {
      this.additionalButtons = null;
    }
  }
}

ButtonMenuController.$inject = [];

export const omButtonMenu = {
  templateUrl: 'shared/button-menu.component.html',
  controller: ButtonMenuController,
  bindings: {
    buttons: '<',
  },
};

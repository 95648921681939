// This directive can be used to memoize the value that a user submitted
// before selecting an autocomplete result, especially for the purpose of
// using the before value for the purpose of analytics tracking.
export function omTrackableAutocompleteInput() {
  return {
    restrict: 'A',
    link(scope, element) {
      element.blur(() => {
        scope.$ctrl.userInputString = element.val();
      })
    },
  };
}

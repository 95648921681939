import uuidv4 from 'uuid/v4';

export class PatientTimelineS3PointerService {
  constructor($stateParams) {
    this.$stateParams = $stateParams;
  }

  getAttachmentKey(basename) {
    return `patients/${this.$stateParams.patientId}/${uuidv4()}/${basename}`;
  }
}

PatientTimelineS3PointerService.$inject = ['$stateParams'];

export const vaccineHighlight = () => (vaccine, query) => {
  const escapedQuery = _.escapeRegExp(query);
  const re = new RegExp(`\\b(${escapedQuery})`, 'i');

  const highlight = (text = '') =>
    text ? text.replace(re, '<strong>$1</strong>') : '';

  const displayWith = extra => `${vaccine.pref_name} (${highlight(extra)})`;

  if (re.test(vaccine.pref_name)) {
    return highlight(vaccine.pref_name);
  } else if (re.test(vaccine.abbreviation)) {
    return displayWith(vaccine.abbreviation);
  }

  return displayWith(vaccine.tags.find(tag => re.test(tag)));
};

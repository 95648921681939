class PrnSelectController {
  constructor(focus, MedicationPrnService, CustomComplete) {
    this.focus = focus;
    this.MedicationPrnService = MedicationPrnService;
    this.CustomComplete = CustomComplete;
    this.customPrnOptions = [];
    this.prnsLoaded = false;
  }

  $onInit() {
    this.loadPrns();
    this.setActivePrn();
  }

  loadPrns() {
    if (this.prnsLoaded) {
      this.customPrnOptions = this.prnOptions;
      return this.prnOptions;
    }
    return this.MedicationPrnService.query().then((response) => {
      this.prnOptions = response;
      this.customPrnOptions = [...this.customPrnOptions, ...response];
      this.prnsLoaded = true;
    });
  }

  filterPrns(search) {
    if (this.prnsLoaded) {
      this.CustomComplete({
        fullList: this.prnOptions,
        filteredList: this.customPrnOptions,
        defaults: { is_active: false },
        key: 'desc',
        search,
      });
    }
  }

  setActivePrn() {
    if (this.activePrn) {
      this.medicationForm.use_as_needed = true;

      const currentPrnNotInList = !this.prnOptions.includes(this.activePrn);
      if (!this.activePrn.is_active && currentPrnNotInList) {
        this.prnOptions = [...this.prnOptions, this.activePrn];
      }

      this.medicationForm.medication_prn = this.prnOptions.find(prn => prn.id === this.activePrn.id);
    }
  }
}

PrnSelectController.$inject = ['focus', 'MedicationPrnService', 'CustomComplete'];

export const omPrnSelect = {
  controller: PrnSelectController,
  templateUrl: 'medications/prn-select.html',
  transclude: false,
  require: {
    medicationForm: '^omMedicationForm',
  },
  bindings: {
    prnOptions: '<',
    activePrn: '<',
  },
};

export const omForm = () => ({
  restrict: 'AE',
  replace: true,
  transclude: true,
  template:
    '<form class="om-form" novalidate autocomplete="off" ng-transclude></form>',
  controllerAs: 'omFormCtrl',
  controller: [
    '$scope',
    '$attrs',
    ($scope, $attrs) => {
      const formController = {};
      return Object.defineProperty(formController, 'form', {
        get: () => {
          // Dig into scope, splitting property names on '.', e.g. `$ctrl.form` => $scope['$ctrl']['form']
          const formPath = $attrs.name.split('.');
          let form = $scope;
          formPath.forEach(prop => {
            form = form[prop];
          });
          return form;
        },
      });
    },
  ],
});

/* eslint-disable no-undef */

export function configureStates($stateProvider, $urlServiceProvider) {
  $urlServiceProvider.rules.when(
    '/patients/:patientId/chart/summaries/new/edit',
    '/patients/:patientId/chart/summaries/new',
  );
  $urlServiceProvider.rules.when(
    '/patients/:patientId/chart/summaries/new/edit/orders/:id/edit',
    '/patients/:patientId/chart/orders/:id/edit',
  );
  $urlServiceProvider.rules.when(
    '/patients/:patientId/chart/summaries/new/edit/orders/list',
    '/patients/:patientId/chart/orders/list',
  );

  $urlServiceProvider.rules.when('', '/schedule');

  $stateProvider
    .state('logout', {
      /*
        Though the /logout route logic is handled by Angular 2,
        this state is still required by ui-router in order to
        navigate to the route
      */
      dontStorePath: true,
      url: '/logout',
    })
    .state('app', {
      templateUrl: 'templates/app.html',
      controller: [
        'profile',
        function app(profile) {
          this.profile = profile;
          return undefined;
        },
      ],
      controllerAs: 'app',
      data: {
        authenticate: true,
      },
      resolve: {
        profile: ['ProfileService', ProfileService => ProfileService.get()],
      },
    })
    .state('app.chart', {
      url: '/patients/:patientId/chart',
      abstract: true,
      resolve: {
        patient: [
          '$stateParams',
          'PatientService',
          ($stateParams, PatientService) =>
            PatientService.get($stateParams.patientId),
        ],
        medications: [
          '$stateParams',
          'PatientMedicationsService',
          ($stateParams, PatientMedications) =>
            PatientMedications.getAllForPatient($stateParams.patientId),
        ],
        newRxCart: [
          '$stateParams',
          'NewRxCart',
          ($stateParams, NewRxCart) =>
            NewRxCart.get({ patientId: $stateParams.patientId }).$promise,
        ],
        patientPharmacies: [
          '$stateParams',
          'PatientPharmacyService',
          ($stateParams, PatientPharmacyService) =>
            PatientPharmacyService.query($stateParams.patientId),
        ],
        renewalsCount: [
          '$stateParams',
          'RefillRequest',
          function renewalsCount($stateParams, RefillRequest) {
            return RefillRequest.count({
              patientId: $stateParams.patientId,
            }).$promise;
          },
        ],
        chartAccess: [
          '$stateParams',
          'ChartAccessService',
          function chartAccess($stateParams, ChartAccessService) {
            if ($stateParams.patientId) {
              ChartAccessService.create($stateParams.patientId);
            }
          },
        ],
      },
      controller: 'EncounterController',
      controllerAs: 'encounter',
      templateUrl: 'templates/encounter.html',
    })
    .state('app.chart.workspace', {
      abstract: true,
      sticky: true,
      dsr: {
        default: { state: 'app.chart.workspace.newWorkspaceItem' },
      },
      views: {
        'newWorkspaceItem@app.chart': {
          template: '<ui-view/>',
        },
        'summaries@app.chart': {
          template: '<ui-view/>',
        },
        'letters@app.chart': {
          template: '<ui-view/>',
        },
        'notes@app.chart': {
          template: '<ui-view><om-note current-message="encounter.currentMessage"/></ui-view>',
        },
        'newPatientTimelinePost@app.chart': {
          template: '<ui-view/>',
        },
        'patientTimelinePosts@app.chart': {
          template: '<ui-view/>',
        },
      },
    })
    .state('app.chart.workspace.notes', {
      url: '/notes/:id/edit',
      component: 'omNote',
    })
    .state('app.chart.workspace.letters', {
      url: '/letters/:id/edit',
      component: 'omLetter',
    })
    .state('app.chart.workspace.newWorkspaceItem', {
      url: '/summaries/new',
      component: 'omNewWorkspaceItem',
    })
    .state('app.chart.workspace.patientTimelinePosts', {
      url: '/patient_timeline_posts/:id/edit',
      component: 'patientTimelinePostsComponent',
    })
    .state('app.chart.workspace.summaries', {
      url: '/summaries/:id/edit',
      component: 'omSummaries',
    })
    .state('app.chart.orders', {
      url: '/orders',
      abstract: true,
      sticky: true,
      dsr: {
        default: { state: 'app.chart.orders.list' },
      },
      views: {
        'orders_list@app.chart': {
          template: '<ui-view/>',
        },
        'orders_edit@app.chart': {
          template: '<ui-view/>',
        },
        'consult_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'follow_up_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'follow_up_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'vaccine_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'vaccine_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'survey_order_new@app.chart': {
          template: '<ui-view/>',
        },
        'survey_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'procedure_order_edit@app.chart': {
          template: '<ui-view/>',
        },
        'generic_follow_up_order_new@app.chart': {
          template: '<ui-view/>',
        },
      },
    })
    .state('app.chart.orders.list', {
      url: '/list',
      component: 'omOrdersList',
    })
    .state('app.chart.orders.edit', {
      url: '/:id/edit',
      component: 'omLabOrderForm',
    })
    .state('app.chart.orders.editProcedureOrder', {
      url: '/procedure_orders/:id/edit',
      component: 'omProcedureOrder',
    })
    .state('app.chart.orders.editVaccineOrder', {
      url: '/vaccine_orders/:orderId/edit',
      component: 'omVaccineOrder',
    })
    .state('app.chart.orders.editConsultOrder', {
      url: '/:id/edit_consult_order',
      component: 'omConsultOrderForm',
    })
    .state('app.chart.orders.newFollowUpOrder', {
      url: '/follow_up_orders/new',
      component: 'omFollowUp',
    })
    .state('app.chart.orders.editFollowUpOrder', {
      url: '/follow_up_orders/:orderType/:id/edit',
      component: 'omFollowUp',
    })
    .state('app.chart.orders.editSurveyOrder', {
      url: '/survey_orders/:id/edit',
      component: 'omSurveyOrder',
    })
    .state('app.chart.orders.newSurveyOrder', {
      url: '/survey_orders/new',
      component: 'omSurveyOrder',
    })
    .state('app.chart.orders.newGenericFollowUpOrder', {
      url: '/generic_follow_up_orders/new',
      component: 'omGenericFollowUp',
    })
    .state('app.chart.renewals', {
      url: '/renewals',
      abstract: true,
      sticky: true,
      dsr: {
        default: { state: 'app.chart.renewals.list' },
      },
      views: {
        'renewals_list@app.chart': {
          template: '<ui-view/>',
        },
      },
    })
    .state('app.chart.renewals.list', {
      url: '/list',
      component: 'omRenewalList',
      resolve: {
        patient: [
          '$stateParams',
          'PatientService',
          ($stateParams, PatientService) =>
            PatientService.get($stateParams.patientId),
        ],
        profile: ['ProfileService', ProfileService => ProfileService.get()],
        cart: [
          '$stateParams',
          'RefillRequest',
          function refillRequest($stateParams, RefillRequest) {
            return RefillRequest.latestCart({
              patientId: $stateParams.patientId,
            });
          },
        ],
      },
    });
}

configureStates.$inject = ['$stateProvider', '$urlServiceProvider'];

// Delete this file once we can upgrade to a Zone.js release containing this fix
// https://github.com/angular/zone.js/pull/1109

export const patchErrorHandler = () => {
  const originOnError = window.onerror;
  window.onerror = (event, ...args) => {
    if (originOnError) {
      if (event.filename && typeof event.filename !== 'undefined') {
        return originOnError.call(
          window,
          event.message,
          event.filename,
          event.lineno,
          event.colno,
          event.error,
        );
      }
      return originOnError.apply(window, args);
    }
    return originOnError;
  };
};

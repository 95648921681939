export class Auth {
  constructor($cookies) {
    this.$cookies = $cookies;
  }
  get() {
    return this.$cookies.get('token');
  }
  set(token) {
    this.$cookies.put('token', token);
  }
  delete() {
    this.$cookies.remove('token');
  }
  isPresent() {
    return !!this.$cookies.get('token');
  }
}

Auth.$inject = ['$cookies'];

import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

class DenialReasonController {
  constructor(DenialReasonService, $stateParams, RenewalRequestService) {
    this.DenialReasonService = DenialReasonService;
    this.RenewalRequestService = RenewalRequestService;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.denialReasons = [];
    this.customChoices = [];
  }
  $onInit() {
    this.loadingReasons = true;
    this.DenialReasonService.getReasonsFor(this.patientId).then(response => {
      this.denialReasons = [...this.denialReasons, ...response];
      this.customChoices = this.denialReasons;
      this.loadingReasons = false;
    });
  }
  refreshChoices(search) {
    this.DenialReasonService.customComplete(
      search,
      this.customChoices,
      this.denialReasons,
    );
    this.customChoices = [...this.customChoices, this.denialReasons];
  }
  resetAndClose() {
    this.renewal.denial_reason = {};
    this.RenewalRequestService.updateRenewal(this.renewal).then(response => {
      this.drawer.close();
      return { ...this.renewal, response };
    });
  }
}
export const omDenialReason = {
  bindings: {
    renewal: '<',
    form: '<',
    deny: '&',
  },
  require: {
    drawer: '^omDrawer',
  },
  templateUrl: 'renewals/denial-reason.html',
  controller: DenialReasonController,
};

DenialReasonController.$inject = [
  'DenialReasonService',
  '$stateParams',
  'RenewalRequestService',
];

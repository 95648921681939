class DeleteMedication {
  $onInit() {
    this.pendingCheckout = this.pendingCheckout || false;
  }
}
export const omDeleteMedication = {
  bindings: {
    pendingCheckout: '<',
    patientName: '<',
    medicationName: '<',
    onDelete: '&',
  },
  controller: DeleteMedication,
  templateUrl: 'medications/delete-medication.component.html',
};

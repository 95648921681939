class MultiSelectController {
  BACKSPACE_KEY = 8;

  constructor($element) {
    this.$element = $element;
    this.selected = '';
  }

  focusOnInput() {
    this.$element.find('.multi-select-input').focus();
  }

  handleSelection(option) {
    if (this.ngModel.$modelValue.find(value => value.id === option.id) == null) {
      const newValue = _.clone(this.ngModel.$modelValue);
      newValue.push(option);
      this.ngModel.$setViewValue(newValue);
      this.onAdd();
    }
    this.selected = '';
  }

  removeItem(option) {
    const newValue = _.clone(this.ngModel.$modelValue);
    _.remove(newValue, value => value.id === option.id);
    this.ngModel.$setViewValue(newValue);
    this.onRemove();
  }

  deleteIfEmpty(event) {
    if (event.keyCode !== this.BACKSPACE_KEY) { return; }
    if (this.selected.length === 0 && this.ngModel.$modelValue.length > 0) {
      const newValue = _.clone(this.ngModel.$modelValue);
      newValue.pop();
      this.ngModel.$setViewValue(newValue);
      this.onRemove();
    }
  }
}

MultiSelectController.$inject = ['$element'];

export const omMultiSelect = {
  bindings: {
    selectableValues: '<',
    onAdd: '&',
    onRemove: '&',
  },
  require: {
    ngModel: 'ngModel',
  },
  controller: MultiSelectController,
  templateUrl: 'shared/multi-select.component.html',
};

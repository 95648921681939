class SupportingDocumentController {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
  }

  delete(id) {
    this.$rootScope.$emit('remove-supporting-document', id);
  }
}

SupportingDocumentController.$inject = ['$rootScope'];

export const omSupportingDocument = {
  bindings: {
    document: '<',
    editable: '<',
  },
  controller: SupportingDocumentController,
  templateUrl: 'orders/shared/supporting-document.component.html',
};

const NON_NETWORK_PLAN_TYPES = {
  'PPO': 'wide',
  'PPO-HDHP': 'wide',
  'PPO-Narrow Network': 'narrow',
  'PPO-ACA': 'narrow',
  'GHIP-PPO': 'narrow',
}

const NETWORK_PLAN_TYPES = ['HMO', 'POS', 'EPO'];

const KNOWN_PLAN_TYPES = [ 'PPO', 'PPO-HDHP', 'PPO-Narrow Network', 'PPO-ACA', "GHIP-PPO", 'EPO', 'HMO', 'POS' ];

const OTHER_NETWORK_NAME = "Other";

const NONE_NETWORK_NAME = "None";

export class ConsultantNetworkService {
  getInsuranceNetworkStatus(patientPrimaryInsurance, contact) {
    this.contact = contact;
    this.patientPrimaryInsurance = patientPrimaryInsurance;
    this.setPatientInsuranceInfo();
    if (this.contact.is_complete === false) {
      return "Unknown";
    }
    if (!this.sufficientNetworkDataPresent()) {
      return 'Unknown';
    }
    if (!this.carrierMatch()) {
      return 'OON';
    }
    if (!this.planTypeIsKnown()) {
      return 'Unknown';
    }
    if (!this.planTypeUsesNetworks()) {
      if (this.isNarrowNetwork()) {
        return "Unknown";
      }
      return "In Network";
    }
    if (this.planHasNetwork()) {
      if (this.networkIsOther()) {
        return "Unknown";
      }
      if (this.networkMatch()) {
        return "In Network";
      }
      return "OON" ;
    }
    if (this.planAndCarrierMatch()) {
      return "In Network";
    }
    return "Unknown";
  }

  setPatientInsuranceInfo() {
    if (this.patientPrimaryInsurance) {
      this.patientInsuranceNetworkId = this.patientPrimaryInsurance.network_id;
      this.patientInsuranceCarrierId = this.patientPrimaryInsurance.carrier_id;
      this.patientInsuranceType = this.patientPrimaryInsurance.type;
      this.patientInsuranceNetwork = this.patientPrimaryInsurance.network;
    }
  }

  sufficientNetworkDataPresent() {
    const patientInsuranceInfo =
    this.patientInsuranceNetworkId || this.patientInsuranceCarrierId;
    const contactNetworks = this.contact.insurance_networks || [];
    const contactInsurances = this.contact.contact_insurances || [];
    const potentialContactMatches =
    contactNetworks.length + contactInsurances.length;
    return patientInsuranceInfo && potentialContactMatches > 0;
  }

  planTypeIsKnown() {
    return KNOWN_PLAN_TYPES.includes(this.patientInsuranceType);
  }

  carrierMatch() {
    return this.contact.contact_insurances.find(insurance => {
      let carrierId = insurance.insurance_carrier_id;
      if (insurance.insurance_carrier) {
        carrierId = insurance.insurance_carrier.id;
      }
      return carrierId === this.patientInsuranceCarrierId;
    });
  }

  planTypeUsesNetworks() {
    return (NETWORK_PLAN_TYPES.includes(this.patientInsuranceType));
  }

  isNarrowNetwork() {
    return (NON_NETWORK_PLAN_TYPES[this.patientInsuranceType] === 'narrow');
  }

  planHasNetwork() {
    const networkIsNull = !!(this.patientInsuranceNetwork === null);
    const networkIsUndefined = !!(this.patientInsuranceNetwork === undefined);
    const networkIsNone = !!(this.patientInsuranceNetwork === NONE_NETWORK_NAME);
    return !(networkIsNull || networkIsUndefined || networkIsNone);
  }

  planAndCarrierMatch() {
    return this.contact.contact_insurances.find(insurance => {
      if (!insurance.insurance_types) { return false; }

      const carrierTypeNames = insurance.insurance_types.map(type => type.name);
      let carrierId = insurance.insurance_carrier_id;

      if (insurance.insurance_carrier) {
        carrierId = insurance.insurance_carrier.id;
      }

      return (
        carrierId === this.patientInsuranceCarrierId &&
        carrierTypeNames.includes(this.patientInsuranceType)
        );
    });
  }

  networkIsOther() {
    return this.patientPrimaryInsurance.network === OTHER_NETWORK_NAME;
  }

  networkMatch() {
    return this.contact.insurance_networks.find(network =>
      network.id === this.patientInsuranceNetworkId);
  }
}

export const omCollapse = ($timeout, LoggerService) => ({
  restrict: 'AE',
  scope: {
    onCollapse: '@',
    onExpand: '@',
    disabledIf: '@',
    expandByDefault: '@',
  },
  bindToController: true,
  controllerAs: 'collapse',
  controller: [
    '$scope',
    '$attrs',
    '$q',
    '$element',
    function controller($scope, $attrs, $q, $element) {
      const ctrl = {
        disabledIf: this.disabledIf,
        expandByDefault: this.expandByDefault,
        onExpand: this.onExpand,
        onCollapse: this.onCollapse,
      };
      const name = $attrs.omCollapse || $attrs.name;
      const notifyAngularElastic = () =>
        $scope.$parent.$broadcast('elastic:adjust');

      ctrl.$onInit = () => {
        $element.addClass('om-animate');
        if (name) {
          _.set($scope.$parent, name, ctrl);
        }
        if ($scope.$parent.$eval(ctrl.expandByDefault)) {
          ctrl.expand();
        }
        $scope.$parent.$watch(ctrl.disabledIf, isDisabled => {
          if (isDisabled) {
            ctrl.isDisabled = true;
            $element.addClass('-disabled');
          } else {
            ctrl.isDisabled = false;
            $element.removeClass('-disabled');
          }
        });
        ctrl.expandedName = null;
      };

      ctrl.toggle = (expandedName = null) => {
        if (!ctrl.isDisabled) {
          if (ctrl.expanded) {
            ctrl.collapse();
          } else {
            ctrl.expand(expandedName);
          }
        }
      };

      ctrl.expand = (expandedName = null) => {
        if (ctrl.onExpand) {
          $scope.$parent.$eval(ctrl.onExpand);
        }
        $element.addClass('om-collapse-expanded');
        $timeout(notifyAngularElastic);
        ctrl.expanded = true;

        if (ctrl.expandedName) {
          LoggerService.error(
            `expandedName was already set to ${
              ctrl.expandedName
            }, parent controller is:`,
            $scope.$parent.$ctrl,
          );
        }
        if (expandedName) {
          ctrl.expandedName = expandedName;
        }
      };

      ctrl.collapse = () => {
        if (ctrl.onCollapse) {
          $scope.$parent.$eval(ctrl.onCollapse);
        }
        $element.removeClass('om-collapse-expanded');
        ctrl.expanded = false;
        ctrl.expandedName = null;
      };

      ctrl.isExpanded = (expandedName = null) =>
        expandedName === ctrl.expandedName && ctrl.expanded;
      ctrl.isCollapsed = () => !ctrl.expanded;

      return ctrl;
    },
  ],
});

omCollapse.$inject = ['$timeout', 'LoggerService'];

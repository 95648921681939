import { omAutosave } from './autosave.directive';
import { omAutosaveCompleteButton } from './autosave-complete-button.directive';
import { omAutosaveErrorMessage } from './autosave-error-message.directive';
import { omAutosaveSavingMessage } from './autosave-saving-message.directive';
import { omForm } from './form.directive';
import { omFormCancel } from './form-cancel.directive';
import { omFormSpinnerIcon } from './form-spinner-icon.directive';
import { omSubForm } from './subform.directive';

angular
  .module('onelifeUi.shared.form', [])
  .directive('omAutosave', omAutosave)
  .directive('omAutosaveCompleteButton', omAutosaveCompleteButton)
  .directive('omAutosaveErrorMessage', omAutosaveErrorMessage)
  .directive('omAutosaveSavingMessage', omAutosaveSavingMessage)
  .directive('omForm', omForm)
  .directive('omFormCancel', omFormCancel)
  .directive('omFormSpinnerIcon', omFormSpinnerIcon)
  .directive('omSubForm', omSubForm);

import { ConsultOrder } from './consult-order';
import { ConsultOrderService } from './consult-order.service';
import { omConsultOrderForm } from './consult-order-form.component';
import { omConsultOrderListItem } from './consult-order-list-item.component';

angular
  .module('onelifeUi.orders.consult', ['onelifeUi.shared'])
  .component('omConsultOrderForm', omConsultOrderForm)
  .component('omConsultOrderListItem', omConsultOrderListItem)
  .factory('ConsultOrder', () => ConsultOrder)
  .factory('ConsultOrderService', ConsultOrderService);

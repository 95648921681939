class CommentsController {
  constructor(CommentService) {
    this.CommentService = CommentService;
  }

  $onInit() {
    this.loadComments();
  }

  $onChanges() {
    this.loadComments();
  }

  loadComments() {
    if (this.commentable.total_comments > 0) {
      this.commentable.loadingComments = true;
      this.getComments(this.commentable).then(comments => {
        this.commentable.comments = comments;
        this.commentable.loadingComments = false;
      });
    } else {
      this.commentable.comments = [];
    }
    this.buildComment();
  }

  createComment() {
    if (
      this.commentable.newComment.body &&
      this.commentable.newComment.body.length > 0
    ) {
      this.saveComment(this.commentable).then(comment => {
        this.commentable.comments.push(comment);
        this.commentable.total_comments += 1;
      });

      this.buildComment();
    }
  }

  deleteComment(id) {
    this.CommentService.delete(id).then(() => {
      this.commentable.comments = _.reject(this.commentable.comments, { id });
      this.commentable.total_comments -= 1;
    });
  }

  buildComment() {
    this.commentable.newComment = {};
  }
}

CommentsController.$inject = ['CommentService'];

export const omComments = {
  bindings: {
    commentable: '<',
    saveComment: '<',
    getComments: '<',
  },
  templateUrl: 'shared/comments.component.html',
  controller: CommentsController,
};

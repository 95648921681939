class PrescriptionHistory {
  constructor(MedicationService, Medication, emphasizeFilter) {
    this.MedicationService = MedicationService;
    this.Medication = Medication;
    this.fullHistory = [];
    this.shortHistory = [];
    this.additionalRecords = [];
    this.emptyHistory = false;
    this.loadingHistory = true;
    this.emphasizeFilter = emphasizeFilter;
  }
  $onInit() {
    this.MedicationService.prescriptionHistory(this.regimenId).then(
      response => {
        this.loadingHistory = false;
        this.fullHistory = response
          .map(rx => new this.Medication(rx))
          .sort((a, b) => new Date(b.display_date) - new Date(a.display_date));
        if (this.fullHistory.length > 4) {
          this.shortHistory = this.fullHistory.slice(0, 4);
          this.additionalRecords = this.fullHistory.slice(4);
        } else {
          this.shortHistory = this.fullHistory;
        }
        this.emptyHistory =
          !this.fullHistory ||
          (this.fullHistory && this.fullHistory.length === 0);
      },
    );
  }
}

PrescriptionHistory.$inject = [
  'MedicationService',
  'Medication',
  'emphasizeFilter',
];

export const omPrescriptionHistory = {
  controller: PrescriptionHistory,
  templateUrl: 'medications/prescription-history.component.html',
  require: {
    medicationsList: '^^omPatientMedicationsList',
  },
  bindings: {
    regimenId: '<',
  },
};

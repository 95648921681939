export class ProcedureOrder {
  constructor(attributes = { supporting_documents: [] }) {
    if (attributes.created_at)
      attributes.created_at = moment(attributes.created_at);

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  addIndication(indication) {
    this.indication = indication;
  }

  removeIndication(indication) {
    if (this.indication === indication) {
      delete this.indication;
    }
  }

  canRequestReviewOnCompletion() {
    return !!this.is_internal;
  }
}

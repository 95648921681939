export class DenialReasonService {
  constructor(ApiService, CustomComplete) {
    this.ApiService = ApiService;
    this.CustomComplete = CustomComplete;
  }
  getReasonsFor(patientId) {
    const ENDPOINT = `/v2/admin/patients/${patientId}/refill_requests/denial_reasons`;
    return this.ApiService.get(ENDPOINT);
  }
  customComplete(search, filteredList, fullList) {
    return this.CustomComplete({
      fullList,
      filteredList,
      limit: 20,
      defaults: { code: null },
      key: 'description',
      search,
    });
  }
}

DenialReasonService.$inject = ['ApiService', 'CustomComplete'];

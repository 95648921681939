class ProcedureTypeController {
  constructor(ENV, $analytics, marked) {
    this.$analytics = $analytics;
    this.procedureTypesIndex = ENV.search.indexes.procedure_types;
    this.procedureLateralities = ['Left', 'Right', 'Bilateral'];
    this.procedurePatientPregnancies = ['pregnant', 'not pregnant'];
    this.marked = marked;
  }

  _trackFieldChanged(field) {
    this.$analytics.eventTrack(
      'Order Field Changed',
      this.getAnalyticsProperties(field),
    );
  }

  getAnalyticsProperties(subComponent) {
    let formType = '';

    if (this.order.status === 'unsigned') {
      formType = 'Sign Form';
    } else if (this.order.status === 'signed') {
      formType = 'Approve Form';
    } else if (this.order.status === 'sent') {
      formType = 'Complete Form';
    } else if (this.order.status === 'completed') {
      formType = 'Completed Form';
    }

    return {
      workflow: 'Charting',
      component: 'Orders',
      order_type: 'Procedure Order',
      order_subtype: this.order.is_internal ? 'Internal Procedure' : 'External Procedure',
      form_type: formType,
      subComponent,
      order_id: this.order.id,
    };
  }
}

ProcedureTypeController.$inject = ['ENV', '$analytics', 'marked'];

export const omProcedureType = {
  bindings: {
    order: '<',
    editable: '<',
    patient: '<',
    updateOrder: '&',
    isInternalProcedureOrderEnabled: '<',
  },
  templateUrl: 'orders/shared/procedure-type.component.html',
  controller: ProcedureTypeController,
};

import { AppointmentTypeService } from './appointment-type.service';
import { FollowUpOrderService } from './follow-up-order.service';
import { FollowUpProvidersService } from './follow-up-providers.service';
import { omBasicFollowUp } from './basic-follow-up.component';
import { omDueDate } from './due-date.component';
import { omFollowUp } from './follow-up.component';
import { omFollowUpListItem } from './follow-up-list-item.component';
import { omVisitFollowUp } from './visit-follow-up.component';
import { omGenericFollowUp } from './generic-follow-up.component';
import { ProblemsFormatterService } from './problems-formatter.service';
import { ReasonDetailService } from './reason-detail.service';
import { SurveyService } from './survey.service';
import { FOLLOW_UP_ORDER_TYPES } from './follow-up-order-types.constant';

angular
  .module('onelifeUi.orders.followUp', [])
  .component('omBasicFollowUp', omBasicFollowUp)
  .component('omDueDate', omDueDate)
  .component('omFollowUp', omFollowUp)
  .component('omFollowUpListItem', omFollowUpListItem)
  .component('omVisitFollowUp', omVisitFollowUp)
  .component('omGenericFollowUp', omGenericFollowUp)
  .factory('AppointmentTypeService', AppointmentTypeService)
  .factory('FollowUpOrderService', FollowUpOrderService)
  .factory('FollowUpProvidersService', FollowUpProvidersService)
  .factory('ProblemsFormatterService', ProblemsFormatterService)
  .factory('ReasonDetailService', ReasonDetailService)
  .factory('SurveyService', SurveyService)
  .constant('FOLLOW_UP_ORDER_TYPES', FOLLOW_UP_ORDER_TYPES);

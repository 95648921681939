import { AuthProvider } from './auth.provider';
import { Auth } from './auth-token';
import { TokenInterceptor } from './token.interceptor';
import { UnauthorizedInterceptor } from './unauthorized.interceptor';

angular
  .module('om.auth', ['ngCookies'])
  .provider('$auth', AuthProvider)
  .service('authToken', Auth)
  .factory('tokenInterceptor', TokenInterceptor)
  .factory('unauthorizedInterceptor', UnauthorizedInterceptor);

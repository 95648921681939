export class TimelineItem {
  constructor(attributes) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });

    this.timelineListItemAuthor = this._setTimelineListItemAuthor();
  }

  isDraftLetter() {
    return this.note_type === "Official Letter" && !this.signed;
  }

  isGeneratedLetter() {
    return this.note_type === "Official Letter" && this.signed;
  }

  isAttachable() {
    return this.doctype === 'note' && this.signed && !this.hasCorruptDocuments() && this.is_protected !== true;
  }

  isIncludedInDocumentList(documentList) {
    return documentList.some(({ id }) => id === this.database_id);
  }

  isDraft() {
    return !!(this.draft || this._hasDraftComments());
  }

  hasCorruptDocuments() {
    const corruptDocuments = this.documents ? this.documents.filter(document => document.is_corrupt): [];
    return corruptDocuments.length > 0;
  }

  _hasDraftComments() {
    return this.comments && this.comments.some(comment => comment.draft);
  }

  _hasUsableSignedByDetails() {
    return this.signed_by_details && this.signed_by_details.first_name && this.signed_by !== 'Internal User';
  }

  _hasUsableAuthorDetails() {
    return this.author_details && this.author_details.first_name && this.author !== 'Internal User'
  }

  _firstInitialLastFromFull(name) {
    if (!name || name === 'Internal User') {
      return name;
    }
    const split = name.split(' ');
    if (!split[1]) {
      return name;
    }
    split[0] = `${split[0].charAt(0)}.`
    const firstInitialLastAll = split.join(' ');
    return firstInitialLastAll.split(',')[0];
  }

  _setTimelineListItemAuthor() {
    let displayAuthor;

    if (this.doctype === 'patient_timeline_post') {
      displayAuthor = this._timelineListPostAuthor();
    } else if (this.signed === true) {
      if (this._hasUsableSignedByDetails()) {
        displayAuthor = `${this.signed_by_details.first_name.slice(0, 1)}. ${this.signed_by_details.last_name}`
      } else {
        displayAuthor = this._firstInitialLastFromFull(this.signed_by);
      }
    } else if (this.signed === false) {
      if (this._hasUsableAuthorDetails()) {
        displayAuthor = `${this.author_details.first_name.slice(0, 1)}. ${this.author_details.last_name}`
      } else {
        displayAuthor = this._firstInitialLastFromFull(this.author);
      }
    } else {
      displayAuthor = this._firstInitialLastFromFull(this.author);
    }

    return displayAuthor;
  }

  _timelineListPostAuthor() {
    let displayAuthor;

    if (this.draft === true) {
      displayAuthor = null;
    } else if (this.author_is_patient === true) {
      displayAuthor = 'Patient';
    } else if (this._hasUsableAuthorDetails()) {
      displayAuthor = `${this.author_details.first_name.slice(0, 1)}. ${this.author_details.last_name}`
    } else {
      displayAuthor = this._firstInitialLastFromFull(this.author);
    }

    return displayAuthor;
  }
}

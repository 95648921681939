export class FuzzyDate {
  constructor() {
    this.precisionMap = {
      1: 'year',
      2: 'month',
      3: 'day',
    };

    this.fuzzyDateRegex = /^(\d{2}\/)?(\d{2}\/)?\d{4}$/;
  }

  isValidDate(dateStr) {
    return this.fuzzyDateRegex.test(dateStr);
  }

  getDateWithTimeZone(dateStr) {
    const precision = this.precisionMap[dateStr.split('/').map(elem => parseInt(elem, 10)).length];
    const date = moment(dateStr, ['MM/DD/YYYY', 'MM/YYYY', 'YYYY'], true).format('YYYY-MM-DD');

    return { date_fuzzy: date, date_precision: precision };
  }
}

import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

const procedureOrdersPath = '/v2/admin/procedure_orders';
const patientsPath = '/v2/admin/patients';

export class ProcedureOrderService {
  constructor($stateParams, $rootScope, ApiService, ProcedureOrder) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.ProcedureOrder = ProcedureOrder;
  }

  get(procedureOrderId) {
    return this.ApiService.get(
      `${procedureOrdersPath}/${procedureOrderId}`,
    ).then(response => new this.ProcedureOrder(response));
  }

  create() {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    return this.ApiService.save(
      `${patientsPath}/${patientId}/procedure_orders`,
      new this.ProcedureOrder(),
    ).then(response => new this.ProcedureOrder(response));
  }

  clone(procedureOrderId) {
    return this.ApiService.save(
      `${procedureOrdersPath}/${procedureOrderId}/clone`,
      {},
    ).then(response => new this.ProcedureOrder(response));
  }

  update(procedureOrder) {
    return this.ApiService.update(
      `${procedureOrdersPath}/${procedureOrder.id}`,
      procedureOrder,
    ).then(response => new this.ProcedureOrder(response));
  }

  delete(procedureOrderId) {
    return this.ApiService.delete(`${procedureOrdersPath}/${procedureOrderId}`);
  }
}

ProcedureOrderService.$inject = [
  '$stateParams',
  '$rootScope',
  'ApiService',
  'ProcedureOrder',
];

export class Pharmacy {
  constructor(attributes = {}) {
    const {
      pharmacy_brand_name: brandName,
      display_name: displayName,
      name: fullName,
    } = attributes;

    if (attributes.address)
      attributes.map = {
        center: {
          latitude: attributes.address.latitude,
          longitude: attributes.address.longitude,
        },
        zoom: 18,
      };
    if (!brandName) attributes.display_name = fullName;
    if (!displayName && brandName) attributes.display_name = brandName;
    attributes.displayFullName = attributes.display_name !== attributes.name;
    attributes.services = this.transformServiceLevelsIntoKeyValuePairs(
      attributes.ss_service_levels || [],
    );

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }
  transformServiceLevelsIntoKeyValuePairs(services) {
    return services.reduce(
      (service, { keyword, description }) => ({
        ...service,
        [_.camelCase(keyword)]: description,
      }),
      {},
    );
  }
}

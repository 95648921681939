class RenewalItemDetail {
  constructor(RenewalRequestService, CommentService, Renewal, RefillRequest) {
    this.RenewalRequestService = RenewalRequestService;
    this.CommentService = CommentService;
    this.Renewal = Renewal;
    this.RefillRequest = RefillRequest;
    this.parent = {};
    this.renewal = {};
    this.userId = {};
    this.showMoreOptionsToggle = true;
    this.showMoreOptions = true;
  }
  $onInit() {
    this.renewal = this.parent.renewal;
    this.totalCount = this.parent.totalCount;
    this.$index = this.parent.$index;
    this.userId = this.parent.profile.id;
    this.epcsEulaMissing = this.parent.profile.epcs_eula_missing;
    this.canSignOnBehalfOf = this.parent.canSignOnBehalfOf;
    this.showSignOnBehalfOfWarning = !this.canSignOnBehalfOf;

    this.showMoreOptionsToggle =
      !this.renewal.dispensable_restricted_controlled_substance &&
      !this.renewal.requires_note_of_medical_need;

    this.showMoreOptions =
      !!(
        this.renewal.notes_to_pharmacist ||
        this.renewal.dispense_as_written ||
        this.renewal.earliest_fill_date
      ) && this.showMoreOptionsToggle;

    this.showDenialReason =
      this.renewal.isDenied() ||
      (!!this.renewal.denial_reason &&
        !!this.renewal.denial_reason.description);

    this._setCurrentUserIsValidPrescriber();
    this._setDefaultPrescriber();
    this.loadDetails();
  }
  getComments(renewal) {
    return this.CommentService.getRenewalComments(renewal);
  }
  saveComment(renewal) {
    return this.CommentService.saveRenewalComment(renewal);
  }
  toggleScrolling(isOpen = false) {
    this.renewalList.scrollingEnabled = !isOpen;
  }
  toggleShowMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
  }

  _setCurrentUserIsValidPrescriber() {
    const matchedPrescriber = this.renewal.valid_prescribers.find(
      prescriber => this.userId === prescriber.id,
    );
    this.renewal.currentUserIsValidPrescriber = matchedPrescriber !== undefined;
  }

  _setDefaultPrescriber() {
    if (this.renewal.cart_state === 'new') {
      if (this.renewal.currentUserIsValidPrescriber) {
        this.renewal.prescriber_id = this.userId;
      } else {
        this.renewal.prescriber = null;
        this.renewal.prescriber_id = null;
      }
    }
  }
  updatePrescriber() {
    const request = this.RenewalRequestService.updateRenewal(this.renewal);

    request.then(response => {
      _.merge(this.renewal, response);
      this.renewal.prescriber = this.renewal.valid_prescribers.find(
        prescriber => prescriber.id === this.renewal.prescriber_id,
      );
      if (this.renewal.prescriber) {
        this.getUpdatedPrescribingCredentials();
      }

      return this.renewal;
    });

    this.addPendingRequest(request);
  }

  getUpdatedPrescribingCredentials() {
    this.renewal.setCredentials();

    const request = this.RenewalRequestService.prescribingCredentialsFor(
      this.renewal,
    );

    this.addPendingRequest(request);
  }

  next() {
    if (this.parent.isLast) this.detailList.unsetCurrent();
    else {
      this.detailList.next();
    }
  }

  loadDetails() {
    if (this.renewal.prescriber && !this.renewal.prescriber_id) {
      this.renewal.prescriber_id = this.renewal.prescriber.id;
    }
    this.updatePrescriber();
    this.RefillRequest.getTodo(this.renewal);
  }

  approve() {
    const updates = {
      id: this.renewal.id,
      cart_state: 'prescribe',
      class_name: this.renewal.class_name,
    };

    this.updateCart(updates);
  }

  approveDisabled() {
    return (
      !this.canSignOnBehalfOf ||
      !this.renewal.canCheckout ||
      this.renewalList.pendingRequests.inProgress
    );
  }

  denyDisabled() {
    return (
      (!this.renewal.currentUserIsValidPrescriber &&
        this.renewal.dispensable_restricted_controlled_substance) ||
      this.renewalList.pendingRequests.inProgress
    );
  }

  deny() {
    const updates = {
      id: this.renewal.id,
      class_name: this.renewal.class_name,
      cart_state: 'do_not_prescribe',
      denial_reason: this.renewal.denial_reason,
    };
    this.updateCart(updates);
  }

  updateCart(updates) {
    const request = this.RenewalRequestService.updateRenewal(updates);
    this.renewalList.pullFromPending(this.renewal);

    request.then(response => {
      if (response.require_2fa)
        this.renewalList.cart.require_2fa = response.require_2fa;
      _.merge(this.renewal, response);
      this.renewalList.addToCheckout(this.renewal);
      this.renewalList.checkSiblings(this.renewal);
      this.next();
    });

    this.addPendingRequest(request);
  }

  addPendingRequest(request) {
    this.renewalList.pendingRequests.add(request);
  }
}

RenewalItemDetail.$inject = [
  'RenewalRequestService',
  'CommentService',
  'Renewal',
  'RefillRequest',
];

export const omRenewalItemDetail = {
  controller: RenewalItemDetail,
  require: {
    parent: '^omRenewalItem',
    detailList: '^^omDetailList',
    renewalList: '^^omRenewalList',
  },
  templateUrl: 'renewals/renewal-item-detail.component.html',
};

export class ReasonDetailService {
  buildDetails(problems) {
    if (problems.length === 0) {
      return "We'd like to follow up with you about...";
    }

    let details = "We'd like to follow up with you about your ";
    const problemList = [];

    problems.forEach((problem, index) => {
      let problemDescription =
        problem.problem_type && problem.problem_type.lay_description
          ? problem.problem_type.lay_description
          : problem.lay_description;
      if (problems.length > 1 && index === problems.length - 1) {
        problemDescription = `and ${problemDescription}`;
      }
      problemList.push(problemDescription);
    });

    if (problemList.length > 2) {
      details += problemList.join(', ');
    } else {
      details += problemList.join(' ');
    }

    details += '.';
    return details;
  }
}

export class Profile {
  constructor(attributes = {}) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
    if (attributes.identities) {
      this.primaryIdentity =
        attributes.identities.find(identity => identity.primary) ||
        attributes.identities[0];
    }
  }

  hasRole(role) {
    return this.roles.includes(role);
  }
}

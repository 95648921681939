class RedactButtonMenuItemController {
  constructor($scope) {
    this.$scope = $scope;
  }

  openModal(modal) {
    this.modal = modal;
    modal.open();
  }

  redactOrder() {
    this.$scope.$emit('redact-order');
    this.modal.close();
  }
}

RedactButtonMenuItemController.$inject = ['$scope'];

export const omRedactButtonMenuItem = {
  bindings: {
    asButton: '<',
  },
  controller: RedactButtonMenuItemController,
  templateUrl: 'orders/shared/redact-button-menu-item.component.html',
};

import { TimelineItem } from './timeline-item';
import { omTimeline } from './timeline.component';
import { omTimelineDetail } from './detail.component';
import { omTransferTimelineItem } from './transfer-timeline-item.component';
import { omAttachTimelineItem } from './attach-timeline-item.component';
import { omTimelineList } from './timeline-list.component';
import { omTimelineListItem } from './timeline-list-item.component';

angular
  .module('onelifeUi.timeline', [])
  .component('omTimelineDetail', omTimelineDetail)
  .component('omTransferTimelineItem', omTransferTimelineItem)
  .component('omAttachTimelineItem', omAttachTimelineItem)
  .component('omTimelineList', omTimelineList)
  .component('omTimelineListItem', omTimelineListItem)
  .component('omTimeline', omTimeline)
  .factory('TimelineItem', () => TimelineItem);

export const omSwapIcons = () => ({
  scope: {
    originalIcon: '@',
    iconToSwap: '@',
  },
  link(scope, element) {
    element.addClass(`${scope.originalIcon}`);

    element.on('mouseenter', () => {
      element.removeClass(`${scope.originalIcon}`);
      element.addClass(`om-icon ${scope.iconToSwap}`);
    });

    element.on('mouseleave', () => {
      element.removeClass(`om-icon ${scope.iconToSwap}`);
      element.addClass(`${scope.originalIcon}`);
    });
  },
});

import { ProcedureOrder } from './procedure-order';
import { ProcedureOrderService } from './procedure-order.service';
import { omProcedureOrder } from './procedure-order.component';
import { omProcedureOrderListItem } from './procedure-order-list-item.component';
import { procedureHighlight } from './procedure-highlight.filter';

angular
  .module('onelifeUi.orders.procedure', [])
  .component('omProcedureOrder', omProcedureOrder)
  .factory('ProcedureOrder', () => ProcedureOrder)
  .component('omProcedureOrderListItem', omProcedureOrderListItem)
  .filter('procedureHighlight', procedureHighlight)
  .service('ProcedureOrderService', ProcedureOrderService);

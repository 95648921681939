const LAB_ORDER_SET_ENDPOINT = '/v2/admin/lab_order_sets';
export class LabOrderSetService {
  constructor(ApiService) {
    this.ApiService = ApiService;
  }

  getAll() {
    return this.ApiService.get(LAB_ORDER_SET_ENDPOINT);
  }
}

LabOrderSetService.$inject = ['ApiService'];

const profilePath = '/v2/admin/profile';
export class ProfileService {
  constructor(ApiService, Profile) {
    this.ApiService = ApiService;
    this.Profile = Profile;
  }

  get() {
    return this.ApiService.get(profilePath, { cache: true }).then(data => new this.Profile({ ...data, primary: data.identities.find(i => i.primary) }));
  }
}

ProfileService.$inject = ['ApiService', 'Profile'];

class DrawerController {
  constructor() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
}

export const omDrawer = () => ({
  restrict: 'E',
  controller: DrawerController,
  controllerAs: 'drawerCtrl',
  transclude: true,
  replace: true,
  template:
    '<div class="om-drawer" ng-transclude om-layout om-layout-align="space-between center"></div>',
});

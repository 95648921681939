export class Summary {
  constructor(attributes = {}) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
    this.no_significant_medical_history = this.noSignificantMedicalHistory();
  }

  _findProblem(problems, problem) {
    return _.find(problems, { id: problem.id });
  }

  updateMedicalHistory(attributes) {
    this.active_problems = attributes.active_problems;
    this.resolved_problems = attributes.resolved_problems;
    this.medical_history = attributes.medical_history;
    this.no_significant_medical_history = this.noSignificantMedicalHistory();
  }

  bmi() {
    let { wt, ht } = this.measurements;

    if (wt) {
      wt = wt.value;
    }
    if (ht) {
      ht = ht.value;
    }
    if (ht && wt) {
      return wt / (ht * ht) * 703;
    }
    return null;
  }

  hasAssessedProblem(problem) {
    return !!this._findProblem(this.assessed_problems, problem);
  }

  noSignificantMedicalHistory() {
    const activeProblemsLength = this.active_problems
      ? this.active_problems.length
      : 0;
    const resolvedProblemsLength = this.resolved_problems
      ? this.resolved_problems.length
      : 0;
    return activeProblemsLength + resolvedProblemsLength === 0;
  }

  addAddendum(addendum) {
    this.summary_addendums.push(addendum);
  }
}

Summary.$inject = ['$resource', '$rootScope', '$stateParams', '$analytics'];

const specialtiesPath = '/v2/admin/specialties';

export class SpecialtiesService {
  constructor($stateParams, $rootScope, ApiService) {
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.ApiService = ApiService;
    this.allSpecialties = [];
  }

  index() {
    if (this.allSpecialties.length === 0) {
      this.ApiService.get(`${specialtiesPath}`).then(response => {
        this.allSpecialties = response;
      });
    }
  }
}

SpecialtiesService.$inject = ['$stateParams', '$rootScope', 'ApiService'];

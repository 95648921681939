class TimelineDetailController {
  constructor($rootScope, isPdfDocument, LabDataService, isMiscTask, marked) {
    this.$rootScope = $rootScope;
    this.isPdfDocument = isPdfDocument;
    this.LabDataService = LabDataService;
    this.isWide = false;
    this.isMiscTask = isMiscTask;
    this.marked = marked;
  }

  $onInit() {
    this.parsedPostText = this.getParsedMarkdown(
      this.timelineItem.content ? this.timelineItem.content.text : '',
    );
    this.parsedComments = (this.timelineItem.comments || []).map(comment =>
      this.getParsedMarkdown(comment.text),
    );
    this.combinedHistory = this.combineTransmissionHistory();
    this.hideNoteBody = this.timelineItem.isGeneratedLetter();
    this.noteTypeIsMiscTask = this.setNoteTypeIsMiscTask();
    return this.LabDataService.getFor(this.timelineItem);
  }

  timelineItemHasDocuments = () =>
    !!this.timelineItem.documents && this.timelineItem.documents.length > 0;

  setShowWide(expanded) {
    this.isWide = expanded;
    this.$rootScope.$emit('expandTimeline', expanded);
  }

  setNoteTypeIsMiscTask() {
    return this.isMiscTask(this.timelineItem.note_type);
  }

  getParsedMarkdown(text) {
    if (text) {
      // this is to fix an issue with unicode parsing of emails in angle brackets (#159667767)
      const unbracketed = text.replace(/\\</g, '&lt;').replace(/\\>/g, '&gt;');
      return this.marked(unbracketed);
    }
    return null;
  }

  combineTransmissionHistory() {
    const combinedHistory = [];
    if (this.timelineItem.faxes && this.timelineItem.faxes.length > 0) {
      this.timelineItem.faxes.forEach(fax => {
        const item = fax;
        item.transmission_type = 'fax';
        item.transmission_type_display = 'Fax';
        combinedHistory.push(item);
      });
    }
    return combinedHistory.sort(
      (a, b) => (a.created_at > b.created_at ? 1 : -1),
    );
  }

  isAttached() {
    return this.timelineItem.isIncludedInDocumentList(
      this.supportingDocumentsAttached,
    );
  }
}

TimelineDetailController.$inject = [
  '$rootScope',
  'isPdfDocument',
  'LabDataService',
  'isMiscTask',
  'marked',
];

export const omTimelineDetail = {
  templateUrl: 'timeline/detail.component.html',
  controller: TimelineDetailController,
  bindings: {
    timelineItem: '<',
    isSupportingDocumentsAttachable: '<',
    supportingDocumentsAttached: '<',
  },
  require: {
    timelineComponent: '^omTimeline',
  },
};

import './shared/shared.module';
import './consult/consult.module';
import './follow-up/follow-up.module';
import './lab/lab.module';
import './specialties/specialties.module';
import './survey/survey.module';
import './procedure/procedure.module';
import './vaccine/vaccine.module';
import { LabelPrinterService } from './label-printer.service';
import { MeasurementOrderTypeSourceService } from './measurement-order-type-source';
import { omActiveOrdersList } from './active-orders-list.component';
import { omInactiveOrdersList } from './inactive-orders-list.component';
import { omOrdersList } from './orders-list.component';
import { OrdersService } from './orders.service';

angular
  .module('onelifeUi.orders', [
    'onelifeUi.orders.shared',
    'onelifeUi.orders.consult',
    'onelifeUi.orders.followUp',
    'onelifeUi.patient',
    'onelifeUi.orders.lab',
    'onelifeUi.orders.survey',
    'onelifeUi.orders.specialties',
    'onelifeUi.orders.procedure',
    'onelifeUi.orders.vaccine',
  ])
  .component('omActiveOrdersList', omActiveOrdersList)
  .component('omInactiveOrdersList', omInactiveOrdersList)
  .component('omOrdersList', omOrdersList)
  .factory(
    'MeasurementOrderTypeSourceService',
    MeasurementOrderTypeSourceService,
  )
  .factory('OrdersService', OrdersService)
  .service('LabelPrinterService', LabelPrinterService);

class NewWorkspaceItem {
  constructor(
    $state,
    MessagingWarningsService,
    NoteService,
    NoteTypeService,
    PatientTimelinePostService,
    SummaryService,
    $q,
    $rootScope,
    $analytics,
  ) {
    this.$state = $state;
    this.MessagingWarningsService = MessagingWarningsService;
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.NoteService = NoteService;
    this.NoteTypeService = NoteTypeService;
    this.SummaryService = SummaryService;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$analytics = $analytics;
  }

  $onInit() {
    const getNoteTypesPromise = this.NoteTypeService.query();
    const getPatientPromise = this.MessagingWarningsService.getPatient();
    const getMessageWarningsPromise = this.MessagingWarningsService.messagingWarnings();

    return this.$q
    .all([
      getNoteTypesPromise,
      getPatientPromise,
      getMessageWarningsPromise,
    ])
    .then(([noteTypes, patient, errors]) => {
      this.miscTaskNoteTypes = noteTypes.misc_task_note_types;
      this.summaryNoteTypes = noteTypes.summary_note_types;
      if ('letter_note_type' in noteTypes) {
        this.letterNoteType = noteTypes.letter_note_type[0];
      }

      this.showMessagingMenuItem = true;
      this.patientAcceptsDigitalCommunications = patient.acceptsDigitalCommunications();

      this.errors = errors;
    });
  }

  newSummary(type) {
    return this.SummaryService.save({ note: { note_type_id: type.id } }).then(
      createdSummary => {
        this._trackNewSummary(createdSummary);
        this.$state.go('app.chart.workspace.summaries', {
          id: createdSummary.id,
        });
        this.$rootScope.$emit('refreshTimeline');
      },
    );
  }

  newMessage() {
    return this.PatientTimelinePostService.create().then(createdPost => {
      this._trackNewPatientTimelinePost(createdPost);
      this.$state.go('app.chart.workspace.patientTimelinePosts', {
        id: createdPost.id,
      });
    });
  }

  newMiscTask(type) {
    const params = { note_type_id: type.id };
    return this.NoteService.save(params).then(createdNote => {
      this._trackNewMiscTask(createdNote);
      this.$state.go('app.chart.workspace.notes', {
        id: createdNote.id,
      });
      this.$rootScope.$emit('refreshTimeline');
    });
  }

  newLetter() {
    const params = { note_type_id: this.letterNoteType.id };
    return this.NoteService.save(params).then(createdLetter => {
      this._trackNewLetter(createdLetter);
      this.$state.go('app.chart.workspace.letters', {
        id: createdLetter.id,
      });
      this.$rootScope.$emit('refreshTimeline');
    });
  }

  _trackNewSummary(createdSummary) {
    this.$analytics.eventTrack('Summary Created', {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: createdSummary.note_type.name,
      summary_id: createdSummary.id,
    });
  }

  _trackNewMiscTask(createdNote) {
    this.$analytics.eventTrack('Miscellaneous Note Created', {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: createdNote.note_type.name,
      note_id: createdNote.id,
    });
  }

  _trackNewPatientTimelinePost(createdPost) {
    this.$analytics.eventTrack('Patient Timeline Post Created', {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: 'Patient Timeline Post',
      patient_timeline_post_id: createdPost.id,
    });
  }

  _trackNewLetter(createdLetter) {
    this.$analytics.eventTrack('Letter Created', {
      workflow: 'Charting',
      component: 'Create New Workspace Item',
      subcomponent: createdLetter.note_type.name,
      note_id: createdLetter.id,
    });
  }
}

NewWorkspaceItem.$inject = [
  '$state',
  'MessagingWarningsService',
  'NoteService',
  'NoteTypeService',
  'PatientTimelinePostService',
  'SummaryService',
  '$q',
  '$rootScope',
  '$analytics',
];

export const omNewWorkspaceItem = {
  templateUrl: 'workspace/new-workspace-item.component.html',
  controller: NewWorkspaceItem,
};

export const omDetailListItem = () => ({
  restrict: 'E',
  require: '^^omDetailList',
  transclude: true,
  replace: true,
  template: `<div ng-show="(detailListCtrl.currentIndex === $index) || (detailListCtrl.currentIndex === null)"
                  class="om-detail-list-item" ng-transclude />`,
  link(scope, element, attrs, detailListCtrl) {
    detailListCtrl.listLength += 1;

    if (
      detailListCtrl.currentIndex !== null &&
      scope.$index <= detailListCtrl.currentIndex
    ) {
      detailListCtrl.currentIndex += 1;
    }

    return scope.$on('$destroy', () => {
      detailListCtrl.listLength -= 1;

      // if we've hit the end of the list, or the list is empty, go back to the list view
      if (detailListCtrl.listLength === 0 || scope.$last) {
        detailListCtrl.unsetCurrent();
      }
    });
  },
});

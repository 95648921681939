import { DenialReasonService } from './denial-reason.service';
import { RefillRequest } from './refill-request';
import { omRenewalCheckout } from './renewal-checkout.component';
import { Renewal } from './renewal';
import { RenewalRequestService } from './renewal-request.service';
import { omDenialReason } from './denial-reason.component';
import { omMismatchedPatientDetails } from './mismatched-patient-details.component';
import { omOriginalRx } from './original-rx.component';
import { omRenewalList } from './renewal-list.component';
import { omRenewalItem } from './renewal-item.component';
import { omRenewalItemDetail } from './renewal-item-detail.component';

angular
  .module('onelifeUi.renewals', [])
  .component('omMismatchedPatientDetails', omMismatchedPatientDetails)
  .component('omDenialReason', omDenialReason)
  .component('omOriginalRx', omOriginalRx)
  .component('omRenewalCheckout', omRenewalCheckout)
  .component('omRenewalList', omRenewalList)
  .component('omRenewalItem', omRenewalItem)
  .component('omRenewalItemDetail', omRenewalItemDetail)
  .factory('RefillRequest', RefillRequest)
  .factory('Renewal', () => Renewal)
  .service('DenialReasonService', DenialReasonService)
  .service('RenewalRequestService', RenewalRequestService);

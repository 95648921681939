export function UnauthorizedInterceptor($q, $injector) {
  return {
    responseError(response) {
      if (response.status === 401 && !response.config.ignore401) {
        $injector.get('$state').go('logout');
      }
      return $q.reject(response);
    },
  };
}

UnauthorizedInterceptor.$inject = ['$q', '$injector'];

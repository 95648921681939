export class CartCheckoutButton {
  constructor() {
    this.disableForNonProvider = false;
    this.saving = false;
    this.savingButtonText = 'Saving...';
    this.buttonText = 'Check Out';
  }
  $onInit() {
    if (this.renewalCheckout) this.renewalCheckout.$modal = this.modal;
    if (this.rxCheckout) this.rxCheckout.$modal = this.modal;
    this.disableForNonProvider = !this.isProvider;
  }
}

CartCheckoutButton.$inject = [];

export const omCartCheckoutButton = {
  require: {
    renewalCheckout: '?^^omRenewalCheckout',
    rxCheckout: '?^^checkoutCtrl',
  },
  bindings: {
    saving: '<',
    isProvider: '<',
    modal: '<',
    disabledCheckout: '&',
    onOpen: '&',
  },
  templateUrl: 'shared/cart-checkout-button.component.html',
  controller: CartCheckoutButton,
};

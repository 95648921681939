class TransferTimelineItemController {
  constructor($rootScope, $state, FeaturesService, $analytics) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.FeaturesService = FeaturesService;
    this.$analytics = $analytics;
  }

  $onInit() {
    this.FeaturesService.hasFeature('new_1life_generate_letter').then(featureEnabled => {
      this.generateLetterEnabled = featureEnabled;
    });
  }

  openTimelineItem = timelineItem => {
    if (timelineItem.lab_order_id) {
      this.$state.go('app.chart.orders.edit', {
        id: timelineItem.lab_order_id,
      });
      this.$rootScope.$emit('selectTab', 'Orders');
      this.trackLabOrderOpened(timelineItem);
    } else {
      const id = timelineItem.summary_id || timelineItem.database_id;
      const type = this.setTimelineItemType(timelineItem);

      this.$state.go('app.chart.workspace.'.concat(type), { id });
      this.$rootScope.$emit('selectTab', 'Workspace');
    }

    this.timelineComponent.unset(timelineItem);
  };

  setTimelineItemType(timelineItem) {
    if (this.generateLetterEnabled && timelineItem.isDraftLetter()) return 'letters';

    return timelineItem.summary_id ? 'summaries' : 'notes';
  }

  openPatientTimelinePost = timelineItem => {
    this.$state.go('app.chart.workspace.patientTimelinePosts', {
      id: timelineItem.database_id,
    });
    this.$rootScope.$emit('selectTab', 'Workspace');

    this.timelineComponent.unset(timelineItem);
  };

  trackLabOrderOpened(labOrderNote) {
    this.$analytics.eventTrack('Clinical Timeline Object Opened', {
      workflow: 'Charting',
      component: 'Clinical Timeline',
      subcomponent: 'Lab Order',
      note_id: labOrderNote.id,
      method: 'Push',
    });
  }
}

TransferTimelineItemController.$inject = ['$rootScope', '$state', 'FeaturesService', '$analytics'];

export const omTransferTimelineItem = {
  templateUrl: 'timeline/transfer-timeline-item.component.html',
  controller: TransferTimelineItemController,
  bindings: {
    timelineItem: '<',
    smallIcon: '<',
  },
  require: {
    timelineComponent: '^omTimeline',
  },
};

export const medicationRouteHighlight = () => (medicationRoute, query) => {
  const escapedQuery = _.escapeRegExp(query);
  const re = new RegExp(`(\\d|\\b){1}(${escapedQuery})`, 'i');

  const highlight = (text = '') =>
    text ? text.replace(re, '$1<strong>$2</strong>') : '';
  const appendSynonymIfExists = appendTo => {
    if (medicationRoute.generic_synonyms) {
      return `${appendTo} <em>${medicationRoute.generic_synonyms[0]}</em>`;
    }
    return appendTo;
  };
  const firstTagMatch = () => {
    if (medicationRoute.tags && medicationRoute.tags.length > 0) {
      return medicationRoute.tags.find(tag => re.test(tag));
    }
    return false;
  };

  if (re.test(medicationRoute.name)) {
    return appendSynonymIfExists(highlight(medicationRoute.name));
  } else if (firstTagMatch()) {
    return `${appendSynonymIfExists(`${medicationRoute.name}`)} (${highlight(
      firstTagMatch(),
    )})`;
  }
  return `${appendSynonymIfExists(`${medicationRoute.name}`)}`;
};

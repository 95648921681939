const TODAY = moment().toDate();
const DEFAULT_VACCINE = { pref_name: null, step_options: [] };

export class VaccineOrder {
  constructor(attributes = { vaccine: DEFAULT_VACCINE, date_due: TODAY }) {
    const { created_at: createdAt, date_due: dateDue, vaccine } = attributes;
    if (createdAt) attributes.created_at = moment(createdAt).toDate();
    if (dateDue) attributes.date_due = moment(dateDue).toDate();
    attributes.vaccine = vaccine || DEFAULT_VACCINE;

    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

export class ErrorInterceptorService {
  constructor($q, LoggerService, HttpStatusService) {
    this.$q = $q;
    this.LoggerService = LoggerService;
    this.HttpStatusService = HttpStatusService;
  }

  responseError = response => {
    let message = '';
    let subTitle = '';

    if (response.data && response.data.error) {
      message = response.data.error;
    } else if (response.data && response.data.message) {
      message = response.data.message;
    } else if (typeof response.data === 'string') {
      message = response.data;
    } else if (response.statusText) {
      message = response.statusText;
    } else if (response.status === 0) {
      message = `CORS error with url '${response.config.url}'`;
    } else {
      message = this.HttpStatusService.getStatusCodeText(response.status);
    }

    if (response.status !== 0) {
      subTitle = ['HTTP status', response.status].join(' ');
    }

    if (message) {
      const logMessage = [message, subTitle].join('\n');
      if (response.status >= 500)
        this.LoggerService.error(logMessage, response);
      else if (response.status !== 404)
        this.LoggerService.log(logMessage, response);
    }

    return this.$q.reject(response);
  };
}

ErrorInterceptorService.$inject = ['$q', 'LoggerService', 'HttpStatusService'];

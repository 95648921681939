class MoveMessageOptionController {
  constructor(
    toastr,
    PatientTimelinePostService,
    PatientService,
    $rootScope,
    $stateParams,
    $state,
  ) {
    this.toastr = toastr;
    this.PatientTimelinePostService = PatientTimelinePostService;
    this.PatientService = PatientService;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$modal = {};
  }

  $onInit() {
    this.newPatientUserInput = null;
    this.newPatient = null;
  }

  openMoveMessageModal(modal) {
    this.$modal = modal;
    this.$modal.open();
  }

  moveToAnotherChart() {
    if (this.newPatient) {
      this.post.new_patient_id = this.newPatient.id;

      this.PatientTimelinePostService.move(this.post).then(
        response => {
          this.toastr.success(response.message);
          this.$rootScope.$emit('refreshTimeline');
          this.$state.go('app.chart.workspace.newWorkspaceItem');
          this.$modal.close();
        },
        err => {
          const message = err.data.message;
          this.newPatientError = `Message transfer failed: ${message}`;
        },
      );
    } else {
      this.newPatientError =
        'We were unable to move the message. Please try again.';
    }
  }

  findNewPatient() {
    this.newPatient = null;
    this.newPatientError = null;
    const lookupId = this.extractLookupId();

    if (lookupId === this.$stateParams.patientId) {
      this.newPatientError =
        "Cannot move a message from a patient's timeline back to itself.";
    } else {
      this.PatientService.get(lookupId).then(
        newPatient => {
          this.newPatientError = null;
          this.newPatient = newPatient;
        },
        () => {
          this.newPatientError =
            'We were unable to find a patient with that ID';
        },
      );
    }
  }

  extractLookupId() {
    const chartUrlRegex = /patients\/(\d*)\/chart/;
    let lookupId = null;

    if (this.newPatientUserInput.match(/^\d*$/)) {
      lookupId = this.newPatientUserInput;
    } else {
      const urlMatch = this.newPatientUserInput.match(chartUrlRegex);
      if (urlMatch) {
        lookupId = urlMatch[1];
      }
    }

    return lookupId;
  }
}

MoveMessageOptionController.$inject = [
  'toastr',
  'PatientTimelinePostService',
  'PatientService',
  '$rootScope',
  '$stateParams',
  '$state',
];

export const omMoveMessageOption = {
  bindings: {
    post: '<',
  },
  controller: MoveMessageOptionController,
  templateUrl: 'messaging/move-message-option.component.html',
};

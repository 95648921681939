const API_ENDPOINT = '/v2/admin/refill_requests/';
export class RenewalRequestService {
  constructor(ApiService, Renewal, LoggerService) {
    this.ApiService = ApiService;
    this.Renewal = Renewal;
    this.LoggerService = LoggerService;
  }
  getRenewal({ id: renewalId }) {
    const request = this.ApiService.get(`${API_ENDPOINT}${renewalId}`);
    request.then(
      response => new this.Renewal(response),
      error =>
        this.LoggerService.log(
          'RenewalRequestService: getRenewal failed',
          error,
        ),
    );

    return request;
  }
  updateRenewal(renewal) {
    const request = this.ApiService.update(
      `${API_ENDPOINT}${renewal.id}`,
      renewal,
    );

    request.then(
      response => this._responseMinusProtectedFields(response),
      error =>
        this.LoggerService.log(
          `RenewalRequestService: updateRenewal failed`,
          error,
        ),
    );

    return request;
  }
  _responseMinusProtectedFields(response) {
    const protectedFields = [
      'approved_fills',
      'notes_to_pharmacist',
      'earliest_fill_date',
      'dispense_as_written',
    ];
    return _.omit(response, protectedFields);
  }
  prescribingCredentialsFor(renewal) {
    const params = { class_name: renewal.class_name, id: renewal.id };
    const request = this.ApiService.get(
      `${API_ENDPOINT}${renewal.id}/prescribing_credentials`,
      { params },
    );

    request.then(
      response => {
        renewal.setCredentials(response);
        return renewal;
      },
      error => {
        this.LoggerService.error(
          `RenewalRequestService: prescribingCredentialsFor ${
            params.class_name
          } ${params.id} failed`,
          error,
        );
      },
    );

    return request;
  }
  markReadyToSign(renewal) {
    return this.ApiService.get(`${API_ENDPOINT}${renewal.id}/ready_to_sign`, {
      params: renewal,
    });
  }
}

RenewalRequestService.$inject = ['ApiService', 'Renewal', 'LoggerService'];

import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';

class SurveyOrderListItemController {
  constructor(SurveyOrderService, $state, $stateParams) {
    this.SurveyOrderService = SurveyOrderService;
    this.$state = $state;
    this.isSurveyOrder = true;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
  }

  $onInit() {
    this.title = 'Survey Order';
    this.ordered_by = this.order.ordered_by;
    this.due_on = this.order.due_on;
  }

  isDeleteable() {
    return this.order.status === 'signed';
  }

  isDueDateVisible() {
    return this.isDeleteable();
  }

  editView() {
    this.$state.go('app.chart.orders.editSurveyOrder', {
      id: this.order.id,
    });
  }

  deleteOrder() {
    this.SurveyOrderService.delete(this.patientId, this.order);
    this.ordersComponent.remove(this.order);
  }
}

SurveyOrderListItemController.$inject = [
  'SurveyOrderService',
  '$state',
  '$stateParams',
];

export const omSurveyOrderListItem = {
  bindings: {
    order: '<',
  },
  require: {
    ordersComponent: '^omOrdersList',
  },
  controller: SurveyOrderListItemController,
  templateUrl: 'orders/order-list-item.component.html',
};

import { Medication } from './medication';
import { MedicationService } from './medication.service';
import { MedicationPrnService } from './medication-prn.service';
import { MedicationRouteService } from '../medications/medication-route.service';
import { PatientMedicationsService } from './patient-medications.service';
import { medicationRouteHighlight } from './medication-route-highlight.filter';
import { omMedicationForm } from './medication-form.component';
import { omPatientMedicationsList } from './patient-medications-list.component';
import { omPatientMedication } from './patient-medication.component';
import { MedicationPrn } from './medication-prn';
import { omPrnSelect } from './prn-select.component';
import { omPrescriptionHistory } from './prescription-history.component';
import { omDeleteMedication } from './delete-medication.component';
import { omRegimenHistory } from './regimen-history.component';
import { MedicationFrequencyIntervalService } from './medication-frequency-interval.service';
import { MedicationFrequencyInterval } from './medication-frequency-interval';

angular
  .module('onelifeUi.medications', [])
  .component('omMedicationForm', omMedicationForm)
  .component('omPrnSelect', omPrnSelect)
  .component('omPrescriptionHistory', omPrescriptionHistory)
  .component('omRegimenHistory', omRegimenHistory)
  .component('omPatientMedication', omPatientMedication)
  .component('omPatientMedicationsList', omPatientMedicationsList)
  .factory('Medication', () => Medication)
  .component('omDeleteMedication', omDeleteMedication)
  .factory('MedicationPrn', () => MedicationPrn)
  .factory('MedicationFrequencyInterval', () => MedicationFrequencyInterval)
  .service(
    'MedicationFrequencyIntervalService',
    MedicationFrequencyIntervalService,
  )
  .factory('PatientMedicationsService', PatientMedicationsService)
  .filter('medicationRouteHighlight', medicationRouteHighlight)
  .service('MedicationService', MedicationService)
  .service('MedicationPrnService', MedicationPrnService)
  .service('MedicationRouteService', MedicationRouteService);

class OriginalRx {
  constructor() {
    this.patient = {};
    this.prescribedMedication = {};
    this.dispensedMedication = {};
    this.pharmacy = {};
    this.prescriber = {};
  }
  $onInit() {
    this.patient = this.prescription.patient;
    this.prescribedMedication = this.prescription.prescribed_med_for_display;
    this.dispensedMedication = this.prescription.dispensed_med_for_display;
    this.pharmacy = this.prescription.pharmacy;
    this.prescriber = this.prescription.prescriber;
  }
}
export const omOriginalRx = {
  bindings: {
    prescription: '<',
    requestedByPatient: '<',
  },
  templateUrl: 'renewals/original-rx.component.html',
  controller: OriginalRx,
};

OriginalRx.$inject = [];

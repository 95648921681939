import { get, orderBy } from 'lodash';
import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';
import { getParamsFromUrl } from '../upgrade-param-mapper';

class ConsultOrderFormController {
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    $analytics,
    $rootScope,
    toastr,
    focus,
    CommentService,
    ConsultOrderService,
    FeaturesService,
    PatientService,
    Todo,
    TodoService,
    SpecialtiesService,
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$analytics = $analytics;
    this.$rootScope = $rootScope;
    this.toastr = toastr;
    this.focus = focus;
    this.CommentService = CommentService;
    this.ConsultOrderService = ConsultOrderService;
    this.FeaturesService = FeaturesService;
    this.PatientService = PatientService;
    this.Todo = Todo;
    this.TodoService = TodoService;
    this.SpecialtiesService = SpecialtiesService;

    this.ready = false;
  }

  $onInit() {
    const orderId = getParamsFromUrl('consult_orders') || this.$stateParams.id; // This is used to get sticky states working in hybrid mode
    const getConsultOrder = this.ConsultOrderService.get(orderId);
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    const getPatient = this.PatientService.get(patientId);
    const getTodo = this.TodoService.getConsultOrderTodo(orderId);
    this.SpecialtiesService.index();
    this.analyticsSubworkflowId = +new Date();
    this.$scope.$on('edit-order', this.editConsultOrder.bind(this));
    this.$scope.$on('reject-order', this.rejectConsultOrder.bind(this));
    this.$scope.$on('sign-order', this.signConsultOrder.bind(this));
    this.$scope.$on('send-order', this.sendConsultOrder.bind(this));
    this.$scope.$on('complete-order', this.completeConsultOrder.bind(this));
    this.$scope.$on('redact-order', this.redactConsultOrder.bind(this));
    this.$scope.$on('delete-order', this.deleteConsultOrder.bind(this));
    this.$scope.$on('omConsultant-change', () => this.changeConsultant());
    this.$scope.$on('omConsultant-select', this.selectConsultant.bind(this));
    this.$scope.$on(
      'omConsultant-specialty-select',
      this.selectSpecialty.bind(this),
    );
    this.addSupportingDocumentEvent = this.$rootScope.$on(
      'add-supporting-document',
      this.addSupportingDocument.bind(this),
    );
    this.removeSupportingDocumentEvent = this.$rootScope.$on(
      'remove-supporting-document',
      this.removeSupportingDocument.bind(this),
    );
    this.specialtyDropdownFeatureFlagEnabled = false;
    this.FeaturesService.hasFeature(
      'specialty_dropdown_for_consult_orders',
    ).then(isEnabled => {
      this.specialtyDropdownFeatureFlagEnabled = isEnabled;
    });

    return this.$q.all([getConsultOrder, getPatient, getTodo]).then(
      ([consultOrder, patient, todo]) => {
        this.consultOrder = consultOrder;
        this.patient = patient;
        this.todo = todo;
        this.updateForm();
        this.setShowRejectionReason();
        this.setShowTransmissionDetails();
        this.ready = true;
        this.emitSupportingDocuments();
        this.$analytics.eventTrack(
          'Consult Order Viewed',
          this.getAnalyticsProperties('Consult Order Form'),
        );
        if (this.consultOrder.status === 'signed') {
          this.focus('order-authCode');
        } else {
          this.focus('order-add');
        }
      },
      errorResponse => {
        if (errorResponse.status === 404) {
          this._errorToastAndReturnToChart(
            'The consult order you tried to open could not be found. It may have been deleted.',
          );
        }
      },
    );
  }

  getAnalyticsProperties(subComponent) {
    return {
      workflow: 'Charting',
      component: 'Orders',
      orderType: 'Consult',
      subComponent,
      orderId: this.consultOrder.id,
      subWorkflowId: this.analyticsSubworkflowId,
    };
  }

  $onDestroy() {
    this.addSupportingDocumentEvent();
    this.removeSupportingDocumentEvent();
    this.emitSupportingDocumentsAttachable(false);
  }

  changeConsultant() {
    this.consultOrder.contact = null;
    this.consultOrder.contact_id = null;
    this.form.$dirty = true;
    this.focus('order-consultantSearch');
  }

  contactSearchFormValue() {
    return get(this, ['form', 'contact-search', '$modelValue']);
  }

  selectConsultant(event, contact) {
    this.consultOrder.contact = contact;
    this.consultOrder.contact_id = contact.id;
    this.updateConsultOrder();
    this._trackConsultantSelection(contact);

    if (this.specialtyDropdownFeatureFlagEnabled) {
      this.autoFillSpecialty();
      this.focus('order-specialtyDropdown');
    } else {
      this.focus('order-clinicalHistory');
    }
  }

  specialties() {
    return this.SpecialtiesService.allSpecialties;
  }

  autoFillSpecialty() {
    const specialtyFromForm = this.selectSpecialtyFromContactSearch();
    this.consultOrder.specialty =
      specialtyFromForm || this.selectSpecialtyFromContact();
    this.consultOrder.specialty_id = get(
      this.consultOrder,
      ['specialty', 'id'],
      null,
    );
  }

  selectSpecialtyFromContact() {
    const contactSpecialties = this.consultOrder.contact.specialties;
    return orderBy(contactSpecialties, ['name'], ['asc'])[0];
  }

  selectSpecialtyFromContactSearch() {
    const contactSearchText = this.contactSearchFormValue();
    return this.specialties().find(
      specialty => specialty.name === contactSearchText,
    );
  }

  selectSpecialty(event, order) {
    this.consultOrder.specialty_id = order.specialty.id;
    this.updateConsultOrder();
    this.focus('order-clinicalHistory');
  }

  addSupportingDocument(event, document) {
    if (
      !this.consultOrder.supporting_documents.find(
        doc => doc.id === document.id,
      )
    ) {
      const previousSupportingDocuments = this.consultOrder.supporting_documents.slice();
      this.consultOrder.supporting_documents.push({
        id: document.id,
        author: document.author,
        created_at: document.created_at,
        subject: document.subject,
      });
      this.updateConsultOrder().then(
        this.emitSupportingDocuments.bind(this),
        this._onUpdateFailure({
          supporting_documents: previousSupportingDocuments,
        }),
      );
    }
  }

  removeSupportingDocument(event, id) {
    this.consultOrder.supporting_documents.splice(
      this.consultOrder.supporting_documents.findIndex(doc => doc.id === id),
      1,
    );
    this.updateConsultOrder().then(this.emitSupportingDocuments.bind(this));
  }

  emitSupportingDocuments() {
    this.$rootScope.$emit(
      'list-consult-order-supporting-documents',
      this.consultOrder.supporting_documents,
    );
  }

  emitSupportingDocumentsAttachable(override) {
    let isSupportingDocumentsAttachable;
    if (override !== undefined) {
      isSupportingDocumentsAttachable = override;
    } else {
      isSupportingDocumentsAttachable = this.editable;
    }
    this.$rootScope.$broadcast(
      'set-supporting-documents-attachable',
      isSupportingDocumentsAttachable,
    );
  }

  setShowRejectionReason() {
    this.showRejectionReason =
      !!this.todo &&
      !!this.todo.comments &&
      this.consultOrder.status === 'unsigned';
  }

  setShowTransmissionDetails() {
    this.showTransmissionDetails =
      this.consultOrder.status === 'sent' ||
      this.consultOrder.status === 'completed';
  }

  editConsultOrder() {
    this.$analytics.eventTrack(
      'Consult Order Edited',
      this.getAnalyticsProperties('Edit Button'),
    );
    this.consultOrder.status = 'unsigned';
    this.updateConsultOrder();
  }

  rejectConsultOrder() {
    if (!this.consultOrder.denial_comments) {
      this.errorResponse = {
        errors: { denial_comments: ['Rejection reason must be set'] },
      };
      return;
    }

    this.consultOrder.status = 'unsigned';
    const toastrText =
      'This order has been rejected and reassigned to the last signer.';
    this.updateConsultOrder().then(() => {
      this.$analytics.eventTrack(
        'Consult Order Rejected',
        this.getAnalyticsProperties('Reject Button'),
      );
      this.toastr.success(toastrText);
      this.returnToOrderList();
    });
  }

  signConsultOrder($event, { onBehalfOfId = null } = {}) {
    this.consultOrder.status = 'signed';
    this.consultOrder.on_behalf_of_id = onBehalfOfId;
    return this.updateConsultOrder().then(
      () => {
        this.$analytics.eventTrack(
          'Consult Order Signed',
          this.getAnalyticsProperties('Sign Button'),
        );
        this.toastr.success('Your order has been signed');
        return this.returnToOrderList();
      },
      errorResponse => {
        this.errorResponse = errorResponse.data;
        this.consultOrder.status = 'unsigned';
        this.updateForm();
        throw errorResponse;
      },
    );
  }

  sendConsultOrder(
    $event,
    {
      automateApproval = false,
      sendManually = false,
      onBehalfOfId = null,
    } = {},
  ) {
    const previousStatus = this.consultOrder.status;
    const insAuthRequired =
      !automateApproval && !!this.consultOrder.ins_auth_code;

    Object.assign(this.consultOrder, {
      status: 'sent',
      is_ins_auth_required: insAuthRequired,
      approval_was_automated: automateApproval,
      send_manually: sendManually,
    });

    this.consultOrder.on_behalf_of_id = onBehalfOfId;

    this.form.saving = true;
    return this.updateConsultOrder().then(() => {
      if (automateApproval) {
        this.$analytics.eventTrack(
          'Consult Order Signed & Sent',
          this.getAnalyticsProperties('Sign & Send Button'),
        );
      } else {
        this.$analytics.eventTrack(
          'Consult Order Approved & Sent',
          this.getAnalyticsProperties('Approve & Send Button'),
        );
      }
      this.form.saving = false;
      this.toastr.success('Your order has been sent');
      return this.returnToOrderList();
    }, this._onUpdateFailure({ status: previousStatus }));
  }

  completeConsultOrder() {
    const previousStatus = this.consultOrder.status;
    this.consultOrder.status = 'completed';
    return this.updateConsultOrder().then(() => {
      this.$analytics.eventTrack(
        'Consult Order Completed',
        this.getAnalyticsProperties('Complete Button'),
      );
      this.toastr.success('Your order has been marked completed');
      return this.returnToOrderList();
    }, this._onUpdateFailure({ status: previousStatus }));
  }

  redactConsultOrder() {
    const previousStatus = this.consultOrder.status;
    this.consultOrder.status = 'redacted';
    return this.updateConsultOrder().then(() => {
      this.$analytics.eventTrack(
        'Consult Order Redacted',
        this.getAnalyticsProperties('Redact Button'),
      );
      this.toastr.success('Your order has been redacted');
      return this.returnToOrderList();
    }, this._onUpdateFailure({ status: previousStatus }));
  }

  deleteConsultOrder() {
    this.errorResponse = null;

    return this.ConsultOrderService.delete(this.consultOrder.id).then(() => {
      this.$analytics.eventTrack(
        'Consult Order Deleted',
        this.getAnalyticsProperties('Delete Button'),
      );
      this.toastr.success('Your order has been deleted');
      return this.returnToOrderList();
    });
  }

  updateIndications() {
    this.focus('order-consultantSearch');
    this.updateConsultOrder();
  }

  updateConsultOrder() {
    this.errorResponse = null;
    this.updateForm();
    return this.ConsultOrderService.update(this.consultOrder).then(
      response => {
        this._mergeConsultOrder(this.consultOrder, response);
        return this.consultOrder;
      },
      errorResponse => {
        if (errorResponse.status === 404) {
          this._errorToastAndReturnToChart(
            'The consult order you tried to update has been deleted.',
          );
        }
        return this.$q.reject(errorResponse);
      },
    );
  }

  returnToOrderList() {
    this.$state.go('app.chart.orders.list');
  }

  getComments(id) {
    return this.CommentService.getConsultOrderComments(id);
  }
  saveComment(id, body) {
    return this.CommentService.saveConsultOrderComment(id, { body });
  }

  updateForm() {
    this.updateEditable();
    this.updateSignable();
    this.$rootScope.$broadcast('update-order-action-bar-secondary-actions');
    this.emitSupportingDocumentsAttachable();
  }

  updateEditable() {
    this.editable = this.consultOrder.status === 'unsigned';
  }

  updateSignable() {
    this.signable =
      this.editable &&
      !!this.consultOrder.indication &&
      !!this.consultOrder.contact &&
      (!this.specialtyDropdownFeatureFlagEnabled ||
        !!this.consultOrder.specialty);
  }

  _mergeConsultOrder(consultOrder, response) {
    this._mergeConsultOrderIndication(consultOrder, response);
    consultOrder.was_auto_approved = response.was_auto_approved;
    consultOrder.can_be_auto_approved = response.can_be_auto_approved;
    consultOrder.consult_order_events = response.consult_order_events;
    consultOrder.transmissions = response.transmissions;
    consultOrder.supporting_documents = response.supporting_documents;
    consultOrder.contact = response.contact;
    this.setShowTransmissionDetails();
  }

  _mergeConsultOrderIndication(consultOrder, response) {
    if (!response.indication) {
      return;
    }

    consultOrder.indication.id = response.indication.id;
    consultOrder.indication.clinical_description =
      response.indication.clinical_description;

    if (response.indication.code) {
      consultOrder.indication.code = response.indication.code;
    } else {
      delete consultOrder.indication.code;
    }
  }

  _onUpdateFailure(rollbackAttributes) {
    return errorResponse => {
      this.errorResponse = errorResponse.data;
      Object.assign(this.consultOrder, rollbackAttributes);
      this.form.saving = false;
      this.updateForm();
      throw errorResponse;
    };
  }

  _trackConsultantSelection(contact) {
    this.$analytics.eventTrack('Order Consultant Selected', {
      workflow: 'Charting',
      component: 'Orders',
      subcomponent: 'Select Button',
      order_id: this.consultOrder.id,
      order_type: this.consultOrder.type,
      form_type: 'Sign Form',
      contact_id: contact.id,
      contact_display_name: contact.display_name,
      contact_company_name: contact.company_name,
    });
  }

  _errorToastAndReturnToChart(errorText) {
    this.toastr.warning(errorText);
    this.$state.go('app.chart.orders.list');
  }
}

ConsultOrderFormController.$inject = [
  '$q',
  '$scope',
  '$state',
  '$stateParams',
  '$analytics',
  '$rootScope',
  'toastr',
  'focus',
  'CommentService',
  'ConsultOrderService',
  'FeaturesService',
  'PatientService',
  'Todo',
  'TodoService',
  'SpecialtiesService',
];

export const omConsultOrderForm = {
  templateUrl: 'orders/consult/consult-order-form.component.html',
  controller: ConsultOrderFormController,
};

export class CartValidationService {
  containsControlledSubstances(items) {
    return _.some(items, 'dispensable_controlled_substance');
  }

  requireTwoFactorCheckout(items) {
    return _.some(items, 'require_2fa');
  }

  allHandwrittenRequestsConfirmed(items) {
    return !items.some(
      item => item.must_handwrite && !item.handwrittenConfirmed,
    );
  }

  allSignedRequestsConfirmed(items) {
    const notSigned = item => item.require_2fa && !item.readyToSign;
    return !items.some(notSigned);
  }
}

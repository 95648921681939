class UnsignedSummaryFormController {
  constructor(ENV, toastr, SummaryService, $rootScope, $analytics) {
    this.toastr = toastr;
    this.messages = {
      alreadySigned: 'Summary is already signed and cannot be edited.',
      noteSigned: 'Note signed',
      cosignTaskCreated: 'Note signed and co-sign task created',
    };
    this.taskAssigneesIndex = ENV.search.indexes.task_assignees;
    this.SummaryService = SummaryService;
    this.$rootScope = $rootScope;
    this.$analytics = $analytics;
  }

  $onInit() {
    this.rootScopeListeners = [];
    this.requestCosign = false;
    const addToDetachableListeners = listener =>
      this.rootScopeListeners.push(listener);

    const updateProblem = (event, updatedProblem) => {
      if (this.summary.hasAssessedProblem(updatedProblem)) {
        this.updateAssessedProblem(updatedProblem);
      } else {
        this.linkAssessedProblem(updatedProblem);
      }
    };

    const saveProblem = (event, savedProblem) => {
      this.linkAssessedProblem(savedProblem);
    };

    const deleteProblem = (event, deletedProblem) => {
      this.unlinkAssessedProblem(deletedProblem);
    };

    addToDetachableListeners(
      this.$rootScope.$on('problemUpdated', updateProblem),
    );
    addToDetachableListeners(
      this.$rootScope.$on('sectionLinked', (event, section, params) =>
        this[`link${section}`](...Array.from(params || [])),
      ),
    );
    addToDetachableListeners(this.$rootScope.$on('problemSaved', saveProblem));
    addToDetachableListeners(
      this.$rootScope.$on('problemDeleted', deleteProblem),
    );
  }

  $onDestroy() {
    this.rootScopeListeners.map(listener => listener());
  }

  hasIncompleteTodo() {
    return !!this.todo && this.todo.isIncomplete();
  }

  signSummary() {
    const ctrl = this;

    if (this.cosignAssignee) {
      this.summary.cosign_task_assignee = this.cosignAssignee;
    }

    return this.SummaryService.sign(this.summary).then(
      summary => {
        this.$analytics.eventTrack('Note Signed');
        this.$rootScope.$emit('summaryClosed');
        if (summary.todos.filter(todo => todo.name === 'Co-Sign').length) {
          this.toastr.success(this.messages.cosignTaskCreated);
        } else {
          this.toastr.success(this.messages.noteSigned);
        }
      },
      errorResponse => {
        ctrl._handleSummarySavingError(errorResponse.data);
      },
    );
  }

  _handleSummarySavingError(updatedSummary) {
    if (!this.summary.signed && updatedSummary.signed) {
      this.$rootScope.$emit('summaryAlreadySigned', updatedSummary);
      this.toastr.warning(this.messages.alreadySigned);
    }
  }

  cosignSubmit(form) {
    if (form.$valid) {
      // Prevents assignee search box indicating error when pristine
      form.$setPristine();
      this.setRequestCosign(true);
    } else {
      form.$setSubmitted();
    }
  }

  setRequestCosign(requestCosign) {
    this.requestCosign = requestCosign;
  }

  signDisabled() {
    if (this.requestCosign) {
      return !this.cosignAssignee;
    }
    return false;
  }

  linkProblems(problems) {
    const problemsIncludedInMedicalHistory = problems.filter(
      problem => problem.included_in_medical_history,
    );
    const activeProblems = problemsIncludedInMedicalHistory.filter(
      item => item.active,
    );
    const resolvedProblems = problemsIncludedInMedicalHistory.filter(
      item => !item.active,
    );

    return this.SummaryService.update({
      active_problem_history_ids: activeProblems.map(
        problem => problem.problem_history_id,
      ),
      resolved_problem_history_ids: resolvedProblems.map(
        problem => problem.problem_history_id,
      ),
      medical_history: true,
    }).then(updatedAttributes => {
      this.summary.updateMedicalHistory(updatedAttributes);
    });
  }

  unlinkActiveProblems() {
    return this.SummaryService.update({
      active_problem_history_ids: [],
      resolved_problem_history_ids: [],
      medical_history: false,
    }).then(updatedAttributes => {
      this.summary.updateMedicalHistory(updatedAttributes);
    });
  }

  linkHealthGoals(healthGoals, healthMaintenanceNote) {
    const hmHistoryId = healthMaintenanceNote
      ? healthMaintenanceNote.health_maintenance_note_history_id
      : null;
    const screeningHistoryIds = _.flatten(
      healthGoals.map(healthGoal =>
        healthGoal.screening_history.map(
          screening => screening.screening_history_id,
        ),
      ),
    );

    return this.SummaryService.update({
      health_goal_history_ids: healthGoals.map(
        healthGoal => healthGoal.health_goal_history_id,
      ),
      health_goal_screening_history_ids: screeningHistoryIds,
      health_maintenance_note_history_id: hmHistoryId,
    }).then(
      ({
        health_goals: updatedGoals,
        health_maintenance_note: updatedMaintenanceNote,
      }) => {
        this.summary.health_goals = updatedGoals;
        this.summary.health_maintenance_note = updatedMaintenanceNote;
      },
    );
  }

  unlinkHealthGoals() {
    this.summary.health_goals = [];
    this.summary.health_maintenance_note = null;
    return this.SummaryService.update({
      health_goal_history_ids: this.summary.health_goals,
      health_goal_screening_history_ids: [],
      health_maintenance_note_history_id: this.summary.health_maintenance_note,
    });
  }

  linkPatientAllergies(patientAllergies) {
    return this.SummaryService.update({
      no_known_allergies: patientAllergies && patientAllergies.length === 0,
      patient_allergy_history_ids: patientAllergies.map(
        patientAllergy => patientAllergy.patient_allergy_history_id,
      ),
    }).then(({ patient_allergies: updatedPatientAllergies }) => {
      this.summary.patient_allergies = updatedPatientAllergies;
      this.summary.no_known_allergies =
        updatedPatientAllergies && updatedPatientAllergies.length === 0;
    });
  }

  unlinkPatientAllergies() {
    this.summary.patient_allergies = [];
    this.summary.no_known_allergies = false;
    return this.SummaryService.update({
      no_known_allergies: this.summary.no_known_allergies,
      patient_allergy_history_ids: this.summary.patient_allergies,
    });
  }

  linkHealthBackground(healthBackground) {
    return this.SummaryService.update({
      health_background_history_id:
        healthBackground.health_background_history_id,
    }).then(({ health_background: updatedHealthBackground }) => {
      this.summary.health_background = updatedHealthBackground;
    });
  }

  unlinkHealthBackground() {
    this.summary.health_background = null;
    return this.SummaryService.update({
      health_background_history_id: this.summary.health_background,
    });
  }

  linkMedications(medications) {
    const toUpdate = {
      no_medications: medications && medications.length === 0,
      patient_medication_regimen_ids: medications.map(
        medication => medication.latest_patient_medication_regimen.id,
      ),
    };
    return this.SummaryService.update(toUpdate).then(
      ({ medications: updatedMedications }) => {
        this.summary.medications = updatedMedications;
        this.summary.no_medications =
          updatedMedications && updatedMedications.length === 0;
      },
    );
  }

  unlinkMedications() {
    this.summary.medications = [];
    this.summary.no_medications = false;
    return this.SummaryService.update({
      no_medications: this.summary.no_medications,
      patient_medication_regimen_ids: this.summary.medications,
    });
  }

  linkVaccinations(savedVaccinations) {
    const toUpdate = {
      vaccine_history_ids: savedVaccinations.map(
        vaccination => vaccination.history_vaccine_history_id,
      ),
    };
    return this.SummaryService.update(toUpdate).then(
      ({ vaccinations }) => (this.summary.vaccinations = vaccinations),
    );
  }

  unlinkVaccinations() {
    this.summary.vaccinations = [];
    return this.SummaryService.update({
      vaccine_history_ids: this.summary.vaccinations,
    });
  }

  _saveAssessedProblems(updatedAssessedProblems) {
    const toUpdate = {
      assessed_problem_history_ids: updatedAssessedProblems.map(
        problem => problem.problem_history_id,
      ),
    };
    return this.SummaryService.update(toUpdate).then(
      ({ assessed_problems: assessedProblems }) =>
        (this.summary.assessed_problems = assessedProblems),
    );
  }

  update() {
    const ctrl = this;

    return this.SummaryService.update({
      subjective: this.summary.subjective,
      objective: this.summary.objective,
      measurements: this.summary.measurements,
      subject: this.summary.subject || '',
      time_based_visit: this.summary.time_based_visit,
    }).catch(errorResponse => {
      ctrl._handleSummarySavingError(errorResponse.data);
    });
  }

  linkAssessedProblem(problem) {
    const updatedAssessedProblems = this.summary.assessed_problems.concat([
      problem,
    ]);
    return this._saveAssessedProblems(updatedAssessedProblems);
  }

  unlinkAssessedProblem(problem) {
    const updatedAssessedProblemsCopy = this.summary.assessed_problems.slice();
    const index = updatedAssessedProblemsCopy.findIndex(
      element => element.id === problem.id,
    );
    if (index > -1) {
      updatedAssessedProblemsCopy.splice(index, 1);
      return this._saveAssessedProblems(updatedAssessedProblemsCopy);
    }
    return null;
  }

  updateAssessedProblem(problem) {
    const updatedAssessedProblems = this.summary.assessed_problems.map(
      assessedProblem => {
        if (assessedProblem.id === problem.id) {
          return problem;
        }
        return assessedProblem;
      },
    );
    return this._saveAssessedProblems(updatedAssessedProblems);
  }

  delete() {
    const ctrl = this;
    return this.SummaryService.delete().then(
      summary => {
        this.$rootScope.$emit('summaryClosed', summary);
      },
      errorResponse => {
        ctrl._handleSummarySavingError(errorResponse.data);
      },
    );
  }
}

UnsignedSummaryFormController.$inject = [
  'ENV',
  'toastr',
  'SummaryService',
  '$rootScope',
  '$analytics',
];

export const omUnsignedSummaryForm = {
  bindings: {
    summary: '<',
    todo: '<',
    getComments: '<',
    saveComment: '<',
  },
  controller: UnsignedSummaryFormController,
  templateUrl: 'summaries/unsigned-summary-form.component.html',
};

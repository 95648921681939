import { omDetailList } from './detail-list.directive';
import { omDetailListItem } from './detail-list-item.directive';
import { omDetailListItemTitle } from './detail-list-item-title.directive';
import { omDetailListItemBody } from './detail-list-item-body.directive';

angular
  .module('onelifeUi.shared.detailList', [])
  .directive('omDetailList', omDetailList)
  .directive('omDetailListItem', omDetailListItem)
  .directive('omDetailListItemTitle', omDetailListItemTitle)
  .directive('omDetailListItemBody', omDetailListItemBody);

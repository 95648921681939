import { omProblems } from './problems.component';
import { omProblemHistory } from './problem-history.component';
import { omProblemForm } from './problem-form.component';
import { Problem } from './problem';
import { ProblemCodeService } from './problem-code.service';
import { ProblemService } from './problem.service';
import { problemTypeHighlight } from './problem-type-highlight.filter';
import { problemCodeHighlight } from './problem-code-highlight.filter';

angular
  .module('onelifeUi.problems', [])
  .component('omProblems', omProblems)
  .component('omProblemHistory', omProblemHistory)
  .component('omProblemForm', omProblemForm)
  .factory('Problem', () => Problem)
  .filter('problemTypeHighlight', problemTypeHighlight)
  .filter('problemCodeHighlight', problemCodeHighlight)
  .service('ProblemCodeService', ProblemCodeService)
  .service('ProblemService', ProblemService);

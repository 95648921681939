export class Problem {
  constructor(attributes = {}) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });

    if (
      this.custom_problem_type_code &&
      this.custom_problem_type_code_description
    )
      this.setCustomProblemCode();
  }

  setNotificationText() {
    this.notificationText = null;
    const autoCreations = this.problem_type.auto_creations;
    const configs = autoCreations.find(
      autoCreation => autoCreation.basic_follow_up_order,
    );

    if (configs && this.id === undefined) {
      this.auto_creations_flags = { basic_follow_up_order: true };

      const intervalValue = parseInt(
        configs.basic_follow_up_order.interval_value,
        10,
      );
      const intervalType = `${configs.basic_follow_up_order.interval_type +
        (intervalValue > 1 ? 's' : '')}`;

      if (intervalType === 'day' && intervalValue === 0) {
        this.notificationText = `Patient will be sent a notification to check in today.`;
      } else {
        this.notificationText = `Patient will be sent a notification to check in ${intervalValue} ${intervalType} from now.`;
      }
    } else {
      this.auto_creations_flags = {};
    }
  }

  setIncludedInMedicalHistory() {
    const unimportant = this.problem_type && this.problem_type.importance === 3;
    this.included_in_medical_history = !unimportant;
  }

  toggleIncludedInMedicalHistory() {
    this.included_in_medical_history = !this.included_in_medical_history;
  }

  setCustomProblemCode() {
    this.custom_problem_code_selection = {
      code: this.custom_problem_type_code,
      detail_description: this.custom_problem_type_code_description,
    };
  }
}

export class NotificationsService {
  constructor({ pusher: { pusher_key, pusher_encrypted } }) {
    this.socket = new Pusher(pusher_key, {
      encrypted: pusher_encrypted,
    });
  }

  alreadySubscribed(channelName) {
    return this.socket
      .allChannels()
      .some(channel => channel.name === channelName);
  }

  subscribe(channelName) {
    if (!this.alreadySubscribed(channelName)) {
      return this.socket.subscribe(channelName);
    }
    return this.socket.channel(channelName);
  }
}

NotificationsService.$inject = ['ENV'];

export class PatientMedicationsService {
  constructor(ApiService, $stateParams, Medication) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
    this.Medication = Medication;
  }

  getAllForPatient(patientId) {
    return this.ApiService.get(
      `/v2/admin/patients/${patientId}/patient_medications`,
    ).then(response => response.map(item => new this.Medication(item)));
  }
}

PatientMedicationsService.$inject = [
  'ApiService',
  '$stateParams',
  'Medication',
];

export function omDateStringValidator() {
  return {
    restrict: 'AE',
    require: 'ngModel',
    link(scope, element, attr, ctrl) {
      const validateDateString = (dateString) => {
        const isNumeric = /[^0-9\/]+/.test(dateString); // eslint-disable-line no-useless-escape
        ctrl.$setValidity('numericValidator', !isNumeric);
        ctrl.$setValidity('lengthValidator', (dateString.length <= 10));

        return dateString;
      };

      ctrl.$parsers.push(validateDateString);
    },
  };
}

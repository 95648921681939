import { HistoryVaccine } from './history-vaccine';

const DEFAULT_ERROR = 'Vaccine Order could not be updated';

class VaccineAdministerController {
  constructor(HistoryVaccineService, VaccineOrderService, $state, FeaturesService, $q) {
    this.HistoryVaccineService = HistoryVaccineService;
    this.VaccineOrderService = VaccineOrderService;
    this.$state = $state;
    this.FeaturesService = FeaturesService;
    this.vaccineBrands = [];
    this.vaccineRoutes = [];
    this.vaccineLocations = [];
    this.vaccineSides = ['Left', 'Right'];
    this.dateToday = moment().format('YYYY-MM-DD');
    this.deleteAndEditFlag = false;
    this.$q = $q;
  }

  $onInit() {
    this.completedVaccineOrder = this.vaccineOrder.status === 'completed';
    this.signedVaccineOrder = this.vaccineOrder.status === 'signed';
    this.deletedVaccineOrder = this.vaccineOrder.status === 'deleted';
    this.declinedVaccineOrder = this.vaccineOrder.status === 'declined';
    this.isExpired = this.vaccineOrder.is_expired;
    this.setHistoryVaccine();
    this.setDeleteEditFeatureFlag();
  }

  setDeleteEditFeatureFlag() {
    // product specialists only
    this.FeaturesService.hasFeature('vaccine_order_delete_and_edit').then(
      isEnabled => (this.deleteAndEditFlag = isEnabled),
    );
  }

  setHistoryVaccine() {
    if (this.vaccineOrder.history_vaccine) {
      this.historyVaccine = new HistoryVaccine(
        this.vaccineOrder.history_vaccine,
      );
      this.setAdministrationOptions();
    } else {
      this.createHistoryVaccine().then(() => {
        this.setAdministrationOptions();
      });
    }
  }

  createHistoryVaccine() {
    return this.HistoryVaccineService.create(this.vaccineOrder).then(
      historyVaccine => {
        this.historyVaccine = historyVaccine;
      },
    );
  }

  setHistoryVaccineSide(side) {
    this.historyVaccine.side = side === 'Left' ? 'L' : 'R';
    this.historyVaccine.selectedSide = side;
  }

  setAdministrationOptions() {
    this.setVaccineBrands();
    this.setVaccineRoutes(this.historyVaccine.vaccine_brand);
    this.setVaccineLocations(this.historyVaccine.vaccine_route);
  }

  setVaccineBrands() {
    this.vaccineBrands = this.historyVaccine.vaccine_brands;
    if (this.vaccineBrands.length === 0) return;
    this.historyVaccine.vaccine_brand = this.setSelectedOption(
      this.historyVaccine.vaccine_brand_id,
      this.vaccineBrands,
    );
  }

  setVaccineRoutes(brand) {
    if (!brand) return;
    this.vaccineRoutes = brand.vaccine_routes;
    if (this.vaccineRoutes.length === 0) return;
    this.historyVaccine.vaccine_route = this.setSelectedOption(
      this.historyVaccine.clinical_route_id,
      this.vaccineRoutes,
    );
  }

  setVaccineLocations(route) {
    if (!route) return;
    this.vaccineLocations = route.vaccine_locations;
    if (this.vaccineLocations.length === 0) return;
    this.historyVaccine.vaccine_location = this.setSelectedOption(
      this.historyVaccine.vaccine_location_id,
      this.vaccineLocations,
    );
  }

  setSelectedOption(id, options) {
    return id ? options.find(option => option.id === id) : options[0];
  }

  updateVaccineOrder = () => {
    this.VaccineOrderService.update(this.vaccineOrder).finally(() => {
      this.administerError = '';
    });
  };

  updateHistoryVaccine() {
    if (this.editingVaccineOrder) return this.$q.when(false);
    const historyVaccineParams = this.historyVaccine.getHistoryVaccineParams();
    return this.HistoryVaccineService.update(historyVaccineParams).then(() => {
      this.administerError = '';
    });
  }

  updateCompletedHistoryVaccine() {
    const historyVaccineParams = {
      ...this.historyVaccine.getHistoryVaccineParams(),
      edited_completed_vaccine_order: true,
    };
    return this.HistoryVaccineService.update(historyVaccineParams)
      .then(data => {
        this.vaccineOrder.events = data.events;
        this.administerError = '';
        this.editingVaccineOrder = false;
      })
  }

  cancelEditingCompletedHistoryVaccine() {
    this.editingVaccineOrder = false;
    this.historyVaccine = this.originalCompletedHistoricalVaccine;
    this.originalCompletedHistoricalVaccine = null;
  }

  completeHistoryVaccine() {
    const historyVaccineParams = this.historyVaccine.getHistoryVaccineParams();
    this.HistoryVaccineService.complete(historyVaccineParams)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  setEditing() {
    this.editingVaccineOrder = true;
    this.originalCompletedHistoricalVaccine = new HistoryVaccine(this.historyVaccine);
  }

  setDeleting(deleting) {
    this.deleting = deleting;
  }

  softDelete() {
    this.VaccineOrderService.softDelete(this.vaccineOrder.id)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  setCancelling(cancelling) {
    this.cancelling = cancelling;
  }

  cancel() {
    this.VaccineOrderService.cancel(this.vaccineOrder.id)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  reactivate() {
    this.VaccineOrderService.reactivate(this.vaccineOrder)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  setDeclining(declining) {
    this.declining = declining;
  }

  decline() {
    this.VaccineOrderService.decline(this.vaccineOrder.id)
      .then(() => {
        this.$state.go('app.chart.orders.list');
      })
      .catch(error => this.setError(error));
  }

  setError(error) {
    if (error && error.data && error.data[0]) {
      this.administerError = error.data[0];
    } else {
      this.administerError = DEFAULT_ERROR;
    }
  }
}

VaccineAdministerController.$inject = [
  'HistoryVaccineService',
  'VaccineOrderService',
  '$state',
  'FeaturesService',
  '$q',
];

export const omVaccineAdminister = {
  controller: VaccineAdministerController,
  templateUrl: 'orders/vaccine/vaccine-administer.component.html',
  transclude: false,
  bindings: { vaccineOrder: '<', editVaccineOrder: '<' },
};

import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

class TimelineController {
  constructor(
    ENV,
    $rootScope,
    $scope,
    FeaturesService,
    $stateParams,
    AppointmentService,
    $search,
    TimelineItem,
    $analytics,
  ) {
    this.ENV = ENV;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.FeaturesService = FeaturesService;
    this.$stateParams = $stateParams;
    this.AppointmentService = AppointmentService;
    this.activeObj = null;
    this.$search = $search;
    this.TimelineItem = TimelineItem;
    this.$analytics = $analytics;
    this.index = this.ENV.search.indexes.clinical_timeline;
    this.isSupportingDocumentsAttachable = false;
    this.supportingDocumentsAttached = [];
    this.currentFilter = 'All';
  }

  $onInit() {
    this.patientId = this.$stateParams.patientId || getPatientIdFromUrl();

    this.AppointmentService.future().then(appointments => {
      this.upcomingAppointments = appointments;
    });

    this.unsetNoteFromEmit = this.$rootScope.$on(
      'unset-timeline-note',
      this.unset.bind(this),
    );

    this.setNoteFromEmit = this.$rootScope.$on(
      'set-timeline-note',
      this.setNoteById.bind(this),
    );

    this.setSupportingDocumentsAttachedFromEmit = this.$rootScope.$on(
      'list-consult-order-supporting-documents',
      this._setSupportingDocumentsAttached.bind(this),
    );

    this.setSupportingDocumentsAttachedFromEmit = this.$rootScope.$on(
      'list-procedure-order-supporting-documents',
      this._setSupportingDocumentsAttached.bind(this),
    );

    this.$scope.$on(
      'set-supporting-documents-attachable',
      this._setIsSupportingDocumentsAttachable.bind(this),
    );

    this.$scope.$on(
      'set-current-filter',
      this._setCurrentFilter.bind(this),
    );
  }

  $onDestroy() {
    this.unsetNoteFromEmit();
    this.setNoteFromEmit();
    this.setSupportingDocumentsAttachedFromEmit();
  }

  setNoteById(event, id) {
    const query = {
      index: this.index,
      size: 5,
      body: {
        query: {
          bool: {
            must: [
              { term: { patient_id: this.patientId } },
              { term: { database_id: id } },
              { term: { doctype: 'note' } },
            ],
          },
        },
      },
    };
    this.unset();
    return this.$search.search(query).then(results => {
      if (results.hits.hits[0]) {
        const timelineItem = new this.TimelineItem(
          results.hits.hits[0]._source,
        );
        this.set(timelineItem);
      }
    });
  }

  unset = timelineItem => {
    this.highlight(timelineItem || this.activeObj);
    this.activeObj = null;
  };

  set = obj => {
    this.activeObj = obj;
  };

  isActive = obj => this.activeObj === obj;

  onItemClick(result) {
    if (this.isActive(result)) {
      this.unset();
    } else {
      this.set(result);
      if (result && result.lab_order_id) {
        this.trackLabOrderOpened(result);
      }
    }
  }

  highlight(item) {
    this.highlightedItem = item;
  }

  showUpcomingAppointments() {
    return (
      this.currentFilter === 'All' ||
      this.currentFilter === 'Clinical' ||
      this.currentFilter === 'Encounters'
    );
  }

  _setIsSupportingDocumentsAttachable($event, isSupportingDocumentsAttachable) {
    this.isSupportingDocumentsAttachable = isSupportingDocumentsAttachable;
  }

  _setSupportingDocumentsAttached($event, documents) {
    this.supportingDocumentsAttached.splice(
      0,
      this.supportingDocumentsAttached.length,
      ...documents,
    );
  }

  _setCurrentFilter($event, filterName) {
    this.currentFilter = filterName;
  }

  trackLabOrderOpened(labOrderNote) {
    this.$analytics.eventTrack('Clinical Timeline Object Opened', {
      workflow: 'Charting',
      component: 'Clinical Timeline',
      subcomponent: 'Lab Order',
      note_id: labOrderNote.id,
      method: 'Native',
    });
  }
}

TimelineController.$inject = [
  'ENV',
  '$rootScope',
  '$scope',
  'FeaturesService',
  '$stateParams',
  'AppointmentService',
  '$search',
  'TimelineItem',
  '$analytics'
];

export const omTimeline = {
  templateUrl: 'timeline/timeline.component.html',
  controller: TimelineController,
  bindings: {
    profileId: '<',
  },
};

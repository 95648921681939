export class PharmacySelect {
  constructor($rootScope) {
    this.$rootScope = $rootScope;
  }
  $onInit() {
    this.$modal = this.modalCtrl.$scope.$modal;
    this.picker.$modal = this.$modal;
  }

  toggleCartScrolling(open) {
    this.$rootScope.$emit('enableScrolling', !open);
  }
}

PharmacySelect.$inject = ['$rootScope'];

export const omPharmacySelect = {
  require: {
    modalCtrl: '^omModal',
    picker: '^omPharmacyPicker',
  },
  bindings: {},
  templateUrl: 'new-rx/pharmacy-select.html',
  controller: PharmacySelect,
};

export const PharmacyChoice = {
  bindings: {
    details: '<',
    phone: '<',
    services: '<',
    faxOnly: '<',
    is24h: '<',
    isLTC: '<',
    isSpecialty: '<',
    isMailOrder: '<',
    isPreferred: '<',
    customerCode: '<',
  },
  templateUrl: 'new-rx/pharmacy-choice.html',
};

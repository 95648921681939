import { getPatientIdFromUrl } from '../../upgrade/upgrade-param-mapper';
import { getParamsFromUrl } from '../upgrade-param-mapper';

class SurveyOrderController {
  constructor(
    $analytics,
    $state,
    SurveyService,
    $stateParams,
    SurveyOrderService,
  ) {
    this.$state = $state;
    this.saving = false;
    this.orderId = getParamsFromUrl('survey_orders') || $stateParams.id || null; // This is used to get sticky states working in hybrid mode
    this.surveyService = SurveyService;
    this.patientId = $stateParams.patientId || getPatientIdFromUrl();
    this.surveyOrderService = SurveyOrderService;
    this.confirmingDelete = false;
    this.deleting = false;
    this.$analytics = $analytics;
    this.analyticsProperties = { type: 'survey_order' };
  }

  $onInit() {
    this.order = {};

    this.surveyService.getAll().then(surveys => {
      this.surveys = surveys;
    });
    if (this.orderId) {
      this.surveyOrderService
        .get(this.patientId, this.orderId)
        .then(response => {
          this.order = response;
        });
    }
  }

  onManualDateEntry(newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }
    this.order.due_on = newDueOnValue;
    this.remindedOn = this.buildReminded();
    this.$analytics.eventTrack('Date field edited', this.analyticsProperties);
  }

  onShortcutDateEntry(quantity, unit, newDueOnValue) {
    if (!newDueOnValue) {
      return;
    }
    this.order.due_on = newDueOnValue;
    this.remindedOn = this.buildReminded();
    const analyticsProperties = { ...this.analyticsProperties, quantity, unit };
    this.$analytics.eventTrack(
      'Date Picker Button Pressed',
      analyticsProperties,
    );
  }

  buildReminded() {
    if (!this.order.due_on) {
      return undefined;
    }

    this.result = this.surveys.find(survey => survey.id === this.survey.id);

    if (this.result) {
      this.granularity = this.result.reminder_granularity;
    } else {
      return undefined;
    }

    this.mapping = {
      daily: 'day',
      weekly: 'week',
      monthly: 'month',
    };

    return `1 ${this.mapping[this.granularity]}`;
  }

  validateForm() {
    this.surveyOrderForm.$setSubmitted();
    return this.surveyOrderForm.$valid;
  }

  cancel() {
    this.$state.go('app.chart.orders.list');
  }

  toggleDeleteConfirmation() {
    this.confirmingDelete = !this.confirmingDelete;
  }

  deleteOrder() {
    this.deleting = true;

    this.surveyOrderService.delete(this.patientId, this.order).then(() => {
      this.deleting = false;
      this.$state.go('app.chart.orders.list');
    });
  }

  _handleError({ data }) {
    this.apiError = data.message;
  }

  signOrder() {
    const valid = this.validateForm();
    if (valid) {
      this.saving = true;
      this.signSurveyOrder()
        .then(() => {
          this.saving = false;
          this.$state.go('app.chart.orders.list');
        })
        .catch(() => {
          this.saving = false;
        });
    }
  }

  signSurveyOrder() {
    const order = _.clone(this.order);
    this.apiError = null;
    order.survey_id = this.survey.id;
    delete order.survey;
    const request = this.surveyOrderService.create(this.patientId, order);
    request.catch(this._handleError.bind(this));
    return request;
  }
}

SurveyOrderController.$inject = [
  '$analytics',
  '$state',
  'SurveyService',
  '$stateParams',
  'SurveyOrderService',
];

export const omSurveyOrder = {
  bindings: { order: '<' },
  controller: SurveyOrderController,
  templateUrl: 'orders/survey/survey-order.component.html',
};

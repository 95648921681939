import { omConsultant } from './consultant.component';
import { omConsultantAutocomplete } from './consultant-autocomplete.component';
import { omConsultantSearch } from './consultant-search.component';
import { omConsultantSearchOptions } from './consultant-search-options.component';
import { omConsultantSection } from './consultant-section.component';
import { omIndications } from './indications.component';
import { omIndicationsList } from './indications-list.component';
import { omIndicationsListItem } from './indications-list-item.component';
import { omLabelEditor } from './label-editor.component';
import { omConfirmDrawer } from './confirm-drawer.component';
import { omSignByDrawer } from './sign-by-drawer.component';
import { omOrderActionBar } from './order-action-bar.component';
import { omOrderActionBarPrimaryButton } from './order-action-bar-primary-button.component';
import { omOrderApproval } from './order-approval.component';
import { omOrderHistory } from './order-history.component';
import { omOrderInstructions } from './order-instructions.component';
import { omOrderStatusLabel } from './order-status-label.component';
import { omProcedureType } from './procedure-type.component';
import { omProcedureOrderGuidance } from './procedure-order-guidance.component';
import { omRedactButtonMenuItem } from './redact-button-menu-item.component';
import { omSetAttributeOnFocus } from './set-attribute-on-focus.directive';
import { omTransmissionDetails } from './transmission-details.component';
import { omSupportingDocument } from './supporting-document.component';
import { omSupportingDocuments } from './supporting-documents.component';
import { omSupportingDocumentsAlert } from './supporting-documents-alert.component';
import { ConsultantNetworkService } from './consultant-network.service';

angular
  .module('onelifeUi.orders.shared', [])
  .component('omConsultant', omConsultant)
  .component('omConsultantAutocomplete', omConsultantAutocomplete)
  .component('omConsultantSearch', omConsultantSearch)
  .component('omConsultantSearchOptions', omConsultantSearchOptions)
  .component('omConsultantSection', omConsultantSection)
  .component('omIndications', omIndications)
  .component('omIndicationsList', omIndicationsList)
  .component('omIndicationsListItem', omIndicationsListItem)
  .component('omLabelEditor', omLabelEditor)
  .component('omConfirmDrawer', omConfirmDrawer)
  .component('omSignByDrawer', omSignByDrawer)
  .component('omOrderActionBar', omOrderActionBar)
  .component('omOrderActionBarPrimaryButton', omOrderActionBarPrimaryButton)
  .component('omOrderApproval', omOrderApproval)
  .component('omOrderHistory', omOrderHistory)
  .component('omOrderInstructions', omOrderInstructions)
  .component('omOrderStatusLabel', omOrderStatusLabel)
  .component('omProcedureType', omProcedureType)
  .component('omProcedureOrderGuidance', omProcedureOrderGuidance)
  .component('omRedactButtonMenuItem', omRedactButtonMenuItem)
  .component('omTransmissionDetails', omTransmissionDetails)
  .component('omSupportingDocument', omSupportingDocument)
  .component('omSupportingDocuments', omSupportingDocuments)
  .component('omSupportingDocumentsAlert', omSupportingDocumentsAlert)
  .directive('omSetAttributeOnFocus', omSetAttributeOnFocus)
  .service('ConsultantNetworkService', ConsultantNetworkService);

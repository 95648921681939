class DetailListController {
  $onInit() {
    this.listLength = 0;
    this.currentIndex = null;
  }

  setCurrent(i) {
    this.currentIndex = i;
  }

  unsetCurrent() {
    this.currentIndex = null;
  }

  next() {
    if (this.listLength === this.currentIndex + 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }
  }
}

export const omDetailList = () => ({
  restrict: 'E',
  controller: DetailListController,
  controllerAs: 'detailListCtrl',
  transclude: true,
  replace: true,
  template: '<div class="om-detail-list" ng-transclude />',
});

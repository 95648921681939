class NoteIconController {

  $onInit() {
    this.small = this.small || false;
    this.transferable = this.transferable || false;
    this.keys = [];
    this.tags.forEach(tag => (this.keys[tag] = true));

    this.iconClass = this.getIconClass();
    if (!this.inheritColor) this.iconClass = this.color(this.iconClass);
  }

  getIconClass() {
    let iconClass = this.mapKeysToCssClass();
    iconClass = `om-icon icon-${iconClass}`;
    if (!this.small) {
      iconClass = `${iconClass}-large`;
    }

    return iconClass;
  }

  useMiscTaskIcon() {
    const miscTaskIconTypes = [
      'Misc Clerical',
      'Misc Task'
    ];
    return miscTaskIconTypes.includes(this.noteType);
  }

  color(iconClass) {
    if (this.keys.results) {
      return `${iconClass} -red`;
    } else if (this.keys.order || this.keys.rx) {
      return `${iconClass} -yellow`;
    } else if (this.keys.travel || this.keys.clerical) {
      return `${iconClass} -green`;
    }

    return iconClass;
  }

  /* eslint-disable complexity */
  mapKeysToCssClass() {
    const keys = this.keys;

    switch (true) {
      case keys.encounter && keys.office:
        return 'visit-office';
      case keys.encounter && keys.phone:
        return 'visit-phone';
      case keys.encounter && keys.triage:
        return 'visit-triage';
      case keys.encounter && keys.video:
        return 'visit-video';
      case keys.encounter && keys['mental health']:
        return 'visit-mental';
      case keys.encounter && keys.email:
        return 'message';
      case keys.results && (keys.laboratory || keys.test || keys.lab):
        return 'lab-report';
      case keys.results && keys.imaging:
        return 'imaging';
      case keys.order:
        return 'lab-order';
      case keys.rx || keys.prescription:
        return 'med';
      case keys.travel && keys.questionnaire:
        return 'survey';
      case keys.clerical &&
        ((keys.request && keys.questionnaire) || keys.release):
        return 'clerical';
      case this.useMiscTaskIcon():
        return 'misc-task';
      case this.noteType === "Official Letter":
        return 'letter';
      default:
        return 'visit-misc';
    }
  }
  /* eslint-enable complexity */
}

NoteIconController.$inject = [];

export const omNoteIcon = {
  bindings: {
    tags: '<',
    small: '<',
    transferable: '<',
    noteType: '<',
    inheritColor: '<',
  },
  controller: NoteIconController,
  template: `<span ng-if="!$ctrl.transferable" ng-class="$ctrl.iconClass"></span>
             <om-swap-icons ng-if="$ctrl.transferable"
                            original-icon="{{$ctrl.iconClass}}"
                            icon-to-swap="icon-ui-transfer {{$ctrl.small ? '' : 'icon-large'}}">
             </om-swap-icons>`,
};

const adminPath = '/v2/admin';
const consultOrdersPath = 'consult_orders';
const notesPath = 'notes';
const patientsPath = `${adminPath}/patients`;
const patientTimelinePostPath = 'patient_timeline/posts';
const procedureOrdersPath = 'procedure_orders';
const summariesPath = 'summaries';
const todosPath = `${adminPath}/todos`;
const todoAssociatedWithPath = `${todosPath}/associated_with`;
const errorMessage =
  'Something went wrong. Please contact helpdesk@onemedical.com';

export class TodoService {
  constructor($rootScope, $stateParams, toastr, ApiService, Todo) {
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.toastr = toastr;
    this.ApiService = ApiService;
    this.Todo = Todo;
  }

  getConsultOrderTodo(consultOrderId) {
    return this.ApiService.get(
      `${adminPath}/${consultOrdersPath}/${consultOrderId}/todo`,
    ).then(response => new this.Todo(response.todo));
  }

  getProcedureOrderTodo(procedureOrderId) {
    return this.ApiService.get(
      `${adminPath}/${procedureOrdersPath}/${procedureOrderId}/todo`,
    ).then(response => new this.Todo(response.todo));
  }

  getNoteTodo(note) {
    return this.getAssociatedTodo(notesPath, note);
  }

  getPatientTimelinePostTodo(post) {
    return this.ApiService.get(
      `${patientsPath}/${
      this.$stateParams.patientId
      }/${patientTimelinePostPath}/${post.id}/todo`,
    ).then(response => new this.Todo(response.todo));
  }

  getRefillRequestTodo({ associated_with_id, associated_with_type }) {
    return this.ApiService.get(todoAssociatedWithPath, {
      params: { associated_with_id, associated_with_type },
    }).then(response => new this.Todo(response.todo));
  }

  getSummaryTodo(summary) {
    return this.getAssociatedTodo(summariesPath, summary);
  }

  getAssociatedTodo(path, resource) {
    return this.ApiService.get(`${adminPath}/${path}/${resource.id}/todo`).then(
      response => new this.Todo(response.todo),
    );
  }

  complete(todo) {
    todo.errorMessage = undefined;
    return this.resolve(todo).then(
      responseTodo => {
        this.$rootScope.$emit('refreshTimeline');
        this.toastr.success('Your task has been completed');
        return responseTodo;
      },
      errorResponse => {
        if (errorResponse.status === 500) {
          todo.errorMessage = errorMessage;
        } else {
          todo.errorMessage = errorResponse.data.todo.message;
        }
      },
    );
  }

  completeWithoutSigningNote(todo) {
    todo.via_note = false;
    return this.complete(todo);
  }

  completeAndSignNote(todo) {
    todo.via_note = true;
    return this.complete(todo);
  }

  reopen(todo) {
    return this.ApiService.update(`${todosPath}/${todo.id}/reopen`, todo).then(
      response => new this.Todo(response.todo),
    );
  }

  resolve(todo) {
    return this.ApiService.update(`${todosPath}/${todo.id}/resolve`, todo).then(response => new this.Todo(response.todo));
  }

  update(todo) {
    return this.ApiService.update(`${todosPath}/${todo.id}`, todo).then(
      response => new this.Todo(response.todo),
    );
  }
}

TodoService.$inject = [
  '$rootScope',
  '$stateParams',
  'toastr',
  'ApiService',
  'Todo',
];

export class ApiService {
  constructor(ENV, $http) {
    this.baseUrl = ENV.api.baseUrl;
    this.$http = $http;
  }

  get(endpoint, config = {}) {
    return this.$http
      .get(`${this.baseUrl}${endpoint}`, config)
      .then(response => response.data);
  }

  save(endpoint, data, config = {}) {
    return this.$http
      .post(`${this.baseUrl}${endpoint}`, data, config)
      .then(response => response.data);
  }

  update(endpoint, data, config = {}) {
    return this.$http
      .put(`${this.baseUrl}${endpoint}`, data, config)
      .then(response => response.data);
  }

  delete(endpoint, config = {}) {
    return this.$http
      .delete(`${this.baseUrl}${endpoint}`, config)
      .then(response => response.data);
  }
}

ApiService.$inject = ['ENV', '$http'];

class QueryBuilder {
  constructor(strategyName) {
    const strategies = {
      query_string_v6(q, options) {
        return {
          index: options.index,
          size: options.size,
          scroll: options.scroll,
          body: {
            sort: options.sort,
            query: {
              bool: {
                must: {
                  query_string: {
                    query: q || '*',
                  },
                },
                filter: options.filter,
              },
            },
          },
        };
      },
      query_string_with_fields_v6(q, options) {
        return {
          index: options.index,
          size: options.size,
          scroll: options.scroll,
          body: {
            sort: options.sort,
            query: {
              bool: {
                must: {
                  query_string: {
                    query: q,
                    fields: options.fields,
                    default_operator: options.operator || 'or',
                  },
                },
                filter: options.filter,
              },
            },
          },
        };
      },
      multi_match_with_fields_v6(q, options) {
        return {
          index: options.index,
          size: options.size,
          scroll: options.scroll,
          body: {
            sort: options.sort,
            query: {
              bool: {
                must: {
                  multi_match: {
                    query: q,
                    fields: options.fields,
                    operator: options.operator || 'or',
                  },
                },
                filter: options.filter,
              },
            },
          },
        };
      },
      function_score_v6(q, options) {
        return {
          index: options.index,
          size: options.size,
          scroll: options.scroll,
          body: {
            sort: options.sort,
            query: {
              function_score: {
                query: {
                  constant_score: {
                    filter: {
                      multi_match: {
                        query: q,
                        fields: options.fields,
                        operator: options.operator || 'or',
                      },
                    },
                  },
                },
                functions:
                  options.fields &&
                  options.fields.map(field => {
                    const [name, boost] = Array.from(field.split('^'));
                    return {
                      filter: {
                        match: {
                          [name]: q,
                        },
                      },
                      weight: boost || '1',
                    };
                  }),
                score_mode: 'first',
              },
            },
          },
        };
      },
    };

    this.build = (q, LoggerService, options) => {
      if (!strategies[strategyName]) {
        LoggerService.log(`Unknown search strategy '${strategyName}'`);
      }
      return strategies[strategyName](q, options);
    };
  }
}

export class omSearchController {
  constructor(
    $scope,
    $attrs,
    $parse,
    $search,
    $rootScope,
    Rollbar,
    LoggerService,
  ) {
    this.LoggerService = LoggerService;
    this.Rollbar = Rollbar;
    this.results = [];
    this.query = (q, options) => {
      if (options == null) {
        options = {};
      }
      const parseOptions = () => {
        const opts = _.merge(
          _.pick($attrs, [
            'index',
            'size',
            'scroll',
            'type',
            'fields',
            'filter',
            'sort',
            'operator',
          ]),
          options,
        );
        ['fields', 'filter', 'sort'].forEach(name => {
          opts[name] = $parse(opts[name])($scope);
        });
        if (opts.index) {
          opts.index = opts.index.split(/[\s,]+/);
        }
        return opts;
      };

      this.moreResults = false;
      this.lastQuery = q;
      this.loading = true;
      this.error = false;
      const query = new QueryBuilder($attrs.strategy || 'query_string_v6').build(
        q,
        LoggerService,
        parseOptions(),
      );
      return $search.search(query).then(
        body => {
          this.loading = false;
          this.scrollId = body._scroll_id;
          this.total = body.hits.total;
          this.results = _.map(body.hits.hits, '_source');
          this.moreResults = this.results.length < this.total;
          if (options.resultSort) {
            this.results = options.resultSort(this.results);
          }
          return this.results;
        },
        err => {
          this.loading = false;
          this.error = true;
          this.results = [];
          this.LoggerService.error(err, { query });
          return this.results;
        },
      );
    };

    this.loadNextPage = body => {
      this.loading = false;
      this.results = this.results.concat(_.map(body.hits.hits, '_source'));
      this.moreResults = this.results.length < this.total;
    };

    this.scrollContentExpired = error => {
      this.loading = false;
      if (error.status === 404) {
        this.query(this.lastQuery, {
          size: this.results.length + parseInt($attrs.size, 10),
        }); // the scroll context expired
      } else {
        this.results = [];
      }
    };

    this.scroll = () => {
      if (!$attrs.scroll) {
        this.Rollbar.error('Missing om-search[scroll]');
      }
      if (this.moreResults) {
        const params = { scrollId: this.scrollId };
        params.scroll = $attrs.scroll;
        this.loading = true;
        $search
          .scroll(params)
          .then(this.loadNextPage, this.scrollContentExpired);
      }
    };

    $scope.filter = filter => this.query(this.lastQuery, { filter });

    if ($attrs.refreshOn) {
      $rootScope.$on($attrs.refreshOn, () => this.query(this.lastQuery));
    }

    if ($attrs.autosearch != null) {
      this.query();
    }
  }
}

omSearchController.$inject = [
  '$scope',
  '$attrs',
  '$parse',
  '$search',
  '$rootScope',
  'Rollbar',
  'LoggerService',
];

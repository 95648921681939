import { getPatientIdFromUrl } from '../upgrade/upgrade-param-mapper';

const appointmentPath = '/v2/admin/appointments';

export class AppointmentService {
  constructor(ApiService, $stateParams) {
    this.ApiService = ApiService;
    this.$stateParams = $stateParams;
  }

  future() {
    const patientId = this.$stateParams.patientId || getPatientIdFromUrl();
    const params = { patient_id: patientId, scope: 'future' };
    return this.ApiService.get(appointmentPath, { params });
  }
}

AppointmentService.$inject = ['ApiService', '$stateParams'];

class ActiveOrdersListController {
  activeOrdersAreEmpty() {
    return this.activeOrders.length === 0;
  }
}

ActiveOrdersListController.$inject = [];

export const omActiveOrdersList = {
  bindings: {
    activeOrders: '<',
  },
  controller: ActiveOrdersListController,
  templateUrl: 'orders/active-orders-list.component.html',
};

class FillAfterDate {
  constructor() {
    this.inputName = 'fillAfterDateField';
    this.medication = {};
  }
  $onInit() {
    this.inputName = this.medication.earliest_fill_date_input_name;
    this.setValidations();
    if (this.recalculateDates) this.recalculateDates();
  }
  setValidations() {
    if (this.medication.earliest_fill_date)
      this.earliestFillDate = new Date(this.medication.earliest_fill_date);

    if (this.medication.min_earliest_fill_date)
      this.minimumFillDate = new Date(this.medication.min_earliest_fill_date);

    if (this.medication.max_earliest_fill_date)
      this.maximumFillDate = this.medication.max_earliest_fill_date;

    this.isRequired = this.medication.dispensable_restricted_controlled_substance;
  }
  setEarliestFillDate() {
    this.medication.earliest_fill_date = this.earliestFillDate;
    if (this.recalculateDates) this.recalculateDates();
  }
}

export const omFillAfterDate = {
  bindings: {
    formError: '<',
    medication: '<',
    recalculateDates: '&',
  },
  controller: FillAfterDate,
  templateUrl: 'shared/fill-after-date.component.html',
};

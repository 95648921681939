import { omCollapse } from './collapse.directive';
import { omCollapseCollapse } from './collapse-collapse.directive';
import { omCollapseExpand } from './collapse-expand.directive';
import { omCollapseToggle } from './collapse-toggle.directive';
import { omCollapsed } from './collapsed.directive';
import { omExpanded } from './expanded.directive';

angular
  .module('onelifeUi.shared.collapse', [])
  .directive('omCollapse', omCollapse)
  .directive('omCollapseToggle', omCollapseToggle)
  .directive('omCollapseExpand', omCollapseExpand)
  .directive('omCollapseCollapse', omCollapseCollapse)
  .directive('omCollapsed', omCollapsed)
  .directive('omExpanded', omExpanded);

export class ProblemsFormatterService {
  shortenDescriptions({ listOfValues, numOfChars }) {
    let ending = '';
    let numberOfMore = 0;
    const total = (totalLength, value) => totalLength + value.length;

    while (
      listOfValues.length > 1 &&
      listOfValues.reduce(total, 0) + ending.length > numOfChars
    ) {
      listOfValues.pop();
      numberOfMore += 1;
      ending = `, ${numberOfMore} more`;
    }

    const limit = numOfChars - ending.length;

    if (listOfValues[0] && listOfValues[0].length > numOfChars) {
      listOfValues[0] = `${listOfValues[0].substring(0, limit)}..`;
    }

    return `${listOfValues.join(', ')}${ending}`;
  }
}

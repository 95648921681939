class PDFViewerController {
  constructor(S3, $element) {
    this.loading = true;
    this.S3 = S3;
    this.$element = $element;
  }
  $onInit() {
    this.S3.getURL(this.key, this.bucket).then((resolved) => {
      const frame = angular.element('<iframe></iframe>');
      const pdfWithViewParameters = `${resolved}#view=FitH`;
      frame[0].setAttribute('src', pdfWithViewParameters);
      this.$element.append(frame);

      frame.on('load', () => {
        this.loading = false;
        frame.blur();
      });
    });
  }
}

PDFViewerController.$inject = ['S3', '$element'];

export const omPdfViewer = {
  controller: PDFViewerController,
  bindings: {
    key: '<',
    bucket: '<',
  },
  template: '<div class="om-item" ng-if="loading">Loading...</div>',
};

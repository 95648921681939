export function omPhoneNumberValidator() {
  return {
    restrict: 'AE',
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
      ctrl.$validators.phoneNumberValidator = modelValue => {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }

        const nonDigitRegEx = /\D/g;
        const leadingOneRegEx = /^1/g;
        let phoneNum = modelValue;

        // Remove any non-numeric characters
        phoneNum = phoneNum.replace(nonDigitRegEx, '');
        // strip out leading 1 for country code
        phoneNum = phoneNum.replace(leadingOneRegEx, '');

        return phoneNum.length === 10;
      };
    },
  };
}

class LabelEditor {
  constructor(LabelPrinterService, $filter, LoggerService) {
    this.LabelPrinterService = LabelPrinterService;
    this.$filter = $filter;
    this.LoggerService = LoggerService;
    this.copies = 1;
    this.labelAddonText = '';
    this.tubeInventory = '';
  }

  $onInit() {
    this.LabelPrinterService.loadPrinter();
    this.setPatientDemographics();
    this.inferCopies();
  }

  $onChanges({ tubeInventory }) {
    if (tubeInventory && !tubeInventory.isFirstChange()) {
      this.tubeInventory = angular.copy(tubeInventory.currentValue);
      this.inferCopies();
    }
  }

  setPatientDemographics() {
    this.patientDOB = this.$filter('omDate4y')(this.patient.dob);
    this.labelText = `${this.patient.first_name} ${
      this.patient.last_name
    }\nDOB: ${this.patientDOB}`;
  }

  inferCopies() {
    const sumOfInventory = this.tubeInventory
      ? this.tubeInventory
          .match(/\d?/g)
          .map(Number)
          .reduce((sum, amount) => sum + amount, 0)
      : 1;

    this.copies = sumOfInventory;
  }

  fullLabelText() {
    return `${this.labelText}\n${this.labelAddonText}`;
  }

  print() {
    const text = this.fullLabelText();
    return this.LabelPrinterService.print(text, this.copies).then(
      success => {
        this.copies = 1;
        this.LoggerService.info(success);
      },
      ({ message: errorMessage }) => this.LoggerService.info(errorMessage),
    );
  }
}

export const omLabelEditor = {
  bindings: {
    tubeInventory: '<',
    patient: '<',
  },
  controller: LabelEditor,
  templateUrl: 'orders/shared/label-editor.component.html',
};

LabelEditor.$inject = ['LabelPrinterService', '$filter', 'LoggerService'];

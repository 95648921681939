const serverSideEncryption = 'AES256';

export class S3 {
  constructor(AwsSession, ENV, $q) {
    this.AwsSession = AwsSession;
    this.ENV = ENV;
    this.$q = $q;
  }

  getURL(key, bucket) {
    bucket = bucket || this.ENV.awsBucket;
    const deferred = this.$q.defer();
    this.getS3Client().then(s3 => {
      s3.getSignedUrl('getObject', { Bucket: bucket, Key: key }, (err, url) => {
        if (err) {
          return deferred.reject(err);
        }
        return deferred.resolve(url);
      });
    });
    return deferred.promise;
  }

  copy(sourceKey, sourceBucket, destKey) {
    const bucket = this.ENV.awsBucket;
    const deferred = this.$q.defer();
    this.getS3Client().then(s3 => {
      s3.copyObject(
        {
          Bucket: bucket,
          Key: destKey,
          CopySource: `/${sourceBucket}/${sourceKey}`,
          ServerSideEncryption: serverSideEncryption,
        },
        err => {
          if (err) {
            return deferred.reject(err);
          }
          return deferred.resolve({ Bucket: bucket, Key: destKey });
        },
      );
    });
    return deferred.promise;
  }

  delete(key, bucket) {
    const deferred = this.$q.defer();
    this.getS3Client().then(s3 => {
      s3.deleteObject({ Bucket: bucket, Key: key }, (err, data) => {
        if (err) {
          return deferred.reject(err);
        }
        return deferred.resolve(data);
      });
    });
    return deferred.promise;
  }

  upload(key, file) {
    const params = {
      Bucket: this.ENV.awsBucket,
      Key: key,
      ContentType: file.type,
      ServerSideEncryption: serverSideEncryption,
      Body: file,
    };
    return this.getS3Client().then(s3 => s3.upload(params).promise());
  }

  getS3Client() {
    if (this.sessionReq) {
      return this.sessionReq;
    }
    if (this.s3) {
      this.sessionReq = undefined;
      return this.$q.resolve(this.s3);
    }

    this.sessionReq = this.AwsSession.get().then(response => {
      // exposing s3 service makes testing easier
      this.s3 = new AWS.S3({
        accessKeyId: response.credentials.access_key_id,
        secretAccessKey: response.credentials.secret_access_key,
        sessionToken: response.credentials.session_token,
      });
      return this.s3;
    });
    return this.sessionReq;
  }
}

S3.$inject = ['AwsSession', 'ENV', '$q'];

export const omSubForm = () => ({
  require: 'omForm',
  restrict: 'A',
  link(scope, element, attrs, omFormCtrl) {
    if (!omFormCtrl.form) {
      return;
    }
    const parentForm = omFormCtrl.form.$$parentForm; // Note this uses private API
    if (!parentForm) {
      return;
    }

    // Remove this form from parent controller
    parentForm.$removeControl(omFormCtrl.form);
  },
});

export function configureToastr(toastrConfig) {
  angular.extend(toastrConfig, {
    allowHtml: true,
    maxOpened: 1,
    preventOpenDuplicates: true,
    tapToDismiss: false,
    toastClass: 'toast-banner',
    positionClass: 'toast-top-center',
  });
}

configureToastr.$inject = ['toastrConfig'];

export class LabelPrinterService {
  constructor(LoggerService, $window, $q) {
    this.LoggerService = LoggerService;
    this.$window = $window;
    this.$q = $q;
  }
  loadPrinter() {
    this.framework = this.$window.dymo.label.framework;

    const printers = this.framework.getPrinters();
    if (printers.length === 0) {
      this.printerLoaded = false;
    } else {
      this.printer = printers.find(
        printer => printer.printerType === 'LabelWriterPrinter',
      );
      this.printerLoaded = !!this.printer;
    }
  }
  createLabel() {
    const labelXML = `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips">
        <PaperOrientation>Landscape</PaperOrientation>
        <Id>ReturnAddress</Id>
        <PaperName>30330 Return Address</PaperName>
        <DrawCommands/>
        <ObjectInfo>
            <TextObject>
                <Name>Text</Name>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
                <LinkedObjectName></LinkedObjectName>
                <Rotation>Rotation0</Rotation>
                <IsMirrored>False</IsMirrored>
                <IsVariable>True</IsVariable>
                <HorizontalAlignment>Left</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <TextFitMode>ShrinkToFit</TextFitMode>
                <UseFullFontHeight>True</UseFullFontHeight>
                <Verticalized>False</Verticalized>
                <StyledText/>
            </TextObject>
            <Bounds X="326" Y="165" Width="2242" Height="795" />
        </ObjectInfo>
    </DieCutLabel>`;

    return this.framework.openLabelXml(labelXML);
  }

  setText(text) {
    this.label = this.createLabel();
    this.label.setObjectText('Text', text);
  }

  print(text, count = 1) {
    this.setText(text);
    return this.$q((resolve, reject) => {
      if (this.printerLoaded) {
        while (count > 0) {
          this.label.print(this.printer.name);
          count -= 1;
        }
        const success = 'Labels sent to Dymo printer';

        resolve(success);
      } else {
        const error =
          'Dymo printer could not be found. Please reconnect and try again.';

        reject(new Error(error));
      }
    });
  }
}

LabelPrinterService.$inject = ['LoggerService', '$window', '$q'];
